import { Box } from "@mui/material"
import React, { useState } from "react"
import { ReactWidget } from "../../Interfaces"
import { addConfigToStyle } from "../../../../helpers/Helpers";
import { generateMarginString } from "../../../events/event/website/components/widgets/options/MarginSelector";

const VideoWidget = ({ widget }: ReactWidget) => {
    const config = widget.config || {};
    const styles: any = {
        maxWidth: '100%', 
        Height: 'auto'
    }
    const parentStyles: any = {};
    const width = config['width'];
    if (width) {
        styles.width = width;
    }

    const margin = config['margin'];
    if (margin) {
        parentStyles.margin = generateMarginString(margin);
    }

    addConfigToStyle('textAlign', config, parentStyles);

    if (widget.config.videoSrc !== '' && widget.config.videoThumbSrc !== '') {
        return <YoutubeEmbed youtubeLink={widget.config.videoSrc} />
    } else {
        return <Box sx={styles}>{widget.type}</Box>
    }
}

const YoutubeEmbed = (props: { youtubeLink: string }) => {
    const { youtubeLink } = props;

    const generateYoutubeEmbedLink = (youtubeLink: string) => {
      const watchLinkRegex = /^https:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)$/;
      if (watchLinkRegex.test(youtubeLink)) {
        const videoId = youtubeLink.split("=")[1];
        return `https://www.youtube-nocookie.com/embed/${videoId}`;
      }

      //default rick roll :/
      return 'https://www.youtube-nocookie.com/embed/dQw4w9WgXcQ';
    };

    return (
        <iframe
            style={{
                width: '100%',
                aspectRatio: '16 / 9',
                border: 0,
            }}
            src={generateYoutubeEmbedLink(youtubeLink)}
            frameBorder="0"
            allowFullScreen={true}
        ></iframe>
    );
};



export default VideoWidget;;

