import { Box, Typography } from "@mui/material"
import React from "react"
import { ReactWidget, ReactWidgetWithStyles } from "../../Interfaces";
import withStandardStyles from "./withStandardStyles";


const ParagraphWidget = ({ widget, standardStyles }: ReactWidgetWithStyles) => {
    const config = widget.config || {};
    const textAlign = config['textAlign'];
    const fontSize = config['fontSize'];

    const styles: any = {};
    if (textAlign) {
        styles.textAlign = textAlign;
    }

    if (fontSize) {
        styles.fontSize = fontSize;
    }


    //lineheight always 1.5 times fontSize
    if (fontSize) {
        const match = fontSize.match(/^(\d+)(\D+)/);
        if (match) {
            styles.lineHeight = (match[1] * 1.5) + match[2];
        }

    }

    if (widget.translations[widget.defaultLanguage] && widget.translations[widget.defaultLanguage].content) {
        return (
            <Typography component="div" sx={{ ...styles, ...standardStyles }} variant="body1" color="primary" mb={2} dangerouslySetInnerHTML={{ __html: widget.translations[widget.defaultLanguage].content || '' }}></Typography>
        );
    } else {
        return null;
    }
}

export default withStandardStyles(ParagraphWidget);