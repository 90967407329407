import { Box, Button, IconButton, List, ListItem, Paper, ThemeProvider, createTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useRevalidator, useRouteLoaderData } from "react-router-dom";
import { ContextParams, EventRouterResponse, NewWidget, SidePanelConfig, WebpageRecord, WebpageRouterResponse, WidgetRecord } from "../../../../interfaces/Interfaces";
import RegistrationForm from "./RegistrationForm";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import { getTranslations } from "../../../../translations/Translations";
import ImprovedChoiceDialog from "../../../../components/ImprovedDialog";
import EditIcon from "@mui/icons-material/Edit";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentSidePanel from "./components/ContentSidePanel";
import WidgetField, { getDefaultJsonConfig, widgettypes } from "./components/WidgetField";
import './Webpage.scss';
import { isDroppableSignal } from "../../../../DroppableSignal";
import { useSignalEffect } from "@preact/signals-react";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { createThemeObjectFromConfig } from "../../../../helpers/Helpers";
import { Settings } from "@mui/icons-material";


export const WidgetContainer = ({
  widget,
  editWidget,
  removeWidget,
  provided,
  index,
  isActiveWidget,
  isBeingDragged,
  widgetChildren,
  isDraggable,
  onResortFn,
  firstChild,
  lastChild,
  nestedDropFn,
  allWidgets }: {
    widget: WidgetRecord,
    editWidget: Function,
    removeWidget: Function,
    provided: any,
    index: number,
    isActiveWidget: Boolean,
    isBeingDragged: Boolean,
    widgetChildren?: WidgetRecord[],
    isDraggable: boolean,
    onResortFn?: Function,
    firstChild: boolean,
    lastChild: boolean,
    nestedDropFn?: Function,
    allWidgets?: WidgetRecord[]
  }) => {

  const [isHovered, setIsHovered] = useState(false);

  const containerClasses = `widget-container${!isBeingDragged && isHovered ? ' hovered' : ''}${!isBeingDragged && isActiveWidget ? ' active-widget' : ''}`;

  return (

    <Box
      className={containerClasses}
      sx={{ position: 'relative' }}
      onMouseEnter={() => !isBeingDragged && setIsHovered(true)}
      onMouseLeave={() => !isBeingDragged && setIsHovered(false)}
    >
      <Box
        className="widget-container-controls"
        sx={{
          display: (!isBeingDragged && (isHovered || isActiveWidget)) ? 'block' : 'none',
          opacity: (!isBeingDragged && (isHovered || isActiveWidget)) ? 1 : 0
        }}>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            editWidget(widget);
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            removeWidget(widget);
          }}
        >
          <DeleteIcon />
        </IconButton>

        <IconButton sx={{ opacity: isDraggable ? 1 : 0, display: isDraggable ? 'inline-flex' : 'none' }}{...provided.dragHandleProps}>
          <DragIndicatorIcon />
        </IconButton>
        {onResortFn ? <><IconButton disabled={firstChild} onClick={() => {
          onResortFn('up', widget)
        }}><ArrowUpwardIcon /></IconButton><IconButton disabled={lastChild} onClick={() => {
          onResortFn('down', widget)
        }}><ArrowDownwardIcon /></IconButton></> : null}
      </Box>
      <Box
        className="widget-container-widget"
        key={index}
        ref={provided.innerRef}
        {...provided.draggableProps}
        onClick={(e: React.SyntheticEvent) => {
          e.stopPropagation();
          editWidget(widget);
        }}
      >
        <WidgetField sx={{ flex: 1 }} widget={widget} widgetChildren={widgetChildren} childWidgetFunctions={{ edit: editWidget, remove: removeWidget, sortFn: onResortFn, nestedDropFn: nestedDropFn }}></WidgetField>
      </Box>
    </Box>
  );
}


const DroppableForm = ({ webpage, isDragging, sidePanelConfig, setSidePanelConfig, reorderFunctions }: {
  webpage: WebpageRecord, sidePanelConfig: SidePanelConfig, setSidePanelConfig: Function, isDragging: Boolean, reorderFunctions: {
    reorderWidgets: Function,
    widgetReorderRequest: Function
  }
}) => {
  const translations = getTranslations();
  const { reorderWidgets, widgetReorderRequest } = reorderFunctions;
  const [contextParams, setContextParams] = useOutletContext<[ContextParams, Function]>();
  const [isDroppableState, setIsDroppableState] = useState<boolean>(isDroppableSignal.peek());
  const [scrollTop, setScrollTop] = useState<number>(0);
  
  let webpageConfig = JSON.parse(webpage.jsonConfig);

  useSignalEffect(() => {
    setIsDroppableState(isDroppableSignal.value);
  })

  const resetSidePanelWidget = () => {
    setSidePanelConfig((prev: SidePanelConfig) => ({ ...prev, activeWidget: null, activeWebpage: null }))
  }

  const removeWidgetPromise = (id: number): Promise<Response> => {
    return fetch("/api/widgets/" + id, {
      method: "delete",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("jwt"),
        "Content-Type": "application/json",
      },
    });
  };

  const handlePageSettingsOnClick = () => {
    setSidePanelConfig((prev: SidePanelConfig) => {
      return {
        ...prev,
        activeWebpage: webpage,
        activeWidget: null
      }
    })
  }

  const removeWidget = (widget: WidgetRecord) => {
    const id = widget.id;

    let parentId = widget.parentId !== null ? widget.parentId : undefined;
    let column = widget.config.column;
    let idsToRemove: number[] = [id];

    const findChildIds = (myParentId: number, widgets: WidgetRecord[], allIds: number[]) => {
      const childrenArray = widgets.filter(widget => widget.parentId === myParentId);
      childrenArray.forEach(childWidget => {
        allIds.push(childWidget.id);
        findChildIds(childWidget.id, widgets, allIds);
      });
    }

    findChildIds(widget.id, webpage.widgets, idsToRemove);

    console.log('idsToRemove', idsToRemove)

    let promises: Promise<Response>[] = [];

    if (sidePanelConfig.activeWidget && sidePanelConfig.activeWidget.id === id) {
      resetSidePanelWidget();
    }

    idsToRemove.forEach((id: number) => {
      promises.push(removeWidgetPromise(id));
    });

    Promise.all(promises).then(() => {
      reorderAfterRemoval(id, parentId, column)
    });
  }

  const onResortFn = (direction: 'up' | 'down', widget: WidgetRecord) => {
    let targetSortOrder;
    let sortOrder = widget.sortorder;
    if (direction === 'up') {
      if (widget.parentId) {
        targetSortOrder = widget.config.sortorder - 1;
      } else {
        targetSortOrder = widget.sortorder - 1;
      }
    } else { // Assuming 'down' direction
      if (widget.parentId) {
        targetSortOrder = widget.config.sortorder + 1;
      } else {
        targetSortOrder = widget.sortorder + 1;
      }
    }

    const parentId = widget.parentId ? widget.parentId : undefined;
    const column = (widget.parentId && typeof widget.config.column !== 'undefined') ? widget.config.column : undefined;

    if (parentId && typeof column !== null) {
      sortOrder = widget.config.sortorder;
    }
    reorderWidgets(targetSortOrder, sortOrder, parentId, column);
  }

  const editWidget = (widget: WidgetRecord) => {
    if (widget.type === 'registrationform') {
      //fix as sidepanelConfig nec.
      setContextParams((prev: ContextParams) => {
        return {
          ...prev,
          activeWidget: widget
        }
      })
    } else {
      setSidePanelConfig((prev: SidePanelConfig) => {
        return {
          ...prev,
          activeWebpage: null,
          activeWidget: widget
        }
      })
    }
  }


  const reorderAfterRemoval = (removedId: number, parentId?: number, column?: number) => {
    let widgets: WidgetRecord[] = [...webpage.widgets];
    if (parentId) {
      widgets = widgets.filter((widget) => widget.parentId === parentId && widget.config.column === column && widget.id !== removedId).sort((a: WidgetRecord, b: WidgetRecord) => a.config.sortorder - b.config.sortorder);;
    } else {
      widgets = widgets.filter((widget) => widget.parentId !== null && widget.id !== removedId).sort((a: WidgetRecord, b: WidgetRecord) => a.sortorder - b.sortorder);
    }
    widgetReorderRequest(widgets, parentId !== undefined && column !== undefined);
  }

  const sortedByParentIdWidgets: any[] = [];
  const nestedIds: number[] = [];

  webpage.widgets.forEach((widget: WidgetRecord) => {
    if (widget.parentId) {
      nestedIds.push(widget.id);
      const rec = sortedByParentIdWidgets.find((rec) => rec.id === widget.parentId);
      if (rec) {
        rec.children = [...rec.children, widget];
      } else {
        sortedByParentIdWidgets.push({
          id: widget.parentId,
          children: [widget]
        })
      }
    }
  })

  return (

    <Droppable droppableId="form" isDropDisabled={isDroppableState}>
      {(dropProvided, snapshot) => {
        return (
          <Box
            flex="1"
            overflow="hidden"
            bgcolor={"#AAA"}
            className="whatsthis"
          >

            <Box
              {...dropProvided.droppableProps}
              ref={dropProvided.innerRef}
              id="main-drop-box"
              className={isDragging ? 'being-dragged' : ''}
              height="100%"
              width="80%"
              ml="auto"
              mr="auto"
              bgcolor={"#FFF"}
              sx={{ boxSizing: 'border-box', backgroundColor: (webpageConfig && webpageConfig.backgroundColor) ?  webpageConfig.backgroundColor : 'white'}}
              p={2}
              overflow="scroll"
            >
              <Box display='flex' justifyContent={'flex-end'}><Paper sx={{backgroundColor: 'white', opacity: '.8', color: 'black', padding: '0.5rem'}}>{translations['website.webpage.settings']}<IconButton onClick={handlePageSettingsOnClick}><Settings /></IconButton></Paper></Box>
              {webpage.widgets.filter((widget: WidgetRecord) => widget.parentId === null).map((widget: WidgetRecord, index: number, filteredWidgets: WidgetRecord[]) => <Draggable
                key={widget.id}
                draggableId={widget.id.toString()}
                index={index}
              >{(provided, draggableSnapshot) => {
                const children = sortedByParentIdWidgets.find((rec) => rec.id === widget.id);
                const widgetConfig = widgettypes.find(myWidget => myWidget.type === widget.type);
                const isDraggable = widgetConfig?.draggable ?? true;

                if (children) {
                  return (
                    <WidgetContainer
                      widgetChildren={children.children}
                      isBeingDragged={snapshot.isDraggingOver}
                      isActiveWidget={sidePanelConfig.activeWidget?.id === widget.id}
                      isDraggable={isDraggable}
                      widget={widget}
                      editWidget={(widget: WidgetRecord) => {
                        editWidget(widget);
                      }} removeWidget={(widget: WidgetRecord) => {
                        removeWidget(widget);
                      }} provided={provided} index={0}
                      onResortFn={onResortFn}
                      firstChild={index === 0}
                      lastChild={index === filteredWidgets.length - 1}
                    />

                  )
                } else {
                  return (
                    <WidgetContainer
                      isBeingDragged={snapshot.isDraggingOver}
                      isActiveWidget={sidePanelConfig.activeWidget?.id === widget.id}
                      widget={widget}
                      isDraggable={isDraggable}
                      onResortFn={onResortFn}
                      editWidget={() => {
                        editWidget(widget);
                      }} removeWidget={() => {
                        removeWidget(widget);
                      }} provided={provided} index={0}
                      firstChild={index === 0}
                      lastChild={index === filteredWidgets.length - 1}
                    />
                  )
                }



              }}
              </Draggable>)}
              {dropProvided.placeholder}
            </Box>
          </Box>
        )
      }}
    </Droppable>
  )
}

function Webpage() {
  const translations = getTranslations();
  const revalidator = useRevalidator();
  const [contextParams, setContextParams] = useOutletContext<[ContextParams, Function]>();
  const [sidePanelConfig, setSidePanelConfig] = useState<SidePanelConfig>({
    activeWidget: null
  })
  const navFn = useNavigate();
  const [isDragging, setIsDragging] = useState<boolean>(false);
  useEffect(() => {
    console.log('tab change');

    resetActiveWidget();
  }, [contextParams.currentTab])
  const { event } = useRouteLoaderData("event") as EventRouterResponse;
  const { webpage } = useRouteLoaderData(
    "webpage"
  ) as WebpageRouterResponse;

  const theme = createTheme(createThemeObjectFromConfig(event.jsonConfig));


  const resetActiveWidget = () => {
    setContextParams((prev: ContextParams) => {
      return {
        ...prev,
        activeWidget: null
      }
    })

    setSidePanelConfig((prev) => ({
      ...prev,
      activeWidget: null,
      activeWebpage:  null
    }))
  }

  function DraggableSetupItem(props: any) {
    return (
      <Draggable
        key={props.widgettype}
        draggableId={props.widgettype}
        index={props.index}
        disableInteractiveElementBlocking={true}
      >
        {(provided, snapshot) => {
          return (<ListItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            sx={{ padding: 0 }}
            data-widgettype={props.widgettype}
          >
            <Box flex={1} p={1}>
              {props.children}
            </Box>
          </ListItem>)
        }}
      </Draggable>
    );
  }

  const handleOnWidgetBack = () => {
    setContextParams((prev: ContextParams) => { return { ...prev, showWarningModal: true } })
  }

  const addWidget = (result: DropResult, type: string) => {
   
    if (result.destination) {
      //Add slider support in nested items later ...
      if (type === 'slider') {
        if (result.destination.droppableId !== 'form') {
          //show warning
          alert(translations["event.website.notSupportedYet"]);
          return null;
        }
      }
      let parentWidgetId: number | null = null;
      let columnId: number | null = null;
      const newIndex = result.destination.index;
      const newWidget: NewWidget = {
        webpageId: webpage.id,
        defaultLanguage: "de",
        language: "de",
        eventId: webpage.eventId,
        type: type,
        sortorder: 0
      };

      const defaultJsonConfig = getDefaultJsonConfig(type);

      if (Object.keys(defaultJsonConfig).length > 0) {
        newWidget.jsonConfig = JSON.stringify(defaultJsonConfig)
      }

      //fetch to add widget
      if (result && result.destination?.droppableId && result.destination.droppableId !== 'form') {
        [parentWidgetId, columnId] = result.destination.droppableId.substring(12).split("c").map(Number);
        newWidget.parentId = parentWidgetId;
        newWidget.jsonConfig = JSON.stringify({
          ...getDefaultJsonConfig(type),
          "column": columnId,
          "sortorder": result.destination.index
        })
      }
      //(newIndex: number, oldIndex: number, parentId?: number, column?: number) 
      fetch("/api/widgets", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("jwt"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newWidget)
      }).then((res) => res.json())
        .then((jsondata) => {
          if (jsondata.success) {
            const freshWidget: WidgetRecord = jsondata.data;
            if (freshWidget.parentId && columnId) {
              reorderWithNewWidget(newIndex, freshWidget, freshWidget.parentId, columnId);
            } else {
              reorderWithNewWidget(newIndex, freshWidget);
            }
          }
        })
    }
  }

  const reorderWithNewWidget = (newIndex: number, newWidget: WidgetRecord, parentId?: number, column?: number) => {
    let widgets: WidgetRecord[] = webpage.widgets;
    if (parentId !== undefined && column !== undefined) {
      widgets = widgets.filter((widget: WidgetRecord) => widget.parentId === parentId && widget.config.column === column);
    }
    widgets.sort((a: WidgetRecord, b: WidgetRecord) => a.config.sortorder - b.config.sortorder);
    widgets.splice(newIndex, 0, newWidget);
    widgetReorderRequest(widgets, parentId !== undefined && column !== undefined);
  };


  const moveFromColumnToForm = (draggableId: string, newIndex: number) => {
    let myWidgetArr: WidgetRecord[] = [...webpage.widgets].filter((widget: WidgetRecord) => widget.parentId === null);
    const draggedWidget: WidgetRecord | undefined = webpage.widgets.find((widget: WidgetRecord) => widget.id === Number(draggableId));

    if (draggedWidget) {
      delete draggedWidget.config.column;
      delete draggedWidget.config.sortorder;
      draggedWidget.parentId = 0;
      myWidgetArr.splice(newIndex, 0, draggedWidget);
      widgetReorderRequest(myWidgetArr, false)
    }
  }

  const moveFromFormToColumn = (draggableId: string, destination: any) => {
    const [parentWidgetId, parentColumnId] = destination.droppableId.substring(12).split("c").map(Number);
    let myWidgetArr: WidgetRecord[] = [...webpage.widgets].filter((widget: WidgetRecord) => widget.parentId === parentWidgetId && widget.config.column === parentColumnId).sort((a: WidgetRecord, b: WidgetRecord) => a.config.sortorder - b.config.sortorder);
    const draggedWidget: WidgetRecord | undefined = webpage.widgets.find((widget: WidgetRecord) => widget.id === Number(draggableId));
    if (draggedWidget) {
      draggedWidget.config = draggedWidget.config ? { ...draggedWidget.config, column: parentColumnId, sortorder: 0 } : { column: parentColumnId, sortorder: 0 };
      draggedWidget.parentId = parentWidgetId;
      myWidgetArr.splice(destination.index, 0, draggedWidget);
      widgetReorderRequest(myWidgetArr, true)
    }
  }

  const reorderWidgets = (newIndex: number, oldIndex: number, parentId?: number, column?: number) => {
    let widgets: WidgetRecord[] = webpage.widgets;
    if (typeof parentId !== 'undefined' && typeof column !== 'undefined') {
      widgets = widgets.filter((widget: WidgetRecord) => widget.parentId === parentId && widget.config.column === column).sort((a: WidgetRecord, b: WidgetRecord) => a.config.sortorder - b.config.sortorder);;
    } else {
      widgets = widgets.filter((widget: WidgetRecord) => widget.parentId === null).sort((a: WidgetRecord, b: WidgetRecord) => a.sortorder - b.sortorder);
    }

    const [movedWidget] = widgets.splice(oldIndex, 1);
    if (typeof movedWidget !== "undefined") {
      widgets.splice(newIndex, 0, movedWidget);
    } else {
      console.log('Problem with fetching widget ... ', oldIndex,);
    }

    widgetReorderRequest(widgets, parentId !== undefined && column !== undefined);
  };

  const onDragStart = () => {
    setIsDragging(true)
  }

  const onDragEnd = (result: any) => {
    console.log(result);
    setIsDragging(false);
    if (result.destination) {
      if (result.source.droppableId === 'list') {
        addWidget(result, result.draggableId)
      } else {
        //some reorder logic ...
        if (result.destination.droppableId === "form" && result.source.droppableId === "form") {
          reorderWidgets(result.destination.index, result.source.index)
        } else {
          if (result.source.droppableId.includes('droppable-id')) {
            if (result.destination.droppableId === "form") {
              moveFromColumnToForm(result.draggableId, result.destination.index);
            } else {
              moveWidget(result.draggableId, result.destination, result.source);
            }
          } else {
            moveFromFormToColumn(result.draggableId, result.destination);
          }
        }
      }
    }
  }

  const widgetReorderRequest = (widgets: WidgetRecord[], config: boolean) => {
    let promises: Promise<Response>[] = [];
    widgets.forEach((widget, index) => {
      if (config) {
        if (typeof widget.config.sortorder !== undefined) {
          widget.config.sortorder = index;
        }
      } else {
        widget.sortorder = index;
      }
      promises.push(
        fetch("/api/widgets/" + widget.id, {
          method: "put",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("jwt"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...widget,
            jsonConfig: JSON.stringify(widget.config)
          }),
        })
      );
    });
    Promise.all(promises).then(() => {
      revalidator.revalidate();
    });
  }

  const moveWidget = (draggableId: number, destination: any, source: any) => {
    let widgets: WidgetRecord[] = webpage.widgets;
    let draggedWidget = widgets.find((widget) => widget.id === Number(draggableId));
    if (draggedWidget) {
      const [parentId, newColumn]: [number, number] = destination.droppableId.split('c').map((part: string, index: number) => index === 0 ? Number(part.replace('droppable-id', '')) : Number(part));

      if (source.droppableId === destination.droppableId) {
        reorderWidgets(destination.index, source.index, parentId, newColumn);
      } else {
        addWidgetToColumn(destination.index, parentId, newColumn, draggedWidget);
      }
    }
  }

  const addWidgetToColumn = (newIndex: number, parentId: number, column: number, newWidget: WidgetRecord,) => {
    let widgets: WidgetRecord[] = webpage.widgets.filter((widget: WidgetRecord) => widget.parentId === parentId && widget.config.column === column).sort((a: WidgetRecord, b: WidgetRecord) => a.config.sortorder - b.config.sortorder);

    newWidget.parentId = parentId;
    newWidget.config.column = column;

    widgets.splice(newIndex, 0, newWidget);

    widgetReorderRequest(widgets, true);
  }




  return (
    <>

      {contextParams.activeWidget === null ? (
        <Box flex={1} display="flex" flexDirection="column" overflow="hidden">
          <Box flex={1} display="flex" flexDirection="row" overflow="hidden">
            <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
              <Box width="200px" overflow="scroll" className="list">
                <Droppable droppableId="list" isDropDisabled={true}>
                  {(provided, draggableSnapshot) => (
                    <List
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="widgettypelist"
                    >
                      {widgettypes.map((type, index) => {
                        return (
                          <DraggableSetupItem
                            widgettype={type.type}
                            key={type.type}
                            index={index}
                          >
                            {type.listElement}
                          </DraggableSetupItem>
                        )
                      })}
                      {provided.placeholder}
                    </List>
                  )}
                </Droppable>
              </Box>
              <ThemeProvider theme={theme}>
                <DroppableForm webpage={webpage} isDragging={isDragging} sidePanelConfig={sidePanelConfig} setSidePanelConfig={setSidePanelConfig} reorderFunctions={{
                  reorderWidgets: reorderWidgets,
                  widgetReorderRequest: widgetReorderRequest
                }} />
              </ThemeProvider>
              <ContentSidePanel config={sidePanelConfig} setConfig={setSidePanelConfig} ></ContentSidePanel>
            </DragDropContext>
          </Box>
        </Box>
      ) : null}
      <ImprovedChoiceDialog
        open={contextParams.showWarningModal}
        title={translations["website.widget.changes"]}
        description={translations["website.widget.changesDescription"]}
      >
        {() => (
          <>
            <Button
              variant="contained"
              onClick={() => {
                //Save first ...  
                //check if target tab ... if so ... navgiaget to it and reset
                if (contextParams.pressedTab) {
                  navFn(contextParams.pressedTab.pathname, {
                    replace: false
                  });
                }
                setContextParams((prev: ContextParams) => { return { ...prev, activeWidget: null, showWarningModal: false, pressedTab: null } })
              }}
            >
              {translations["save"]}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                //reset widget and close modal
                if (contextParams.pressedTab) {
                  navFn(contextParams.pressedTab.pathname, {
                    replace: false
                  });
                }
                setContextParams((prev: ContextParams) => { return { ...prev, activeWidget: null, showWarningModal: false, pressedTab: null } })
              }}
            >
              {translations["withoutSave"]}
            </Button>
            <Button
              onClick={() => {
                //reset widget and close modal
                setContextParams((prev: ContextParams) => { return { ...prev, showWarningModal: false, pressedTab: null } })
              }}
            >
              {translations["cancel"]}
            </Button>
          </>
        )
        }
      </ImprovedChoiceDialog>
      {contextParams.activeWidget && contextParams.activeWidget.type === 'registrationform' && <RegistrationForm widget={contextParams.activeWidget} onBackClick={handleOnWidgetBack} />}

    </>
  )
}

export default Webpage;