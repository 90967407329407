import React from "react";
import { Container, Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

function MobileAdmin()  {

    const navFn = useNavigate();

    return (<Box
        component="main"
        sx={{
          backgroundColor: "#006e55",
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          minHeight: "100%",
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '1rem',
            paddingBottom: '0.5',
            margin: '1rem',
            borderRadius: "6px",
            backgroundColor: "#FFF",
            alignItems: 'center'
          }}
        >
          <Button
            variant="contained"
            size="large"
            startIcon={<QrCodeScannerIcon />}
            onClick={()=>{
                navFn("/scanner/", {
                    replace: false
                  });
            }}
          >
            Scan QR codes
          </Button>
        </Container>
      </Box>)
}

export default MobileAdmin;