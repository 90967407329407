import { Box, Typography, FormControl, TextField } from "@mui/material";
import React from "react";
import { DisplayErrors } from "../DisplayErrors";

interface NumberFieldProps {
    fieldName: string;
    formik: any;
}

function isIntegerOrFloat(value: string) {
    const numberValue = +value;
    return !isNaN(numberValue) && isFinite(numberValue);
}

const NumberField = ({ fieldName, formik }: NumberFieldProps) => {
    return <TextField
        size="small"
        {...formik.getFieldProps(fieldName)}
        onChange={(e) => {
            const value = e.target.value.trim();
            if (value === '' || isIntegerOrFloat(value)) {
                formik.setFieldValue(fieldName, value === '' ? 0 : value);
            }
        }}
    ></TextField>
}

export default NumberField;