import React from "react";
import {
  Box,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { getTranslations } from "../../../../translations/Translations";
import {
  EventRouterResponse,
  IRecord,
} from "../../../../interfaces/Interfaces";
import { useRevalidator, useRouteLoaderData } from "react-router-dom";
import { useFormik } from "formik";
import SaveBar from "../../../../components/SaveBar";
import UploadControl from "../../../../components/UploadControl";
import * as Yup from "yup";

function CateringTab() {
  const translations = getTranslations();
  const { event } = useRouteLoaderData("event") as EventRouterResponse;
  const revalidator = useRevalidator();

  const getFlatObj = (obj: IRecord) => {
    return {
      id: obj.id,
      cateringOrdered: obj.cateringOrdered,
      cateringDone: obj.cateringDone,
      cateringInfo: obj.cateringInfo || "",
      cateringFileId: obj.cateringFileId || "",
    };
  };

  const formik = useFormik({
    initialValues: getFlatObj(event),
    validationSchema: Yup.object({
      cateringOrdered: Yup.boolean(),
      cateringDone: Yup.boolean(),
      cateringInfo: Yup.string().notRequired(),
      cateringFileId: Yup.string().notRequired(),
    }),
    onSubmit: (values) => {
      fetch("/api/events/" + event.id + "/", {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("jwt"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
        .then((res) => res.json())
        .then((jsondata) => {
          if (jsondata.success) {
            revalidator.revalidate();
          }
        })
        .finally(() => {
          formik.setSubmitting(false);
          formik.resetForm({
            values: values,
          });
        });
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{ display: "flex", flexGrow: 1, overflow: "scroll" }}
    >
      <Box display="flex" flexDirection="column" position="relative" flex={1}>
        <Box display="flex" flexDirection="column" position="relative" flex={1}>
          <Box display="flex" flexDirection="row">
            <Box flex="1" display="flex" flexDirection="column" gap={1} p={2}>
              <Box display="flex" flexDirection={"row"}>
                <Box width="260px" flexShrink={0} className="fieldlabel">
                  <Typography>{translations["cateringtab.ordered"]}</Typography>
                </Box>
                <Box className="switchlabel">
                  {translations["cateringtab.orderedno"]}
                </Box>
                <Box flex={1}>
                  <FormControlLabel
                    control={
                      <Switch
                        name="cateringOrdered"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        checked={formik.values.cateringOrdered}
                      />
                    }
                    label={translations["cateringtab.orderedyes"]}
                  />
                </Box>
              </Box>
            </Box>
            <Box flex="1" display="flex" flexDirection="column" gap={1} p={2}>
              <Box display="flex" flexDirection={"row"}>
                <Box width="260px" flexShrink={0} className="fieldlabel">
                  <Typography>{translations["cateringtab.done"]}</Typography>
                </Box>
                <Box className="switchlabel">
                  {translations["cateringtab.doneno"]}
                </Box>
                <Box flex={1}>
                  <FormControlLabel
                    control={
                      <Switch
                        name="cateringDone"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        checked={formik.values.cateringDone}
                      />
                    }
                    label={translations["cateringtab.doneyes"]}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexDirection={"row"} p={2}>
            <Box width="260px" flexShrink={0} className="fieldlabel">
              <Typography>{translations["cateringtab.info"]}</Typography>
            </Box>
            <TextField
              fullWidth
              multiline={true}
              minRows={3}
              {...formik.getFieldProps("cateringInfo")}
            ></TextField>
          </Box>
          <Box display="flex" flexDirection={"row"} p={2}>
            <Box width="260px" flexShrink={0} className="fieldlabel">
              <Typography>{translations["cateringtab.orderform"]}</Typography>
            </Box>
            <UploadControl
              name="cateringFileId"
              purpose="catering"
              value={formik.values.cateringFileId}
              eventId={event.id}
              setFieldValue={formik.setFieldValue}
              onUploadComplete={(response) => {
                fetch("/api/events/" + event.id + "/", {
                  method: "PUT",
                  headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    id: event.id,
                    language: event.language,
                    defaultLanguage: event.defaultLanguage,
                    cateringFileId: response.publicId,
                  }),
                })
                  .then((res) => res.json())
                  .then((jsondata) => {
                    if (jsondata.success) {
                      revalidator.revalidate();
                    }
                  });
              }}
              onDeleteComplete={() => {
                fetch("/api/events/" + event.id + "/", {
                  method: "PUT",
                  headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    id: event.id,
                    language: event.language,
                    defaultLanguage: event.defaultLanguage,
                    cateringFileId: "",
                  }),
                })
                  .then((res) => res.json())
                  .then((jsondata) => {
                    if (jsondata.success) {
                      revalidator.revalidate();
                    }
                  });
              }}
            />
          </Box>
        </Box>
        <SaveBar formik={formik} />
      </Box>
    </form>
  );
}

export default CateringTab;
