import React, { useState } from "react";
import { getLanguage, getTranslations } from "../../translations/Translations";
import {
  Box,
  Button,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { Tag, TagsRouterResponse } from "../../interfaces/Interfaces";
import { useRevalidator, useRouteLoaderData } from "react-router-dom";
import TagForm from "./TagForm";
import { DirtyFormDialog } from "../../components/DirtyFormDialog";

function TagsTab() {
  const translations = getTranslations();
  const { tags } = useRouteLoaderData("tags") as TagsRouterResponse;
  const [tag, setTag] = useState<Tag | null>(tags[0]);
  const [nextTag, setNextTag] = useState<Tag | null>(null);
  const revalidator = useRevalidator();
  const [dirty, setDirty] = useState<Boolean>(false);
  const language = getLanguage();

  const newtag = () => {
    fetch("/api/tags", {
      method: "post",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("jwt"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        defaultLanguage: language,
        language: language,
        title: "Neue Tag",
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        let p: Tag = result.data;
        setTag(p);
        revalidator.revalidate();
      });
  };

  const getButtonClass = (id: number): string => {
    if (tag?.id === id) {
      return "cliclalistitembutton active";
    }
    return "cliclalistitembutton";
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      className="collectionedit"
      flex="1"
      overflow="hidden"
    >
      <Box width={"260px"} className="list">
        <Box p={1}>
          <Button variant="contained" onClick={newtag}>
            {translations["tagstab.add"]}
          </Button>
        </Box>
        <DirtyFormDialog
          open={nextTag !== null}
          continue={() => {
            setTag(nextTag);
            setNextTag(null);
          }}
          cancel={() => {
            setNextTag(null);
          }}
        />
        <List className="cliclalist">
          {tags.map((p: Tag, index: number) => {
            return (
              <ListItemButton
                key={p.id}
                className={getButtonClass(p.id)}
                onClick={() => {
                  if (dirty) {
                    setNextTag(p);
                  } else {
                    setTag(p);
                  }
                }}
              >
                <ListItemText
                  primary={p.translations[p.defaultLanguage].title}
                />
              </ListItemButton>
            );
          })}
        </List>
      </Box>
      {!tag ? (
        <Box flex="1" p={2}>
          <Typography>{translations["tagstab.empty"]}</Typography>
        </Box>
      ) : null}
      {!!tag ? (
        <TagForm
          key={tag.id}
          tag={tag}
          setDirty={setDirty}
          onDelete={() => {
            fetch("/api/tags/" + tag.id, {
              method: "delete",
              headers: {
                Accept: "application/json",
                Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                "Content-Type": "application/json",
              },
            }).then(() => {
              if (tag.id === tags[0].id) {
                setTag(tags[1]);
              } else {
                setTag(tags[0]);
              }
              revalidator.revalidate();
            });
          }}
        />
      ) : null}
    </Box>
  );
}

export default TagsTab;
