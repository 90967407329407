import React from "react";
import { Box, List, ListItem } from "@mui/material";
import {
  LocationRecord,
  ModuleRecord,
  SessionRecord,
} from "../../../../../interfaces/Interfaces";
import { getTranslations } from "../../../../../translations/Translations";
import { calculateTimeDifference } from "../../../../../helpers/DateFunctions";

const SessionInfo = (props: {
  session: SessionRecord | null;
  block: ModuleRecord | null;
  currentLocation: LocationRecord | undefined;
  defaultLanguage: string;
}) => {
  const translations = getTranslations();
  const { session, currentLocation, defaultLanguage, block } = props;
  if (session) {
    return (
      <Box>
        <Box mb={'1rem'}>{translations["scanner.session"]}: {session.translations[defaultLanguage].title}</Box>
        {(typeof block !== 'undefined' && block !== null) ? <Box mb={'1rem'}>{translations["scanner.module"]}: {block.translations[defaultLanguage].title}</Box>: null}
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          {session.startdate ? (
            <Box>
              {translations["scanner.date"]}: {session.startdate}
            </Box>
          ) : (
            <Box></Box>
          )}
          {session.starttime && session.endtime && (
            <Box>
              {translations["scanner.from"]} {session.starttime}{" "}
              {translations["scanner.until"]} {session.endtime}
            </Box>
          )}

          {session.starttime && !session.endtime && (
            <Box>
              {translations["scanner.from"]} {session.starttime}
            </Box>
          )}
        </Box>
        {session.starttime && session.endtime && (
          <Box>
            {translations["scanner.duration"]}:{" "}
            {calculateTimeDifference(session.starttime, session.endtime)}
          </Box>
        )}

        {session.locationId && currentLocation && (
          <Box>
            Ort:
            <List sx={{ paddingLeft: "1rem", paddingTop: "0" }}>
              <ListItem disablePadding>
                {currentLocation.translations[defaultLanguage].street}
              </ListItem>
              <ListItem disablePadding>
                {currentLocation.translations[defaultLanguage].postalcode}{" "}
                {currentLocation.translations[defaultLanguage].city}
              </ListItem>
              <ListItem disablePadding>
                {currentLocation.translations[defaultLanguage].country}
              </ListItem>
            </List>
          </Box>
        )}
        {session.translations.de.shortDescription && (
          <Box>
            Beschreibung:{" "}
            {session.translations[defaultLanguage].shortDescription}
          </Box>
        )}
        {/* WE NEED THIS INFO LATER ON */}
        {/*}<Box>
          Referent: Missing
        </Box>
        <Box>
          Format: Missing (Panel/Workshop/Keynote)
        </Box>*/}
      </Box>
    );
  } else {
    return null;
  }
};

export default SessionInfo;
