import React, { useEffect, useState } from "react";
import {
  getGridTranslations,
  getTranslations,
} from "../../translations/Translations";
import {
  DataGrid,
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
  GridRowParams,
} from "@mui/x-data-grid";
import { useRouteLoaderData, useSearchParams } from "react-router-dom";
import {
  ContextsRouterResponse,
  INewRecord,
  IRecord,
} from "../../interfaces/Interfaces";
import EditIcon from "@mui/icons-material/Edit";
import * as Yup from "yup";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useRevalidator } from "react-router-dom";
import { DisplayErrors } from "../../components/DisplayErrors";
import { Search } from "@mui/icons-material";
import useDebounce from "../../helpers/useDebounce";
import SaveBar from "../../components/SaveBar";

function Contexts() {
  const translations = getTranslations();
  const gridtranslations = getGridTranslations();
  const { contexts } = useRouteLoaderData("contexts") as ContextsRouterResponse;
  const [open, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce(search, 500);
  const [myDialogTitle, setMyDialogTitle] = useState<string>(
    translations["contexts.newcontexttitle"]
  );
  const revalidator = useRevalidator();
  const setSearchParams = useSearchParams()[1];

  const getFlatObj = (obj: IRecord) => {
    return {
      id: obj.id,
      name: obj.name || "",
      invoiceStreet: obj.invoiceStreet || "",
      invoicePostalcode: obj.invoicePostalcode || "",
      invoiceHousenumber: obj.invoiceHousenumber || "",
      invoiceCity: obj.invoiceCity || "",
      invoiceCountry: obj.invoiceCountry || "",
      contactEmail: obj.contactEmail || "",
      contactPhone: obj.contactPhone || "",
    };
  };
  const [initialValues, setInitialValues] = useState<IRecord>(
    getFlatObj({ id: -1 })
  );

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required(translations["errors.requiredfield"]),
    }),
    onSubmit: (values) => {
      if (values.id === -1) {
        var formvalues: INewRecord = { ...values };
        delete formvalues.id;

        fetch("/api/contexts/", {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("jwt"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formvalues),
        })
          .then((res) => res.json())
          .then((jsondata) => {
            if (jsondata.success) {
              values.id = jsondata.data.id;
              revalidator.revalidate();
            }
          })
          .finally(() => {
            formik.setSubmitting(false);
            setOpen(false);
          });
      } else {
        fetch("/api/contexts/" + values.id + "/", {
          method: "PUT",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("jwt"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        })
          .then((res) => res.json())
          .then((jsondata) => {
            if (jsondata.success) {
              revalidator.revalidate();
            }
          })
          .finally(() => {
            formik.setSubmitting(false);
            setOpen(false);
          });
      }
    },
  });

  useEffect(() => {
    if (debouncedSearch === "") {
      setSearchParams({});
    } else {
      setSearchParams({
        search: debouncedSearch,
      });
    }
  }, [debouncedSearch, setSearchParams]);

  let columns: (GridColDef | GridActionsColDef)[] = [
    {
      field: "id",
      headerName: translations["contexts.id"],
    },
    {
      field: "name",
      headerName: translations["contexts.name"],
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          onClick={() => {
            let context = contexts.find((context) => context.id === params.id);
            if (typeof context !== "undefined") {
              let obj = getFlatObj(context as IRecord);
              formik.resetForm({
                touched: {},
                errors: {},
              });
              setInitialValues(obj);
              formik.setValues(obj, false);
              setMyDialogTitle(translations["contexts.editcontexttitle"]);
              setOpen(true);
            }
          }}
          label={translations["contexts.edit"]}
        />,
        /*<GridActionsCellItem
          icon={<DeleteIcon />}
          onClick={() => {}}
          label={translations["contexts.delete"]}
        />,*/
      ],
    },
  ];

  let rows = contexts;

  return (
    <>
      <Box p={1}>
        <TextField
          label={translations["contexts.search"]}
          value={search}
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        ></TextField>
        <Button
          style={{ marginLeft: 8 }}
          variant="contained"
          onClick={() => {
            let obj = getFlatObj({ id: -1 });
            formik.resetForm({
              touched: {},
              errors: {},
            });
            setInitialValues(obj);
            formik.setValues(obj, false);
            setMyDialogTitle(translations["contexts.newcontexttitle"]);
            setOpen(true);
          }}
        >
          {translations["contexts.add"]}
        </Button>
      </Box>
      <DataGrid
        rows={rows}
        columns={columns}
        localeText={gridtranslations}
      ></DataGrid>
      <Dialog
        className="window"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        scroll="body"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>{myDialogTitle}</DialogTitle>
        <DialogContent dividers={false}>
          <form onSubmit={formik.handleSubmit}>
            <Box flex="1" display="flex" flexDirection="column" gap={1} p={2}>
              <Box display="flex" flexDirection={"row"} className="fieldlabel">
                <Box width="260px" flexShrink={0}>
                  <Typography>{translations["contexts.name"]}</Typography>
                </Box>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    error={Boolean(formik.touched.name && formik.errors.name)}
                    {...formik.getFieldProps("name")}
                  ></TextField>
                  <DisplayErrors
                    name="name"
                    touched={Boolean(formik.touched.name)}
                    errors={formik.errors.name}
                  />
                </FormControl>
              </Box>
              <Divider />
              <Box display="flex" flexDirection={"row"} className="fieldlabel">
                <Box width="260px" flexShrink={0}>
                  <Typography>
                    {translations["contexts.contactemail"]}
                  </Typography>
                </Box>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    error={Boolean(
                      formik.touched.contactEmail && formik.errors.contactEmail
                    )}
                    {...formik.getFieldProps("contactEmail")}
                  ></TextField>
                  <DisplayErrors
                    name="contactEmail"
                    touched={Boolean(formik.touched.contactEmail)}
                    errors={formik.errors.contactEmail}
                  />
                </FormControl>
              </Box>
              <Box display="flex" flexDirection={"row"} className="fieldlabel">
                <Box width="260px" flexShrink={0}>
                  <Typography>
                    {translations["contexts.contactphone"]}
                  </Typography>
                </Box>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    error={Boolean(
                      formik.touched.contactPhone && formik.errors.contactPhone
                    )}
                    {...formik.getFieldProps("contactPhone")}
                  ></TextField>
                  <DisplayErrors
                    name="contactPhone"
                    touched={Boolean(formik.touched.contactPhone)}
                    errors={formik.errors.contactPhone}
                  />
                </FormControl>
              </Box>
              <Divider />
              <Box display="flex" flexDirection={"row"} className="fieldlabel">
                <Box width="260px" flexShrink={0}>
                  <Typography>
                    {translations["contexts.invoicestreet"]}
                  </Typography>
                </Box>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    error={Boolean(
                      formik.touched.invoiceStreet &&
                        formik.errors.invoiceStreet
                    )}
                    {...formik.getFieldProps("invoiceStreet")}
                  ></TextField>
                  <DisplayErrors
                    name="invoiceStreet"
                    touched={Boolean(formik.touched.invoiceStreet)}
                    errors={formik.errors.invoiceStreet}
                  />
                </FormControl>
                <Box
                  flexShrink={0}
                  className="fieldlabel"
                  ml={3}
                  mr={1}
                  width="100px"
                >
                  <Typography>
                    {translations["contexts.invoicehousenumber"]}
                  </Typography>
                </Box>
                <FormControl>
                  <TextField
                    size="small"
                    error={Boolean(
                      formik.touched.invoiceHousenumber &&
                        formik.errors.invoiceHousenumber
                    )}
                    {...formik.getFieldProps("invoiceHousenumber")}
                  ></TextField>
                  <DisplayErrors
                    name="invoiceHousenumber"
                    touched={Boolean(formik.touched.invoiceHousenumber)}
                    errors={formik.errors.invoiceHousenumber}
                  />
                </FormControl>
              </Box>
              <Box display="flex" flexDirection={"row"} className="fieldlabel">
                <Box width="260px" flexShrink={0}>
                  <Typography>
                    {translations["contexts.invoicepostalcode"]}
                  </Typography>
                </Box>
                <FormControl style={{ width: 240 }}>
                  <TextField
                    size="small"
                    error={Boolean(
                      formik.touched.invoicePostalcode &&
                        formik.errors.invoicePostalcode
                    )}
                    {...formik.getFieldProps("invoicePostalcode")}
                  ></TextField>
                  <DisplayErrors
                    name="invoicePostalcode"
                    touched={Boolean(formik.touched.invoicePostalcode)}
                    errors={formik.errors.invoicePostalcode}
                  />
                </FormControl>

                <Box
                  flexShrink={0}
                  className="fieldlabel"
                  ml={3}
                  mr={1}
                  width="100px"
                >
                  <Typography>
                    {translations["contexts.invoicecity"]}
                  </Typography>
                </Box>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    error={Boolean(
                      formik.touched.invoiceCity && formik.errors.invoiceCity
                    )}
                    {...formik.getFieldProps("invoiceCity")}
                  ></TextField>
                  <DisplayErrors
                    name="invoiceCity"
                    touched={Boolean(formik.touched.invoiceCity)}
                    errors={formik.errors.invoiceCity}
                  />
                </FormControl>
              </Box>
            </Box>
          </form>
        </DialogContent>
        <SaveBar
          formik={formik}
          onCancel={() => {
            setOpen(false);
          }}
          manualSubmit={true}
        />
      </Dialog>
    </>
  );
}

export default Contexts;
