import { Box } from "@mui/material"
import {  ReactWidgetWithStyles } from "../../../../../../interfaces/Interfaces"
import React from "react"
import withStandardProps from "./HOCs/withStandardStyles"
import { addConfigToStyle } from "../../../../../../helpers/Helpers"

const ImageField = ({ widget, standardStyles }: ReactWidgetWithStyles) => {
    const config = widget.config || {};
    const styles: any = {
        maxWidth: '100%', 
        Height: 'auto'
    }
    
    const width = config['width'];

    if (width) {
        styles.width = width;
    }

    const parentStyles: any = {};

    addConfigToStyle('textAlign', config, parentStyles)

    if (widget.config.src) {
        return <Box sx={{...parentStyles, width: '100%'}}><Box component="img" sx={{...styles, ...standardStyles}} src={widget.config.src}></Box></Box>
    } else {
        return <Box sx={styles}>{widget.type}</Box>
    }
}

export default withStandardProps(ImageField);