import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useField } from "formik";
import { MyCheckBoxInputProps, SrcSelectorProps } from "../../../../../../templates/Interfaces";
import OptionContainer from "./OptionContainer";
import FileSelectModal from "../../modals/FileSelectModal";
import { getTranslations } from "../../../../../../../translations/Translations";
import ClearIcon from "@mui/icons-material/Clear";



export const SrcSelector = (props: SrcSelectorProps) => {
    const translations = getTranslations();
    const { label, name, type } = props;
    const [field, meta, helpers] = useField(name);
    const [showModal, setShowModal] = useState<boolean>(false);
    
    const srcSelectorType = type ? type : "image";

    console.log(field.value);
    const onFileClear = () => {
        helpers.setValue('');
    }

    const onFileSelect = (selectedUrl: {url: string, videoSrc: string}) => {
        if (selectedUrl) {
            helpers.setValue(selectedUrl.url)
        }
    }

    return (
        <>
            <OptionContainer sx={{ flexDirection: 'column', alignContent: 'start'}}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1, width: '100%' }}>
                    
                    <Typography sx={{ flex: 1 }} mr={'1rem'}>{srcSelectorType === 'file' ? translations['event.website.options.file'] : label}</Typography>
                    <Button onClick={() => { setShowModal(true) }}>{srcSelectorType === "image" ? translations['event.website.options.fileSelectImage'] :  translations['event.website.options.fileSelectFile']}</Button>
                </Box>
                {(field.value !== '' && srcSelectorType === "image") ? (<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1, width: '100%', justifyContent: 'space-between' }}>
                    <Box mt={2} sx={{ maxHeight: '100px', maxWidth: '100px' }} component='img' src={field.value}></Box>
                    <Button onClick={onFileClear}><ClearIcon /></Button>
                </Box>):null}
                {(field.value !== '' && srcSelectorType === "file") ? (<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1, width: '100%', justifyContent: 'space-between' }}>
                    <Box mt={2} sx={{ maxHeight: '100px', maxWidth: '100px' }}><a target="_blank" href={field.value}>Selected File</a></Box>
                    <Button onClick={onFileClear}><ClearIcon /></Button>
                </Box>):null}
            </OptionContainer>

            <FileSelectModal open={showModal}  type={srcSelectorType === 'file' ? 'pdf' : undefined} onSelectImage={onFileSelect} closeModal={() => {
                setShowModal(false)
            }} />
        </>
    );
};

export default SrcSelector;

