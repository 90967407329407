import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import { getTranslations } from "../../../../translations/Translations";
import { EventRouterResponse } from "../../../../interfaces/Interfaces";
import { useRouteLoaderData } from "react-router-dom";

function RelationsTab() {
  const translations = getTranslations();
  const { event } = useRouteLoaderData("event") as EventRouterResponse;

  return (
    <Box display="flex" flexDirection="column" overflow="scroll">
      <Box display="flex" flexDirection="row">
        <Box flex="1" display="flex" flexDirection="column" gap={1} p={2}>
          <Box display="flex" flexDirection={"row"}>
            <Box width="260px" flexShrink={0}>
              <Typography>Beziehung zu</Typography>
            </Box>
            <TextField size="small" fullWidth />
          </Box>
        </Box>
        <Box flex="1" display="flex" flexDirection="column" gap={1} p={2}>
          <Box display="flex" flexDirection={"row"}>
            <Box width="260px" flexShrink={0}>
              <Typography>Name der Gruppe</Typography>
            </Box>
            <TextField size="small" fullWidth />
          </Box>
        </Box>
      </Box>
      <Box p={2}>
        <Typography>Reihenfolge der Veranstaltungen</Typography>
      </Box>
    </Box>
  );
}

export default RelationsTab;
