import * as React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useEffect, useState } from 'react';

export type Item = {
    id: string;
    title: string;
};

export type DraggableListItemProps = {
    item: Item;
    index: number;
    onDraggableListItemClick?: Function;
    activeIndex?: number | null
};

const renderClassName = (index: number, activeIndex: number | null | undefined, isDragging: boolean) => {
    let classNameString = 'drag-list-item';
    
    if (isDragging) {
        classNameString += ' dragging-item'
    }

    if (index === activeIndex) {
        classNameString += ' active-item';
    }   

    return classNameString;
}

const DraggableListItem = ({ item, index, onDraggableListItemClick, activeIndex }: DraggableListItemProps) => {
    return (
        <Draggable draggableId={index.toString()} index={index}>
            {(provided, snapshot) => (
                <ListItem
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        minWidth: '150px',
                        marinRight: '2rem'
                    }}
                    className={renderClassName(index, activeIndex, snapshot.isDragging)}
                    onClick={() => {
                        if (onDraggableListItemClick) {
                            onDraggableListItemClick(item);
                        }
                    }}
                >
                    <ListItemText primary={item.title} sx={{ flex: 1 }} />
                    <ListItemIcon sx={{ minWidth: 0 }}><DragIndicatorIcon /></ListItemIcon>
                </ListItem>
            )}
        </Draggable>
    );
};

export default DraggableListItem;
