import { useField } from "formik";
import { MyTextInputProps } from "../../Interfaces";
import { Grid, TextField } from "@mui/material";
import React from "react";

export const TextInput = (props: MyTextInputProps) => {
    const { label, required, ...inputProps } = props;
    const multiline = props.multiline;
    const [field, meta] = useField(inputProps);
    let className = "text-input";
    let styling: any[] = [{ marginBottom: '1rem',  }, { input: { borderRadius: '2px', backgroundColor: 'common.white' }}];

    if (multiline) {
        className += " multiline";
        styling[0] = { marginBottom: '1rem', borderRadius: '2px'};
    }
    return (
            <TextField
                sx={styling}
                label={`${label} ${(required ? '*' : '')}`}
                variant="filled"
                className={className}
                fullWidth
                {...field}
                error={!!(meta.touched && meta.error)}
                helperText={meta.touched && meta.error ? meta.error : ''}
                {...inputProps}
            />
    );
};