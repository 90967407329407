import React from "react";
import { getTranslations } from "../../translations/Translations";
import TopToolbar from "../../components/TopToolbar";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { Outlet, matchPath, useLocation } from "react-router-dom";
import { CustomNavLink } from "../../components/CustomNavLink";

function Attributes() {
  const translations = getTranslations();
  const location = useLocation();
  const urls = ["/admin/attributes/", "/admin/attributes/categories/"];
  const getActiveUrl = () => {
    let active = [...urls].reverse().filter((url) => {
      return matchPath(url + "/*", location.pathname);
    });
    if (active.length === 0) {
      return urls[0];
    } else {
      return active[0];
    }
  };

  return (
    <>
      <TopToolbar
        title={
          <Typography fontSize={"24px"} mt={1} ml={2}>
            {translations["attributes.title"]}
          </Typography>
        }
      />
      <Box>
        <Tabs className="tabs" value={getActiveUrl()}>
          <Tab
            label={translations["attributes.tags"]}
            component={CustomNavLink}
            to={urls[0]}
            value={urls[0]}
            replace={false}
          />
          <Tab
            label={translations["attributes.categories"]}
            component={CustomNavLink}
            to={urls[1]}
            value={urls[1]}
            replace={false}
          />
        </Tabs>
      </Box>
      <Box display="flex" overflow="hidden" flex={1}>
        <Outlet />
      </Box>
    </>
  );
}

export default Attributes;
