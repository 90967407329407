import React from "react";
import "./App.css";
import { Outlet } from "react-router-dom";
import { AuthContextProvider } from "./AuthProvider";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MaskProvider } from "./MaskProvider";
import Mask from "./components/Mask";
import { Box } from "@mui/material";
import isMobile from "./helpers/isMobile";


const theme = createTheme({
  palette: {
    primary: {
      main: "#ea7c15",
      contrastText: "#fff",
    },
    contrastThreshold: 4.5,
  },
});

function App() {
  return (
    <AuthContextProvider>
      <MaskProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ThemeProvider theme={theme}>
            <Mask />
            <Box className="app" sx={{flexDirection: isMobile() ? 'column': null}}>
              <Outlet />
            </Box>
          </ThemeProvider>
        </LocalizationProvider>
      </MaskProvider>
    </AuthContextProvider>
  );
}

export default App;
