import { Box, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React from "react";
import { useField } from "formik";
import { MyCheckBoxInputProps } from "../../../../../../templates/Interfaces";
import AlignVerticalTopIcon from '@mui/icons-material/AlignVerticalTop';
import AlignVerticalCenterIcon from '@mui/icons-material/AlignVerticalCenter';
import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom';
import OptionContainer from "./OptionContainer";

export const FlexAlignSelector = (props: MyCheckBoxInputProps) => {
    const { label, name } = props;
    const [field, , { setValue }] = useField(name);

    const handleAlignmentChange = (event: React.MouseEvent<HTMLElement>, newValue: string) => {
        setValue(newValue);
    };

    return (
        <OptionContainer>
            <Typography>{label}</Typography>
            <ToggleButtonGroup value={field.value || ''} exclusive onChange={handleAlignmentChange}>
                <ToggleButton value="flex-start"><AlignVerticalTopIcon /></ToggleButton>
                <ToggleButton value="center"><AlignVerticalCenterIcon /></ToggleButton>
                <ToggleButton value="flex-end"><AlignVerticalBottomIcon /></ToggleButton>
            </ToggleButtonGroup>
        </OptionContainer>
    );
};

export default FlexAlignSelector;