import { Box, Typography } from "@mui/material";
import { FieldRecord } from "../../../../../../interfaces/Interfaces";
import React from "react";

const FieldLabel = ({field}:{field: FieldRecord}) => {
    return (
      <Box className="fieldlabel" sx={{display: 'flex', justifyContent: 'space-between'}} minWidth={"300px"} mb={'1rem'}>
            <Typography>
              {field.id}: {field.translations[field.defaultLanguage].title}{field.required && '*'}
            </Typography>
          </Box>
    )
  }

  export default FieldLabel;