import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { getTranslations } from "../../../translations/Translations";
import { Helmet } from "react-helmet";

const GridWrapper = (props: any) => {
    const pageWidth = 1170;
    return (
        <Grid container sx={{ display: 'flex', flex: 1, flexDirection: 'column', maxWidth: pageWidth + 'px', margin: '0 auto', boxSizing: 'border-box', padding: '1rem' }}>
            {props.children}
        </Grid>
    )

}

const NotFound = () => {
    const translations = getTranslations();
    return (
        <Box sx={{ width: '100%', flex: 1 }}>
            <Helmet>
                <title>Clicla: Not Found</title>
                <meta name="description" content="Clicla: not found!" />
            </Helmet>
            <GridWrapper>
                <Grid item xs={12} mb={4} sx={{ width: '100%' }}>
                    <Typography variant={"h2"}>Clicla Support</Typography>
                </Grid>
            </GridWrapper>
            <Box sx={{ width: '100%', backgroundColor: '#5D8800', height: '20px' }}></Box>
            <GridWrapper>
                <Grid item xs={12} mt={4} sx={{ width: '100%' }}>
                    <Typography variant={"body1"}>{translations["notfound"]}</Typography>
                </Grid>
            </GridWrapper>
        </Box>
    )
};

export default NotFound;