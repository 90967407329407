import React from "react";
import { useRouteError } from "react-router-dom";
import { getTranslations } from "../translations/Translations";

export default function ErrorPage() {
  const translations = getTranslations();
  const error = useRouteError();
  const errorobject: Record<string, any> = error as Record<string, any>;

  return (
    <div id="errorpage">
      <h1>{translations["errorpage.title"]}</h1>
      <p>{translations["errorpage.info"]}</p>
      <p>
        <i>{errorobject.statusText || errorobject.message}</i>
      </p>
    </div>
  );
}
