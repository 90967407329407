import { Box, Button, Typography, Modal, Input, FormControl } from "@mui/material";
import { DisplayErrors } from "./DisplayErrors";
import React, { ChangeEvent, useState, useEffect, useRef, forwardRef } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { getTranslations } from "../translations/Translations";
import { UploadResponse } from "../interfaces/Interfaces";
import UploadButton from "./buttons/UploadButton";

type UploadCompleteFunction = (response: UploadResponse) => void;
type DeleteCompleteFunction = () => void;

interface UploadControlProps {
  name: string
  value: string
  eventId: number
  setFieldValue: Function
  beforeUpload?: Function
  onUploadError?: Function
  afterUploadClick?: Function,
  onUploadComplete: UploadCompleteFunction
  onDeleteComplete?: DeleteCompleteFunction
  purpose?: string
  buttonP?: number
  accept?: string
  hideUploadButton?: number,
  multiple?: boolean, //If this is enable multiple upload wiht no setValue -
  autoUpload?: boolean,
  error?: any
}

const UploadControl = forwardRef(function UploadControl(props: UploadControlProps, ref) {
  const translations = getTranslations();
  const [value, setValue] = useState<string>(props.value);
  const isFirstMount = useRef(true);


  const inputElement = useRef<HTMLInputElement>(null);

  const deleteFn = () => {
    if (props.autoUpload) {
    fetch("/api/files/delete/" + props.value, {
      method: "delete",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      },
    })
      .then((response) => response.json())
      .then((jsonresponse) => {
        if (jsonresponse.success) {
          if (props.onDeleteComplete) {
            setValue("");
            props.onDeleteComplete();
          }
        }
      });
    } else {
      if (props.onDeleteComplete) {
        setValue("");
        props.onDeleteComplete();
      }
    }
  };

  useEffect(() => {
    if (isFirstMount.current) {
      isFirstMount.current = false;
      return;
  }

  if (props.afterUploadClick) { 
      props.afterUploadClick();
  }
  },[props.value])

  useEffect(() => {
    if (typeof props.hideUploadButton !== 'undefined' && props.hideUploadButton !== 0) {
      inputElement.current?.click();
    }
  }, [props.hideUploadButton]);

  const uploadFiles = async (input: HTMLInputElement) => {
    const data = new FormData();
    const eventtarget = input;

    data.append("eventId", "" + props.eventId);
    data.append("purpose", "" + props.purpose);
    data.append("noResize", "true");

    let fileLength;

    if (eventtarget.files && eventtarget.files.length > 0) {
      fileLength = eventtarget.files.length;
    }

    const uploadPromise = async (data: FormData, file: string | Blob) => {
      if (data.has('file')) {
        data.delete('file')
      };

      data.append('file', file);

      const response = await fetch("/api/files/upload", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("jwt"),
        },
        body: data,
      });

      const jsonResponse = await response.json();
      return JSON.parse(jsonResponse);
    }

    try {
      if (eventtarget.files && eventtarget.files.length > 0) {
        const uploadPromises = Array.from(eventtarget.files).map((file) => {
          return uploadPromise(data, file)
        });

        const responses = await Promise.all(uploadPromises);

        const allSuccessful = responses.every((response) => response.success);
        if (allSuccessful) {
          if (fileLength === 1) {
            setValue(responses[0].publicId);
            props.setFieldValue(props.name, responses[0].publicId);
            props.onUploadComplete(responses[0]);
          } else {
            props.onUploadComplete(responses[0]);
          }

        }
      }
    } catch (error) {
      console.log('ERROR', error);
    }
  }

  return (
    <Box display="flex" flexDirection={"row"} alignItems={"center"}>
      <FormControl>
        <Input
          style={{ display: "none" }}
          id={props.name}
          inputProps={{ accept: props.accept, multiple: props.multiple }}
          type="file"
          inputRef={inputElement}
          ref={ref}
        
          onChange={(e) => {
            if (typeof props.autoUpload === 'undefined' || props.autoUpload === true) {
              if (props.beforeUpload) {
                props.beforeUpload()
              }
              uploadFiles(e.target as HTMLInputElement)
            } else {
              if (e.target instanceof HTMLInputElement && (e.target.files && e.target.files.length > 0)) {
                setValue(e.target.files[0].name);
                props.setFieldValue(props.name, e.target.files[0].name);
                if (props.afterUploadClick) {
                  props.afterUploadClick();
                }
              }
            }
          }}
        />
      </FormControl>
      {typeof props.hideUploadButton === 'undefined' &&
        <Box sx={{marginRight: '1rem'}}>
        <label htmlFor={props.name} >
          <UploadButton />
        </label>
        </Box>
      }
      <DisplayErrors name={props.name} errors={props.error} />
      {typeof value !== "undefined" &&
        value !== null &&
        value !== "" &&
        props.onDeleteComplete ? (
        <Box display="flex" flexDirection={"row"} flex={1}>
          <Box flexShrink={0} className="fieldlabel" ml={2} mr={2}>
            <Typography>
              {`${translations["uploadcontrol.currentimage"]}: ${value}`}
            </Typography>
          </Box>
          {props.autoUpload === true && (
            <Box flexShrink={0} mr={2}>
              <img alt="" src={"/api/files/read/" + value} />
            </Box>
          )}
          <Box>
            <Button variant="contained" onClick={deleteFn}>
              <DeleteIcon />
            </Button>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
});

export default UploadControl;
