import React, { useContext } from 'react';
import { MaskContext } from '../MaskProvider';
import { Backdrop , CircularProgress } from '@mui/material';

interface MaskContextType {
    maskVisible: boolean,
    toggleMask: Function
}

const Mask: React.FC = () => {
  const { maskVisible } = useContext(MaskContext) as MaskContextType;

  return (
    <Backdrop sx={{zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff'}} open={maskVisible}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Mask;