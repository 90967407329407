import React from "react";
import { Box, CircularProgress } from "@mui/material";

export const Loading = () => {
  return (
    <Box display={"flex"} flex={1} justifyContent="center" alignItems="center">
      <CircularProgress sx={{ color: "#006e55" }} />
    </Box>
  );
};
