import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, Icon, IconButton, MenuItem, Select, SelectChangeEvent, TextField, Typography, styled } from "@mui/material";


import { useRevalidator, useRouteLoaderData } from "react-router-dom";
import { DataGrid, GridActionsCellItem, GridActionsColDef, GridColDef, GridColumnHeaderParams, GridColumnVisibilityModel, GridEventListener, GridLocaleText, GridRowClassNameParams, GridRowParams, GridValueGetterParams } from "@mui/x-data-grid";

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import useId from "@mui/material/utils/useId";
import { previousDay } from "date-fns";
import { Label } from "@mui/icons-material";
import { EventRecord, EventRouterResponse, FieldRecord, Filter, IRecord, ModuleRecord, SessionRecord } from "../../../interfaces/Interfaces";
import { getTranslations } from "../../../translations/Translations";
import useDebounce from "../../../helpers/useDebounce";
import { Loading } from "../../events/event/scanner/components/Loading";
import ImprovedChoiceDialog from "../../../components/ImprovedDialog";
import "./AddActivityModal.scss";
import { Module } from "yet-another-react-lightbox";

interface RadioFilterState {
    radioFilter: string | undefined,
    radioFilterValue: string | undefined
}

interface ActivitiesModal {
    sessions: SessionRecord[],
    modules: ModuleRecord[],
    isLoading: boolean
}

const AddActivityModal = ({ events, localText, open, closeModal, loginSettings }: { loginSettings: { loginId: number | null, loginAccess: IRecord[] }, events: EventRecord[], open: boolean, closeModal: Function, localText: Partial<GridLocaleText> }) => {
    const translations = getTranslations();
    const revalidator = useRevalidator();
    const [filteredEvents, setFilteredEvents] = useState<IRecord[]>([]);
    const [selectedEvent, setSelectedEvent] = useState<number | null>();
    const [search, setSearch] = useState<string>("");
    const debouncedSearch = useDebounce(search, 500);

    const [activitySearch, setActivitySearch] = useState<string>("");
    const debouncedActivitySearch = useDebounce(activitySearch, 500);
    const [eventFilters, setEventFilters] = useState<Filter[]>([]);

    const [radioFilter, setRadioFilter] = useState<RadioFilterState>({
        radioFilter: '',
        radioFilterValue: ''
    })

    const [selectedBlock, setSelectedBlock] = useState<string>('');
    const [filteredActivities, setFilteredActivities] = useState<SessionRecord[]>([])
    const [activityFilters, setActivityFilters] = useState<Filter[]>([]);
    const [modalState, setModalState] = useState<{ showNothingSelectedModal: boolean }>(
        {
            showNothingSelectedModal: false
        }
    )

    const [selectedActivityState, setSelectedActivityState] = useState<number[]>([])
    const [activities, setActivities] = useState<ActivitiesModal>({
        isLoading: false,
        sessions: [],
        modules: []
    });

    useEffect(() => {
        if (activities.isLoading === false) {
            //modules tbc... (can't scan for them right now anyway...)
            setFilteredActivities([...activities.sessions])
        }
    }, [activities])

    const modalClose = () => {
        clearSelectedActivity();
        clearSelectedEvent();
        closeModal();
    };

    /*const selectAllFilteredActivities = () => {
        setActivities((prev) => {
            const newIds = filteredActivities.map(reg => reg.id);
            const uniqueNewIds = newIds.filter(id => !prev.selectedActivities.includes(id));
            return {
                ...prev,
                selectedActivities: [...prev.selectedActivities, ...uniqueNewIds]
            };
        })
    }

    const deselectAllFiltered = () => {
        setActivities((prev) => {
            const filteredIds = filteredActivities.map(activity => activity.id);
            const updatedSelectedActivities = prev.selectedActivities.filter((id) => !filteredIds.includes(id));
            return {
                ...prev,
                selectedActivities: updatedSelectedActivities
            }
        });
    }*/

    const showNothingSelectedModal = () => {
        setModalState((prev) => ({ ...prev, showNothingSelectedModal: true }))
    }

    const hideNothingSelectedModal = () => {
        setModalState((prev) => ({ ...prev, showNothingSelectedModal: false }))
    }

    const saveSelected = () => {
        if (selectedActivityState && selectedActivityState.length > 0 && loginSettings.loginId) {
            fetch("/api/loginAccess", {
                method: 'POST',
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization:
                        "Bearer " + sessionStorage.getItem("jwt"),
                },
                body: JSON.stringify({
                    loginId: loginSettings.loginId,
                    activityId: selectedActivityState[0],
                    eventId: selectedEvent
                })
            }).then((res) => res.json()).then((response) => {
                if (response.success) {
                    revalidator.revalidate();
                    modalClose();
                }
            });
        } else {
            showNothingSelectedModal();
        }
    }

    const titleFilter = (record: any, value: string) => {
        return record.translations[record.defaultLanguage].title?.toLocaleLowerCase().includes(value.toLocaleLowerCase());
    };

    const blockFilter = (record: any, value: string) => (value === '') ? true : record.blockId === Number(value);

    useEffect(() => {
        if (open === true) {
            setFilteredEvents(events);
        }
    }, [open])

    //Maybe cache them?
    const fetchSessionModules = async (eventId: number) => {
        const searchParams = new URLSearchParams({
            filter: JSON.stringify([{ property: "eventId", value: eventId }]),
            sort: JSON.stringify([{ property: "sortorder", direction: "ASC" }]),
        });

        try {
            const sessionsResponse = await fetch("/api/activities?" + searchParams.toString(), {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                },
            });

            const modulesResponse = await fetch("/api/blocks?" + searchParams.toString(), {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                },
            });

            const sessions = await sessionsResponse.json();
            const modules = await modulesResponse.json();

            return { sessions: sessions.data, modules: modules.data };
        } catch (error) {
            console.error("Error fetching data:", error);
            return { sessions: [], modules: [] };
        }
    };

    const setSelectedActivity = (activityId: number) => {
        setSelectedActivityState([activityId])
    }

    const clearSelectedActivity = () => {
        setActivities(prev => ({
            ...prev,
            selectedActivities: []
        }));
        setSelectedActivityState([]);
    }

    const clearSelectedEvent = () => {
        setSelectedEvent(null);
    }

    /*const toggleActivity = (activityId: number) => {
        setActivities(prev => ({
            ...prev,
            selectedActivities: prev.selectedActivities.includes(activityId)
                ? prev.selectedActivities.filter(id => id !== activityId)
                : [...prev.selectedActivities, activityId]
        }));
    }*/

    const fetchData = async () => {
        if (selectedEvent) {
            try {
                setActivities((prev) => ({
                    ...prev,
                    isLoading: true
                }))
                const { sessions, modules } = await fetchSessionModules(selectedEvent);
                setActivities({
                    sessions: sessions,
                    modules: modules,
                    isLoading: false
                })
            } catch (error) {
                // Handle error if needed
                console.error("Error fetching data:", error);
                setActivities((prev) => ({
                    ...prev,
                    isLoading: false
                }))
            }
        }
    };


    useEffect(() => {
        fetchData();
    }, [selectedEvent]);

    useEffect(() => {
        if (debouncedSearch !== "") {
            setEventFilters((prevFilters) => {
                const filterIndex = prevFilters.findIndex(
                    (filter) => filter.filterName === "search"
                );
                if (filterIndex !== -1) {
                    prevFilters[filterIndex].filterFunction = (row: EventRecord) =>
                        titleFilter(row, debouncedSearch);
                    return [...prevFilters];
                } else {
                    return [
                        ...prevFilters,
                        {
                            filterName: "search",
                            filterFunction: (row: EventRecord) =>
                                titleFilter(row, debouncedSearch),
                        },
                    ];
                }
            });
        } else {
            setEventFilters((prevFilters) =>
                prevFilters.filter((filter) => filter.filterName !== "search")
            );
        }
    }, [debouncedSearch]);

    useEffect(() => {
        if (selectedBlock !== null) {
            setActivityFilters((prevFilters) => {
                const filterIndex = prevFilters.findIndex(
                    (filter) => filter.filterName === "block"
                );
                if (filterIndex !== -1) {
                    prevFilters[filterIndex].filterFunction = (row: any) =>
                        blockFilter(row, selectedBlock);
                    return [...prevFilters];
                } else {
                    return [
                        ...prevFilters,
                        {
                            filterName: "block",
                            filterFunction: (row: any) =>
                                blockFilter(row, selectedBlock),
                        },
                    ];
                }
            });
        } else {
            setActivityFilters((prevFilters) =>
                prevFilters.filter((filter) => filter.filterName !== "block")
            );
        }
    }, [selectedBlock]);

    useEffect(() => {
        if (debouncedActivitySearch !== "") {
            setActivityFilters((prevFilters) => {
                const filterIndex = prevFilters.findIndex(
                    (filter) => filter.filterName === "search"
                );
                if (filterIndex !== -1) {
                    prevFilters[filterIndex].filterFunction = (row: any) =>
                        titleFilter(row, debouncedActivitySearch);
                    return [...prevFilters];
                } else {
                    return [
                        ...prevFilters,
                        {
                            filterName: "search",
                            filterFunction: (row: any) =>
                                titleFilter(row, debouncedActivitySearch),
                        },
                    ];
                }
            });
        } else {
            setActivityFilters((prevFilters) =>
                prevFilters.filter((filter) => filter.filterName !== "search")
            );
        }
    }, [debouncedActivitySearch])

    useEffect(() => {
        if (eventFilters.length > 0) {
            //shallow copy of orginals rows
            let filteredRows = [...events];
            eventFilters.forEach((myFilter) => {
                filteredRows = filteredRows.filter((record) =>
                    myFilter.filterFunction(record)
                );
            });
            setFilteredEvents(filteredRows)
        } else {
            setFilteredEvents(events);
        }
    }, [eventFilters]);

    useEffect(() => {
        if (activityFilters.length > 0) {
            //shallow copy of orginals rows
            let filteredRows = [...activities.sessions];
            activityFilters.forEach((myFilter) => {
                filteredRows = filteredRows.filter((record) =>
                    myFilter.filterFunction(record)
                );
            });
            setFilteredActivities(filteredRows)
        } else {
            setFilteredActivities([...activities.sessions]);
        }
    }, [activityFilters]);

    const eventColumns: (GridColDef | GridActionsColDef)[] = [
        {
            field: "id",
            headerName: translations["logins.id"],
        },
        {
            field: 'title',
            headerName: 'title',
            /*sortable: false, 
            width: 200, */
            flex: 1,
            valueGetter: (params: GridValueGetterParams<EventRecord>) => {
                return params.row.translations[params.row.defaultLanguage].title
            },
        }
    ];

    const modulesEventsColumns: (GridColDef | GridActionsColDef)[] = [
        {
            field: "id",
            headerName: translations["logins.id"],
        },
        {
            field: 'title',
            headerName: 'title',
            /*sortable: false, 
            width: 200, */
            flex: 1,
            valueGetter: (params: GridValueGetterParams<EventRecord>) => {
                return params.row.translations[params.row.defaultLanguage].title
            },
        },
        {
            field: 'block',
            headerName: 'block',
            flex: 1,
            valueGetter: (params: GridValueGetterParams<EventRecord>) => {

                if (params.row.block) {
                    const block = activities.modules.find(module => module.id === params.row.block.id)
                    return block?.translations[params.row.defaultLanguage].title
                }
                return null;
            },
        }
    ];

    const handleRowClick: GridEventListener<'rowClick'> = (params) => {
        setSelectedEvent(params.row.id);
        clearSelectedActivity()
        clearActivityFilters()
    };

    const isActivitySelectable = (id: any): boolean => {
        return !loginSettings.loginAccess.some((activity) => activity.activityId === id);
    }

    const handleActivityRowClick: GridEventListener<'rowClick'> = (params) => {
        setSelectedActivity(params.row.id)
    };

    const clearActivityFilters = () => {
        setActivitySearch('');
        setSelectedBlock('');
    }

    return (
        <>
            <Dialog
                className="window"
                open={open}
                fullWidth={true}
                fullScreen={true}
                sx={{ "&": { padding: '1rem' }, "& .MuiDialog-paperFullScreen": { display: 'flex', flexDirection: 'column' } }}
            >
                <DialogContent sx={{ display: 'flex', flex: 1, flexDirection: 'column' }} dividers={false}>
                    <Box p={'1rem 0 1rem 0'} sx={{ display: 'flex', flexDirection: 'row' }}>
                        <TextField
                            sx={{ width: 300 }}
                            value={search}
                            label={translations["contacts.search"]}
                            onChange={(event: any) => {
                                setSearch(event.target.value);
                            }}
                        />
                    </Box>

                    {/* EVENTS DATA GRID */}
                    {filteredEvents && (<DataGrid
                        sx={{
                            "&": {
                                flex: selectedEvent ? 0.25 : 1,
                                marginBottom: selectedEvent ? '1rem' : 0
                            },
                            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                                outline: "none !important",
                            },
                        }}
                        onRowClick={handleRowClick}
                        rows={filteredEvents}
                        columns={eventColumns}
                        localeText={localText}
                    ></DataGrid>)}

                    {/* SESSIONS / MODULES DATA GRID*/}
                    {selectedEvent ? (
                        !activities.isLoading ? (
                            <>
                                <Box p={'1rem 0 1rem 0'} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <TextField
                                            sx={{ width: 300 }}
                                            value={activitySearch}
                                            label={translations["contacts.search"]}
                                            onChange={(event: any) => {
                                                setActivitySearch(event.target.value);
                                            }}
                                        />
                                        {activitySearch === '' ?
                                            null
                                            : <Button variant="contained" sx={{ height: '100%', marginLeft: '1rem' }} onClick={() => {
                                                setActivitySearch('');
                                            }}>{translations['remove']}</Button>}
                                    </Box>
                                    {activities.modules.length > 0 ? (
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Typography sx={{ marginRight: '1rem' }}>{translations["logins.access.blockFilter"]}</Typography>

                                            <>
                                                <Select
                                                    value={selectedBlock}
                                                    sx={{ marginRight: radioFilter.radioFilter !== '' ? '1rem' : '', maxWidth: '200px' }}
                                                    onChange={(e: SelectChangeEvent) => {
                                                        setSelectedBlock(e.target.value)
                                                        setActivitySearch('');
                                                    }}
                                                >
                                                    {activities.modules
                                                        .map((module: ModuleRecord) => (
                                                            <MenuItem key={module.id} value={module.id}>
                                                                {module.translations[module.defaultLanguage].title}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                                {selectedBlock === '' ?
                                                    null
                                                    : <Button variant="contained" sx={{ height: '100%', marginLeft: '1rem' }} onClick={() => {
                                                        setSelectedBlock('');
                                                    }}>{translations['remove']}</Button>}
                                            </>

                                        </Box>
                                    ) : null}
                                </Box>

                                <DataGrid
                                    sx={{
                                        "&": {
                                            flex: 1,
                                        },
                                        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                                            outline: "none !important",
                                        },
                                    }}
                                    onRowClick={(params, e, details) => {
                                        if (isActivitySelectable(params.id)) {
                                            handleActivityRowClick(params, e, details);
                                        }
                                    }}
                                    rows={filteredActivities}
                                    getRowClassName={(params) => {
                                        return isActivitySelectable(params.id) ? '' : 'disabled--row'
                                    }}
                                    isRowSelectable={(params) => isActivitySelectable(params.id)}
                                    columns={[...modulesEventsColumns]}
                                    localeText={localText}
                                />
                            </>
                        ) : (
                            <Loading />
                        )
                    ) : null}
                </DialogContent>
                <DialogActions sx={{ padding: "24px", paddingTop: "0", display: 'flex', justifyContent: "space-between" }}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            saveSelected();
                        }}
                    >
                        {translations["add"]}
                    </Button>
                    <Button
                        onClick={() => {
                            modalClose();
                        }}
                    >
                        {translations["cancel"]}
                    </Button>
                </DialogActions>
            </Dialog>
            {/* nothing selected modal */}
            <ImprovedChoiceDialog
                open={modalState.showNothingSelectedModal}
                title={translations["logins.access.nothingSelectedTitle"]}
                description={translations["logins.access.nothingSelectedDescription"]}
            >
                {(handleClose) => (
                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                        <Button
                            variant="contained"
                            onClick={() => {
                                handleClose();
                                hideNothingSelectedModal();
                            }}
                        >
                            {translations["confirm"]}
                        </Button>
                    </Box>
                )
                }
            </ImprovedChoiceDialog>
        </>
    )
}
export default AddActivityModal;