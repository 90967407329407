import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import { getTranslations } from "../../../../translations/Translations";
import {
  Outlet,
  matchPath,
  useLocation,
  useRouteLoaderData,
} from "react-router-dom";
import { EventRouterResponse } from "../../../../interfaces/Interfaces";
import { CustomNavLink } from "../../../../components/CustomNavLink";

function EventBudget() {
  const translations = getTranslations();
  const { event } = useRouteLoaderData("event") as EventRouterResponse;
  const location = useLocation();
  const urls = [
    "/admin/events/" + event.id + "/budget",
    "/admin/events/" + event.id + "/budget/documents",
  ];
  const getActiveUrl = () => {
    let active = [...urls].reverse().filter((url) => {
      return matchPath(url + "/*", location.pathname);
    });
    if (active.length === 0) {
      return urls[0];
    } else {
      return active[0];
    }
  };
  return (
    <Box display="flex" flex={1} overflow="hidden" flexDirection={"column"}>
      {/*}<Box>
        <Tabs value={getActiveUrl()} className="subtabbar">
          <Tab
            label={translations["budget.calculation"]}
            component={CustomNavLink}
            value={urls[0]}
            to={urls[0]}
            replace={false}
          />
          <Tab
            label={translations["budget.documents"]}
            component={CustomNavLink}
            value={urls[1]}
            to={urls[1]}
            replace={false}
          />
        </Tabs>
  </Box>{*/}
      <Box display="flex" overflow="hidden" flex={1}>
        <Outlet />
      </Box>
    </Box>
  );
}

export default EventBudget;
