import { useField } from "formik";
import {  MyRadioInputGroup } from "../../Interfaces";
import { FormControl, FormHelperText, FormLabel, MenuItem, Paper, Select } from "@mui/material";
import React from "react";

export const MyComboBox = (props: MyRadioInputGroup) => {
    const { label, options, description, ...inputProps } = props;
    const [field, meta] = useField(inputProps);
    return (
        <Paper sx={{ padding: '1rem' }}>
            <FormControl fullWidth error={!!(meta.touched && meta.error)}>
                <FormLabel sx={{ color: 'var(--color-primary)', fontWeight: 'bold' }}>{label}</FormLabel>
                <Select fullWidth {...field} >
                     {options.map((option, index) => <MenuItem key={index} value={option.value}>{option.label}</MenuItem>)}
                </Select>
                {meta.error && <FormHelperText>{meta.error}</FormHelperText>}
            </FormControl>
        </Paper>
    )
}

export default MyComboBox;




