import React, { useState } from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { getTranslations } from "../../../../translations/Translations";
import {
  EventRouterResponse,
  ModuleRecord,
  SessionRecord,
  SessionsRouterResponse,
} from "../../../../interfaces/Interfaces";
import { useRevalidator, useRouteLoaderData } from "react-router-dom";
import EducationForm from "./EducationForm";

interface SessionProps {
  session: SessionRecord;
  index: number;
  onClick: Function;
  activeSession: SessionRecord | null;
}

function Session(props: SessionProps) {
  let buttonClass = "session";

  if (props.activeSession?.id === props.session.id) {
    buttonClass = "session active";
  }

  return (
    <ListItemButton
      className={buttonClass}
      onClick={() => {
        props.onClick(props.session);
      }}
      sx={{ flexGrow: 0 }}
    >
      <ListItemText
        primary={
          props.session.translations[props.session.defaultLanguage].title
        }
      />
    </ListItemButton>
  );
}

interface ModuleProps {
  module: ModuleRecord;
  activeSession: SessionRecord | null;
  index: number;
  onSessionClick: Function;
}

function Module(props: ModuleProps) {
  let moduleClass = "module";

  return (
    <ListItem className={moduleClass} sx={{ padding: 0 }}>
      <div>{props.module.translations[props.module.defaultLanguage].title}</div>

      <List className="sessionslist">
        {props.module.sessions.map((p2: SessionRecord, index2: number) => {
          return (
            <Session
              session={p2}
              index={index2}
              key={"session-" + p2.id}
              activeSession={props.activeSession}
              onClick={(session: SessionRecord) => {
                props.onSessionClick(session);
              }}
            />
          );
        })}
      </List>
    </ListItem>
  );
}

function EducationTab() {
  const translations = getTranslations();
  const { event } = useRouteLoaderData("event") as EventRouterResponse;
  const { sessions, modules } = useRouteLoaderData(
    "education"
  ) as SessionsRouterResponse;
  const [session, setSession] = useState<SessionRecord | null>(null);
  const revalidator = useRevalidator();

  const getRootLevel = (): (SessionRecord | ModuleRecord)[] => {
    let arr: (SessionRecord | ModuleRecord)[] = [];
    arr.forEach((module) => {
      module.sessions = sessions.filter((session) => {
        return session.blockId === module.id;
      });
      if (module.sessions.length > 0) {
        arr.push(module);
      }
    });
    sessions.forEach((session) => {
      if (session.blockId === null) {
        arr.push(session);
      }
    });

    arr.sort(
      (a: SessionRecord | ModuleRecord, b: SessionRecord | ModuleRecord) => {
        if (a.sortorder === b.sortorder) {
          return 0;
        }
        if (a.sortorder < b.sortorder) {
          return -1;
        }
        return 1;
      }
    );
    return arr;
  };
  const [rootlvl, setRootlvl] = useState(getRootLevel());

  const getBranchLevel = (
    root: (SessionRecord | ModuleRecord)[],
    branchId: number
  ) => {
    return root.find((record: SessionRecord | ModuleRecord) => {
      return !record.activity && record.id === branchId;
    }) as ModuleRecord;
  };

  if (session == null && module == null && rootlvl.length > 0) {
    let d = rootlvl[0];
    if (d.activity) {
      setSession(d);
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="row"
      className="collectionedit"
      flex="1"
      overflow="hidden"
    >
      <Box
        width={"260px"}
        className="list"
        display="flex"
        flexDirection={"column"}
        gap={1}
        p={1}
      >
        <Box display="flex" flexDirection={"column"} gap={1}>
          <Button variant="contained" onClick={() => {}}>
            {translations["sessions.addmodule"]}
          </Button>
        </Box>
        <Box display="flex" flexDirection={"column"} flex={1} flexShrink={0}>
          <List
            className="sessionslist"
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              flexShrink: 0,
              gap: "8px",
            }}
          >
            {rootlvl.map((p: SessionRecord | ModuleRecord, index: number) => {
              if (p.activity) {
                return (
                  <Session
                    session={p}
                    key={"session-" + p.id}
                    index={index}
                    onClick={() => {
                      setSession(p);
                    }}
                    activeSession={session}
                  />
                );
              } else {
                return (
                  <Module
                    key={"module-" + p.id}
                    index={index}
                    module={p}
                    onSessionClick={(s: SessionRecord) => {
                      setSession(s);
                    }}
                    activeSession={session}
                  />
                );
              }
            })}
          </List>
        </Box>
      </Box>
      {!session ? (
        <Box flex="1" p={2}>
          <Typography>{translations["sessions.empty"]}</Typography>
        </Box>
      ) : null}
      {!!session ? <EducationForm key={session.id} session={session} /> : null}
    </Box>
  );
}

export default EducationTab;
