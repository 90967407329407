import { Box, Paper } from "@mui/material"
import { ReactWidget, WidgetRecord } from "../../../../../../interfaces/Interfaces"
import React from "react"
import { getTranslations } from "../../../../../../translations/Translations";

const RegistrationField = ({ widget }: ReactWidget) => {
    const translations = getTranslations();
    return (<Paper elevation={1} sx={{borderRadius: '0', padding: '1rem'}}>{translations["event.website.options.registrationform.title"]}</Paper>)
};

export default RegistrationField;