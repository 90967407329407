import { Box } from "@mui/material";
import React from "react";
import { getTranslations } from "../../../../../translations/Translations";
const translations = getTranslations();

const NoRegistrations = () => {
  return (
    <Box flex={1} sx={{ padding: "1rem" }}>
      {translations["scanner.noRegistrations"]}
    </Box>
  );
};

export default NoRegistrations;
