import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, TimeField } from "@mui/x-date-pickers";
import { getTranslations } from "../../../../translations/Translations";
import {
  Category,
  EventRouterResponse,
  IRecord,
  Tag,
} from "../../../../interfaces/Interfaces";
import {
  useRouteLoaderData,
  useRevalidator,
  useOutletContext,
} from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import SaveBar from "../../../../components/SaveBar";
import {
  formatDate,
  formatTime,
  isValidDateValue,
  parseDate,
  parseTime,
} from "../../../../helpers/DateFunctions";
import { DisplayErrors } from "../../../../components/DisplayErrors";

interface FlagContext {
  setFlags: Function;
}

function EventDetailsTab() {
  const translations = getTranslations();
  const [categories, setCategories] = useState<Category[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const { event } = useRouteLoaderData("event") as EventRouterResponse;
  const revalidator = useRevalidator();
  const { setFlags } = useOutletContext<FlagContext>();
  const [eventFlags, setEventFlags] = useState<{ [key: string]: boolean }>({
    educationTime: event.educationTime,
    partner: event.partner,
    location: event.location,
    catering: event.catering,
    venue: event.venue,
  });
  const [selectedTags, setSelectedTags] = useState([...event.tags]);

  useEffect(() => {
    const controller = new AbortController();
    fetch("/api/categories", {
      signal: controller.signal,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setCategories(result.data);
      })
      .catch((err: DOMException) => {
        if (err.name === "AbortError") {
        }
      });

    fetch("/api/tags", {
      signal: controller.signal,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setTags(result.data);
      })
      .catch((err: DOMException) => {
        if (err.name === "AbortError") {
        }
      });
    return () => {
      controller.abort();
    };
  }, []);

  const getFlatObj = (obj: IRecord) => {
    return {
      id: obj.id,
      title: obj.translations[obj.defaultLanguage].title || "",
      description: obj.translations[obj.defaultLanguage].description || "",
      startdate: parseDate(obj.startdate),
      enddate: parseDate(obj.enddate),
      starttime: parseTime(obj.starttime),
      endtime: parseTime(obj.endtime),
      language: obj.defaultLanguage,
      defaultLanguage: obj.defaultLanguage,
      apellaOnly: obj.apellaOnly,
      blocker: obj.blocker,
      repeating: obj.repeating || "",
      bill: obj.bill,
      perspectivum: obj.perspectivum,
      perspectivumUrl: obj.perspectivumUrl || "",
      gotowebinar: obj.gotowebinar,
      gotowebinarUrl: obj.gotowebinarUrl || "",
      type: obj.type,
      categoryIds: event.categories.map((cat: Category) => cat.id),
      tagIds: event.tags.map((tag: Tag) => tag.id),
    };
  };

  const getSubmissableData = (obj: IRecord) => {
    return {
      ...obj,
      startdate: formatDate(obj.startdate),
      enddate: formatDate(obj.enddate),
      starttime: formatTime(obj.starttime) || "",
      endtime: formatTime(obj.endtime) || "",
    };
  };

  const formik = useFormik({
    initialValues: getFlatObj(event),
    validationSchema: Yup.object({
      title: Yup.string()
        .max(400, translations["errors.maxlength400"])
        .required(translations["errors.requiredfield"]),
      description: Yup.string().required(translations["errors.requiredfield"]),
      startdate: Yup.date()
        .typeError(translations["errors.invaliddate"])
        .required(translations["errors.requiredfield"]),
      enddate: Yup.date()
        .typeError(translations["errors.invaliddate"])
        .required(translations["errors.requiredfield"])
        .when(["startdate"], ([startdate], schema) => {
          if (isValidDateValue(startdate)) {
            return schema.min(
              startdate,
              translations["eventdetailstab.errors.startdatebeforeenddate"]
            );
          } else {
            return schema;
          }
        }),
      starttime: Yup.date()
        .typeError(translations["errors.invaliddate"])
        .notRequired(),
      endtime: Yup.date()
        .typeError(translations["errors.invaliddate"])
        .notRequired()
        .when(
          ["startdate", "enddate", "starttime"],
          ([startdate, enddate, starttime], schema) => {
            if (
              isValidDateValue(startdate) &&
              isValidDateValue(enddate) &&
              isValidDateValue(starttime) &&
              formatDate(startdate) === formatDate(enddate)
            ) {
              return schema.min(
                starttime,
                translations["eventdetailstab.errors.startdatebeforeenddate"]
              );
            } else {
              return schema;
            }
          }
        ),
      perspetivum: Yup.boolean(),
      perspectivumUrl: Yup.string().when("perspectivum", {
        is: true,
        then: (schema) => schema.required(translations["errors.requiredfield"]),
        otherwise: (schema) => schema,
      }),
      gotowebinarUrl: Yup.string().when("gotowebinar", {
        is: true,
        then: (schema) => schema.required(translations["errors.requiredfield"]),
        otherwise: (schema) => schema,
      }),
    }),
    onSubmit: (values) => {
      fetch("/api/events/" + event.id + "/", {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("jwt"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(getSubmissableData(values)),
      })
        .then((res) => res.json())
        .then((jsondata) => {
          if (jsondata.success) {
            revalidator.revalidate();
          }
        })
        .finally(() => {
          formik.setSubmitting(false);
          formik.resetForm({
            values: values,
          });
        });
    },
  });

  const handleEventFlagsChange = (
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    let key = e.target.name;
    let flags: { [key: string]: boolean | null } = {
      educationTime: null,
      partner: null,
      catering: null,
      venue: null,
      location: null,
    };
    if (flags.hasOwnProperty(key)) {
      let savebody: IRecord = {
        id: event.id,
        language: event.language,
        defaultLanguage: event.defaultLanguage,
      };
      savebody[key] = checked;
      flags[key] = checked;

      setFlags(flags);
      fetch("/api/events/" + event.id + "/", {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("jwt"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(savebody),
      })
        .then((res) => res.json())
        .then((jsondata) => {
          if (jsondata.success) {
            revalidator.revalidate();
            setEventFlags({
              ...eventFlags,
              [key]: checked,
            });
          }
        });
    }
  };

  const colorCategory = (categoryId: number) => {
    if (formik.values.categoryIds.includes(categoryId)) {
      return "primary";
    }
    return "default";
  };

  const colorEventType = (eventType: string): "default" | "primary" => {
    if (formik.values.type === eventType) {
      return "primary";
    }
    return "default";
  };

  /*
<Box display="flex" flexDirection={"row"}>
            <Box width="260px" flexShrink={0}></Box>
            <Box>
              <FormControlLabel
                labelPlacement="start"
                control={<Checkbox {...formPropsFn.switch("makler34C")} />}
                label={translations["eventdetailstab.makler34C"]}
              />
              <FormControlLabel
                labelPlacement="start"
                control={<Checkbox {...formPropsFn.switch("makler34D")} />}
                label={translations["eventdetailstab.makler34D"]}
              />
              <FormControlLabel
                labelPlacement="start"
                control={<Checkbox {...formPropsFn.switch("makler34I")} />}
                label={translations["eventdetailstab.makler34I"]}
              />
              <FormControlLabel
                labelPlacement="start"
                control={<Checkbox {...formPropsFn.switch("makler34F")} />}
                label={translations["eventdetailstab.makler34F"]}
              />
            </Box>
          </Box>
  */

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{ display: "flex", flexGrow: 1, overflow: "scroll" }}
    >
      <Box display="flex" flexDirection="column" position="relative" flex={1}>
        <Box display="flex" flexDirection="row" flex={1}>
          <Box flex="1" display="flex" flexDirection="column" gap={1} p={2}>
            <Box display="flex" flexDirection={"row"}>
              <Box width="260px" flexShrink={0} className="fieldlabel">
                <Typography>{translations["eventdetailstab.title"]}</Typography>
              </Box>
              <FormControl fullWidth>
                <TextField
                  size="small"
                  {...formik.getFieldProps("title")}
                  error={Boolean(formik.touched.title && formik.errors.title)}
                ></TextField>
                <DisplayErrors
                  name="title"
                  touched={Boolean(formik.touched.title)}
                  errors={formik.errors.title}
                />
              </FormControl>
            </Box>
            <Box display="flex" flexDirection={"row"}>
              <Box width="260px" flexShrink={0} className="fieldlabel">
                <Typography>
                  {translations["eventdetailstab.description"]}
                </Typography>
              </Box>
              <FormControl fullWidth>
                <TextField
                  size="small"
                  multiline={true}
                  minRows={5}
                  error={Boolean(
                    formik.touched.description && formik.errors.description
                  )}
                  {...formik.getFieldProps("description")}
                ></TextField>
                <DisplayErrors
                  name="description"
                  touched={Boolean(formik.touched.description)}
                  errors={formik.errors.description}
                />
              </FormControl>
            </Box>
            <Box display="flex" flexDirection={"row"}>
              <Box width="260px" flexShrink={0} className="fieldlabel">
                <Typography>{translations["eventdetailstab.start"]}</Typography>
              </Box>

              <FormControl>
                <Box display="flex" flexDirection={"row"} gap={1}>
                  <DatePicker
                    format="dd.MM.yyyy"
                    onAccept={(eventorvalue) => {
                      formik.setFieldValue("startdate", eventorvalue);
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        name: "startdate",
                        onChange: (eventorvalue) => {
                          formik.setFieldValue("startdate", eventorvalue);
                        },
                        error: Boolean(
                          formik.touched.startdate && formik.errors.startdate
                        ),
                        onBlur: formik.handleBlur,
                      },
                    }}
                    value={formik.values.startdate}
                  />
                  <TimeField
                    format="HH:mm"
                    slotProps={{
                      textField: {
                        size: "small",
                        name: "starttime",
                        onChange: (eventorvalue) => {
                          formik.setFieldValue("starttime", eventorvalue);
                        },
                        error: Boolean(
                          formik.touched.starttime && formik.errors.starttime
                        ),
                        onBlur: formik.handleBlur,
                      },
                    }}
                    value={formik.values.starttime}
                  />
                </Box>
                <DisplayErrors
                  name="startdate"
                  touched={Boolean(formik.touched.startdate)}
                  errors={formik.errors.startdate}
                />
                <DisplayErrors
                  name="starttime"
                  touched={Boolean(formik.touched.starttime)}
                  errors={formik.errors.starttime}
                />
              </FormControl>
            </Box>
            <Box display="flex" flexDirection={"row"}>
              <Box width="260px" flexShrink={0} className="fieldlabel">
                <Typography>{translations["eventdetailstab.end"]}</Typography>
              </Box>
              <FormControl>
                <Box display="flex" flexDirection={"row"} gap={1}>
                  <DatePicker
                    format="dd.MM.yyyy"
                    onAccept={(eventorvalue) => {
                      formik.setFieldValue("enddate", eventorvalue);
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        name: "enddate",
                        onChange: (eventorvalue) => {
                          formik.setFieldValue("enddate", eventorvalue);
                        },
                        error: Boolean(
                          formik.touched.enddate && formik.errors.enddate
                        ),
                        onBlur: formik.handleBlur,
                      },
                    }}
                    value={formik.values.enddate}
                  />
                  <TimeField
                    format="HH:mm"
                    slotProps={{
                      textField: {
                        size: "small",
                        name: "endtime",
                        onChange: (eventorvalue) => {
                          formik.setFieldValue("endtime", eventorvalue);
                        },
                        error: Boolean(
                          formik.touched.endtime && formik.errors.endtime
                        ),
                        onBlur: formik.handleBlur,
                      },
                    }}
                    value={formik.values.endtime}
                  />
                </Box>
                <DisplayErrors
                  name="enddate"
                  touched={Boolean(formik.touched.enddate)}
                  errors={formik.errors.enddate}
                />
                <DisplayErrors
                  name="endtime"
                  touched={Boolean(formik.touched.endtime)}
                  errors={formik.errors.endtime}
                />
              </FormControl>
            </Box>
            <Box display="flex" flexDirection={"row"}>
              <Box width="260px" flexShrink={0} className="fieldlabel">
                <Typography>
                  {translations["eventdetailstab.apellaonly"]}
                </Typography>
              </Box>
              <Box className="switchlabel">
                {translations["eventdetailstab.apellaonlyno"]}
              </Box>
              <Box flex={1}>
                <FormControlLabel
                  control={
                    <Switch
                      name="apellaOnly"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.apellaOnly}
                    />
                  }
                  label={translations["eventdetailstab.apellaonlyyes"]}
                />
              </Box>
            </Box>
          </Box>
          <Box flex="1" display="flex" flexDirection="column" gap={1} p={2}>
            <Box className="eventtoggles">
              <Box display="flex" flexDirection={"row"} className="fieldlabel">
                <Box width="260px" flexShrink={0}>
                  <Typography>
                    {translations["eventdetailstab.venue"]}
                  </Typography>
                </Box>
                <Box className="switchlabel">
                  {translations["eventdetailstab.venueno"]}
                </Box>
                <Box flex={1}>
                  <FormControlLabel
                    control={
                      <Switch
                        name="venue"
                        onChange={handleEventFlagsChange}
                        checked={eventFlags.venue}
                      />
                    }
                    label={translations["eventdetailstab.venueyes"]}
                  />
                </Box>
              </Box>
              <Box display="flex" flexDirection={"row"}>
                <Box width="260px" flexShrink={0} className="fieldlabel">
                  <Typography>
                    {translations["eventdetailstab.location"]}
                  </Typography>
                </Box>
                <Box className="switchlabel">
                  {translations["eventdetailstab.locationno"]}
                </Box>
                <Box flex={1}>
                  <FormControlLabel
                    control={
                      <Switch
                        name="location"
                        onChange={handleEventFlagsChange}
                        checked={eventFlags.location}
                      />
                    }
                    label={translations["eventdetailstab.locationyes"]}
                  />
                </Box>
              </Box>
              <Box display="flex" flexDirection={"row"}>
                <Box width="260px" flexShrink={0} className="fieldlabel">
                  <Typography>
                    {translations["eventdetailstab.educationtime"]}
                  </Typography>
                </Box>
                <Box className="switchlabel">
                  {translations["eventdetailstab.educationtimeno"]}
                </Box>
                <Box>
                  <FormControlLabel
                    control={
                      <Switch
                        name="educationTime"
                        onChange={handleEventFlagsChange}
                        checked={eventFlags.educationTime}
                      />
                    }
                    label={translations["eventdetailstab.educationtimeyes"]}
                  />
                </Box>
              </Box>
              <Box display="flex" flexDirection={"row"}>
                <Box width="260px" flexShrink={0} className="fieldlabel">
                  <Typography>
                    {translations["eventdetailstab.partner"]}
                  </Typography>
                </Box>
                <Box className="switchlabel">
                  {translations["eventdetailstab.partnerno"]}
                </Box>
                <Box flex={1}>
                  <FormControlLabel
                    control={
                      <Switch
                        name="partner"
                        onChange={handleEventFlagsChange}
                        checked={eventFlags.partner}
                      />
                    }
                    label={translations["eventdetailstab.partneryes"]}
                  />
                </Box>
              </Box>
              <Box display="flex" flexDirection={"row"}>
                <Box width="260px" flexShrink={0} className="fieldlabel">
                  <Typography>
                    {translations["eventdetailstab.catering"]}
                  </Typography>
                </Box>
                <Box className="switchlabel">
                  {translations["eventdetailstab.cateringno"]}
                </Box>
                <Box flex={1}>
                  <FormControlLabel
                    control={
                      <Switch
                        name="catering"
                        onChange={handleEventFlagsChange}
                        checked={eventFlags.catering}
                      />
                    }
                    label={translations["eventdetailstab.cateringyes"]}
                  />
                </Box>
              </Box>
            </Box>

            <Box display="flex" flexDirection={"row"}>
              <Box width="260px" flexShrink={0} className="fieldlabel">
                <Typography>
                  {translations["eventdetailstab.blocker"]}
                </Typography>
              </Box>
              <Box className="switchlabel">
                {translations["eventdetailstab.blockerno"]}
              </Box>
              <Box flex={1}>
                <FormControlLabel
                  control={
                    <Switch
                      name="blocker"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.blocker}
                    />
                  }
                  label={translations["eventdetailstab.blockeryes"]}
                />
              </Box>
            </Box>
            <Box display="flex" flexDirection={"row"}>
              <Box width="260px" flexShrink={0} className="fieldlabel">
                <Typography>
                  {translations["eventdetailstab.repeating"]}
                </Typography>
              </Box>
              <TextField
                fullWidth
                size="small"
                error={Boolean(
                  formik.touched.repeating && formik.errors.repeating
                )}
                name="repeating"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.repeating}
              ></TextField>
            </Box>
            <Box display="flex" flexDirection={"row"}>
              <Box width="260px" flexShrink={0} className="fieldlabel">
                <Typography>{translations["eventdetailstab.type"]}</Typography>
              </Box>
              <Box display="flex" flexDirection={"row"} flexWrap="wrap" gap={1}>
                <Chip
                  label={translations["WEBINAR"]}
                  color={colorEventType("WEBINAR")}
                  onClick={() => {
                    formik.setFieldValue("type", "WEBINAR");
                  }}
                />
                <Chip
                  label={translations["SEMINAR"]}
                  color={colorEventType("SEMINAR")}
                  onClick={() => {
                    formik.setFieldValue("type", "SEMINAR");
                  }}
                />
                <Chip
                  label={translations["TRAINING"]}
                  color={colorEventType("TRAINING")}
                  onClick={() => {
                    formik.setFieldValue("type", "TRAINING");
                  }}
                />
                <Chip
                  label={translations["WORKSHOP"]}
                  color={colorEventType("WORKSHOP")}
                  onClick={() => {
                    formik.setFieldValue("type", "WORKSHOP");
                  }}
                />
                <Chip
                  label={translations["PRESENTATION"]}
                  color={colorEventType("PRESENTATION")}
                  onClick={() => {
                    formik.setFieldValue("type", "PRESENTATION");
                  }}
                />
              </Box>
            </Box>
            <Box display="flex" flexDirection={"row"}>
              <Box width="260px" flexShrink={0} className="fieldlabel">
                <Typography>
                  {translations["eventdetailstab.category"]}
                </Typography>
              </Box>
              <Box display="flex" flexDirection={"row"} flexWrap="wrap" gap={1}>
                {categories.map((value, index) => {
                  return (
                    <Chip
                      label={value.translations[value.defaultLanguage].title}
                      key={index}
                      color={colorCategory(value.id)}
                      onClick={() => {
                        if (formik.values.categoryIds.includes(value.id)) {
                          formik.setFieldValue(
                            "categoryIds",
                            formik.values.categoryIds.filter((id) => {
                              return id !== value.id;
                            })
                          );
                        } else {
                          formik.setFieldValue("categoryIds", [
                            ...formik.values.categoryIds,
                            value.id,
                          ]);
                        }
                      }}
                    />
                  );
                })}
              </Box>
            </Box>

            <Box display="flex" flexDirection={"row"}>
              <Box width="260px" flexShrink={0} className="fieldlabel">
                <Typography>{translations["eventdetailstab.bill"]}</Typography>
              </Box>
              <Box className="switchlabel">
                {translations["eventdetailstab.billno"]}
              </Box>
              <Box flex={1}>
                <FormControlLabel
                  control={
                    <Switch
                      name="bill"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.bill}
                    />
                  }
                  label={translations["eventdetailstab.billyes"]}
                />
              </Box>
            </Box>
            <Box display="flex" flexDirection={"row"}>
              <Box width="260px" flexShrink={0} className="fieldlabel">
                <Typography>
                  {translations["eventdetailstab.perspectivum"]}
                </Typography>
              </Box>
              <Box className="switchlabel">
                {translations["eventdetailstab.perspectivumno"]}
              </Box>
              <Box flex={1}>
                <FormControlLabel
                  control={
                    <Switch
                      name="perspectivum"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.perspectivum}
                    />
                  }
                  label={translations["eventdetailstab.perspectivumyes"]}
                />
              </Box>
            </Box>
            {formik.values.perspectivum ? (
              <Box display="flex" flexDirection={"row"}>
                <Box width="260px" flexShrink={0} className="fieldlabel">
                  <Typography>
                    {translations["eventdetailstab.perspectivumurl"]}
                  </Typography>
                </Box>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    error={Boolean(
                      formik.touched.perspectivumUrl &&
                        formik.errors.perspectivumUrl
                    )}
                    {...formik.getFieldProps("perspectivumUrl")}
                  ></TextField>
                  <DisplayErrors
                    name="perspectivumUrl"
                    touched={Boolean(formik.touched.perspectivumUrl)}
                    errors={formik.errors.perspectivumUrl}
                  />
                </FormControl>
              </Box>
            ) : null}
            <Box display="flex" flexDirection={"row"}>
              <Box width="260px" flexShrink={0} className="fieldlabel">
                <Typography>
                  {translations["eventdetailstab.gotowebinar"]}
                </Typography>
              </Box>
              <Box className="switchlabel">
                {translations["eventdetailstab.gotowebinarno"]}
              </Box>
              <Box flex={1}>
                <FormControlLabel
                  control={
                    <Switch
                      name="gotowebinar"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.gotowebinar}
                    />
                  }
                  label={translations["eventdetailstab.gotowebinaryes"]}
                />
              </Box>
            </Box>
            {formik.values.gotowebinar ? (
              <Box display="flex" flexDirection={"row"}>
                <Box width="260px" flexShrink={0} className="fieldlabel">
                  <Typography>
                    {translations["eventdetailstab.gotowebinarurl"]}
                  </Typography>
                </Box>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    error={Boolean(
                      formik.touched.gotowebinarUrl &&
                        formik.errors.gotowebinarUrl
                    )}
                    {...formik.getFieldProps("gotowebinarUrl")}
                  ></TextField>
                  <DisplayErrors
                    name="gotowebinarUrl"
                    touched={Boolean(formik.touched.gotowebinarUrl)}
                    errors={formik.errors.gotowebinarUrl}
                  />
                </FormControl>
              </Box>
            ) : null}
            <Box display="flex" flexDirection={"row"}>
              <Box width="260px" flexShrink={0} className="fieldlabel">
                <Typography>{translations["eventdetailstab.tags"]}</Typography>
              </Box>
              <Autocomplete
                multiple
                fullWidth
                size="small"
                options={tags}
                getOptionLabel={(option) =>
                  option.translations[option.defaultLanguage].title || ""
                }
                isOptionEqualToValue={(option: Tag, value: Tag) => {
                  return option?.id === value?.id;
                }}
                onChange={(
                  event: React.SyntheticEvent<Element, Event>,
                  value: Tag | Tag[],
                  reason: string
                ): void => {
                  if (Array.isArray(value)) {
                    formik.setFieldValue(
                      "tagIds",
                      value.map((v) => v.id)
                    );
                    setSelectedTags(value);
                  } else {
                    formik.setFieldValue("tagIds", [value.id]);
                    setSelectedTags([value]);
                  }
                }}
                value={selectedTags}
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
          </Box>
        </Box>
        <SaveBar formik={formik} />
      </Box>
    </form>
  );
}

export default EventDetailsTab;
