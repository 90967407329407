import React, { useState, useEffect } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { getTranslations } from "../../../../translations/Translations";
import {
  Outlet,
  matchPath,
  useLocation,
  useRouteLoaderData,
} from "react-router-dom";
import {
  EventRouterResponse,
  FlagStates,
} from "../../../../interfaces/Interfaces";
import { CustomNavLink } from "../../../../components/CustomNavLink";
import { BlinkingDiv } from "../../../../components/BlinkingDiv";

function EventDetails() {
  const translations = getTranslations();
  const { event } = useRouteLoaderData("event") as EventRouterResponse;
  const [flags, setFlags] = useState<FlagStates>({
    catering: null,
    educationTime: null,
    venue: null,
    partner: null,
    location: null,
  });

  useEffect(() => {
    // Function to reset the flag to null after 5 seconds
    const resetFlag = (flagKey: keyof FlagStates) => {
      const timer = setTimeout(() => {
        setFlags((prevFlags) => ({
          ...prevFlags,
          [flagKey]: null,
        }));
      }, 5000);

      return () => clearTimeout(timer);
    };

    // Watch for changes in the flags object
    for (const flagKey in flags) {
      if (
        flags.hasOwnProperty(flagKey) &&
        flags[flagKey as keyof FlagStates] === true
      ) {
        resetFlag(flagKey as keyof FlagStates);
      }
    }
  }, [flags]);

  const location = useLocation();
  const urls = [
    "/admin/events/" + event.id + "/details",
    "/admin/events/" + event.id + "/details/education",
    "/admin/events/" + event.id + "/details/partners",
    "/admin/events/" + event.id + "/details/catering",
    "/admin/events/" + event.id + "/details/venue",
    "/admin/events/" + event.id + "/details/locations",
    "/admin/events/" + event.id + "/details/registrationsettings",
    "/admin/events/" + event.id + "/details/relations",
  ];
  const getActiveUrl = () => {
    let active = [...urls].reverse().filter((url) => {
      return matchPath(url + "/*", location.pathname);
    });
    if (active.length === 0) {
      return urls[0];
    } else {
      return active[0];
    }
  };

  return (
    <Box display="flex" flex={1} overflow="hidden" flexDirection={"column"}>
      <Box>
        <Tabs value={getActiveUrl()} className="subtabbar">
          <Tab
            label={translations["eventdetails.eventdetails"]}
            component={CustomNavLink}
            value={urls[0]}
            to={urls[0]}
            replace={false}
          />
          <Tab
            label={translations["eventdetails.registrationsettings"]}
            component={CustomNavLink}
            value={urls[6]}
            to={urls[6]}
            replace={false}
          />
          {event.educationTime ? (
            <Tab
              label={
                flags.educationTime === true ? (
                  <BlinkingDiv>
                    {translations["eventdetails.education"]}
                  </BlinkingDiv>
                ) : (
                  translations["eventdetails.education"]
                )
              }
              component={CustomNavLink}
              value={urls[1]}
              to={urls[1]}
              replace={false}
            />
          ) : null}
          {event.partner ? (
            <Tab
              label={
                flags.partner === true ? (
                  <BlinkingDiv>
                    {translations["eventdetails.partner"]}
                  </BlinkingDiv>
                ) : (
                  translations["eventdetails.partner"]
                )
              }
              component={CustomNavLink}
              value={urls[2]}
              to={urls[2]}
              replace={false}
            />
          ) : null}
          {event.catering ? (
            <Tab
              label={
                flags.catering === true ? (
                  <BlinkingDiv>
                    {translations["eventdetails.catering"]}
                  </BlinkingDiv>
                ) : (
                  translations["eventdetails.catering"]
                )
              }
              component={CustomNavLink}
              value={urls[3]}
              to={urls[3]}
              replace={false}
            />
          ) : null}
          {event.venue ? (
            <Tab
              label={
                flags.venue === true ? (
                  <BlinkingDiv>
                    {translations["eventdetails.venue"]}
                  </BlinkingDiv>
                ) : (
                  translations["eventdetails.venue"]
                )
              }
              component={CustomNavLink}
              value={urls[4]}
              to={urls[4]}
              replace={false}
            />
          ) : null}
          {event.location ? (
            <Tab
              label={
                flags.location === true ? (
                  <BlinkingDiv>
                    {translations["eventdetails.location"]}
                  </BlinkingDiv>
                ) : (
                  translations["eventdetails.location"]
                )
              }
              component={CustomNavLink}
              value={urls[5]}
              to={urls[5]}
              replace={false}
            />
          ) : null}
          <Tab
            label={translations["eventdetails.relations"]}
            component={CustomNavLink}
            value={urls[7]}
            to={urls[7]}
            replace={false}
          />
        </Tabs>
      </Box>
      <Box display="flex" overflow="hidden" flex={1} flexDirection={"column"}>
        <Outlet context={{ setFlags }} />
      </Box>
    </Box>
  );
}

export default EventDetails;
