import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import {
  SidePanelConfig,
  WebpageRecord,
  WidgetRecord,
} from "../../../../../interfaces/Interfaces";
import { getTranslations } from "../../../../../translations/Translations";
import { Form, Formik } from "formik";
import {
  MyColorPicker,
  MyNumberTextField,
  MyTextAreaField,
  MyTextField,
} from "./Fields";
import { useRevalidator } from "react-router-dom";
import SidePanel from "./SidePanel";
import {
  returnDefaultJsonConfig,
  returnDefaultWebpageJsonConfig,
  webpageOptions,
  widgettypes,
} from "./WidgetField";
import TextAlignSelector from "./widgets/options/TextAlignSelector";
import MCEEditor from "../../../../../components/HTMLEditor";
import { ColorPicker } from "react-color-palette";
import AlignSelector from "./widgets/options/AlignSelector";
import HtmlNumberSelector from "./widgets/options/HtmlNumberSelector";
import MarginSelector from "./widgets/options/MarginSelector";
import NumberField from "./widgets/options/NumberField";
import SrcSelector from "./widgets/options/SrcSelector";
import ListItemManager from "./widgets/options/ListItemManager";
import FlexAlignSelector from "./widgets/options/FlexAlignSelector";
import TogglePicker from "./widgets/options/TogglePicker";
import MultiSrcSelector from "./widgets/options/MultiSrcSelector";
import VideoSrcSelector from "./widgets/options/VideoSrcSelector";

const translations = getTranslations();

const findDifferences = (obj1: any, obj2: any) => {
  const differences: any = {};

  Object.keys(obj1).forEach((key) => {
    if (obj1[key] !== obj2[key]) {
      differences[key] = { oldValue: obj1[key], newValue: obj2[key] };
    }
  });

  return differences;
};

const formatDifferences = (obj: any) => {
  const newValues: any = {};

  Object.keys(obj).forEach((key) => {
    newValues[key] = obj[key].newValue;
  });

  return newValues;
};

const getFlatWebpageObj = (obj: WebpageRecord | null) => {
  if (obj !== null) {
    return {
      eventId: obj.eventId,
      id: obj.id,
      jsonConfig: returnDefaultWebpageJsonConfig(obj.jsonConfig),
    };
  } else {
    return {};
  }
};

const getFlatWidgetObj = (obj: WidgetRecord | null) => {
  if (obj !== null) {
    return {
      eventId: obj.eventId,
      id: obj.id,
      type: obj.type,
      language: obj.defaultLanguage,
      defaultLanguage: obj.defaultLanguage,
      title: obj.translations[obj.defaultLanguage].title,
      options: obj.translations[obj.defaultLanguage].options,
      widgetId: obj.widgetId,
      sortorder: obj.sortorder,
      jsonConfig: returnDefaultJsonConfig(obj.type, obj.config),
      content: obj.translations[obj.defaultLanguage].content || "",
      min: obj.min,
      max: obj.max,
      defaultfield: obj.defaultfield || null,
      required: obj.required || false,
      attendance: obj.attendance || false,
      adminfield: obj.adminfield || false,
    };
  } else {
    return {};
  }
};

const RenderFieldOption = ({
  type,
  data,
  option,
}: {
  type: string;
  data: string;
  option: string;
}) => {
  const optionTitleTranslation =
    translations[`event.website.options.${option}`];
  const label = optionTitleTranslation ? optionTitleTranslation : option;
  switch (option) {
    case "columns":
      return <NumberField name={`jsonConfig.${option}`} label={label} />;
    case "textAlign":
      return <TextAlignSelector name={`jsonConfig.${option}`} label={label} />;
    case "align":
      return <AlignSelector name={`jsonConfig.${option}`} label={label} />;
    case "alignItems":
      return <FlexAlignSelector name={`jsonConfig.${option}`} label={label} />;
    case "content":
      if (type === "paragraph") {
        return (
          <MCEEditor name={option} isSubmitting={false} title={label} />
        );
      } else {
        return <MyTextAreaField name={option} label={label} />;
      }
    case "videoSrc":
      return <VideoSrcSelector name={`jsonConfig.${option}`} label={label} />;
    case "src":
    case "backgroundImageSrc":
    case "listimage":
      return <SrcSelector name={`jsonConfig.${option}`} label={label} />;
    case "srcs":
      return <MultiSrcSelector name={`jsonConfig.${option}`} label={label} />
    case "padding":
    case "margin":
    case "columnPadding":
    case "columnMargin":
      return <MarginSelector name={`jsonConfig.${option}`} label={label} />;
    case "fontSize":
    case "width":
    case "containerWidth":
      return <HtmlNumberSelector name={`jsonConfig.${option}`} label={label} />;
    case "backgroundColor":
    case "columnBackgroundColor":
    case "fontColor":
      return <MyColorPicker name={`jsonConfig.${option}`} label={label} />;
    case "slantedTop":
    case "slantedBottom":
      return null;
    case "listitems":
      return <ListItemManager name={`jsonConfig.${option}`} label={label} />;
    case "showNavigation":
      return (
        <TogglePicker
          name={`jsonConfig.${option}`}
          yesText={"Ja"}
          noText={"Nein"}
          label={label}
        />
      );
    default:
      return <MyTextField name={`jsonConfig.${option}`} label={label} />;
  }
};

const TypeOptions = ({
  webpage,
  widget,
  jsonConfig,
}: {
  webpage?: WebpageRecord;
  widget?: WidgetRecord;
  jsonConfig: string;
}) => {
  if (widget) {
    const widgetTemplate = widgettypes.find(
      (obj) => obj["type"] === widget.type
    );
    if (widgetTemplate && widgetTemplate.fieldOptions) {
      return (
        <>
          <Box sx={{ mb: "1rem" }}>
            {translations[`event.website.options.type`]}:{" "}
            {translations[`website.widgettype.${widget.type}`]}
          </Box>
          {widgetTemplate.fieldOptions.map((option, index) => {
            return (
              <Box key={`${widget.type}-${index}`} sx={{ mb: "1rem" }}>
                <RenderFieldOption
                  key={`${widget.type}-${index}`}
                  data={jsonConfig}
                  type={widget.type}
                  option={option}
                />
              </Box>
            );
          })}
        </>
      );
    } else {
      return null;
    }
  }

  if (webpage) {
    return (
      <>
        <Box sx={{ mb: "1rem" }}>
          {translations["website.webpage.settings"]}
        </Box>
        {webpageOptions.fieldOptions.map((option, index) => {
          return (
            <Box key={`${webpage.type}-${index}`} sx={{ mb: "1rem" }}>
              <RenderFieldOption
                key={`${webpage.type}-${index}`}
                data={jsonConfig}
                type={webpage.type}
                option={option}
              />
            </Box>
          );
        })}
      </>
    );
  }
};

const ContentSidePanel = ({
  config,
  setConfig,
}: {
  config: SidePanelConfig;
  setConfig: Function;
}) => {
  const { activeWidget, activeWebpage } = config;
  const [widgetInitialValues, setWidgetInitialValues] = useState<any>(
    getFlatWidgetObj(null)
  );
  const [webpageInitialValues, setWebpageInitialValues] = useState<any>(
    getFlatWebpageObj(null)
  );
  const revalidator = useRevalidator();

  useEffect(() => {
    if (activeWidget) {
      setWidgetInitialValues(getFlatWidgetObj(activeWidget));
    }
  }, [activeWidget]);

  useEffect(() => {
    console.log("activeWebpage", activeWebpage);
    if (activeWebpage) {
      setWebpageInitialValues(getFlatWebpageObj(activeWebpage));
    }
  }, [activeWebpage]);

  useEffect(() => {
    console.log("webpageInitialValues", webpageInitialValues);
  }, [webpageInitialValues]);

  const clearActiveField = () => {
    if (activeWidget) {
      setWidgetInitialValues(getFlatWidgetObj(null));
    }

    if (activeWebpage) {
      setWebpageInitialValues(getFlatWebpageObj(null));
    }

    setConfig((prev: SidePanelConfig) => ({
      ...prev,
      activeWidget: null,
      activeWebpage: null,
    }));
  };

  if (
    activeWidget &&
    activeWidget !== null &&
    Object.keys(widgetInitialValues).length > 0
  ) {
    return (
      <Formik
        initialValues={widgetInitialValues}
        enableReinitialize={true}
        onSubmit={(values) => {
          const differences = findDifferences(widgetInitialValues, values);
          const newValues = formatDifferences(differences);
          if (newValues.jsonConfig) {
            newValues.jsonConfig = JSON.stringify({
              ...widgetInitialValues.jsonConfig,
              ...newValues.jsonConfig,
            });
          }

          if (activeWidget !== null && Object.keys(newValues).length > 0) {
            fetch("/api/widgets/" + activeWidget.id, {
              method: "put",
              headers: {
                Accept: "application/json",
                Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                "Content-Type": "application/json",
              },
              body: JSON.stringify(newValues),
            })
              .then((res) => res.json())
              .then((jsondata) => {
                if (jsondata.success) {
                  revalidator.revalidate();
                }
              });
          }
        }}
      >
        {({ values, resetForm, isValid }) => {
          return (
            <SidePanel sx={{ maxWidth: "300px" }} onClose={clearActiveField}>
              <Form
                noValidate
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <Box sx={{ flex: 1 }}>
                  {activeWidget !== null &&
                    typeof activeWidget !== "undefined" && (
                      <TypeOptions
                        widget={activeWidget}
                        jsonConfig={activeWidget.jsonConfig}
                      />
                    )}
                </Box>
                <Button variant="contained" type="submit">
                  {translations["save"]}
                </Button>
              </Form>
            </SidePanel>
          );
        }}
      </Formik>
    );
  }

  if (
    activeWebpage &&
    activeWebpage !== null &&
    Object.keys(webpageInitialValues).length > 0
  ) {
    return (
      <Formik
        initialValues={webpageInitialValues}
        enableReinitialize={true}
        onSubmit={(values) => {
          const differences = findDifferences(webpageInitialValues, values);
          const newValues = formatDifferences(differences);
          if (newValues.jsonConfig) {
            newValues.jsonConfig = JSON.stringify({
              ...webpageInitialValues.jsonConfig,
              ...newValues.jsonConfig,
            });
          }

          if (activeWebpage !== null && Object.keys(newValues).length > 0) {
            fetch("/api/webpages/" + activeWebpage.id, {
              method: "put",
              headers: {
                Accept: "application/json",
                Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                "Content-Type": "application/json",
              },
              body: JSON.stringify(newValues),
            })
              .then((res) => res.json())
              .then((jsondata) => {
                if (jsondata.success) {
                  revalidator.revalidate();
                }
              });
          }
        }}
      >
        {({ values, resetForm, isValid }) => {
          return (
            <SidePanel sx={{ maxWidth: "300px" }} onClose={clearActiveField}>
              <Form
                noValidate
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <Box sx={{ flex: 1 }}>
                  {activeWebpage !== null &&
                    typeof activeWebpage !== "undefined" && (
                      <TypeOptions
                        webpage={activeWebpage}
                        jsonConfig={activeWebpage.jsonConfig}
                      />
                    )}
                </Box>
                <Button variant="contained" type="submit">
                  {translations["save"]}
                </Button>
              </Form>
            </SidePanel>
          );
        }}
      </Formik>
    );
  }

  return null;
};

export default ContentSidePanel;
