import { Grid, Box, Typography } from "@mui/material";
import { useField } from "formik";
import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { fontList } from "../fonts/fonts";
import { useRouteLoaderData } from "react-router-dom";



const MCEEditor = ({
    name,
    isSubmitting,
    title,
    initialBackgroundColor
}: {
    name: string;
    isSubmitting: boolean | undefined;
    title: string;
    initialBackgroundColor?: string
}) => {
    const editorRef = useRef<any>(null);
    const [field, meta, helpers] = useField(name);
    const apiKey = "yrryrudygx2u6hc488a53p3fx0isfijo0rfge9bbs4kfc94w";
    let backgroundColor = 'grey';


    if (initialBackgroundColor) {
        backgroundColor = initialBackgroundColor;
    }

    const generateFonts = () => {
        let fontFamilyString = '';
        fontList.forEach((font) => {
            fontFamilyString += `${font.name}=${font.value};`
        })
        return fontFamilyString;
    }

    return (
        <>
            <Box width="260px" flexShrink={0} className="fieldlabel">
                <Typography>{title}</Typography>
            </Box>
            {/* @ts-ignore*/}
            <Editor
                onInit={(evt: any, editor: any) => (editorRef.current = editor)}
                apiKey={apiKey}
                disabled={isSubmitting}
                value={field.value}
                onEditorChange={(stringifiedHtmlValue: string) => {
                    helpers.setValue(stringifiedHtmlValue);
                }}
                init={{
                    resize: false,
                    height: 300,
                    menubar: false,
                    width: "100%",
                    plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "code",
                        "help",
                        "wordcount",
                    ],
                    font_family_formats: generateFonts(),
                    setup: (myEditor) => {
                        myEditor.on('blur', (e) => {
                           var getParagraph = editorRef.current.dom.select('p');
                           console.log('para', getParagraph);
                        })
                    },
                    toolbar:
                        "fontfamily | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | ",
                    content_style:
                        "body { font-family:Lato,sans-serif; font-size:16px; background-color: " + backgroundColor +"; }",
                }}
            />
        </>
    );
};

export default MCEEditor;