import { Box, Button } from "@mui/material";
import { ContainedStyledButton } from "./ContainedStyledButton";
import { useState } from "react";
import React from "react";
import { CheckType } from "../../../../../interfaces/Interfaces";
import { getTranslations } from "../../../../../translations/Translations";



const Registrations = ({
  checkType,
  setCheckType,
  audioRef,
  handleStartScanner,
  iosInitialized
}: {
  checkType: CheckType;
  setCheckType: Function;
  audioRef: HTMLAudioElement | null;
  handleStartScanner: Function;
  iosInitialized: [boolean, (str: boolean) => void];
}) => {
  
  const translations = getTranslations();
  const [isIosInitialized, setIsIosInitiliazed] = iosInitialized;
  return (
    <Box padding={"1rem"} display="flex" flexDirection="column">
      <Box
        display="flex"
        flexDirection="row"
        flex="1"
        sx={{ marginBottom: "1rem" }}
      >
        <Button
          disableElevation
          variant="contained"
          sx={
            checkType === "checkin"
              ? {
                  flex: 1,
                  backgroundColor: "#006e55!important",
                  marginRight: "1rem",
                }
              : {
                  flex: 1,
                  backgroundColor: "#d1d1d1!important",
                  marginRight: "1rem",
                }
          }
          onClick={() => {
            setCheckType("checkin");
          }}
        >
          Check-In
        </Button>
        <Button
          disableElevation
          variant="contained"
          sx={
            checkType === "checkout"
              ? { flex: 1, backgroundColor: "#ea7c15!important" }
              : { flex: 1, backgroundColor: "#d1d1d1!important" }
          }
          onClick={() => {
            setCheckType("checkout");
          }}
        >
          Check-Out
        </Button>
      </Box>
      <ContainedStyledButton
        sx={[{ width: "100%" }]}
        variant="contained"
        disableRipple
        className="scan-button"
        onClick={() => {
          if (audioRef && !isIosInitialized) {
            audioRef.play();
            setTimeout(function () {
              if (audioRef !== null) {
                audioRef.setAttribute("src", "/scanQR.mp3");
              }
            }, 500);
            setIsIosInitiliazed(true);
          }
          handleStartScanner();
        }}
      >
        {translations["scanner.scan"]}
      </ContainedStyledButton>
    </Box>
  );
};

export default Registrations;
