import { Box, FormControl, TextField, Typography } from "@mui/material"
import React from "react"
import { FieldRecordWidget } from "../../../../../../interfaces/Interfaces";
import FieldLabel from "./FieldLabel"

const RegTextArea = ({ registrationfield }: FieldRecordWidget) => {
  return (
    <Box display="flex" flexDirection="column">
      <FieldLabel field={registrationfield} />
      <FormControl sx={{ minWidth: "300px" }}>
      <TextField size="small" multiline={true} minRows={3}></TextField>
      </FormControl>
    </Box>
  )
}

export default RegTextArea;