import React, { forwardRef } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";

export const CustomNavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(
  (props, ref) => (
    <NavLink
      {...props}
      ref={ref}
      replace={false}
      className={({ isActive }) =>
        `${props.className} ${isActive ? "selected" : ""}`
      }
    >
      {props.children}
    </NavLink>
  )
);
