import React from "react";
import LeftToolbar from "../components/LeftToolbar";
import { Outlet } from "react-router-dom";

import MobileAdmin from './MobileAdmin';
import isMobile from "../helpers/isMobile";

function Admin() {

  return (
    <>
      {isMobile() ? (
        <MobileAdmin />
      ) : (
        <>
          <LeftToolbar />
          <main className="content">
            <Outlet />
          </main>
        </>
      )}
    </>
  );
}

export default Admin;
