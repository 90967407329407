import React, { useState, useEffect } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircleOutline';
import CheckBoxBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import PlayCirlceIcon from '@mui/icons-material/PlayCircleOutline';

import {
  Box
} from "@mui/material";
import {
  ParsedFile
} from "../interfaces/Interfaces";
import AlertDialog from './AlertDialog';
import './QuiltedImageList.scss';
import { getTranslations } from '../translations/Translations';


const srcset = (image: string | undefined, size: number, rows = 1, cols = 1) => {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${size * rows
      }&fit=crop&auto=format&dpr=2 2x`,
  };
}


interface QuiltedImageListProps {
  images: ParsedFile[],
  onImgClick?: Function,
  onImgRemove?: Function,
  deleteMode: boolean,
  selectedIds: number[];
  setSelectedIds: (ids: number[]) => void;
  onRemoveFiles?: Function
}

const QuiltedImageList = (props: QuiltedImageListProps) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [alertShown, setAlertShown] = useState<boolean>(false);
  const [confirmFunction, setConfirmFunction] = useState<Function>(() => { });
  const [deleteMode, setDeleteMode] = useState<boolean>(props.deleteMode);
  const [selectedIndexes, setSelectedIndexes] = useState<number[]>([]);
  const translations = getTranslations();
  

  const handleToggleSelection = (e: React.MouseEvent<HTMLButtonElement>, index: number, id: number) => {
    e.stopPropagation();
    const updatedIndexes = selectedIndexes.includes(index)
      ? selectedIndexes.filter((id) => id !== index)
      : [...selectedIndexes, index];

      const updatedIds = props.selectedIds.includes(id)
      ? props.selectedIds.filter((itemId) => itemId !== id)
      : [...props.selectedIds, id];

      setSelectedIndexes(updatedIndexes);
      props.setSelectedIds(updatedIds);
  };

  useEffect(() => {
    setDeleteMode(props.deleteMode);
  }, [props.deleteMode]);

  useEffect(() => {
    if (props.selectedIds.length === 0) {
      setSelectedIndexes([])
    }
  }, [props.selectedIds])

  const shadow = '0px 0px 5px rgba(255,255,255,1)';

  return (
    <ImageList variant="quilted" cols={6} rowHeight={300} sx={{ width: '100%' }}>
      {props.images.map((item, index) => (
        <ImageListItem
          key={index}
          data-item-id={item.id}
          cols={item.cols || 1}
          rows={item.rows || 1}
          sx={[{ '&:hover': { cursor: 'pointer' } }]}
          onMouseOver={() => setHoveredIndex(index)}
          onMouseOut={() => setHoveredIndex(null)}
          onClick={() => {
            if (props?.onImgClick) {
              props?.onImgClick(index);
            }
          }}
        >
          <img {...srcset(item.url, 200, item.rows, item.cols)} alt={item.title} loading="lazy" />
          {deleteMode && (
            <ImageListItemBar
              sx={{
                background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
              }}
              //title={item.title}
              position="top"
              actionPosition="right"
              actionIcon={
                <IconButton sx={{ color: 'white' }} aria-label={`star ${item.title}`} onClick={e => handleToggleSelection(e, index, item.id)}>
                  {selectedIndexes.includes(index) ? <CheckBoxIcon sx={{
                    filter: `drop-shadow(${shadow})`,
                    color: '#ea7c15'
                  }} /> : <CheckBoxBlankIcon />}
                </IconButton>
              }
            />
          )}
          {hoveredIndex === index && !deleteMode && (
            <ImageListItemBar
              sx={{
                background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
              }}
              //title={item.title}
              position="top"
              actionPosition="right"
              actionIcon={
                <IconButton sx={{ color: 'white' }} aria-label={`star ${item.title}`} onClick={(e) => {
                  e.stopPropagation();
                  setConfirmFunction(() => {
                    return () => {
                      if (props.onImgRemove) {
                        props.onImgRemove(item.id)
                      }
                    }
                  })
                  setAlertShown(true);
                }}>
                  <RemoveCircleIcon />

                </IconButton>
              }
            />
          )}
          {item.type === 'video' && <Box className="video-overlay" m={4}><PlayCirlceIcon sx={{ color: 'white', width: '50%', height: '50%' }} /></Box>}
        </ImageListItem>
      ))}
      <AlertDialog
        open={[alertShown, setAlertShown]}
        title={translations["media.alertRemoveTitel"]}
        description={translations["media.alertRemoveDescription"]}
        confirmFunction={confirmFunction}
      />
    </ImageList>
  );
};

export default QuiltedImageList;