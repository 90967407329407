import React from "react";
import { getTranslations } from "../../translations/Translations";
import TopToolbar from "../../components/TopToolbar";
import { Typography } from "@mui/material";

function Statistics() {
  const translations = getTranslations();

  return (
    <>
      <TopToolbar
        title={
          <Typography fontSize={"24px"} mt={1} ml={2}>
            {translations["statistics.title"]}
          </Typography>
        }
      />
    </>
  );
}

export default Statistics;
