import React, { useEffect, useState } from "react";
import {
  getGridTranslations,
  getTranslations,
} from "../../translations/Translations";
import {
  DataGrid,
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
  GridRowParams,
} from "@mui/x-data-grid";
import {
  useRevalidator,
  useRouteLoaderData,
  useSearchParams,
} from "react-router-dom";
import * as Yup from "yup";
import {
  INewRecord,
  IRecord,
  RolesRouterResponse,
} from "../../interfaces/Interfaces";
import EditIcon from "@mui/icons-material/Edit";
import useDebounce from "../../helpers/useDebounce";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { DisplayErrors } from "../../components/DisplayErrors";
import SaveBar from "../../components/SaveBar";
import { useAuth } from "../../AuthProvider";

function Roles() {
  const auth = useAuth();
  const translations = getTranslations();
  const gridtranslations = getGridTranslations();
  const { contexts, roles} = useRouteLoaderData(
    "roles"
  ) as RolesRouterResponse;
  const [open, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce(search, 500);
  const [myDialogTitle, setMyDialogTitle] = useState<string>(
    translations["roles.newroletitle"]
  );
  const revalidator = useRevalidator();
  const [contextId, setContextId] = useState<number | string>("");
  const setSearchParams = useSearchParams()[1];
  const getFlatObj = (obj: IRecord) => {
    return {
      id: obj.id,
      name: obj.name || "",
      contextId: obj.contextId || "",
      event: obj.event || false,
      scanner: obj.scanner || false,
      budget: obj.budget || false,
      contacts: obj.contacts || false,
    };
  };
  const [initialValues, setInitialValues] = useState<IRecord>(
    getFlatObj({ id: -1 })
  );

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required(translations["errors.requiredfield"]),
      contextId: Yup.number().when(["id"], ([id], schema) => {
        if (id !== 1 && id !== 2) {
          return schema.required(translations["errors.requiredfield"]);
        } else {
          return schema;
        }
      }),
    }),
    onSubmit: (values) => {
      if (values.id === -1) {
        var formvalues: INewRecord = { ...values };
        delete formvalues.id;

        fetch("/api/roles/", {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("jwt"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formvalues),
        })
          .then((res) => res.json())
          .then((jsondata) => {
            if (jsondata.success) {
              values.id = jsondata.data.id;
              revalidator.revalidate();
            }
          })
          .finally(() => {
            formik.setSubmitting(false);
            setOpen(false);
          });
      } else {
        fetch("/api/roles/" + values.id + "/", {
          method: "PUT",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("jwt"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        })
          .then((res) => res.json())
          .then((jsondata) => {
            if (jsondata.success) {
              revalidator.revalidate();
            }
          })
          .finally(() => {
            formik.setSubmitting(false);
            setOpen(false);
          });
      }
    },
  });

  useEffect(() => {
    let obj: INewRecord = {};
    if (debouncedSearch !== "") {
      obj.search = debouncedSearch;
    }
    if (contextId !== "") {
      obj.contextId = contextId;
    }
    setSearchParams(obj);
  }, [debouncedSearch, contextId, setSearchParams]);

  let columns: (GridColDef | GridActionsColDef)[] = [
    {
      field: "id",
      headerName: translations["roles.id"],
    },
    {
      field: "name",
      headerName: translations["roles.name"],
      flex: 1,
    },
    {
      field: "contextId",
      headerName: translations["roles.context"],
      valueFormatter(params) {
        return (
          contexts.find((context) => context.id === params.value)?.name || "-"
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          onClick={() => {
            let role = roles.find((role) => role.id === params.id);
            if (typeof role !== "undefined") {
              let obj = getFlatObj(role as IRecord);
              formik.resetForm({
                touched: {},
                errors: {},
              });
              setInitialValues(obj);
              formik.setValues(obj, false);
              setMyDialogTitle(translations["roles.editroletitle"]);
              setOpen(true);
            }
          }}
          label="Edit"
        />,
      ],
    },
  ];

  let rows = roles;

  return (
    <>
      <Box p={1}>
        <TextField
          label={translations["roles.search"]}
          value={search}
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        ></TextField>
        {auth?.user?.role?.id === 1 ? (
          <FormControl
            sx={{ minWidth: "231px" }}
            size="small"
            style={{ marginLeft: 8 }}
          >
            <InputLabel id="contextlabel">
              {translations["roles.context"]}
            </InputLabel>
            <Select
              labelId="contextlabel"
              value={contextId}
              onChange={(e) => {
                setContextId(e.target.value);
              }}
            >
              <MenuItem value="" key={0}>
                -
              </MenuItem>
              {contexts.map((cat) => {
                return (
                  <MenuItem key={cat.id} value={cat.id}>
                    {cat.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        ) : null}
        <Button
          style={{ marginLeft: 8 }}
          variant="contained"
          onClick={() => {
            let obj = getFlatObj({ id: -1 });
            formik.resetForm({
              values: obj,
              touched: {},
              errors: {},
            });
            setInitialValues(obj);
            formik.setValues(obj);
            setMyDialogTitle(translations["roles.newroletitle"]);
            setOpen(true);
          }}
        >
          {translations["roles.add"]}
        </Button>
      </Box>
      <DataGrid
        rows={rows}
        columns={columns}
        localeText={gridtranslations}
      ></DataGrid>
      <Dialog
        className="window"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        scroll="body"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>{myDialogTitle}</DialogTitle>
        <DialogContent dividers={false}>
          <form onSubmit={formik.handleSubmit}>
            <Box flex="1" display="flex" flexDirection="column" gap={1} p={2}>
              <Box display="flex" flexDirection={"row"}>
                <Box width="260px" flexShrink={0} className="fieldlabel">
                  <Typography>{translations["roles.name"]}</Typography>
                </Box>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    error={Boolean(formik.touched.name && formik.errors.name)}
                    {...formik.getFieldProps("name")}
                  ></TextField>
                  <DisplayErrors
                    name="name"
                    touched={Boolean(formik.touched.name)}
                    errors={formik.errors.name}
                  />
                </FormControl>
              </Box>
              <Box display="flex" flexDirection={"row"}>
                <Box width="260px" flexShrink={0} className="fieldlabel">
                  <Typography>{translations["logins.context"]}</Typography>
                </Box>
                <FormControl fullWidth>
                  <Select
                    name="contextId"
                    size="small"
                    value={formik.values.contextId}
                    error={Boolean(
                      formik.touched.contextId && formik.errors.contextId
                    )}
                    onChange={(e) => {
                      formik.setFieldValue("contextId", e.target.value);
                    }}
                  >
                    {contexts.map((context) => {
                      return (
                        <MenuItem key={context.id} value={context.id}>
                          {context.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <DisplayErrors
                    name="contextId"
                    touched={Boolean(formik.touched.contextId)}
                    errors={formik.errors.contextId}
                  />
                </FormControl>
              </Box>
              <Divider />
              <Box display="flex" flexDirection={"row"}>
                <Box width="260px" flexShrink={0} className="fieldlabel">
                  <Typography>{translations["roles.event"]}</Typography>
                </Box>
                <Box className="switchlabel">
                  {translations["roles.eventno"]}
                </Box>
                <Box flex={1}>
                  <FormControlLabel
                    control={
                      <Switch
                        name="event"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        checked={formik.values.event}
                      />
                    }
                    label={translations["roles.eventyes"]}
                  />
                </Box>
              </Box>
              <Box display="flex" flexDirection={"row"}>
                <Box width="260px" flexShrink={0} className="fieldlabel">
                  <Typography>{translations["roles.contacts"]}</Typography>
                </Box>
                <Box className="switchlabel">
                  {translations["roles.contactsno"]}
                </Box>
                <Box flex={1}>
                  <FormControlLabel
                    control={
                      <Switch
                        name="contacts"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        checked={formik.values.contacts}
                      />
                    }
                    label={translations["roles.contactsyes"]}
                  />
                </Box>
              </Box>
              <Box display="flex" flexDirection={"row"}>
                <Box width="260px" flexShrink={0} className="fieldlabel">
                  <Typography>{translations["roles.budget"]}</Typography>
                </Box>
                <Box className="switchlabel">
                  {translations["roles.budgetno"]}
                </Box>
                <Box flex={1}>
                  <FormControlLabel
                    control={
                      <Switch
                        name="budget"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        checked={formik.values.budget}
                      />
                    }
                    label={translations["roles.budgetyes"]}
                  />
                </Box>
              </Box>

              <Box display="flex" flexDirection={"row"}>
                <Box width="260px" flexShrink={0} className="fieldlabel">
                  <Typography>{translations["roles.scanner"]}</Typography>
                </Box>
                <Box className="switchlabel">
                  {translations["roles.scannerno"]}
                </Box>
                <Box flex={1}>
                  <FormControlLabel
                    control={
                      <Switch
                        name="scanner"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        checked={formik.values.scanner}
                      />
                    }
                    label={translations["roles.scanneryes"]}
                  />
                </Box>
              </Box>
            </Box>
          </form>
        </DialogContent>
        <SaveBar
          formik={formik}
          onCancel={() => {
            setOpen(false);
          }}
          manualSubmit={true}
        />
      </Dialog>
    </>
  );
}

export default Roles;
