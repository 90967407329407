import { Grid } from "@mui/material";
import { MyInputProps } from "../../Interfaces";
import { MultiSelectGroup } from "./MultiSelectGroup";
import { RadioInputGroup } from "./RadioInputGroup";
import { TextInput } from "./TextInput";
import { MyCheckBox} from "./Checkbox";
import { TypographyField } from "./TypographyField";
import React from "react";
import MyComboBox from "./MyComboBox";
import { TermsContainer } from "./TermsContainer";

export const Field = (props: MyInputProps) => {
  const { type, title, options, fieldId, beforeContent, afterContent, config, ...restProps } = props;

  const componentMap: { [key: string]: JSX.Element | null } = {
    title: <TypographyField data-field={fieldId} title={title} />,
    type: <TypographyField data-field={fieldId} title={title} />,
    text: <TextInput data-field={fieldId} type={type} {...restProps} />,
    email: <TextInput data-field={fieldId} type={type} {...restProps} />,
    textarea: <TextInput multiline maxRows={4} minRows={2} data-field={fieldId} type={type} {...restProps} />,
    checkbox: <MyCheckBox data-field={fieldId} {...restProps}/>,
    terms: <TermsContainer data-field={fieldId} config={config} {...restProps} />,
    radio: (
      typeof options !== 'undefined' && options !== null && options.length > 0 ? (
        <RadioInputGroup data-field={fieldId} options={options} beforeContent={beforeContent} afterContent={afterContent} {...restProps} />
      ) : null
    ),
    combobox: (
      typeof options !== 'undefined' && options !== null && options.length > 0 ? <MyComboBox data-field={fieldId} options={options} {...restProps}/> : null  
    ),
    multivalues: (
      typeof options !== 'undefined' && options !== null && options.length > 0 ? <MultiSelectGroup data-field={fieldId} options={options} beforeContent={beforeContent} afterContent={afterContent} {...restProps} /> : null
    ),
  };

  let returnCmp = null;
  if (type === 'radio' || type === 'multivalues') {
    returnCmp = <Grid item xs={12} flexDirection={'column'} mb={'1rem'}>
      {componentMap[type]}
    </Grid>
  } else {
    returnCmp = <Grid item xs={12} flexDirection={'column'} mb={'1rem'}>
      {beforeContent}
      {componentMap[type]}
      {afterContent}
    </Grid>
  }


  return returnCmp;
};