import React, { useEffect } from "react";
import { Box, FormControl, TextField, Typography } from "@mui/material";
import { getTranslations } from "../../../../translations/Translations";
import {
  EventRecord,
  IRecord,
  PartnerRecord,
} from "../../../../interfaces/Interfaces";
import { useRevalidator } from "react-router-dom";
import { useFormik } from "formik";
import SaveBar from "../../../../components/SaveBar";
import UploadControl from "../../../../components/UploadControl";
import * as Yup from "yup";
import { DisplayErrors } from "../../../../components/DisplayErrors";

interface PartnerInfoFormProps {
  partner: PartnerRecord;
  onDelete: Function;
  event: EventRecord;
  setDirty: Function;
}

function PartnerInfoForm(props: PartnerInfoFormProps) {
  const translations = getTranslations();
  const revalidator = useRevalidator();
  const setDirty = props.setDirty;

  const getFlatObj = (obj: IRecord) => {
    return {
      id: obj.id,
      name: obj.name || "",
      email: obj.email || "",
      tel: obj.tel || "",
      logoId: obj.logoId,
      info: obj.translations[obj.defaultLanguage].info || "",
      language: obj.defaultLanguage,
      defaultLanguage: obj.defaultLanguage,
    };
  };

  const formik = useFormik({
    initialValues: getFlatObj(props.partner),
    validationSchema: Yup.object({
      name: Yup.string()
        .max(400, translations["errors.maxlength400"])
        .required(translations["errors.requiredfield"]),
      email: Yup.string()
        .email(translations["errors.invalidemail"])
        .required(translations["errors.requiredfield"]),
      tel: Yup.string().required(translations["errors.requiredfield"]),
      info: Yup.string().notRequired(),
      logoId: Yup.string().notRequired(),
    }),
    onSubmit: (values) => {
      fetch("/api/partners/" + props.partner.id + "/", {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("jwt"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
        .then((res) => res.json())
        .then((jsondata) => {
          console.log(formik);
          if (jsondata.success) {
            revalidator.revalidate();
          }
        })
        .finally(() => {
          formik.setSubmitting(false);
          formik.resetForm({
            values: values,
          });
        });
    },
  });

  useEffect(() => {
    setDirty(formik.dirty);
  }, [formik.dirty, setDirty]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{ display: "flex", flexGrow: 1, overflow: "scroll" }}
    >
      <Box flex="1" display="flex" flexDirection="column">
        <Box flex="1" display="flex" flexDirection="column" gap={1} p={2}>
          <Box display="flex" flexDirection={"row"} className="fieldlabel">
            <Box width="260px" flexShrink={0}>
              <Typography>{translations["partnerform.name"]}</Typography>
            </Box>
            <FormControl fullWidth>
              <TextField
                size="small"
                error={Boolean(formik.touched.name && formik.errors.name)}
                {...formik.getFieldProps("name")}
              ></TextField>
              <DisplayErrors
                name="name"
                touched={Boolean(formik.touched.name)}
                errors={formik.errors.name}
              />
            </FormControl>
          </Box>
          <Box display="flex" flexDirection={"row"}>
            <Box width="260px" flexShrink={0} className="fieldlabel">
              <Typography>{translations["partnerform.logo"]}</Typography>
            </Box>
            <UploadControl
              name="logoId"
              purpose="partnerlogo"
              value={formik.values.logoId}
              eventId={props.event.id}
              setFieldValue={formik.setFieldValue}
              onUploadComplete={(response) => {
                fetch("/api/partners/" + props.partner.id + "/", {
                  method: "PUT",
                  headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    id: props.partner.id,
                    language: props.partner.language,
                    defaultLanguage: props.partner.defaultLanguage,
                    logoId: response.publicId,
                  }),
                })
                  .then((res) => res.json())
                  .then((jsondata) => {
                    if (jsondata.success) {
                      revalidator.revalidate();
                    }
                  });
              }}
              onDeleteComplete={() => {
                fetch("/api/partners/" + props.partner.id + "/", {
                  method: "PUT",
                  headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    id: props.partner.id,
                    language: props.partner.language,
                    defaultLanguage: props.partner.defaultLanguage,
                    logoId: "",
                  }),
                })
                  .then((res) => res.json())
                  .then((jsondata) => {
                    if (jsondata.success) {
                      revalidator.revalidate();
                    }
                  });
              }}
            />
          </Box>
          <Box display="flex" flexDirection={"row"}>
            <Box width="260px" flexShrink={0} className="fieldlabel">
              <Typography>{translations["partnerform.email"]}</Typography>
            </Box>
            <FormControl fullWidth>
              <TextField
                size="small"
                error={Boolean(formik.touched.email && formik.errors.email)}
                {...formik.getFieldProps("email")}
              ></TextField>
              <DisplayErrors
                name="email"
                touched={Boolean(formik.touched.email)}
                errors={formik.errors.email}
              />
            </FormControl>
          </Box>
          <Box display="flex" flexDirection={"row"}>
            <Box width="260px" flexShrink={0} className="fieldlabel">
              <Typography>{translations["partnerform.tel"]}</Typography>
            </Box>
            <FormControl fullWidth>
              <TextField
                size="small"
                error={Boolean(formik.touched.tel && formik.errors.tel)}
                {...formik.getFieldProps("tel")}
              ></TextField>
              <DisplayErrors
                name="tel"
                touched={Boolean(formik.touched.tel)}
                errors={formik.errors.tel}
              />
            </FormControl>
          </Box>
          <Box display="flex" flexDirection={"row"}>
            <Box width="260px" flexShrink={0} className="fieldlabel">
              <Typography>{translations["partnerform.otherinfo"]}</Typography>
            </Box>
            <FormControl fullWidth>
              <TextField
                multiline={true}
                minRows={3}
                error={Boolean(formik.touched.info && formik.errors.info)}
                {...formik.getFieldProps("info")}
              ></TextField>
              <DisplayErrors
                name="info"
                touched={Boolean(formik.touched.info)}
                errors={formik.errors.info}
              />
            </FormControl>
          </Box>
        </Box>
        <SaveBar
          formik={formik}
          onDelete={() => {
            props.onDelete();
          }}
        />
      </Box>
    </form>
  );
}

export default PartnerInfoForm;
