import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Collapse,
  FormControl,
  InputAdornment,
  InputLabel,
  Link,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import TopToolbar from "../../components/TopToolbar";
import { getTranslations } from "../../translations/Translations";
import { EventRecord } from "../../interfaces/Interfaces";
import { DatePicker } from "@mui/x-date-pickers";
import { ExpandLess, ExpandMore, Search } from "@mui/icons-material";
import BlockIcon from "@mui/icons-material/Block";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SellIcon from "@mui/icons-material/Sell";
import MapIcon from "@mui/icons-material/Map";
import LanguageIcon from "@mui/icons-material/Language";
import EditIcon from "@mui/icons-material/Edit";
import useDebounce from "../../helpers/useDebounce";
import { Category } from "../../interfaces/Interfaces";
import {
  formatDate,
  formatTime,
  localeFormatDate,
  parseDate,
  parseTime,
} from "../../helpers/DateFunctions";

interface jsonobject {
  [key: string]: string;
}

function Eventslist() {
  const translations = getTranslations();
  const [rows, setRows] = useState<EventRecord[]>([]);
  const navFn = useNavigate();
  const [opened, setOpened] = useState<number[]>([]);

  const [search, setSearch] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [pickedDate, setPickedDate] = useState<Date | null>(null);
  const [categories, setCategories] = useState<Category[]>([]);
  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    const controller = new AbortController();
    let url: string = "/api/categories";

    fetch(url, {
      signal: controller.signal,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setCategories(result.data);
      })
      .catch((err: DOMException) => {
        if (err.name === "AbortError") {
        }
      });
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    let url: string = "/api/events";
    let objlist: jsonobject[] = [];

    if (debouncedSearch !== "") {
      objlist.push({ property: "search", value: debouncedSearch });
    }
    if (category !== "") {
      objlist.push({ property: "category", value: category });
    }
    if (pickedDate !== null) {
      objlist.push({
        property: "startdate",
        value: formatDate(pickedDate) || "",
      });
    }

    if (objlist.length > 0) {
      let params: URLSearchParams = new URLSearchParams({
        filter: JSON.stringify(objlist),
      });
      url = url + "?" + params.toString();
    }

    fetch(url, {
      signal: controller.signal,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setRows(result.data);
      })
      .catch((err: DOMException) => {
        if (err.name === "AbortError") {
        }
      });
    return () => {
      controller.abort();
    };
  }, [debouncedSearch, category, pickedDate]);

  const getEventDetails = (event: EventRecord) => {
    let startdate = localeFormatDate(parseDate(event.startdate));
    let enddate = localeFormatDate(parseDate(event.enddate));
    let starttime = formatTime(parseTime(event.starttime));
    let endtime = formatTime(parseTime(event.endtime));

    return (
      <>
        {event.blocker ? (
          <div>
            <BlockIcon />
            <span>{" " + translations["events.blocker"] + " "}</span>
          </div>
        ) : (
          ""
        )}
        <div>
          <CalendarTodayIcon />
          <span>{" " + startdate}</span>
          {startdate !== enddate ? <span>{"-" + enddate}</span> : ""}{" "}
        </div>
        {starttime !== null ? (
          <div>
            <AccessTimeIcon />
            {" " + starttime}
            {starttime !== endtime && endtime !== null ? "-" + endtime : ""}
          </div>
        ) : null}
        <div>{translations[event.type]}</div>
      </>
    );
  };

  const getAddress = (event: EventRecord) => {
    let addressString = "";

    if (addressString === "") {
      return null;
    } else {
      return (
        <Box display="flex" flexDirection="row">
          <Box>
            <MapIcon />
          </Box>
          <Box>{addressString}</Box>
        </Box>
      );
    }
  };

  return (
    <>
      <TopToolbar
        title={
          <Typography fontSize={"24px"} mt={1} ml={2}>
            {translations["events.title"]}
          </Typography>
        }
      />
      <Box
        display="flex"
        flexDirection="column"
        flex={1}
        pl={2}
        pr={2}
        className="events"
        overflow="hidden"
      >
        <Box display="flex" flexDirection="row" gap={1}>
          <TextField
            label={translations["events.search"]}
            value={search}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          ></TextField>
          <FormControl sx={{ minWidth: "231px" }}>
            <InputLabel id="categorylabel">
              {translations["events.category"]}
            </InputLabel>
            <Select
              labelId="categorylabel"
              value={category}
              onChange={(e) => {
                setCategory(e.target.value);
              }}
            >
              <MenuItem value="" key={0}>
                -
              </MenuItem>
              {categories.map((cat) => {
                return (
                  <MenuItem key={cat.id} value={cat.id}>
                    {cat.translations[cat.defaultLanguage].title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <DatePicker
            label={translations["events.date"]}
            format="d.M.y"
            onChange={function (value: Date | null): void {
              if (
                (value instanceof Date && !isNaN(value.getTime())) ||
                value === null
              ) {
                setPickedDate(value);
              }
            }}
            value={pickedDate}
          />
        </Box>
        <List
          className="eventlist"
          sx={{ overflow: "scroll", marginTop: "10px" }}
        >
          {rows.map((event: EventRecord) => {
            return (
              <Box
                component="section"
                key={event.id}
                className="listitem"
                mb={1}
              >
                <ListItemButton
                  className="event"
                  onClick={() => {
                    if (opened.includes(event.id)) {
                      setOpened(
                        opened.filter((val) => {
                          return val !== event.id;
                        })
                      );
                    } else {
                      setOpened([...opened, event.id]);
                    }
                  }}
                >
                  <ListItemText disableTypography={true}>
                    <Box
                      justifyContent="space-between"
                      display="flex"
                      flexDirection="row"
                      className="todoevent"
                      pr="4px"
                    >
                      <Box>
                        {"# " +
                          event.id +
                          " " +
                          event.translations[event.defaultLanguage].title}
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="row"
                        gap={2}
                        className="eventdetails"
                      >
                        {getEventDetails(event)}
                      </Box>
                    </Box>
                  </ListItemText>
                  {opened.includes(event.id) ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse
                  in={opened.includes(event.id)}
                  timeout="auto"
                  unmountOnExit
                >
                  <Box
                    className="eventdescription"
                    display="flex"
                    flexDirection="row"
                  >
                    <Box flex={1} display="flex" flexDirection="column" gap={1}>
                      <Box display="flex" flexDirection="row" gap={1}>
                        {event.categories.map((category) => {
                          return (
                            <div className="category" key={category.id}>
                              <SellIcon />
                              {
                                category.translations[category.defaultLanguage]
                                  .title
                              }
                            </div>
                          );
                        })}
                      </Box>
                      <Box>
                        {event.translations[event.defaultLanguage].description}
                      </Box>
                    </Box>
                    <Box margin="10px" bgcolor="#EEE" width="2px"></Box>
                    <Box
                      width="160px"
                      display="flex"
                      flexDirection="column"
                      gap={1}
                      justifyContent="center"
                    >
                      {getAddress(event)}
                      <Box display="flex" flexDirection="row">
                        <Box>
                          <LanguageIcon />
                        </Box>
                        <Box></Box>
                      </Box>
                    </Box>
                    <Box margin="10px" bgcolor="#EEE" width="2px"></Box>
                    <Box width="160px" textAlign="center">
                      <Button
                        variant="contained"
                        startIcon={<EditIcon />}
                        onClick={() => {
                          navFn("/admin/events/" + event.id + "/", {
                            replace: false,
                          });
                        }}
                      >
                        {translations["events.edit"]}
                      </Button>
                    </Box>
                  </Box>
                </Collapse>
              </Box>
            );
          })}
        </List>
      </Box>
    </>
  );
}

export default Eventslist;
