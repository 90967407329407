import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { FieldRecord, SidePanelConfig } from "../../../../../interfaces/Interfaces";
import { getTranslations } from "../../../../../translations/Translations";
import { Form, Formik } from "formik";
import { MyCheckBox, MyTextField, OptionsList, ParentSelector } from "./Fields";
import { useRevalidator } from "react-router-dom";
import SidePanel from "./SidePanel";
import { registrationfields } from "./RegistrationFields";
import MCEEditor from "../../../../../components/HTMLEditor";
import SrcSelector from "./widgets/options/SrcSelector";

const translations = getTranslations();

const findDifferences = (obj1: any, obj2: any) => {
    const differences: any = {};

    Object.keys(obj1).forEach(key => {
        if (obj1[key] !== obj2[key]) {
            differences[key] = { oldValue: obj1[key], newValue: obj2[key] };
        }
    });

    return differences;
}

const formatDifferences = (obj: any) => {
    const newValues: any = {};

    Object.keys(obj).forEach(key => {
        newValues[key] = obj[key].newValue;
    })

    return newValues;
}

const RenderFieldOption = ({
    field,
    type,
    option,
}: {
    field: FieldRecord,
    type: string;
    option: string;
}) => {
    const optionTitleTranslation = translations[`event.website.options.${option}`];
    const label = optionTitleTranslation ? optionTitleTranslation : option;
    const isDefaultField = field.defaultfield === 'email';

    //build exception when the field has a parent for the required field to be dependant on the parent.config.required instead of the required option...
    //this is to resolve an issue with server not accepting the form if it's pflichtfeld but we don't show the field because of ...
    switch (option) {
        case "required":
            if (field.jsonConfig && field.jsonConfig.parent && field.jsonConfig.parent !== '') {
                console.log('name is jsonConfig.parentRequired');
                //set parentRequired to false when submitting ...
                return <MyCheckBox name={`jsonConfig.parentRequired`} label={translations['option.' + option]} disabled={isDefaultField} />;
            }
            return <MyCheckBox name={option} label={translations['option.' + option]} disabled={isDefaultField} />
        case "options":
            return <OptionsList options={field[option]} />
        case "checkboxlabel":
            return <MyTextField name={`jsonConfig.${option}`} label={translations['option.' + option]} />
        case "title":
            if (type === 'text') {
                return <MCEEditor name={option} title={translations['option.' + option]} isSubmitting={false} />
            }
            return <MyTextField name={option} label={translations['option.' + option]} />
        case "parent":
            return <ParentSelector name={`jsonConfig.${option}`} widgetId={field.widgetId} label={label} />
        case "content":
            return <MCEEditor name={'content'} title={translations['option.' + option]} isSubmitting={false} />
        case "termstext":
            return <MCEEditor name={`jsonConfig.${option}`} title={translations['option.' + option]} isSubmitting={false} />
        case "src":
            return <SrcSelector name={`jsonConfig.${option}`} label={label} type="image"/>;
        case "fileSrc":
            return <SrcSelector name={`jsonConfig.${option}`} label={label} type="file"/>;
        default:
            return <MyTextField name={`jsonConfig.${option}`} label={label} />;
    }
};

const FieldOptions = ({ activeField }: { activeField: FieldRecord }) => {
    if (activeField) {
        const registrationfieldTemplate = registrationfields.find(
            (obj) => obj["type"] === activeField.type
        );
        if (registrationfieldTemplate && registrationfieldTemplate.configOptions) {
            return (
                <>
                    {registrationfieldTemplate.configOptions.map((option, index) => {
                        return (
                            <Box key={`${activeField.type}-${index}`} sx={{ mb: "1rem" }}>
                                <RenderFieldOption
                                    field={activeField}
                                    type={activeField.type}
                                    option={option}
                                />
                            </Box>
                        );
                    })}
                </>
            );
        } else {
            return null;
        }
    }
}

export const getRegistrationFieldSettingsByType = (type: string) => {
    return registrationfields.find((registationfield) => registationfield.type === type);
};

export const returnDefaultJsonConfig = (type: string, config: any, record?: FieldRecord) => {
    const registationfieldConfig = getRegistrationFieldSettingsByType(type);
    let newConfig: any = { ...config };

    if (registationfieldConfig && registationfieldConfig.defaultConfig) {
        newConfig = { ...registationfieldConfig.defaultConfig, ...config };
    }

    if (newConfig.parent === '') {
        newConfig.parentValue = newConfig.parentValue || '';
        newConfig.parentMode = newConfig.parentMode || '';
        newConfig.parentRequired = newConfig.parentRequired || record?.required || false;
    };

    return newConfig;
};

const getFlatObj = (obj: FieldRecord | null) => {
    if (obj !== null) {
        return {
            eventId: obj.eventId,
            id: obj.id,
            type: obj.type,
            language: obj.defaultLanguage,
            defaultLanguage: obj.defaultLanguage,
            title: obj.translations[obj.defaultLanguage].title,
            options: obj.translations[obj.defaultLanguage].options,
            widgetId: obj.widgetId,
            sortorder: obj.sortorder,
            content: obj.translations[obj.defaultLanguage].content || '',
            jsonConfig: returnDefaultJsonConfig(obj.type, obj.config, obj),
            min: obj.min,
            max: obj.max,
            defaultfield: obj.defaultfield || null,
            required: obj.required || false,
            attendance: obj.attendance || false,
            adminfield: obj.adminfield || false
        }
    } else {
        return {};
    }
};

const RegistrationFormSidePanel = ({ config, setConfig }: { config: SidePanelConfig, setConfig: Function }) => {
    const { activeField } = config;
    const [initialValues, setInitialValues] = useState<any>(getFlatObj(null));
    const revalidator = useRevalidator();
    const clearActiveField = (field: FieldRecord) => {
        setConfig((prev: SidePanelConfig) => ({ ...prev, activeField: null }))
    }

    const setActiveField = (field: FieldRecord) => {
        setConfig((prev: SidePanelConfig) => ({ ...prev, activeField: field }))
    }

    useEffect(() => {
        if (config.activeField) {
            setInitialValues(getFlatObj(config.activeField));
        }
    }, [config.activeField])

    if (activeField) {
        if (activeField !== null && Object.keys(initialValues).length > 0) {
            return (
                <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    onSubmit={(values, { resetForm, setSubmitting }) => {
                        const differences = findDifferences(initialValues, values);
                        const newValues = formatDifferences(differences);
                        if (newValues.jsonConfig) {
                            newValues.jsonConfig = JSON.stringify({
                                ...initialValues.jsonConfig,
                                ...newValues.jsonConfig,
                            });
                        }

                        //always set validation on true if has parent - look at newValues.jsonConfig.parentRequired instead
                        if (values.jsonConfig.parent !== '') {
                            newValues.required = false;
                        }

                        console.log('differences', differences);

                        if (Object.keys(differences).length > 0) {
                            fetch("/api/registrationfields/" + values.id, {
                                method: "PUT",
                                headers: {
                                    Accept: "application/json",
                                    Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify(newValues),
                            })
                                .then((res) => res.json())
                                .then((jsondata) => {
                                    if (jsondata.success) {
                                        revalidator.revalidate();
                                        return jsondata;
                                    } else {
                                        console.log('error - server returned false for the REQ');
                                        console.log(JSON.stringify(jsondata));
                                        return jsondata;
                                    }
                                })
                                .then((jsondata) => {
                                    if (jsondata.data) {
                                        console.log('EXEC');
                                        setActiveField(jsondata.data);
                                        setInitialValues(getFlatObj(jsondata.data));
                                        resetForm();
                                    }
                                })
                        }
                    }}
                >
                    {({ values, resetForm, isValid }) => {
                        return (
                            <SidePanel sx={{ maxWidth: '300px' }} onClose={clearActiveField}>
                                <Form noValidate>
                                    <Typography mb={1} className={values.defaultfield === true ? 'is-defaultfield' : ''}>
                                        {translations["registrationform.field.type"]}: {translations['registrationform.' + activeField.type]}<br />
                                        {translations["registrationform.field.defaultfield"]}: {values.defaultfield ? translations['yes'].toLowerCase() : translations['no'].toLowerCase()}<br />
                                        {values.defaultfield && `${translations["registrationform.field.defaultfieldType"]}: ${translations["registrationform.defaultfield." + values.defaultfield]}`}
                                    </Typography>
                                    <FieldOptions activeField={values} />
                                    <Button type="submit">Save</Button>
                                </Form>
                            </SidePanel>
                        )
                    }}
                </Formik>
            )
        } else {
            return null;
        }
    } else {
        return null;
    }
}

export default RegistrationFormSidePanel;