import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Collapse,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  SxProps,
  Typography,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import "./TodoList.scss";
import { getTranslations } from "../translations/Translations";
import { EventRecord, TodoRecord } from "../interfaces/Interfaces";
import { localeFormatDate, parseDate } from "../helpers/DateFunctions";

interface TodoListProps {
  sx?: SxProps | undefined;
}

function TodoList(props: TodoListProps) {
  const translations = getTranslations();
  const [events, setEvents] = useState<EventRecord[]>([]);
  const [filterSelection, setFilterSelection] = useState<string>("ALL");
  const [sortSelection, setSortSelection] = useState<string>("closest");
  const [opened, setOpened] = useState<number[]>([]);

  useEffect(() => {
    const controller = new AbortController();

    let url: string = "/api/events/tasks";

    let filter = [];
    if (filterSelection !== "ALL") {
      filter.push({
        property: "eventId",
        value: Number.parseInt(filterSelection),
      });
    } else {
      //filter.push({ property: "upcoming", value: true});
    }
    let params: URLSearchParams = new URLSearchParams({
      sort: JSON.stringify([
        { property: "startdate", direction: "ASC" },
        { property: "starttime", direction: "ASC" },
      ]),
      filter: JSON.stringify(filter),
    });

    url = url + "?" + params.toString();
    fetch(url, {
      signal: controller.signal,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setEvents(result.data);
      })
      .catch((err: DOMException) => {
        if (err.name === "AbortError") {
        }
      });
    return () => {
      controller.abort();
    };
  }, [filterSelection, sortSelection]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={props.sx}
      className="ui-widget todolist"
    >
      <Box display="flex" m={1} flex={1} flexDirection={"column"}>
        <Box
          className="titlebar"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Typography className="todotitle">
            {translations["todos.title"]}
          </Typography>
          <Box display="flex" flexDirection="row">
            <InputLabel
              id="todolist-filter"
              sx={{ padding: "8px 4px 0px 0px" }}
            >
              <FilterAltIcon />
            </InputLabel>
            <Select
              size="small"
              labelId="todolist-filter"
              value={filterSelection}
              onChange={(e) => {
                setFilterSelection(e.target.value);
              }}
            >
              <MenuItem value={"ALL"}>{translations["todos.all"]}</MenuItem>
            </Select>
            <InputLabel
              id="todolist-sort"
              sx={{ lineHeight: "40px", padding: "0px 4px 0px 10px" }}
            >
              {translations["todos.sort"]}
            </InputLabel>
            <Select
              size="small"
              labelId="todolist-sort"
              value={sortSelection}
              onChange={(e) => {
                setSortSelection(e.target.value);
              }}
            >
              <MenuItem value={"closest"}>
                {translations["todos.closest"]}
              </MenuItem>
            </Select>
          </Box>
        </Box>
        <List className="list" sx={{ marginTop: "10px" }}>
          {events.length > 0 &&
            events.map((event: EventRecord) => {
              return (
                <Box
                  component="section"
                  key={event.id}
                  className="listitem"
                  mb={1}
                >
                  <ListItemButton
                    onClick={() => {
                      if (opened.includes(event.id)) {
                        setOpened(
                          opened.filter((val) => {
                            return val !== event.id;
                          })
                        );
                      } else {
                        setOpened([...opened, event.id]);
                      }
                    }}
                  >
                    <ListItemText disableTypography={true}>
                      <Box
                        justifyContent="space-between"
                        display="flex"
                        flexDirection="row"
                        className="todoevent"
                        pr="4px"
                      >
                        <Box>
                          {"# " +
                            event.id +
                            " " +
                            event.translations[event.defaultLanguage].title}
                        </Box>
                        <Box>
                          {localeFormatDate(parseDate(event.startdate))}
                        </Box>
                      </Box>
                    </ListItemText>
                    {opened.includes(event.id) ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </ListItemButton>
                  <Collapse
                    in={opened.includes(event.id)}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {event.tasks.map((task: TodoRecord) => {
                        return (
                          <ListItem sx={{ pl: 4 }} key={task.id}>
                            <ListItemText
                              disableTypography={true}
                              className="todotask"
                            >
                              <Box
                                justifyContent="space-between"
                                display="flex"
                                flexDirection="row"
                                className="todoevent"
                                width="350px"
                              >
                                <Box>{task.title}</Box>
                                <Box>
                                  {task.assignedTo
                                    ? task.assignedTo.firstname
                                    : null}
                                </Box>
                              </Box>
                            </ListItemText>
                            <ListItemSecondaryAction>
                              <Button
                                className="taskbutton"
                                variant="contained"
                              >
                                {task.completed
                                  ? translations["todos.completed"]
                                  : translations["todos.open"]}
                              </Button>
                            </ListItemSecondaryAction>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Collapse>
                </Box>
              );
            })}
          {events.length === 0 ? (
            <ListItem sx={{ gap: 1 }} key="-1">
              <ListItemText primary={translations["todos.notodosfound"]} />
            </ListItem>
          ) : null}
        </List>
        <Box className="bottombar"></Box>
      </Box>
    </Box>
  );
}

export default TodoList;
