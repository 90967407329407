import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { getTranslations } from "../translations/Translations";

interface DirtyFormDialogProps {
  cancel?: Function;
  continue?: Function;
  open: boolean;
}

export function DirtyFormDialog(props: DirtyFormDialogProps) {
  const translations = getTranslations();
  return (
    <Dialog
      className="window"
      open={props.open}
      onClose={() => props.cancel?.()}
      scroll="body"
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle>{translations["savebar.dityformnavtitle"]}</DialogTitle>
      <DialogContent dividers={false}>
        <DialogContentText>
          {translations["savebar.dityformnav"]}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => props.continue?.()}>
          {translations["savebar.continue"]}
        </Button>
        <Button variant="contained" onClick={() => props.cancel?.()}>
          {translations["savebar.cancel"]}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
