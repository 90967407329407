import { Html5Qrcode } from "html5-qrcode";
import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Toolbar,
  IconButton,
  Snackbar,
  SnackbarContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CustomAudio from "../../../../../components/CustomAudio";

interface SnackBarObject {
  message: string;
  type: string;
}

interface Html5QrcodePluginProps {
  stop: Function;
  onResult: Function;
  start: boolean;
  continuous: boolean;
  snackBarObject: SnackBarObject;
  audioRef: HTMLAudioElement | null;
}

const Html5QrcodePlugin = (props: Html5QrcodePluginProps) => {
  const { onResult, stop, start, continuous, snackBarObject, audioRef } = props;
  const qrcodeRegionId = "scanner";
  const [scanner, setScanner] = useState<Html5Qrcode>();
  const parentElementRef = useRef<any>(null);
  const [scannerLoaded, setScannerLoaded] = useState<boolean>(false);
  const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
  const [playSound, setPlaySound] = useState<boolean>(false);
  const [scanResult, setScanResult] = useState("");

  useEffect(() => {
    setScanner(new Html5Qrcode(qrcodeRegionId));
  }, []);

  useEffect(() => {
    if (snackBarObject.message !== "") {
      setSnackBarOpen(true);
    } else {
      setSnackBarOpen(false);
    }
  }, [snackBarObject]);

  useEffect(() => {
    if (start === true) {
      handleOnScanStart();
    }
  }, [scanner, start]);

  const handleScanSuccess = function (code: string) {
    if (audioRef) {
      audioRef.play();
    }

    onResult(code);

    if (continuous) {
      scanner?.pause();
      setTimeout(() => {
        if (scanner?.getState() === 3) {
          scanner?.resume();
        }
      }, 2000);
    } else {
      handleClose();
    }
  };

  const getColorForType = (type: string): string => {
    switch (type) {
      case "checkin":
        return "#006e55";

      case "checkout":
        return "#ea7c15";

      case "error":
        return "#ca3737";

      default:
        return "#727272";
    }
  };

  const handleClose = function () {
    setScannerLoaded(false);
    scanner?.stop();
    stop();
  };

  const handleOnScanStart = () => {
    Html5Qrcode.getCameras().then((devices) => {
      let width = 75;
      if (parentElementRef.current.clientWidth) {
        width = (parentElementRef.current.clientWidth / 3) * 2;
      }
      if (devices && devices.length) {
        console.log("STARTING...");
        scanner
          ?.start(
            {
              facingMode: "environment",
            },
            {
              fps: 10,
              qrbox: width,
            },
            function (code) {
              handleScanSuccess(code);
            },
            function (code) {
              //Error CB
            }
          )
          .then(
            //Promise completed -> scanner loaded
            () => {
              setScannerLoaded(true);
            }
          );
      }
    });
  };

  return (
    <Box
      ref={parentElementRef}
      visibility={start ? "visible" : "hidden"}
      display="flex"
      flexDirection="column"
      width="100%"
      height="100%"
      alignItems="stretch"
      zIndex={5200}
      position="absolute"
      justifyItems="space-between"
    >
      <CustomAudio audioUrl={"/scanQR.mp3"} play={playSound} />
      {scannerLoaded && (
        <>
          <Toolbar sx={{ backgroundColor: "white" }}>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={snackBarOpen}
            autoHideDuration={2000}
            onClose={() => {
              setSnackBarOpen(false);
            }}
          >
            <SnackbarContent
              sx={{
                backgroundColor: getColorForType(snackBarObject.type),
              }}
              message={snackBarObject.message}
            />
          </Snackbar>
        </>
      )}

      <div
        style={{ flex: "1", display: "flex", width: "100%" }}
        id={qrcodeRegionId}
      />
    </Box>
  );
};

export default Html5QrcodePlugin;
