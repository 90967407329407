import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { getTranslations } from "../../../../translations/Translations";
import {
  EventRouterResponse,
  FieldRecord,
  IRecord,
  ModuleRecord,
  SessionRecord,
  SessionsRouterResponse,
} from "../../../../interfaces/Interfaces";
import { useRevalidator, useRouteLoaderData } from "react-router-dom";
import {
  Droppable,
  Draggable,
  DragDropContext,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import SessionForm from "./SessionForm";
import ModuleForm from "./ModuleForm";
import useDebounce from "../../../../helpers/useDebounce";
import SearchIcon from '@mui/icons-material/Search';
import { GridClearIcon } from "@mui/x-data-grid";

interface SessionProps {
  session: SessionRecord;
  index: number;
  onClick: Function;
  activeSession: SessionRecord | null;
  draggingModule: number | null;
  isFiltered: string;
}

function Session(props: SessionProps) {
  let buttonClass = "cliclalistitembutton";

  if (props.activeSession?.id === props.session.id) {
    buttonClass = "cliclalistitembutton active";
  }
  let draggableId = "session-" + props.session.id;
  /*if (
    props.draggingModule != null &&
    props.draggingModule !== props.session.blockId
  ) {
    draggableId = "blockedmodule-" + props.draggingModule;
  }
  console.log(draggableId);
  console.log("props.draggingModule", props.draggingModule);
  console.log("props.session.blockId", props.session.blockId);*/

  return (
    <Draggable
      key={"session-" + props.session.id}
      draggableId={draggableId}
      index={props.index}
      isDragDisabled={props.isFiltered !== ''}
    >
      {(provided) => (
        <ListItem
          className={buttonClass}
          {...provided.draggableProps}
          secondaryAction={
            <IconButton {...provided.dragHandleProps} sx={{ display: props.isFiltered !== '' ? 'none' : 'block' }}>
              <DragIndicatorIcon />
            </IconButton>
          }
        >
          <ListItemButton
            ref={provided.innerRef}
            onClick={() => {
              props.onClick(props.session);
            }}
            sx={{ flexGrow: 0 }}
          >
            <ListItemText
              primary={
                props.session.translations[props.session.defaultLanguage].title
              }
            />
          </ListItemButton>
        </ListItem>
      )}
    </Draggable>
  );
}

interface ModuleEndProps {
  activeModule: ModuleRecord | null;
  module: number;
  index: number;
  draggingModule: number | null;
}

function ModuleEnd(props: ModuleEndProps) {
  let moduleClass = "moduleEnd";
  if (props.activeModule?.id === props.module) {
    moduleClass = "module active";
  }
  let draggableId = "moduleend-" + props.module;
  /*if (props.draggingModule !== null && props.draggingModule !== props.module) {
    draggableId = "blockedmodule-" + props.module;
  }*/
  return (
    <Draggable
      key={"moduleend-" + props.module}
      draggableId={draggableId}
      index={props.index}
      disableInteractiveElementBlocking={true}
      isDragDisabled={true}
    >
      {(provided) => (
        <ListItem
          hidden={props.draggingModule === props.module}
          className={moduleClass}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Box height="20px"></Box>
        </ListItem>
      )}
    </Draggable>
  );
}

interface ModuleProps {
  activeModule: ModuleRecord | null;
  module: ModuleRecord;
  index: number;
  onModuleClick: Function;
  draggingModule: number | null;
}

function Module(props: ModuleProps) {
  let moduleClass = "module";
  if (props.activeModule?.id === props.module.id) {
    moduleClass = "module active";
  }
  let draggableId = "module-" + props.module.id;
  /*if (
    props.draggingModule !== null &&
    props.draggingModule !== props.module.id
  ) {
    draggableId = "blockedmodule-" + props.module;
  }*/

  return (
    <Draggable
      key={"module-" + props.module.id}
      draggableId={draggableId}
      index={props.index}
      disableInteractiveElementBlocking={true}
    >
      {(provided) => (
        <ListItem
          className={moduleClass}
          ref={provided.innerRef}
          {...provided.draggableProps}
          secondaryAction={
            <IconButton {...provided.dragHandleProps}>
              <DragIndicatorIcon />
            </IconButton>
          }
        >
          <ListItemButton
            ref={provided.innerRef}
            onClick={() => {
              props.onModuleClick(props.module);
            }}
            sx={{ flexGrow: 0 }}
          >
            <ListItemText
              primary={
                props.module.translations[props.module.defaultLanguage].title
              }
            />
          </ListItemButton>
        </ListItem>
      )}
    </Draggable>
  );
}

interface ModuleEndRecord extends IRecord {
  moduleend: boolean;
}

function Sessions() {
  const translations = getTranslations();
  const { event } = useRouteLoaderData("event") as EventRouterResponse;
  const { sessions, modules, categories } = useRouteLoaderData(
    "sessions"
  ) as SessionsRouterResponse;
  const [sessionRegistrations, setSessionRegistrations] = useState<IRecord[]>([]);
  const [session, setSession] = useState<SessionRecord | null>(null);
  const [fields, setFields] = useState<FieldRecord[]>([])
  const [module, setModule] = useState<ModuleRecord | null>(null);
  const revalidator = useRevalidator();
  const [draggingModule, setDraggingModule] = useState<number | null>(null);

  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search, 500);

  const getRootLevel = (): (
    | SessionRecord
    | ModuleRecord
    | ModuleEndRecord
  )[] => {
    modules.forEach((module) => {
      module.sessions = sessions.filter((session) => {
        return session.blockId === module.id;
      });
    });

    let arr: (SessionRecord | ModuleRecord)[] = [...modules, ...sessions];

    arr.sort(
      (a: SessionRecord | ModuleRecord, b: SessionRecord | ModuleRecord) => {
        if (a.sortorder === b.sortorder) {
          return 0;
        }
        if (a.sortorder < b.sortorder) {
          return -1;
        }
        return 1;
      }
    );

    let arr2: (SessionRecord | ModuleRecord | ModuleEndRecord)[] = [];
    let openModule: number | null = null;
    arr.forEach((value: SessionRecord | ModuleRecord) => {
      if (value.activity) {
        if (debouncedSearch !== '') {
          if (value.translations[value.defaultLanguage].title?.toLocaleLowerCase().includes(debouncedSearch)) {
            arr2.push(value);
          }
        } else {
          if (openModule !== null && value.blockId == null) {
            arr2.push({ id: openModule, moduleend: true });
            openModule = null;
          }
          arr2.push(value);
        }
      } else {
        if (debouncedSearch === '') {
          if (openModule !== null) {
            arr2.push({ id: openModule, moduleend: true });
          }
          openModule = value.id;
          arr2.push(value);
        }
      }
    });
    if (openModule !== null) {
      arr2.push({ id: openModule, moduleend: true });
    }

    return arr2;
  };
  let rootlvl = getRootLevel();

  const onDragStart = (result: any) => {
    let draggableId = result.draggableId as string;
    if (draggableId.indexOf("module-") > -1) {
      setDraggingModule(
        Number.parseInt(draggableId.replace("module-", ""), 10)
      );
    } else {
      setDraggingModule(null);
    }
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;
    let copyrootlvl = [...rootlvl];
    let draggedObject: ModuleRecord | SessionRecord | ModuleEndRecord | null;
    draggedObject = copyrootlvl.splice(result.source.index, 1)[0];
    copyrootlvl.splice(result.destination.index, 0, draggedObject);
    let promises: Promise<Response>[] = [];

    if ("activity" in draggedObject) {
      if (draggedObject.activity) {
        // check if there's a module or module-end above
        var modulefound: ModuleRecord | null = null;
        copyrootlvl.forEach(
          (item: SessionRecord | ModuleRecord | ModuleEndRecord, index) => {
            if (index < result.destination.index) {
              if ("activity" in item && item.activity === false) {
                modulefound = item as ModuleRecord;
              }
              if ("moduleend" in item) {
                modulefound = null;
              }
            }
          }
        );

        if (modulefound === null) {
          draggedObject.blockId = "";
          draggedObject.block = null;
        } else if ("id" in modulefound) {
          draggedObject.blockId = modulefound["id"];
          draggedObject.block = modulefound;
        }
      }
    }

    copyrootlvl.forEach((item, index) => {
      if (!("moduleend" in item) && "activity" in item) {
        if (item.sortorder !== index + 1) {
          if (item.activity) {
            promises.push(
              fetch("/api/activities/" + item.id, {
                method: "put",
                headers: {
                  Accept: "application/json",
                  Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  sortorder: index + 1,
                  blockId: item.blockId,
                }),
              })
            );
          } else {
            promises.push(
              fetch("/api/blocks/" + item.id, {
                method: "put",
                headers: {
                  Accept: "application/json",
                  Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  sortorder: index + 1,
                }),
              })
            );
          }
        }
      }
    });

    setDraggingModule(null);
    Promise.all(promises).then((responses) => {
      revalidator.revalidate();
    });
  };

  const newmodule = () => {
    fetch("/api/blocks", {
      method: "post",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("jwt"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        defaultLanguage: event.defaultLanguage,
        language: event.defaultLanguage,
        title: "Neue Zeitgruppe",
        eventId: event.id,
        sortorder: getRootLevel().length,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        let p: ModuleRecord = result.data;
        setSession(null);
        setModule(p);
        revalidator.revalidate();
      });
  };

  const newsession = () => {
    fetch("/api/activities", {
      method: "post",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("jwt"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        defaultLanguage: event.defaultLanguage,
        language: event.defaultLanguage,
        title: "Neue Aktivität",
        eventId: event.id,
        sortorder: getRootLevel().length,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        let p: SessionRecord = result.data;
        setModule(null);
        setSession(p);
        revalidator.revalidate();
      });
  };

  if (session == null && module == null && rootlvl.length > 0) {
    let d = rootlvl[0];
    if ("activity" in d) {
      if (d.activity) {
        setSession(d as SessionRecord);
      } else {
        setModule(d as ModuleRecord);
      }
    }
  }

  const fetchActivityRegistrations = () => {
    fetch("/api/registrations?" + new URLSearchParams({
      filter: JSON.stringify([
        {
          property: "activityId",
          value: session?.id,
        },
      ])
    }).toString(), {
      headers: {
        Accept: "application/json",
        Authorization:
          "Bearer " + sessionStorage.getItem("jwt"),
      },
    }).then((res) => res.json()).then((res) => {
      if (res.success) {
        setSessionRegistrations(res.data)
      }
    })
  }

  useEffect(() => {
    fetchActivityRegistrations();

    fetch("/api/registrationfields?" + new URLSearchParams({
      filter: JSON.stringify([
        {
          property: "eventId",
          value: event.id,
        },
      ]),
      sort: JSON.stringify([
        { property: "sortorder", direction: "ASC" },
      ]),
    }).toString(), {
      headers: {
        Accept: "application/json",
        Authorization:
          "Bearer " + sessionStorage.getItem("jwt"),
      },
    })
      .then((res) => res.json())
      .then((regs) => {
        setFields(() => {
          return regs.data
        })
      });
  }, [session])

  return (
    <Box display="flex" flexDirection="row" className="collectionedit" flex="1">
      <Box
        width={"260px"}
        className="list"
        display="flex"
        flexDirection={"column"}
        gap={1}
      >
        <Box display="flex" flexDirection={"column"} gap={1} p={1}>
          <Button variant="contained" onClick={newmodule}>
            {translations["sessions.addmodule"]}
          </Button>
          <Button variant="contained" onClick={newsession}>
            {translations["sessions.addsession"]}
          </Button>
          <Box display="flex" flexDirection={"row"} mr={0}>
            <Box display="flex" flexDirection={"row"}  sx={{ width: '100%'}} alignItems={"center"} justifyContent={"center"}><TextField
              sx={{flex: 1}}
              value={search}
              label={translations["contacts.search"]}
              onChange={(event: any) => {
                setSearch(event.target.value);
              }} />
              {search !== '' ? <GridClearIcon onClick={() => {setSearch('')}}/> : null}
            </Box>

          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection={"column"}
          flex={1}
          flexShrink={0}
          overflow={"scroll"}
        >
          <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
            <Droppable droppableId="activities">
              {(provided, snapshot: DroppableStateSnapshot) => (
                <List
                  className="cliclalist"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    flexShrink: 0,
                  }}
                >
                  {rootlvl.map(
                    (
                      p: SessionRecord | ModuleRecord | ModuleEndRecord,
                      index: number
                    ) => {
                      if ("activity" in p) {
                        if (p.activity === true) {
                          return (
                            <Session
                              draggingModule={draggingModule}
                              session={p as SessionRecord}
                              key={"session-" + p.id}
                              index={index}
                              onClick={() => {
                                setModule(null);
                                setSession(p as SessionRecord);
                              }}
                              isFiltered={debouncedSearch}
                              activeSession={session}
                            />
                          );
                        } else {
                          return (
                            <Module
                              key={"module-" + p.id}
                              index={index}
                              module={p as ModuleRecord}
                              activeModule={module}
                              onModuleClick={(m: ModuleRecord) => {
                                setSession(null);
                                setModule(m);
                              }}
                              draggingModule={draggingModule}
                            />
                          );
                        }
                      } else {
                        return (
                          <ModuleEnd
                            key={"moduleend-" + p.id}
                            index={index}
                            module={p.id}
                            activeModule={module}
                            draggingModule={draggingModule}
                          />
                        );
                      }
                    }
                  )}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      </Box>
      {!session && !module ? (
        <Box flex="1" p={2}>
          <Typography>{translations["sessions.empty"]}</Typography>
        </Box>
      ) : null}
      {!!module ? (
        <ModuleForm
          key={module.id}
          module={module}
          onCopy={(values: IRecord) => {
            fetch("/api/blocks/copy/" + module.id, {
              method: "post",
              headers: {
                Accept: "application/json",
                Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                "Content-Type": "application/json",
              },
            }).then(() => {
              revalidator.revalidate();
            });
          }}
          onDelete={() => {
            fetch("/api/blocks/" + module.id, {
              method: "delete",
              headers: {
                Accept: "application/json",
                Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                "Content-Type": "application/json",
              },
            }).then(() => {
              setModule(null);
              revalidator.revalidate();
            });
          }}
        />
      ) : null}
      {!!session ? (
        <SessionForm
          key={session.id}
          session={session}
          categories={categories}
          onCopy={(values: IRecord) => {
            fetch("/api/activities/copy/" + session.id, {
              method: "post",
              headers: {
                Accept: "application/json",
                Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                "Content-Type": "application/json",
              },
            }).then(() => {
              revalidator.revalidate();
            });
          }}
          onRefresh={fetchActivityRegistrations}
          registrations={sessionRegistrations}
          fields={fields}
          onDelete={() => {
            fetch("/api/activities/" + session.id, {
              method: "delete",
              headers: {
                Accept: "application/json",
                Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                "Content-Type": "application/json",
              },
            }).then(() => {
              setSession(null);
              revalidator.revalidate();
            });
          }}
        />
      ) : null}
    </Box>
  );
}

export default Sessions;
