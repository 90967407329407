import { Box,Checkbox,FormControlLabel, FormLabel, } from "@mui/material"
import React from "react"
import { FieldRecordWidget } from "../../../../../../interfaces/Interfaces";
import FieldLabel from "./FieldLabel"

const RegCheckboxField = ({ registrationfield }: FieldRecordWidget) => {
    console.log(registrationfield);
    return (
        <Box display="flex" flexDirection="column">
            <FieldLabel field={registrationfield} />
            <FormControlLabel control={<Checkbox value={''} />} label={<FormLabel sx={{ color: 'var(--color-primary)' }}>{registrationfield.config.checkboxlabel}</FormLabel>} />
        </Box>
    )
}

export default RegCheckboxField;

