import React from "react";
import { ReactWidget, ReactWidgetWithStyles } from "../../../../../../../interfaces/Interfaces";
import { generateMarginString } from "../options/MarginSelector";


const standardPropsDefaults = [[0,0,0,0],[0,0,0,0]];
export const standardProps = ['margin','padding'];

export const createDefaultConfig = () => {
    const obj: { [key: string]: any } = {};
    standardProps.forEach((prop, index) => {
        obj[prop] = [...standardPropsDefaults[index]];
    });
    return obj;
};

const withStandardProps = (Widget: React.ComponentType<ReactWidgetWithStyles>) => {
    return (props: ReactWidget) => {
        const {widget} = props;
        const config = widget.config || {};
        const styles: any = {};
        standardProps.forEach((prop) => {
            if (config[prop]) {
                styles[prop] = config[prop]
            }
        })
        const padding = config['padding'];
        const margin = config['margin'];
        if (margin) {
            styles.margin = generateMarginString(margin); 
        }
        if (padding) {
            styles.padding = generateMarginString(padding);
        }
        return <Widget {...props} standardStyles={styles} />;
    };
};

export default withStandardProps;