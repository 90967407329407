import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Modal
} from "@mui/material";
import { getTranslations } from "../../../../translations/Translations";
import {
  EventRouterResponse,
  IRecord,
  FilesRouterResponse,
  FileRecord,
  ParsedFile
} from "../../../../interfaces/Interfaces";
import { useRevalidator, useRouteLoaderData } from "react-router-dom";
import { useFormik } from "formik";
import UploadControl from "../../../../components/UploadControl";
import QuiltedImageList from "../../../../components/QuiltedImageList";
import UploadButton from "../../../../components/buttons/UploadButton";
import Lightbox, { GenericSlide, YoutubeSlide, Slide, SlideImage, useLightboxState, ImageSlide } from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import ChoiceDialog from "../../../../components/ChoiceDialog";
import YoutubeWindow from "../../../../components/YoutubeWindow";
//Icons
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import AlertDialog from "../../../../components/AlertDialog";

import useMaskContext from '../../../../hooks/useMaskContext';

declare module "yet-another-react-lightbox" {
  export interface YoutubeSlide extends GenericSlide {
    type: "youtube";
    videolink: string;
    alt: string | undefined;
    poster: string | undefined;
  }

  interface SlideTypes {
    "youtube": YoutubeSlide;
  }
}

function isYoutubeSlide(slide: Slide): slide is YoutubeSlide {
  return slide.type === "youtube";
}

function Media() {
  const translations = getTranslations();
  const { event } = useRouteLoaderData("event") as EventRouterResponse;
  const { files } = useRouteLoaderData("media") as FilesRouterResponse;
  const [parsedFiles, setParsedFiles] = useState<ParsedFile[]>([]);
  const revalidator = useRevalidator();
  const [deleteMode, setDeleteMode] = useState<boolean>(false);
  const [index, setIndex] = useState(-1);
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [showImageRemoveDialog, setShowImageRemoveDialog] = useState<boolean>(false);
  const { toggleMask } = useMaskContext();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showYoutubeWindow, setShowYoutubeWindow] = useState<boolean>(false);
  const [openFileInput, setOpenFileInput] = useState<number>(0)
  const [uploadAccept, setUploadAccept] = useState<string>("image");

  const uploadAccepts: any = {
    file: "application/pdf",
    image: "image/gif, image/jpeg, image/jpg, image/png"
  }

  const parseFiles = (files: FileRecord[]) => {
    let result = [];
    result = files.map((file: FileRecord) => {


      let fileObject: ParsedFile = {
        url: `/api/files/read/${file.publicId}`,
        cols: 1,
        rows: 1,
        type: 'image',
        id: file.id,
      };

      if (file.videolink !== null) {
        fileObject.type = 'video';
        fileObject.videolink = file.videolink;
      }
      return fileObject;
    })

    return result;
  }

  console.log(event);

  useEffect(() => {
    setParsedFiles(parseFiles(files));
  }, [files]);

  const getFlatObj = (obj: IRecord) => {
    return {
      id: obj.id,
      fileId: obj.fileId || "",
    };
  };

  const formik = useFormik({
    initialValues: getFlatObj(event),
    onSubmit: (values) => {
      fetch("/api/events/" + event.id + "/", {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("jwt"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
        .then((res) => res.json())
        .then((jsondata) => {
          if (jsondata.success) {
            revalidator.revalidate();
          }
        });
    },
  });

  const slides: (SlideImage | YoutubeSlide)[] = parsedFiles.map((item) => {
    if (typeof item !== 'undefined') {
      if (item.type === 'image') {
        return {
          type: 'image',
          src: item.url,
          alt: item.title
        } as SlideImage;
      } else if (item.type === 'video') {
        return {
          type: "youtube",
          videolink: item.videolink,
          alt: item.title,
          poster: item.url
        } as YoutubeSlide;
      }
    }
    return null;
  }).filter((item): item is SlideImage | YoutubeSlide => item !== null);

  //Styling logic - Spread operator makes a shallow copy
  const buttonStyle = [{ backgroundColor: '#006e55', borderRadius: 0, boxShadow: 'none', fontSize: '1rem', padding: '0.5rem 1rem' }, { '&:hover': { backgroundColor: '#ea7c15' } }, { '& svg': { marginRight: '0.5rem' } }];
  const deleteButtonStyle = [
    { ...buttonStyle[0] },
    { ...buttonStyle[1] },
    { ...buttonStyle[2] },
  ];
  if (deleteMode) {
    deleteButtonStyle[0].backgroundColor = '#ea7c15';
  }

  const removeFiles = async (ids: number | number[] = []) => {
    toggleMask(true);
    try {
      const deletePromises = ([] as number[]).concat(ids).map((id) => {
        return fetch("/api/files/" + id, {
          method: "delete",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("jwt"),
            "Content-Type": "application/json",
          },
        });
      });
      await Promise.all(deletePromises);
      setIndex(-1);
      setSelectedIds([]);
      toggleMask(false);
      revalidator.revalidate();

    } catch (error) {
      toggleMask(false);
      console.error("Error deleting files:", error);
    }
  };

  const YoutubeEmbed = (props: { youtubeLink: string }) => {
    const { youtubeLink } = props;
    const navWidth = 64;
    const aspectRatio = 9 / 16;

    const generateYoutubeEmbedLink = (youtubeLink: string) => {
      const watchLinkRegex = /^https:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)$/;
      if (watchLinkRegex.test(youtubeLink)) {
        const videoId = youtubeLink.split("=")[1];
        return `https://www.youtube.com/embed/${videoId}`;
      }

      //default rick roll :/
      return 'https://www.youtube.com/embed/dQw4w9WgXcQ';
    };


    const calculateWidth = () => {
        const availableHeight = window.innerHeight * (90 / 100);
        const maxWidth = window.innerWidth - navWidth * 2;

        return Math.min(
            availableHeight * (1 / aspectRatio),
            maxWidth
        );
    };

    const [width, setWidth] = useState(calculateWidth());

    useEffect(() => {
        const handleResize = () => {
            setWidth(calculateWidth());
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const height = width * aspectRatio;

    return (
        <iframe
            style={{
                width: `${width}px`,
                height: `${height}px`,
                border: 0,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)'
            }}
            src={generateYoutubeEmbedLink(youtubeLink)}
            frameBorder="0"
            allowFullScreen={true}
        ></iframe>
    );
};

  const RenderYoutubeSlide = ({ slide }: { slide: YoutubeSlide }) => {
    const index = slides.findIndex((el) => el === slide);
    const { currentIndex } = useLightboxState();
    return index === currentIndex ? (
      <div className="container" style={{ width: '100%', height: '100%' }}>
        <YoutubeEmbed youtubeLink={slide.videolink} ></YoutubeEmbed>
      </div>
    ) : null;
  }

  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    minWidth: '50%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{ display: "flex", flexGrow: 1, overflow: "scroll" }}
    >
      <Box display="flex" flexDirection="column" position="relative" flex={1} p={2}>
        <Box display="flex" flexDirection={"row"} justifyContent='space-between'>
          <UploadButton onClick={() => {
            setShowModal(true);
          }} />
          <ChoiceDialog
            open={[showModal, setShowModal]}
            title={translations["media.alertMultiRemoveTitel"]}
            description={translations["media.alertMultiRemoveDescription"]}
            buttons={([<Button onClick={() => {
              setShowYoutubeWindow(true);
              setShowModal(false);
            }}>{translations["media.youtubeVideo"]}</Button>,
            <Button onClick={() => {
              setUploadAccept('image')
              setShowModal(false);
              setOpenFileInput((trigger) => trigger + 1);
            }}>{translations["media.photograph"]}</Button>,
            <Button onClick={() => {
              setUploadAccept('file')
              setShowModal(false);
              setOpenFileInput((trigger) => trigger + 1);
            }}>{translations["media.file"]}</Button>])}
          />
          <Modal open={showYoutubeWindow} aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"><Box sx={modalStyle}><YoutubeWindow onClose={() => {
              setShowYoutubeWindow(false);
              revalidator.revalidate()
            }} /></Box></Modal>
          <UploadControl
            name="fileId"
            value={formik.values.fileId}
            eventId={event.id}
            setFieldValue={formik.setFieldValue}
            beforeUpload={() => {
              toggleMask(true);
            }}
            hideUploadButton={openFileInput}
            multiple
            autoUpload={true}
            onUploadComplete={() => {
              toggleMask(false);
              revalidator.revalidate()
            }}
            onUploadError={() => {
              console.log('ERROR ...');
              toggleMask(false);
            }}
            purpose="media"
            accept={uploadAccepts[uploadAccept]}
          />
          <Box>
            {deleteMode && <Button sx={[{ ...buttonStyle[0], mr: 1, color: 'white' }, { ...buttonStyle[1] }, { ...buttonStyle[2] }]}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                setDeleteMode(!deleteMode);
              }}
            ><ClearIcon />{translations["media.cancel"]}</Button>}
            <Button
              variant="contained"
              component="span"
              sx={deleteButtonStyle}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                if (deleteMode) {
                  setShowImageRemoveDialog(true);
                }
                setDeleteMode(!deleteMode);
              }}
            >
              {deleteMode ? <><CheckIcon />{translations["media.confirm"]}</> : <><DeleteIcon /> {translations["media.delete"]}</>}
            </Button>
          </Box>
        </Box>
        <Box display="flex" flexDirection={"row"}>
          <QuiltedImageList
            images={parsedFiles}
            onImgClick={(index: number) => {
              setIndex(index)
            }}
            deleteMode={deleteMode}
            onImgRemove={(id: number) => {
              removeFiles(id);
            }}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
          />
          <Lightbox
            open={index >= 0}
            index={index}
            close={() => setIndex(-1)}
            slides={slides}
            render={{
              slide: ({ slide }) =>
                isYoutubeSlide(slide) ? (
                  <RenderYoutubeSlide slide={slide} />
                ) : <ImageSlide slide={slide} />,
            }}
          /><AlertDialog
            open={[showImageRemoveDialog, setShowImageRemoveDialog]}
            title={translations["media.alertMultiRemoveTitel"]}
            description={translations["media.alertMultiRemoveDescription"]}
            confirmFunction={() => { removeFiles(selectedIds) }}
          />
        </Box>
      </Box>
    </form>
  );
}

export default Media;