import { Avatar, Box, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import React, { useEffect, useState } from "react";
import ContextMenu from "./ContextMenu";

import { getTranslations } from "../translations/Translations";
import { previousDay } from "date-fns";

import { MenuObject } from "../interfaces/Interfaces";

import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";

interface TopToolbarProps {
  title: React.ReactNode;
}

function TopToolbar(props: TopToolbarProps) {
  const navFn = useNavigate();
  const translations = getTranslations();
  const [menu, setMenu] = useState<MenuObject>({
    open: false,
    location: null
  })

  useEffect(() => {
    setMenu((prev) => ({
      ...prev,
      open: true
    }))
  }, [menu.location])

  return (
    <>
      <Box
        className="topToolbar"
        p={0}
        justifyContent="space-between"
        height={"60px"}
        minHeight="60px"
        flexShrink={0}
      >
        <Box>{props.title}</Box>
        <Box p="10px" sx={{paddingRight: '1rem'}}>
          <Avatar onClick={(e) => {
            const target = e.currentTarget;
            if (target !== null) {
              setMenu((prev) => ({
                ...prev,
                location: target.getBoundingClientRect()
              }));
            }
          }}>
            <PersonIcon />
          </Avatar>
        </Box>
      </Box>
      {menu.open && menu.location !== null ? (
        <ContextMenu
          config={[menu, setMenu]}
          children={
            <List>
              <ListItemButton
                onClick={() => {
                  console.log("SHOULD LOG OUT");
                  setMenu((prev) => ({
                    ...prev,
                    open: false
                  }))
                  navFn("/logout", {
                    replace: true
                  });
                }}
              >
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary={translations["toptoolbar.logoff"]} />
              </ListItemButton>
            </List>
          }
        />
      ) : null}
    </>
  );
}

export default TopToolbar;
