
import { generateMarginString } from "../../../events/event/website/components/widgets/options/MarginSelector";
import { ReactWidgetWithStyles } from "../../Interfaces";
import React from "react";

const withStandardStyles = (Widget: React.ComponentType<ReactWidgetWithStyles>) => {
    return (props: any) => {
        const { widget } = props;
        const styles: any = {};
        const config = widget.config || {};
        const margin = config['margin'];
        const padding = config['padding'];
        if (margin) {
            styles.margin = generateMarginString(margin);
        }
        if (padding) {
            styles.padding = generateMarginString(padding);
        }
        return <Widget {...props} standardStyles={styles} />;
    };
};

export default withStandardStyles;