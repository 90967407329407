import React from "react";
import { LoaderFunctionArgs, defer } from "react-router-dom";
import App from "../App";
import {
  LogoutRoute,
  ProtectedAdminRoute,
  ProtectedRoute,
} from "../components/ProtectedRoute";
import Admin from "../view/Admin";
import Dashboard from "../view/dashboard/Dashboard";
import Event from "../view/events/event";
import Eventslist from "../view/events";
import Login from "../view/login/Login";
import ErrorPage from "../view/ErrorPage";
import { RouteObject } from "react-router-dom";
import EventDashboard from "../view/events/event/EventDashboard";
import EventDetails from "../view/events/event/eventdetails";
import EventDetailsTab from "../view/events/event/eventdetails/EventDetailsTab";
import EducationTab from "../view/events/event/eventdetails/EducationTab";
import PartnerInfoTab from "../view/events/event/eventdetails/PartnerInfoTab";
import CateringTab from "../view/events/event/eventdetails/CateringTab";
import VenueTab from "../view/events/event/eventdetails/VenueTab";
import RelationsTab from "../view/events/event/eventdetails/RelationsTab";
import Sessions from "../view/events/event/sessions";
import Media from "../view/events/event/media";
import LocationTab from "../view/events/event/eventdetails/LocationTab";
import RegistrationSettingsTab from "../view/events/event/eventdetails/RegistrationSettingsTab";
import Attributes from "../view/attributes";
import TagsTab from "../view/attributes/TagsTab";
import CategoriesTab from "../view/attributes/CategoriesTab";
import Statistics from "../view/statistics";
import Calendar from "../view/calendar";
import Website from "../view/events/event/website";
import EventContacts from "../view/events/event/contacts";
import EventChat from "../view/events/event/chat";
import EventBudget from "../view/events/event/budget";
import EventStatistics from "../view/events/event/statistics";
import Mailings from "../view/events/event/mailings";
import Registrations from "../view/events/event/contacts/Registrations";
import Attendance from "../view/events/event/contacts/Attendance";
import Webpage from "../view/events/event/website/Webpage";
import {
  EventRecord,
  FieldRecord,
  WebpageRecord,
  WidgetRecord,
} from "../interfaces/Interfaces";
import Usermanagement from "../view/usermanagement";
import Logins from "../view/usermanagement/Logins";
import Contexts from "../view/usermanagement/Contexts";
import ScannerPage from "../view/events/event/scanner";
import ImportedContacts from "../view/events/event/contacts/ImportedContacts";
import EventBudgetCalculation from "../view/events/event/budget/Calculation";
import MailEditTab from "../view/events/event/mailings/MailEditTab";
import Roles from "../view/usermanagement/Roles";
import { fetchDataFromUrl } from "../helpers/RequestFunctions";
import { Box } from "@mui/material";
import EventPage from "../view/templates";


const routes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        path: "",
        element: <Login />,
      },
      {
        index: true,
        path: "login",
        element: <Login />,
      },
      {
        path: "logout",
        element: <LogoutRoute />,
      },
      {
        path: "/event/:eventId",
        element: (
          <EventPage />
        ),
        id: "eventpage",
        loader: async ({ params, request }: LoaderFunctionArgs) => {
          console.log(params);
    
          let url = "/api/tempwebsite/" + params.eventId;
          let eventData;
    
          try {
            const response = await fetch(url, {
              signal: request.signal,
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
            });
            if (!response.ok) {
              throw new Error('Not Found');
            }
            const regs = await response.json();
            eventData = regs.data;
          } catch (error) {
            console.log('ERROR');
            eventData = {};
          }
    
          return defer({
            eventData: eventData
          });
        }
      },
      {
        path: "scanner",
        id: "scanner",
        children: [
          {
            index: true,
            path: "",
            element: (
              <ProtectedRoute>
                <ScannerPage />
              </ProtectedRoute>
            ),
          },
          {
            path: ":eventId",
            element: (
              <ProtectedRoute>
                <ScannerPage />
              </ProtectedRoute>
            ),
            id: "scanevent",
            loader: async ({ params, request }: LoaderFunctionArgs) => {
              let searchparams: URLSearchParams = new URLSearchParams({
                filter: JSON.stringify([
                  { property: "eventId", value: params.eventId },
                ]),
                sort: JSON.stringify([
                  { property: "sortorder", direction: "ASC" },
                ]),
              });

              const event = await fetch("/api/scanner/events/" + params.eventId, {
                signal: request.signal,
                headers: {
                  Accept: "application/json",
                  Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                },
              })
                .then((response) => response.json())
                .then((eventrecordresponse) => {
                  return eventrecordresponse.data;
                });

              let url = "/api/scanner/activities?" + searchparams.toString();

              const sessions = await fetch(url, {
                signal: request.signal,
                headers: {
                  Accept: "application/json",
                  Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                },
              })
                .then((res) => res.json())
                .then((sessionsrecordresponse) => sessionsrecordresponse.data);

              let url2 = "/api/scanner/locations?" + searchparams.toString();

              const locations = await fetch(url2, {
                signal: request.signal,
                headers: {
                  Accept: "application/json",
                  Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                },
              })
                .then((res) => res.json())
                .then((locationrecordresponse) => locationrecordresponse.data);

              let url3 = "/api/scanner/blocks?" + searchparams.toString();

              const modules = await fetch(url3, {
                signal: request.signal,
                headers: {
                  Accept: "application/json",
                  Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                },
              })
                .then((res) => res.json())
                .then((modulesresponse) => modulesresponse.data);

              return defer({
                event: event,
                sessions: sessions,
                locations: locations,
                modules: modules
              });
            },
          },
        ],
      },
      {
        path: "admin",
        element: (
          <ProtectedAdminRoute>
            <Admin />
          </ProtectedAdminRoute>
        ),
        children: [
          {
            index: true,
            path: "",
            element: <Dashboard />,
          },
          {
            id: "statistics",
            path: "statistics",
            element: <Statistics />,
          },
          {
            id: "calendar",
            path: "calendar",
            element: <Calendar />,
          },
          {
            id: "usermanagement",
            path: "usermanagement",
            element: (
              <ProtectedAdminRoute>
                <Usermanagement />
              </ProtectedAdminRoute>
            ),
            children: [
              {
                index: true,
                path: "",
                id: "logins",
                element: <Logins />,
                loader: async ({ params, request }: LoaderFunctionArgs) => {
                  const search = new URL(request.url).searchParams.get(
                    "search"
                  );
                  const contextId = new URL(request.url).searchParams.get(
                    "contextId"
                  );

                  let url = "/api/logins";
                  let filters = [];
                  if (typeof search !== "undefined" && search !== null) {
                    filters.push({ property: "search", value: search });
                  }
                  if (typeof contextId !== "undefined" && contextId !== null) {
                    filters.push({ property: "contextId", value: contextId });
                  }
                  if (filters.length > 0) {
                    let searchparams: URLSearchParams = new URLSearchParams({
                      filter: JSON.stringify(filters),
                    });
                    url = url + "?" + searchparams.toString();
                  }

                  const logins = await fetch(url, {
                    signal: request.signal,
                    headers: {
                      Accept: "application/json",
                      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                    },
                  })
                    .then((res) => res.json())
                    .then((tagresponse) => tagresponse.data);
                  const roles = await fetch("/api/roles", {
                    signal: request.signal,
                    headers: {
                      Accept: "application/json",
                      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                    },
                  })
                    .then((res) => res.json())
                    .then((tagresponse) => tagresponse.data);

                  const contexts = await fetch("/api/contexts", {
                    signal: request.signal,
                    headers: {
                      Accept: "application/json",
                      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                    },
                  })
                    .then((res) => res.json())
                    .then((tagresponse) => tagresponse.data);


                  const events = await fetch("/api/events", {
                    signal: request.signal,
                    headers: {
                      Accept: "application/json",
                      Authorization:
                        "Bearer " + sessionStorage.getItem("jwt"),
                    },
                  })
                    .then((res) => res.json())
                    .then(
                      (eventrecordresponse) =>
                        eventrecordresponse.data
                    );

                  return defer({
                    logins: logins,
                    contexts: contexts,
                    roles: roles,
                    events: events
                  });
                },
              },
              {
                index: true,
                path: "roles",
                id: "roles",
                element: <Roles />,
                loader: async ({ params, request }: LoaderFunctionArgs) => {
                  const search = new URL(request.url).searchParams.get(
                    "search"
                  );
                  const contextId = new URL(request.url).searchParams.get(
                    "contextId"
                  );

                  let url = "/api/roles";
                  let filters = [];
                  if (typeof search !== "undefined" && search !== null) {
                    filters.push({ property: "search", value: search });
                  }
                  if (typeof contextId !== "undefined" && contextId !== null) {
                    filters.push({ property: "contextId", value: contextId });
                  }
                  if (filters.length > 0) {
                    let searchparams: URLSearchParams = new URLSearchParams({
                      filter: JSON.stringify(filters),
                    });
                    url = url + "?" + searchparams.toString();
                  }
                  const roles = await fetch(url, {
                    signal: request.signal,
                    headers: {
                      Accept: "application/json",
                      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                    },
                  })
                    .then((res) => res.json())
                    .then((tagresponse) => tagresponse.data);

                  const contexts = await fetch("/api/contexts", {
                    signal: request.signal,
                    headers: {
                      Accept: "application/json",
                      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                    },
                  })
                    .then((res) => res.json())
                    .then((tagresponse) => tagresponse.data);



                  return defer({
                    contexts: contexts,
                    roles: roles
                  });
                },
              },
              {
                index: true,
                path: "contexts",
                id: "contexts",
                element: <Contexts />,
                loader: async ({ params, request }: LoaderFunctionArgs) => {
                  const search = new URL(request.url).searchParams.get(
                    "search"
                  );
                  let url = "/api/contexts";

                  if (typeof search !== "undefined" && search !== null) {
                    let searchparams: URLSearchParams = new URLSearchParams({
                      filter: JSON.stringify([
                        { property: "search", value: search },
                      ]),
                    });
                    url = url + "?" + searchparams.toString();
                  }
                  const contexts = await fetch(url, {
                    signal: request.signal,
                    headers: {
                      Accept: "application/json",
                      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                    },
                  })
                    .then((res) => res.json())
                    .then((tagresponse) => tagresponse.data);

                  return defer({ contexts: contexts });
                },
              },
            ],
          },
          {
            path: "attributes",
            element: <Attributes />,
            children: [
              {
                index: true,
                id: "tags",
                path: "",
                element: <TagsTab />,
                loader: async ({ params, request }: LoaderFunctionArgs) => {
                  let url = "/api/tags";
                  const tags = await fetch(url, {
                    signal: request.signal,
                    headers: {
                      Accept: "application/json",
                      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                    },
                  })
                    .then((res) => res.json())
                    .then((tagresponse) => tagresponse.data);

                  return defer({ tags: tags });
                },
              },
              {
                id: "categories",
                path: "categories",
                element: <CategoriesTab />,
                loader: async ({ params, request }: LoaderFunctionArgs) => {
                  let url = "/api/categories";
                  const categories = await fetch(url, {
                    signal: request.signal,
                    headers: {
                      Accept: "application/json",
                      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                    },
                  })
                    .then((res) => res.json())
                    .then((catresponse) => catresponse.data);

                  return defer({ categories: categories });
                },
              },
            ],
          },
          {
            path: "events",
            children: [
              {
                index: true,
                path: "",
                element: <Eventslist />,
              },
              {
                path: ":eventId",
                element: <Event />,
                id: "event",
                children: [
                  {
                    index: true,
                    path: "",
                    element: <EventDashboard />,
                  },
                  {
                    path: "details",
                    element: <EventDetails />,
                    children: [
                      {
                        index: true,
                        path: "",
                        element: <EventDetailsTab />,
                      },
                      {
                        id: "education",
                        path: "education",
                        element: <EducationTab />,
                        loader: async ({
                          params,
                          request,
                        }: LoaderFunctionArgs) => {
                          let searchparams: URLSearchParams =
                            new URLSearchParams({
                              filter: JSON.stringify([
                                { property: "eventId", value: params.eventId },
                              ]),
                              sort: JSON.stringify([
                                { property: "sortorder", direction: "ASC" },
                              ]),
                            });
                          let url =
                            "/api/activities?" + searchparams.toString();
                          const sessions = await fetch(url, {
                            signal: request.signal,
                            headers: {
                              Accept: "application/json",
                              Authorization:
                                "Bearer " + sessionStorage.getItem("jwt"),
                            },
                          })
                            .then((res) => res.json())
                            .then(
                              (sessionsrecordresponse) =>
                                sessionsrecordresponse.data
                            );
                          let url2 = "/api/blocks?" + searchparams.toString();
                          const modules = await fetch(url2, {
                            signal: request.signal,
                            headers: {
                              Accept: "application/json",
                              Authorization:
                                "Bearer " + sessionStorage.getItem("jwt"),
                            },
                          })
                            .then((res) => res.json())
                            .then(
                              (modulesrecordresponse) =>
                                modulesrecordresponse.data
                            );

                          return defer({
                            sessions: sessions,
                            modules: modules,
                          });
                        },
                      },
                      {
                        path: "partners",
                        id: "partners",
                        element: <PartnerInfoTab />,
                        loader: async ({
                          params,
                          request,
                        }: LoaderFunctionArgs) => {
                          let searchparams: URLSearchParams =
                            new URLSearchParams({
                              filter: JSON.stringify([
                                { property: "eventId", value: params.eventId },
                              ]),
                              sort: JSON.stringify([
                                { property: "sortorder", direction: "ASC" },
                              ]),
                            });
                          let url = "/api/partners?" + searchparams.toString();
                          const partners = await fetch(url, {
                            signal: request.signal,
                            headers: {
                              Accept: "application/json",
                              Authorization:
                                "Bearer " + sessionStorage.getItem("jwt"),
                            },
                          })
                            .then((res) => res.json())
                            .then(
                              (partnerrecordresponse) =>
                                partnerrecordresponse.data
                            );

                          return defer({ partners: partners });
                        },
                      },
                      {
                        path: "locations",
                        id: "locations",
                        element: <LocationTab />,
                        loader: async ({
                          params,
                          request,
                        }: LoaderFunctionArgs) => {
                          let searchparams: URLSearchParams =
                            new URLSearchParams({
                              filter: JSON.stringify([
                                { property: "eventId", value: params.eventId },
                              ]),
                              sort: JSON.stringify([
                                { property: "sortorder", direction: "ASC" },
                              ]),
                            });
                          let url = "/api/locations?" + searchparams.toString();
                          const locations = await fetch(url, {
                            signal: request.signal,
                            headers: {
                              Accept: "application/json",
                              Authorization:
                                "Bearer " + sessionStorage.getItem("jwt"),
                            },
                          })
                            .then((res) => res.json())
                            .then(
                              (locationrecordresponse) =>
                                locationrecordresponse.data
                            );

                          return defer({ locations: locations });
                        },
                      },
                      {
                        path: "catering",
                        element: <CateringTab />,
                      },
                      {
                        path: "venue",
                        element: <VenueTab />,
                      },
                      {
                        path: "registrationsettings",
                        element: <RegistrationSettingsTab />,
                      },
                      {
                        path: "relations",
                        element: <RelationsTab />,
                      },
                    ],
                  },
                  {
                    path: "sessions",
                    id: "sessions",
                    element: <Sessions />,
                    loader: async ({ params, request }: LoaderFunctionArgs) => {
                      let searchparams: URLSearchParams = new URLSearchParams({
                        filter: JSON.stringify([
                          { property: "eventId", value: params.eventId },
                        ]),
                        sort: JSON.stringify([
                          { property: "sortorder", direction: "ASC" },
                        ]),
                      });
                      let url = "/api/activities?" + searchparams.toString();
                      const sessions = await fetch(url, {
                        signal: request.signal,
                        headers: {
                          Accept: "application/json",
                          Authorization:
                            "Bearer " + sessionStorage.getItem("jwt"),
                        },
                      })
                        .then((res) => res.json())
                        .then(
                          (sessionsrecordresponse) =>
                            sessionsrecordresponse.data
                        );
                      let url2 = "/api/blocks?" + searchparams.toString();
                      const modules = await fetch(url2, {
                        signal: request.signal,
                        headers: {
                          Accept: "application/json",
                          Authorization:
                            "Bearer " + sessionStorage.getItem("jwt"),
                        },
                      })
                        .then((res) => res.json())
                        .then(
                          (modulesrecordresponse) => modulesrecordresponse.data
                        );

                      const categories = await fetchDataFromUrl("/api/categories");
                      return defer({ sessions: sessions, modules: modules, categories: categories });
                    },
                  },
                  {
                    path: "website",
                    element: <Website />,
                    loader: async ({ params, request }: LoaderFunctionArgs) => {
                      let searchparams: URLSearchParams = new URLSearchParams({
                        filter: JSON.stringify([
                          { property: "eventId", value: params.eventId },
                        ]),
                      });

                      let url = "/api/webpages?" + searchparams.toString();
                      const webpages: WebpageRecord[] = await fetch(url, {
                        signal: request.signal,
                        headers: {
                          Accept: "application/json",
                          Authorization:
                            "Bearer " + sessionStorage.getItem("jwt"),
                        },
                      })
                        .then((res) => res.json())
                        .then((regs) => regs.data);

                      return defer({
                        webpages: webpages,
                      });
                    },
                    children: [
                      {
                        id: "webpage",
                        path: ":path?",
                        index: true,
                        element: <Webpage />,
                        loader: async ({
                          params,
                          request,
                        }: LoaderFunctionArgs) => {
                          let searchparams: URLSearchParams =
                            new URLSearchParams({
                              filter: JSON.stringify([
                                { property: "eventId", value: params.eventId },
                              ]),
                            });

                          let url = "/api/webpages?" + searchparams.toString();

                          const webpages: WebpageRecord[] = await fetch(url, {
                            signal: request.signal,
                            headers: {
                              Accept: "application/json",
                              Authorization:
                                "Bearer " + sessionStorage.getItem("jwt"),
                            },
                          })
                            .then((res) => res.json())
                            .then((regs) => regs.data);

                          let registrationWidgetIds: number[] = [];
                          let webpage: WebpageRecord = webpages[0];
                          if (
                            typeof params.path !== "undefined" &&
                            params.path !== ""
                          ) {
                            const path = decodeURI(params.path);
                            [webpage] = webpages.filter((wp: WebpageRecord) => {
                              return (
                                wp.translations[wp.defaultLanguage].title ===
                                path
                              );
                            });
                          }
                          webpage.widgets.forEach((widget: WidgetRecord) => {
                            if (widget.type === "registrationform") {
                              registrationWidgetIds.push(widget.id);
                            }
                          });

                          let fields: FieldRecord[] = [];

                          if (registrationWidgetIds.length > 0) {
                            let url2 = "/api/registrationfields?" + new URLSearchParams({
                              filter: JSON.stringify([
                                {
                                  property: "eventId",
                                  value: params.eventId,
                                }
                              ]),
                              sort: JSON.stringify([
                                { property: "sortorder", direction: "ASC" },
                              ]),
                            }).toString();

                            fields = await fetch(url2, {
                              signal: request.signal,
                              headers: {
                                Accept: "application/json",
                                Authorization:
                                  "Bearer " + sessionStorage.getItem("jwt"),
                              },
                            })
                              .then((res) => res.json())
                              .then((regs) => regs.data);
                          }

                          return defer({
                            registrationWidgetIds: registrationWidgetIds,
                            webpages: webpages,
                            webpage: webpage,
                            fields: fields,
                          });
                        },
                      },
                    ],
                  },
                  {
                    id: "contacts",
                    path: "contacts",
                    element: <EventContacts />,
                    children: [
                      {
                        id: "importedcontacts",
                        element: <ImportedContacts />,
                        path: "",
                        index: true,
                        loader: async ({
                          params,
                          request,
                        }: LoaderFunctionArgs) => {
                          let searchparams: URLSearchParams =
                            new URLSearchParams({
                              filter: JSON.stringify([
                                { property: "eventId", value: params.eventId },
                                { property: "contact", value: true },
                              ]),
                            });

                          let url =
                            "/api/registrations?" + searchparams.toString();

                          const registrations = await fetch(url, {
                            signal: request.signal,
                            headers: {
                              Accept: "application/json",
                              Authorization:
                                "Bearer " + sessionStorage.getItem("jwt"),
                            },
                          }).then((res) => res.json());
                          let searchparams2: URLSearchParams =
                            new URLSearchParams({
                              filter: JSON.stringify([
                                { property: "eventId", value: params.eventId },
                              ]),
                              sort: JSON.stringify([
                                { property: "sortorder", direction: "ASC" },
                              ]),
                            });
                          let url2 =
                            "/api/registrationfields?" +
                            searchparams2.toString();

                          const fields = await fetch(url2, {
                            signal: request.signal,
                            headers: {
                              Accept: "application/json",
                              Authorization:
                                "Bearer " + sessionStorage.getItem("jwt"),
                            },
                          })
                            .then((res) => res.json())
                            .then((regs) => regs.data);

                          let urlwp =
                            "/api/webpages?" + searchparams.toString();

                          const webpages: WebpageRecord[] = await fetch(urlwp, {
                            signal: request.signal,
                            headers: {
                              Accept: "application/json",
                              Authorization:
                                "Bearer " + sessionStorage.getItem("jwt"),
                            },
                          })
                            .then((res) => res.json())
                            .then((regs) => regs.data);

                          let registrationWidgetId: number | undefined =
                            undefined;

                          webpages.forEach((webpage: WebpageRecord) => {
                            webpage.widgets.forEach((widget: WidgetRecord) => {
                              if (widget.type === "registrationform") {
                                registrationWidgetId = widget.id;
                              }
                            });
                          });

                          return defer({
                            registrations: registrations.data,
                            registrationsCount: registrations.total,
                            fields: fields,
                            eventId: params.eventId,
                            registrationWidgetId: registrationWidgetId,
                          });
                        },
                      },
                      {
                        id: "registrations",
                        path: "registrations",
                        element: <Registrations />,
                        loader: async ({
                          params,
                          request,
                        }: LoaderFunctionArgs) => {
                          let searchparams: URLSearchParams =
                            new URLSearchParams({
                              filter: JSON.stringify([
                                { property: "eventId", value: params.eventId },
                                { property: "registered", value: true },
                                {
                                  property: "adminApprovalRequired",
                                  value: false,
                                },
                                { property: "waitinglist", value: false },
                                {
                                  property: "view",
                                  value: "AttendanceHistory",
                                },
                              ]),
                            });

                          let url =
                            "/api/registrations?" + searchparams.toString();

                          const registrations = await fetch(url, {
                            signal: request.signal,
                            headers: {
                              Accept: "application/json",
                              Authorization:
                                "Bearer " + sessionStorage.getItem("jwt"),
                            },
                          }).then((res) => res.json());
                          let searchparams2: URLSearchParams =
                            new URLSearchParams({
                              filter: JSON.stringify([
                                { property: "eventId", value: params.eventId },
                              ]),
                              sort: JSON.stringify([
                                { property: "sortorder", direction: "ASC" },
                              ]),
                            });
                          let url2 =
                            "/api/registrationfields?" +
                            searchparams2.toString();

                          const fields = await fetch(url2, {
                            signal: request.signal,
                            headers: {
                              Accept: "application/json",
                              Authorization:
                                "Bearer " + sessionStorage.getItem("jwt"),
                            },
                          })
                            .then((res) => res.json())
                            .then((regs) => regs.data);

                          let urlwp =
                            "/api/webpages?" + searchparams.toString();

                          const webpages: WebpageRecord[] = await fetch(urlwp, {
                            signal: request.signal,
                            headers: {
                              Accept: "application/json",
                              Authorization:
                                "Bearer " + sessionStorage.getItem("jwt"),
                            },
                          })
                            .then((res) => res.json())
                            .then((regs) => regs.data);

                          let registrationWidgetId: number | undefined =
                            undefined;

                          webpages.forEach((webpage: WebpageRecord) => {
                            webpage.widgets.forEach((widget: WidgetRecord) => {
                              if (widget.type === "registrationform") {
                                registrationWidgetId = widget.id;
                              }
                            });
                          });

                          let urlsessions =
                            "/api/activities?" + searchparams2.toString();

                          const sessions = await fetch(urlsessions, {
                            signal: request.signal,
                            headers: {
                              Accept: "application/json",
                              Authorization:
                                "Bearer " + sessionStorage.getItem("jwt"),
                            },
                          })
                            .then((res) => res.json())
                            .then((regs) => regs.data);

                          return defer({
                            registrations: registrations.data,
                            registrationsCount: registrations.total,
                            sessions: sessions,
                            fields: fields,
                            eventId: params.eventId,
                            registrationWidgetId: registrationWidgetId,
                          });
                        },
                      },
                      {
                        id: "adminapproval",
                        path: "adminapproval",
                        element: (
                          <Registrations
                            routeLoader="adminapproval"
                            adminApproval={true}
                            adminApprovalRejected={false}
                          />
                        ),
                        loader: async ({
                          params,
                          request,
                        }: LoaderFunctionArgs) => {
                          let searchparams: URLSearchParams =
                            new URLSearchParams({
                              filter: JSON.stringify([
                                { property: "eventId", value: params.eventId },
                                { property: "registered", value: true },
                                {
                                  property: "adminApprovalRequired",
                                  value: true,
                                },
                                { property: "waitinglist", value: false },
                              ]),
                            });

                          let url =
                            "/api/registrations?" + searchparams.toString();

                          const registrations = await fetch(url, {
                            signal: request.signal,
                            headers: {
                              Accept: "application/json",
                              Authorization:
                                "Bearer " + sessionStorage.getItem("jwt"),
                            },
                          }).then((res) => res.json());
                          let searchparams2: URLSearchParams =
                            new URLSearchParams({
                              filter: JSON.stringify([
                                { property: "eventId", value: params.eventId },
                              ]),
                              sort: JSON.stringify([
                                { property: "sortorder", direction: "ASC" },
                              ]),
                            });
                          let url2 =
                            "/api/registrationfields?" +
                            searchparams2.toString();

                          const fields = await fetch(url2, {
                            signal: request.signal,
                            headers: {
                              Accept: "application/json",
                              Authorization:
                                "Bearer " + sessionStorage.getItem("jwt"),
                            },
                          })
                            .then((res) => res.json())
                            .then((regs) => regs.data);

                          let urlwp =
                            "/api/webpages?" + searchparams.toString();

                          const webpages: WebpageRecord[] = await fetch(urlwp, {
                            signal: request.signal,
                            headers: {
                              Accept: "application/json",
                              Authorization:
                                "Bearer " + sessionStorage.getItem("jwt"),
                            },
                          })
                            .then((res) => res.json())
                            .then((regs) => regs.data);

                          let registrationWidgetId: number | undefined =
                            undefined;

                          webpages.forEach((webpage: WebpageRecord) => {
                            webpage.widgets.forEach((widget: WidgetRecord) => {
                              if (widget.type === "registrationform") {
                                registrationWidgetId = widget.id;
                              }
                            });
                          });

                          let urlsessions =
                            "/api/activities?" + searchparams2.toString();

                          const sessions = await fetch(urlsessions, {
                            signal: request.signal,
                            headers: {
                              Accept: "application/json",
                              Authorization:
                                "Bearer " + sessionStorage.getItem("jwt"),
                            },
                          })
                            .then((res) => res.json())
                            .then((regs) => regs.data);

                          return defer({
                            registrations: registrations.data,
                            registrationsCount: registrations.total,
                            sessions: sessions,
                            fields: fields,
                            eventId: params.eventId,
                            registrationWidgetId: registrationWidgetId,
                          });
                        },
                      },
                      {
                        id: "adminapprovalrejected",
                        path: "adminapprovalrejected",
                        element: (
                          <Registrations
                            routeLoader="adminapprovalrejected"
                            adminApproval={true}
                            adminApprovalRejected={true}
                          />
                        ),
                        loader: async ({
                          params,
                          request,
                        }: LoaderFunctionArgs) => {
                          let searchparams: URLSearchParams =
                            new URLSearchParams({
                              filter: JSON.stringify([
                                { property: "eventId", value: params.eventId },
                                { property: "registered", value: true },
                                {
                                  property: "adminApprovalRequired",
                                  value: true,
                                },
                                {
                                  property: "adminApprovalRejected",
                                  value: true,
                                },
                                { property: "waitinglist", value: false },
                              ]),
                            });

                          let url =
                            "/api/registrations?" + searchparams.toString();

                          const registrations = await fetch(url, {
                            signal: request.signal,
                            headers: {
                              Accept: "application/json",
                              Authorization:
                                "Bearer " + sessionStorage.getItem("jwt"),
                            },
                          }).then((res) => res.json());
                          let searchparams2: URLSearchParams =
                            new URLSearchParams({
                              filter: JSON.stringify([
                                { property: "eventId", value: params.eventId },
                              ]),
                              sort: JSON.stringify([
                                { property: "sortorder", direction: "ASC" },
                              ]),
                            });
                          let url2 =
                            "/api/registrationfields?" +
                            searchparams2.toString();

                          const fields = await fetch(url2, {
                            signal: request.signal,
                            headers: {
                              Accept: "application/json",
                              Authorization:
                                "Bearer " + sessionStorage.getItem("jwt"),
                            },
                          })
                            .then((res) => res.json())
                            .then((regs) => regs.data);

                          let urlwp =
                            "/api/webpages?" + searchparams.toString();

                          const webpages: WebpageRecord[] = await fetch(urlwp, {
                            signal: request.signal,
                            headers: {
                              Accept: "application/json",
                              Authorization:
                                "Bearer " + sessionStorage.getItem("jwt"),
                            },
                          })
                            .then((res) => res.json())
                            .then((regs) => regs.data);

                          let registrationWidgetId: number | undefined =
                            undefined;

                          webpages.forEach((webpage: WebpageRecord) => {
                            webpage.widgets.forEach((widget: WidgetRecord) => {
                              if (widget.type === "registrationform") {
                                registrationWidgetId = widget.id;
                              }
                            });
                          });

                          let urlsessions =
                            "/api/activities?" + searchparams2.toString();

                          const sessions = await fetch(urlsessions, {
                            signal: request.signal,
                            headers: {
                              Accept: "application/json",
                              Authorization:
                                "Bearer " + sessionStorage.getItem("jwt"),
                            },
                          })
                            .then((res) => res.json())
                            .then((regs) => regs.data);

                          return defer({
                            registrations: registrations.data,
                            registrationsCount: registrations.total,
                            sessions: sessions,
                            fields: fields,
                            eventId: params.eventId,
                            registrationWidgetId: registrationWidgetId,
                          });
                        },
                      },
                      {
                        id: "waitinglist",
                        path: "waitinglist",
                        element: (
                          <Registrations
                            routeLoader="waitinglist"
                            waitinglist={true}
                          />
                        ),
                        loader: async ({
                          params,
                          request,
                        }: LoaderFunctionArgs) => {
                          let searchparams: URLSearchParams =
                            new URLSearchParams({
                              filter: JSON.stringify([
                                { property: "eventId", value: params.eventId },
                                { property: "registered", value: true },
                                { property: "waitinglist", value: true },
                              ]),
                            });

                          let url =
                            "/api/registrations?" + searchparams.toString();

                          const registrations = await fetch(url, {
                            signal: request.signal,
                            headers: {
                              Accept: "application/json",
                              Authorization:
                                "Bearer " + sessionStorage.getItem("jwt"),
                            },
                          }).then((res) => res.json());
                          let searchparams2: URLSearchParams =
                            new URLSearchParams({
                              filter: JSON.stringify([
                                { property: "eventId", value: params.eventId },
                              ]),
                              sort: JSON.stringify([
                                { property: "sortorder", direction: "ASC" },
                              ]),
                            });
                          let url2 =
                            "/api/registrationfields?" +
                            searchparams2.toString();

                          const fields = await fetch(url2, {
                            signal: request.signal,
                            headers: {
                              Accept: "application/json",
                              Authorization:
                                "Bearer " + sessionStorage.getItem("jwt"),
                            },
                          })
                            .then((res) => res.json())
                            .then((regs) => regs.data);

                          let urlwp =
                            "/api/webpages?" + searchparams.toString();

                          const webpages: WebpageRecord[] = await fetch(urlwp, {
                            signal: request.signal,
                            headers: {
                              Accept: "application/json",
                              Authorization:
                                "Bearer " + sessionStorage.getItem("jwt"),
                            },
                          })
                            .then((res) => res.json())
                            .then((regs) => regs.data);

                          let registrationWidgetId: number | undefined =
                            undefined;

                          webpages.forEach((webpage: WebpageRecord) => {
                            webpage.widgets.forEach((widget: WidgetRecord) => {
                              if (widget.type === "registrationform") {
                                registrationWidgetId = widget.id;
                              }
                            });
                          });

                          let urlsessions =
                            "/api/activities?" + searchparams2.toString();

                          const sessions = await fetch(urlsessions, {
                            signal: request.signal,
                            headers: {
                              Accept: "application/json",
                              Authorization:
                                "Bearer " + sessionStorage.getItem("jwt"),
                            },
                          })
                            .then((res) => res.json())
                            .then((regs) => regs.data);

                          return defer({
                            registrations: registrations.data,
                            registrationsCount: registrations.total,
                            sessions: sessions,
                            fields: fields,
                            eventId: params.eventId,
                            registrationWidgetId: registrationWidgetId,
                          });
                        },
                      },
                      {
                        id: "attendance",
                        path: "attendance",
                        element: <Attendance />,
                        loader: async ({
                          params,
                          request,
                        }: LoaderFunctionArgs) => {
                          const searchparams = new URLSearchParams({
                            filter: JSON.stringify([
                              { property: "eventId", value: params.eventId },
                            ]),
                          });
                          let searchparams2: URLSearchParams =
                            new URLSearchParams({
                              filter: JSON.stringify([
                                {
                                  property: "eventId",
                                  value: params.eventId,
                                },
                              ]),
                              sort: JSON.stringify([
                                { property: "sortorder", direction: "ASC" },
                              ]),
                            });

                          const fetchOptions = {
                            signal: request.signal,
                            headers: {
                              Accept: "application/json",
                              Authorization:
                                "Bearer " + sessionStorage.getItem("jwt"),
                            },
                          };

                          const fetchJson = async (url: string) => {
                            const response = await fetch(url, fetchOptions);
                            return response.json();
                          };

                          const [attendances, registrations, fields, sessions] =
                            await Promise.all([
                              fetchJson(
                                "/api/attendances/?" + searchparams.toString()
                              ),
                              fetchJson(
                                "/api/registrations?" + searchparams.toString()
                              ),
                              fetchJson(
                                "/api/registrationfields?" +
                                searchparams2.toString()
                              ),
                              fetchJson(
                                "/api/activities?" + searchparams2.toString()
                              ),
                            ]);

                          return defer({
                            attendances: attendances.data,
                            registrations: registrations.data,
                            fields: fields.data,
                            sessions: sessions.data,
                            eventId: params.eventId,
                          });
                        },
                      },
                    ],
                  },
                  {
                    path: "chat",
                    element: <EventChat />,
                  },
                  {
                    path: "budget",
                    element: <EventBudget />,
                    children: [
                      {
                        index: true,
                        id: "budget",
                        element: <EventBudgetCalculation />,

                        loader: async ({
                          params,
                          request,
                        }: LoaderFunctionArgs) => {
                          let searchparams: URLSearchParams =
                            new URLSearchParams({
                              filter: JSON.stringify([
                                { property: "eventId", value: params.eventId },
                              ]),
                            });

                          const budgetExpenses = await fetch(
                            "/api/budgetExpenses?" + searchparams.toString(),
                            {
                              signal: request.signal,
                              headers: {
                                Accept: "application/json",
                                Authorization:
                                  "Bearer " + sessionStorage.getItem("jwt"),
                              },
                            }
                          )
                            .then((res) => res.json())
                            .then((json) => json.data);

                          const budgetIncomes = await fetch(
                            "/api/budgetIncomes?" + searchparams.toString(),
                            {
                              signal: request.signal,
                              headers: {
                                Accept: "application/json",
                                Authorization:
                                  "Bearer " + sessionStorage.getItem("jwt"),
                              },
                            }
                          )
                            .then((res) => res.json())
                            .then((json) => json.data);

                          const budgetIncomeFixeds = await fetch(
                            "/api/budgetIncomeFixeds?" +
                            searchparams.toString(),
                            {
                              signal: request.signal,
                              headers: {
                                Accept: "application/json",
                                Authorization:
                                  "Bearer " + sessionStorage.getItem("jwt"),
                              },
                            }
                          )
                            .then((res) => res.json())
                            .then((json) => json.data);

                          return defer({
                            budgetExpenses: budgetExpenses,
                            budgetIncomeFixeds: budgetIncomeFixeds,
                            budgetIncomes: budgetIncomes,
                          });
                        },
                      },
                    ],
                  },
                  {
                    path: "statistics",
                    element: <EventStatistics />,
                  },
                  {
                    path: "mailings",
                    element: <Mailings />,
                    children: [
                      {
                        index: true,
                        id: "mailedit",
                        path: "",
                        element: <MailEditTab />,
                        loader: async ({
                          params,
                          request,
                        }: LoaderFunctionArgs) => {
                          let searchparams: URLSearchParams =
                            new URLSearchParams({
                              filter: JSON.stringify([
                                { property: "eventId", value: params.eventId },
                              ]),
                            });

                          let url =
                            "/api/registrationmails?" + searchparams.toString();

                          const registrationsmails = await fetch(url, {
                            signal: request.signal,
                            headers: {
                              Accept: "application/json",
                              Authorization:
                                "Bearer " + sessionStorage.getItem("jwt"),
                            },
                          })
                            .then((res) => res.json())
                            .then(
                              (registrationsmails) => registrationsmails.data
                            );

                          const markers = await fetch(
                            "/api/mailmarkers?eventId=" + params.eventId,
                            {
                              signal: request.signal,
                              headers: {
                                Accept: "application/json",
                                Authorization:
                                  "Bearer " + sessionStorage.getItem("jwt"),
                              },
                            }
                          )
                            .then((res) => res.json())
                            .then((markers) => markers.data);

                          return defer({
                            registrationsmails: registrationsmails,
                            markers: markers,
                          });
                        },
                      },
                    ],
                  },
                  {
                    path: "media",
                    id: "media",
                    element: <Media />,
                    loader: async ({ params, request }: LoaderFunctionArgs) => {
                      let searchparams: URLSearchParams = new URLSearchParams({
                        filter: JSON.stringify([
                          { property: "eventId", value: params.eventId },
                          { property: "purpose", value: "media" },
                        ]),
                      });

                      let url = "/api/files?" + searchparams.toString();

                      const files = await fetch(url, {
                        signal: request.signal,
                        headers: {
                          Accept: "application/json",
                          Authorization:
                            "Bearer " + sessionStorage.getItem("jwt"),
                        },
                      })
                        .then((res) => res.json())
                        .then((files) => files.data);

                      return defer({ files: files });
                    },
                  },
                ],
                loader: async ({ params, request }: LoaderFunctionArgs) => {
                  const event = await fetch("/api/events/" + params.eventId, {
                    signal: request.signal,
                    headers: {
                      Accept: "application/json",
                      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                    },
                  })
                    .then((response) => response.json())
                    .then((eventrecordresponse) => eventrecordresponse.data);

                  return defer({ event: event });
                },
              },
            ],
          },
        ],
      },
      /*{
        path: "event/:publicId/",
        element: <Website />,
        children: [
          {
            id: "eventwebpage",
            path: ":path?",
            index: true,
            element: <Webpage />,
            loader: async ({ params, request }: LoaderFunctionArgs) => {
              let searchparams: URLSearchParams = new URLSearchParams({
                filter: JSON.stringify([
                  { property: "publicEventId", value: params.publicEventId },
                ]),
              });

              let url = "/api/webpages?" + searchparams.toString();

              const webpages: WebpageRecord[] = await fetch(url, {
                signal: request.signal,
                headers: {
                  Accept: "application/json",
                  Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                },
              })
                .then((res) => res.json())
                .then((regs) => regs.data);

              let registrationWidgetId: number | undefined = undefined;
              let webpage: WebpageRecord = webpages[0];
              if (typeof params.path !== "undefined" && params.path !== "") {
                const path = decodeURI(params.path);
                [webpage] = webpages.filter((wp: WebpageRecord) => {
                  return wp.translations[wp.defaultLanguage].title === path;
                });
              }

              webpage.widgets.forEach((widget: WidgetRecord) => {
                if (widget.type === "registrationform") {
                  registrationWidgetId = widget.id;
                }
              });

              let fields: FieldRecord[] = [];

              if (typeof registrationWidgetId !== "undefined") {
                let searchparams2: URLSearchParams = new URLSearchParams({
                  filter: JSON.stringify([
                    {
                      property: "eventId",
                      value: webpage.eventId,
                    },
                  ]),
                  sort: JSON.stringify([
                    { property: "sortorder", direction: "ASC" },
                  ]),
                });
                let url2 =
                  "/api/registrationfields?" + searchparams2.toString();

                fields = await fetch(url2, {
                  signal: request.signal,
                  headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                  },
                })
                  .then((res) => res.json())
                  .then((regs) => regs.data);
              }

              return defer({
                registrationWidgetId: registrationWidgetId,
                webpages: webpages,
                webpage: webpage,
                fields: fields,
              });
            },
          },
        ],
      },*/
    ],
  },
];

export default routes;
