import { styled } from "@mui/system";

export const BlinkingDiv = styled("div")`
  animation: blink-animation 1s infinite;
  animation-iteration-count: 2;

  @keyframes blink-animation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
