import React, { useEffect } from "react";
import { Box, FormControl, TextField, Typography } from "@mui/material";
import { getTranslations } from "../../translations/Translations";
import { IRecord, Tag } from "../../interfaces/Interfaces";
import { useRevalidator } from "react-router-dom";
import { useFormik } from "formik";
import SaveBar from "../../components/SaveBar";
import * as Yup from "yup";
import { DisplayErrors } from "../../components/DisplayErrors";

interface TagFormProps {
  tag: Tag;
  onDelete: Function;
  setDirty: Function;
}

function TagForm(props: TagFormProps) {
  const translations = getTranslations();
  const revalidator = useRevalidator();
  const setDirty = props.setDirty;

  const getFlatObj = (obj: IRecord) => {
    return {
      id: obj.id,
      title: obj.translations[obj.defaultLanguage].title || "",
      language: obj.defaultLanguage,
      defaultLanguage: obj.defaultLanguage,
    };
  };

  const formik = useFormik({
    initialValues: getFlatObj(props.tag),
    validationSchema: Yup.object({
      title: Yup.string()
        .max(400, translations["errors.maxlength400"])
        .required(translations["errors.requiredfield"]),
    }),
    onSubmit: (values) => {
      fetch("/api/tags/" + props.tag.id + "/", {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("jwt"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
        .then((res) => res.json())
        .then((jsondata) => {
          console.log(formik);
          if (jsondata.success) {
            revalidator.revalidate();
          }
        })
        .finally(() => {
          formik.setSubmitting(false);
          formik.resetForm({
            values: values,
          });
        });
    },
  });

  useEffect(() => {
    setDirty(formik.dirty);
  }, [formik.dirty, setDirty]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{ display: "flex", flexGrow: 1, overflow: "scroll" }}
    >
      <Box flex="1" display="flex" flexDirection="column">
        <Box flex="1" display="flex" flexDirection="column" gap={1} p={2}>
          <Box display="flex" flexDirection={"row"} className="fieldlabel">
            <Box width="260px" flexShrink={0}>
              <Typography>{translations["tagform.title"]}</Typography>
            </Box>
            <FormControl fullWidth>
              <TextField
                size="small"
                error={Boolean(formik.touched.title && formik.errors.title)}
                {...formik.getFieldProps("title")}
              ></TextField>
              <DisplayErrors
                name="title"
                touched={Boolean(formik.touched.title)}
                errors={formik.errors.title}
              />
            </FormControl>
          </Box>
        </Box>
        <SaveBar
          formik={formik}
          onDelete={() => {
            props.onDelete();
          }}
        />
      </Box>
    </form>
  );
}

export default TagForm;
