import React, { useId } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface AlertDialogProps {
  open: [boolean, Function];
  title: string;
  description: string;
  confirmText?: string;
  declineText?: string;
  confirmFunction?: Function;
}

export default function AlertDialog(props: AlertDialogProps) {
  const [open, setOpen] = props.open;
  const titleId = useId();
  const descriptionId = useId();

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmClose = () => {
    if (props.confirmFunction) {
      props.confirmFunction();
    }
    handleClose();
  };

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      aria-labelledby={titleId}
      aria-describedby={descriptionId}
    >
      <DialogTitle id={titleId}>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText
          id={descriptionId}
          dangerouslySetInnerHTML={{ __html: props.description }}
        />
      </DialogContent>
      <DialogActions>
        {props.confirmFunction && (
          <Button onClick={handleConfirmClose}>
            {props.confirmText ? props.confirmText : "Bestätigen"}
          </Button>
        )}
        <Button onClick={handleClose} autoFocus>
          {props.declineText ? props.declineText : "Abbrechen"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
