import React, { useState } from "react";
import "./Login.scss";
import { useNavigate } from "react-router-dom";
import { TextField, Button, Container, Box, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../AuthProvider";
import { getTranslations } from "../../translations/Translations";

import isMobile from "../../helpers/isMobile";
import { DisplayError } from "../../components/DisplayErrors";

const Login = () => {
  const translations = getTranslations();
  const navigate = useNavigate();
  const auth = useAuth();
  const [loginError,setLoginError] = useState<string>('');

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .max(255)
        .required(translations["login.usernamerequired"]),
      password: Yup.string()
        .max(255)
        .required(translations["login.passwordrequired"]),
    }),
    onSubmit: (values) => {
      let formData = new URLSearchParams();
      formData.append("username", values.username);
      formData.append("password", values.password);

      fetch("/api/logins/authenticate", {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: formData,
      })
        .then((res) => res.json())
        .then((jsondata) => {
          if (jsondata.success) {
            
            setLoginError('');
            auth?.login(jsondata.data);

            if (jsondata.data) {
              if (
                jsondata.data.role?.id === 1 ||
                jsondata.data.role?.id === 2
              ) {
                navigate("/admin/", { replace: true });
              } else if (jsondata.data.role?.scanner) {
                navigate("/scanner/", { replace: true });
              }
            }
          } else {
            setLoginError(translations['login.wrong'])
          }
        })
        .finally(() => {
          formik.setSubmitting(false);
          formik.resetForm({
            values: values,
          });
        });
    },
  });

  return (
    <>
      {isMobile() ? (
        <Box
          component="main"
          sx={{
            backgroundColor: "#006e55",
            alignItems: "center",
            display: "flex",
            flexGrow: 1,
            minHeight: "100%",
          }}
        >
          <Container
            maxWidth="sm"
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "1rem",
              paddingBottom: "0.5",
              margin: "1rem",
              borderRadius: "6px",
              backgroundColor: "#FFF",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              sx={{
                height: "auto",
                width: "200px",
              }}
              alt="The house from the offer."
              src="/clicla2_logo.png"
            />
            <form onSubmit={formik.handleSubmit}>
              <TextField
                fullWidth
                label={translations["login.username"]}
                margin="normal"
                autoComplete="username"
                variant="outlined"
                {...formik.getFieldProps("username")}
              />
              <TextField
                fullWidth
                label={translations["login.password"]}
                margin="normal"
                autoComplete="current-password"
                type="password"
                variant="outlined"
                {...formik.getFieldProps("password")}
              />
              {loginError !== '' ? 
                <DisplayError error={loginError} name={""}/>
              :null}
              <Box sx={{ py: 2 }}>
                <Button
                  color="primary"
                  disabled={
                    formik.isSubmitting ||
                    formik.values.password === "" ||
                    formik.values.username === ""
                  }
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {translations["login.signin"]}
                </Button>
              </Box>
            </form>
          </Container>
        </Box>
      ) : (
        <Box
          component="main"
          sx={{
            backgroundColor: "#006e55",
            alignItems: "center",
            display: "flex",
            flexGrow: 1,
            minHeight: "100%",
          }}
        >
          <Container
            maxWidth="sm"
            sx={{
              borderRadius: "6px",
              backgroundColor: "#FFF",
            }}
          >
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ my: 3 }}>
                <Typography color="textPrimary" variant="h4">
                  Clicla 2
                </Typography>
                <Typography color="textSecondary" gutterBottom variant="body2">
                  {translations["login.signin"]}
                </Typography>
              </Box>
              <TextField
                fullWidth
                label={translations["login.username"]}
                margin="normal"
                autoComplete="username"
                variant="outlined"
                {...formik.getFieldProps("username")}
              />
              <TextField
                fullWidth
                label={translations["login.password"]}
                margin="normal"
                autoComplete="current-password"
                type="password"
                variant="outlined"
                {...formik.getFieldProps("password")}
              />
              <Box sx={{ py: 2 }}>
                <Button
                  color="primary"
                  disabled={
                    formik.isSubmitting ||
                    formik.values.password === "" ||
                    formik.values.username === ""
                  }
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {translations["login.signin"]}
                </Button>
              </Box>
            </form>
          </Container>
        </Box>
      )}
    </>
  );
};

export default Login;
