import { Box, FormControlLabel, InputAdornment, Radio, RadioGroup, TextField } from "@mui/material";
import { getTranslations } from "../../../../../translations/Translations";
import React from "react";
import Title from "./registrationfields/Title";
import Divider from "./registrationfields/Divider";
import Text from "./registrationfields/Text";
import RegTextField from "./registrationfields/RegTextField";
import RegTextArea from "./registrationfields/RegTextArea";
import RegRadioButtons from "./registrationfields/RegRadioButtons";
import RegComboBox from "./registrationfields/RegComboBox";
import RegDateField from "./registrationfields/DateField";
import RegCheckboxField from "./registrationfields/RegCheckboxField";
import { DatePicker } from "@mui/x-date-pickers";
import { FieldRecord } from "../../../../../interfaces/Interfaces";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CheckBox from "@mui/icons-material/CheckBox";
import TermsField from "./registrationfields/TermsFields";
const translations = getTranslations();


export const registrationfields = [
    {
        type: "title",
        listElement: (
            <Box>{translations["registrationform.title"]}</Box>
        ),
        formElement: Title,
        configOptions: ['title','required','parent'],
        defaultConfig: {
            parent: ''
        }
    },
    {
        type: "text",
        listElement: (
            <Box>{translations["registrationform.text"]}</Box>
        ),
        formElement: Text,
        configOptions: ['title','required','parent'],
        defaultConfig: {
            parent: ''
        }
    },
    {
        type: "divider",
        listElement: (
            <Box>{translations["registrationform.divider"]}</Box>
        ),
        formElement: Divider,
        defaultConfig: {
            parent: ''
        }
    },
    {
        type: "textfield",
        listElement: (
            <TextField
                label={translations["registrationform.textfield"]}
                size="small"
            />
        ),
        formElement: RegTextField,
        configOptions: ['title','required','parent'],
        defaultConfig: {
            parent: ''
        }
    },
    {
        type: "checkbox",
        listElement: (
            <CheckBox />
        ),
        formElement: RegCheckboxField,
        configOptions: ['title','checkboxlabel', 'required', 'parent'],
        defaultConfig: {
            parent: '',
            checkboxlabel: 'Checkbox text'
        }
    },
    {
        type: "terms",
        listElement: (
            <Box>{translations["registrationform.terms"]}</Box>
        ),
        configOptions: ['title','checkboxlabel','termstext','termslink'],
        formElement: TermsField,
        defaultConfig: {
            parent: '',
            checkboxlabel: 'Checkbox text',
            termstext: 'TermsText',
            termslink: ''
            //fileSrc: ''
        },
        required: true
    },
    {
        type: "telephonefield",
        listElement: (
            <TextField
                label={translations["registrationform.telephonefield"]}
                size="small"
            />

        ),
        configOptions: ['title','required','parent'],
        formElement: RegTextField,
        defaultConfig: {
            parent: ''
        }
    },
    {
        type: "emailfield",
        listElement: (
            <TextField
                label={translations["registrationform.emailfield"]}
                size="small"
            />
        ),
        configOptions: ['title','required','parent'],
        formElement: RegTextField,
        defaultConfig: {
            parent: ''
        }
    },
    {
        type: "textarea",
        listElement: (
            <TextField
                label={translations["registrationform.textarea"]}
                multiline={true}
                minRows={3}
                size="small"
            />
        ),
        formElement: RegTextArea,
        configOptions: ['title','required','parent'],
        defaultConfig: {
            parent: ''
        }
    },
    {
        type: "radiobuttons",
        listElement: (
            <>
                <Box>{translations["registrationform.radiobuttons"]}</Box>
                <RadioGroup defaultValue="opt1">
                    <FormControlLabel
                        value="opt1"
                        control={<Radio />}
                        label={translations["registrationform.radiobuttonsopt1"]}
                    />
                    <FormControlLabel
                        value="opt2"
                        control={<Radio />}
                        label={translations["registrationform.radiobuttonsopt1"]}
                    />
                </RadioGroup>
            </>
        ),
        configOptions: ['title','options','required','parent'],
        formElement: RegRadioButtons,
        defaultConfig: {
            parent: ''
        }
    },
    {
        type: "combobox",
        listElement: (
            <TextField
                label={translations["registrationform.combobox"]}
                size="small"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <KeyboardArrowDownIcon />
                        </InputAdornment>
                    ),
                }}
            />
        ),
        configOptions: ['title','options','required','parent'],
        formElement: RegComboBox,
        defaultConfig: {
            parent: ''
        }
    },
    /*{
        type: "countryfield",
        listElement: (
            <TextField
                label={translations["registrationform.countryfield"]}
                size="small"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <KeyboardArrowDownIcon />
                        </InputAdornment>
                    ),
                }}
            />
        ),
        configOptions: ['title','required','parent'],
        formElement: RegComboBox,
        defaultConfig: {
            parent: ''
        }
        
    },
    {
        type: "datefield",
        listElement: (
            <DatePicker
                label={translations["registrationform.datefield"]}
                format="d.M.y"
                slotProps={{
                    textField: {
                        size: "small",
                    },
                }}
            />
        ),
        configOptions: ['title','required','parent'],
        formElement: RegDateField,
        defaultConfig: {
            parent: ''
        }
    },
    {
        type: "birthdatefield",
        listElement: (
            <DatePicker
                label={translations["registrationform.birthdatefield"]}
                format="d.M.y"
                slotProps={{
                    textField: {
                        size: "small",
                    },
                }}
            />
        ),
        configOptions: ['title','required','parent'],
        formElement: RegDateField,
        defaultConfig: {
            parent: ''
        }
    },*/
    /*{
        type: "switch",
        formElement: <Box>Not yet implemented</Box>,
        listElement: <>
            <Box>{translations["registrationform.switch"]}</Box>
            <Switch />
        </>
    }*/
];

const RegistrationField = ({
    field,
}: {
    field: FieldRecord;

}) => {
    const { type } = field;
    const fieldConfig = registrationfields.find((w) => w.type === type);
    const FieldComponent = fieldConfig && fieldConfig.formElement;
    return FieldComponent ? (
        <Box sx={{ flex: 1 }} className={`${type}-widget`}>
            <FieldComponent registrationfield={field} />
        </Box>
    ) : (
        <Box sx={{ flex: 1 }}>{field.type}</Box>
    );
};

export default RegistrationField;