import { Box } from "@mui/material"
import React from "react"
import { FieldRecordWidget } from "../../../../../../interfaces/Interfaces";

const Title = ({ registrationfield }: FieldRecordWidget) => {
    return (
        <Box className="title" sx={{display: 'flex',flexDirection: 'column'}}>
            {registrationfield.translations[registrationfield.defaultLanguage].title}
        </Box>
    )
}

export default Title;