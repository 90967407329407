import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  LinearProgress,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { getTranslations } from "../../translations/Translations";

interface EventStageRecord {
  id: number;
  translations: any;
  defaultLanguage: string;
  taskCompletion: number;
}

function EventStageList() {
  const translations = getTranslations();
  const [items, setItems] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    let params: URLSearchParams = new URLSearchParams({
      view: "EventCompletion",
    });
    fetch("/api/events?" + params.toString(), {
      signal: controller.signal,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setItems(result.data);
      })
      .catch((err: DOMException) => {
        if (err.name === "AbortError") {
        }
      });
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <Box display="flex" flexDirection="column" className="ui-widget">
      <Box display="flex" m={1} flex={1} flexDirection={"column"}>
        <Box className="titlebar">
          <Typography>{translations["eventstagelist.title"]}</Typography>
        </Box>
        <List className="list">
          {items &&
            items.map &&
            items.map((element: EventStageRecord) => {
              return (
                <ListItem sx={{ display: "block" }} key={element.id}>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                  >
                    <Box>#{element.id}</Box>
                    <Box fontWeight={"bold"}>
                      {element.translations[element.defaultLanguage].title}
                    </Box>
                  </Box>
                  <Box position="relative" mt={1}>
                    <LinearProgress
                      sx={{ height: "30px" }}
                      variant="determinate"
                      value={element.taskCompletion}
                    />
                    <Box
                      position="absolute"
                      color="#fff"
                      height="30px"
                      lineHeight="30px"
                      width="30px"
                      top="0px"
                      left="4px"
                    >
                      {element.taskCompletion + " %"}
                    </Box>
                  </Box>
                  <Box display="flex" mt={1} justifyContent="end">
                    <Button variant="contained">
                      {translations["eventstagelist.edit"]}
                    </Button>
                  </Box>
                </ListItem>
              );
            })}
        </List>
        <Box className="bottombar"></Box>
      </Box>
    </Box>
  );
}

export default EventStageList;
