import React, { useState, useEffect } from "react";
import {
  getGridTranslations,
  getTranslations,
} from "../../../../translations/Translations";
import { useRevalidator, useRouteLoaderData } from "react-router-dom";
import {
  AttendanceRouterReponse,
  INewRecord,
  IRecord,
} from "../../../../interfaces/Interfaces";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowParams,
  GridValueFormatterParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import SaveBar from "../../../../components/SaveBar";
import { useFormik } from "formik";
import {
  formatDateTime,
  localeFormatDateTime,
  parseDateTime,
} from "../../../../helpers/DateFunctions";
import ClearIcon from "@mui/icons-material/Clear";
import { DateTimePicker } from "@mui/x-date-pickers";
import { DisplayErrors } from "../../../../components/DisplayErrors";

interface jsonobject {
  [key: string]: string | number | boolean;
}

function Attendance() {
  const translations = getTranslations();
  const gridtranslations = getGridTranslations();
  const { attendances, registrations, fields, eventId, sessions } =
    useRouteLoaderData("attendance") as AttendanceRouterReponse;
  const [rows, setRows] = useState<IRecord[]>(attendances);
  const [open, setOpen] = useState<boolean>(false);
  const revalidator = useRevalidator();
  const [registration, setRegistration] = useState<IRecord | null>(null);
  const [activity, setActivity] = useState<IRecord | null>(null);
  const [registrationFilter, setRegistrationFilter] = useState<IRecord | null>(
    null
  );
  const [activityFilter, setActivityFilter] = useState<IRecord | null>(null);
  const [doubleFilter, setDoubleFilter] = useState<string>("false");
  const [checkInFilter, setCheckInFilter] = useState<string>("null");

  useEffect(() => {
    const controller = new AbortController();
    let url: string = "/api/attendances";
    let objlist: jsonobject[] = [
      {
        property: "eventId",
        value: eventId,
      },
    ];

    if (activityFilter !== null) {
      objlist.push({ property: "activityId", value: activityFilter.id });
    }
    if (checkInFilter !== "null") {
      objlist.push({ property: "checkIn", value: checkInFilter });
    }
    if (doubleFilter !== "false") {
      objlist.push({ property: "doubleCheck", value: doubleFilter });
    }
    if (registrationFilter !== null) {
      objlist.push({
        property: "registrationId",
        value: registrationFilter.id,
      });
    }

    if (objlist.length > 0) {
      let params: URLSearchParams = new URLSearchParams({
        filter: JSON.stringify(objlist),
      });
      url = url + "?" + params.toString();
    }

    fetch(url, {
      signal: controller.signal,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setRows(result.data);
      })
      .catch((err: DOMException) => {
        if (err.name === "AbortError") {
        }
      });
    return () => {
      controller.abort();
    };
  }, [
    eventId,
    registrationFilter,
    activityFilter,
    doubleFilter,
    checkInFilter,
    attendances,
  ]);

  const getFlatObj = (obj: INewRecord) => {
    var result: INewRecord = {
      registrationId: obj.registrationId,
      activityId: obj.activityId,
      checkIn: !!obj.checkIn,
      checkDate: obj.checkDate,
    };
    return result;
  };
  const [initialValues, setInitialValues] = useState<INewRecord>(
    getFlatObj({ checkIn: true })
  );

  const getSubmissableData = (obj: INewRecord) => {
    console.log(obj);
    return {
      ...obj,
      eventId: eventId,
      checkDate: formatDateTime(obj.checkDate),
    };
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      fetch("/api/attendances", {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("jwt"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(getSubmissableData(values)),
      })
        .then((res) => res.json())
        .then((jsondata) => {
          if (jsondata.success) {
            revalidator.revalidate();
          }
        })
        .finally(() => {
          setRegistration(null);
          setActivity(null);
          formik.setSubmitting(false);
          formik.resetForm({
            values: values,
          });
          setOpen(false);
        });
    },
  });

  const deleteAttendance = (attendance: IRecord) => {
    fetch("/api/attendances/" + attendance.id, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("jwt"),
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((jsondata) => {
        if (jsondata.success) {
          revalidator.revalidate();
        }
      });
  };

  /*useEffect(() => {
    setRowCountState(attendances.length);
    setRows(attendances);
  }, [attendances]);*/

  const baseColumns = [
    {
      flex: 1,
      headerName: translations["attendance.type"],
      field: "type",
      valueGetter: (params: GridValueGetterParams<IRecord>) => {
        return params.row.checkIn
          ? translations["attendance.checkedIn"]
          : translations["attendance.checkedOut"];
      },
      renderCell: (params: any) => {
        return (
          <Chip
            label={params.value}
            color={params.row.checkIn ? "primary" : "default"}
          />
        );
      },
    },
    {
      flex: 1,
      headerName: translations["attendance.date"],
      field: "checkDate",
      valueFormatter: (params: GridValueFormatterParams<IRecord>) => {
        return localeFormatDateTime(parseDateTime(params.value));
      },
    },
    {
      flex: 1,
      headerName: translations["attendance.activity"],
      field: "activity",
      valueFormatter: (params: GridValueFormatterParams<IRecord>) => {
        return params.value
          ? params.value.translations[params.value.defaultLanguage].title
          : "-";
      },
    },
    {
      field: "actions",
      type: "actions",
      flex: 1,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={<ClearIcon />}
          onClick={() => {
            let attendance = attendances.find(
              (attendance) => attendance.id === params.id
            );
            if (typeof attendance !== "undefined") {
              deleteAttendance(attendance);
            }
          }}
          label={translations["attendance.delete"]}
        />,
      ],
    },
  ];

  const filteredColumns = fields
    .filter(
      (field) =>
        field.type !== "divider" &&
        field.type !== "text" &&
        field.type !== "title"
    )
    .map((field) => ({
      field: "field" + field.id,
      headerName: field.translations[field.defaultLanguage].title,
      flex: 1,
      filterable: false,
      //groupable: false,
      hideable: false,
      pinnable: false,
      valueGetter: (params: GridValueGetterParams<IRecord>) => {
        return params.row.registration.values["field" + field.id];
      },
    }));

  const columns = [...filteredColumns, ...baseColumns];

  const firstnamefield = fields.filter(
    (field) => field.defaultfield === "firstname"
  )[0];
  const lastnamefield = fields.filter(
    (field) => field.defaultfield === "lastname"
  )[0];

  return (
    <>
      <Box p={1} gap={1} display="flex" flexDirection="row">
        <Button
          variant="contained"
          onClick={() => {
            let obj = getFlatObj({});
            formik.resetForm({
              values: obj,
              touched: {},
              errors: {},
            });
            setInitialValues(obj);
            formik.setValues(obj);
            setOpen(true);
          }}
        >
          {translations["attendance.add"]}
        </Button>
        <Autocomplete
          sx={{ width: 300 }}
          options={registrations}
          value={registrationFilter}
          onChange={(event: any, value: IRecord | null) => {
            setRegistrationFilter(value);
          }}
          size="small"
          autoHighlight
          getOptionLabel={(option) =>
            option.values["field" + firstnamefield.id] +
            " " +
            option.values["field" + lastnamefield.id]
          }
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              {option.values["field" + firstnamefield.id]}{" "}
              {option.values["field" + lastnamefield.id]}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={translations["attendance.registration"]}
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
        />
        <Autocomplete
          sx={{ width: 300 }}
          options={sessions}
          value={activityFilter}
          onChange={(event: any, value: IRecord | null) => {
            setActivityFilter(value);
          }}
          size="small"
          autoHighlight
          getOptionLabel={(option) =>
            option.translations[option.defaultLanguage].title
          }
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              {option.translations[option.defaultLanguage].title}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={translations["attendance.activity"]}
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
        />
        <FormControl sx={{ width: 200 }}>
          <InputLabel id="checkinfilterlabel">
            {translations["attendance.type"]}
          </InputLabel>
          <Select
            size="small"
            labelId={"checkinfilterlabel"}
            label={translations["attendance.type"]}
            value={checkInFilter}
            autoWidth={true}
            onChange={(e) => {
              setCheckInFilter(e.target.value);
            }}
          >
            <MenuItem value={"null"}>{translations["attendance.all"]}</MenuItem>
            <MenuItem value={"true"}>
              {translations["attendance.checkedIn"]}
            </MenuItem>
            <MenuItem value={"false"}>
              {translations["attendance.checkedOut"]}
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ width: 200 }}>
          <InputLabel id="doublefilterlabel">
            {translations["attendance.filterdoubles"]}
          </InputLabel>
          <Select
            labelId={"doublefilterlabel"}
            label={translations["attendance.filterdoubles"]}
            size="small"
            value={doubleFilter}
            onChange={(e) => {
              setDoubleFilter(e.target.value);
            }}
          >
            <MenuItem value={"true"}>{translations["attendance.yes"]}</MenuItem>
            <MenuItem value={"false"}>{translations["attendance.no"]}</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <DataGrid
        rows={rows}
        columns={columns}
        localeText={gridtranslations}
      ></DataGrid>
      <Dialog
        className="window"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        scroll="body"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>{translations["attendance.add"]}</DialogTitle>
        <DialogContent dividers={false}>
          <form>
            <Box display="flex" flexDirection="column">
              <Box display="flex" flexDirection={"row"} mb={1}>
                <Box width="260px" flexShrink={0} className="fieldlabel">
                  <Typography>{translations["attendance.type"]}</Typography>
                </Box>
                <Box className="switchlabel">
                  {translations["attendance.typecheckout"]}
                </Box>
                <Box flex={1}>
                  <FormControlLabel
                    control={
                      <Switch
                        name="checkIn"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        checked={formik.values.checkIn}
                      />
                    }
                    label={translations["attendance.typecheckin"]}
                  />
                </Box>
              </Box>
              <Box display="flex" flexDirection={"row"} mb={1}>
                <Box width="260px" flexShrink={0} className="fieldlabel">
                  <Typography>{translations["attendance.date"]}</Typography>
                </Box>
                <FormControl>
                  <Box display="flex" flexDirection={"row"} gap={1}>
                    <DateTimePicker
                      format="dd.MM.yyyy HH:mm:ss"
                      onAccept={(eventorvalue) => {
                        formik.setFieldValue("checkDate", eventorvalue);
                      }}
                      sx={{ width: "300px" }}
                      ampm={false}
                      slotProps={{
                        textField: {
                          size: "small",
                          name: "checkDate",
                          onChange: (eventorvalue) => {
                            formik.setFieldValue("checkDate", eventorvalue);
                          },
                          error: Boolean(
                            formik.touched.checkDate && formik.errors.checkDate
                          ),
                          onBlur: formik.handleBlur,
                        },
                      }}
                    />
                  </Box>
                  <DisplayErrors
                    name="checkDate"
                    touched={Boolean(formik.touched.checkDate)}
                    errors={formik.errors.checkDate}
                  />
                </FormControl>
              </Box>
              <Box display="flex" flexDirection="row" mb={1}>
                <Box className="fieldlabel" minWidth={"260px"}>
                  <Typography>
                    {translations["attendance.registration"]}
                  </Typography>
                </Box>
                <Autocomplete
                  sx={{ width: 300 }}
                  options={registrations}
                  value={registration}
                  onChange={(event: any, v: IRecord | null) => {
                    setRegistration(v);
                    if (v == null) return;
                    formik.setFieldValue("registrationId", v?.id);
                  }}
                  size="small"
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.values["field" + firstnamefield.id] +
                    " " +
                    option.values["field" + lastnamefield.id]
                  }
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.values["field" + firstnamefield.id]}{" "}
                      {option.values["field" + lastnamefield.id]}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </Box>
              <Box display="flex" flexDirection="row" mb={1}>
                <Box className="fieldlabel" minWidth={"260px"}>
                  <Typography>{translations["attendance.activity"]}</Typography>
                </Box>
                <Autocomplete
                  sx={{ width: 300 }}
                  options={sessions}
                  value={activity}
                  onChange={(event: any, v: IRecord | null) => {
                    setActivity(v);
                    if (v == null) return;
                    formik.setFieldValue("activityId", v?.id);
                  }}
                  size="small"
                  autoHighlight
                  getOptionLabel={(option) =>
                    option.translations[option.defaultLanguage].title
                  }
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.translations[option.defaultLanguage].title}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </Box>
            </Box>
          </form>
        </DialogContent>
        <SaveBar
          formik={formik}
          manualSubmit={true}
          onCancel={() => {
            setOpen(false);
          }}
        ></SaveBar>
      </Dialog>
    </>
  );
}

export default Attendance;
