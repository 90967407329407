import { Box, Button } from "@mui/material"
import { ReactWidgetWithStyles } from "../../../../../../interfaces/Interfaces"
import React from "react"
import withStandardProps from "./HOCs/withStandardStyles";

const ButtonWidget = ({ widget, standardStyles }: ReactWidgetWithStyles) => {
    const config = widget.config || {};
    const align = config['align'];
    const textAlign = config['textAlign'];

    const styles: any = {
        padding: '1rem',
        minWidth: '30%',
        fontSize: '1.5rem',
        textTransform: 'inherit'
    };

    if (textAlign) {
        styles.textAlign = textAlign;
    }

    if (widget.translations[widget.defaultLanguage] && widget.translations[widget.defaultLanguage].content) {
        return (
            <Box sx={{textAlign: align}}>
            <Button sx={{...styles, ...standardStyles}}  variant="outlined"
        color="primary">{widget.translations[widget.defaultLanguage].content}</Button>
            </Box>
        );
    } else {
        return <Box sx={styles}>{widget.type}</Box>
    }
}

export default withStandardProps(ButtonWidget);