import { Box, FormControl, MenuItem, Select, TextField, Typography } from "@mui/material"
import React from "react"
import { FieldRecordWidget } from "../../../../../../interfaces/Interfaces";
import FieldLabel from "./FieldLabel"
import { getTranslations } from "../../../../../../translations/Translations"

const RegRadioButtons = ({ registrationfield }: FieldRecordWidget) => {
    const translations = getTranslations();
    let options = registrationfield.translations[registrationfield.defaultLanguage].options;

    if (options) {
        return (
            <Box>
            <FieldLabel field={registrationfield} />
            <FormControl fullWidth size="small">
              <Select>
                {options.length === 0 ? (
                  <MenuItem value="">
                    {translations["registrationform.nooptionsdefined"]}
                  </MenuItem>
                ) : null}
        
                {options.map((option: string) => {
                  return (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          )
    } else {
        return null;
    }
 
}

export default RegRadioButtons;