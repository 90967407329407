import React from 'react';
import { Box, Button, Typography, Modal, Input, FormControl } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import { getTranslations } from "../../translations/Translations";

interface UploadButtonProps {
    onClick?: Function
}

const UploadButton = (props: UploadButtonProps) => {
    const translations = getTranslations();

     const handleClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };

    return (
        <Button
          variant="contained"
          component="span"
          sx={[{ backgroundColor: '#006e55', borderRadius: 0, boxShadow: 'none', fontSize: '1rem', padding: '0.5rem 1rem' }, { '&:hover': { backgroundColor: '#ea7c15' } }, { '& svg': { marginRight: '0.5rem' } }]}
          onClick={handleClick}
        >
          <UploadIcon /> {translations["uploadcontrol.upload"]}
        </Button>
    )
}

export default UploadButton;