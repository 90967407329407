import { Box, Switch, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useField } from "formik";
import { MyCheckBoxInputProps } from "../../../../../../templates/Interfaces";
import OptionContainer from "./OptionContainer";


export const TogglePicker = (props: MyCheckBoxInputProps) => {
    const { label, name, yesText, noText } = props;
    const [field, meta, { setValue }] = useField(name);
    const [toggleValue, setToggleValue] = useState<boolean>(field.value || false);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (!loading) {
            setValue(toggleValue);
        }
    }, [toggleValue, setValue, loading]);

    useEffect(() => {
        if (field.value !== undefined) {
            setToggleValue(field.value);
            setLoading(false);
        }
    }, [field.value]);

    if (loading) {
        return null;
    }

    return (
        <OptionContainer sx={{ flexDirection: 'column', alignItems: 'stretch' }}>
            <Typography>{label}</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Box className="switchlabel">
                    {noText}
                </Box>
                <Switch
                    checked={toggleValue}
                    onChange={(e, newValue) => {
                        setToggleValue(newValue)
                    }}
                />
                <Box className="switchlabel">
                    {yesText}
                </Box>

            </Box>
        </OptionContainer>
    );
};

export default TogglePicker;