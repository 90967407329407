import React from "react";
import { List } from "@mui/material";
import { ScanRecord } from "../../../../interfaces/Interfaces";
import ScanListItem from "./components/ScanListItem";

const ScanList = ({
  scanList,
  scanSetter,
}: {
  scanList: ScanRecord[];
  scanSetter: Function;
}) => {
  return (
    <List
      sx={{
        //flex: 1,
        flexGrow: 1,
        flexBasis: 0,
        overflow: "scroll",
        width: "100%",
        bgcolor: "background.paper",
        border: "1px solid #c7c7c7",
        borderLeft: 0,
        borderRight: 0,
        paddingTop: 0,
        paddingBottom: 0,
        boxShadow: "inset 0 0 10px #c7c7c7",
      }}
    >
      {scanList &&
        scanList
          .filter((scan) => scan.deleted === false)
          .sort((a, b) => Number(b.date) - Number(a.date))
          .map((scan, index) => (
            <ScanListItem
              key={index}
              scan={scan}
              onClick={() => {
                scanSetter(scan);
              }}
            />
          ))}
    </List>
  );
};

export default ScanList;
