import React from "react";
import { useRouteLoaderData } from "react-router-dom";

import EventPageIndex from "./apella/EventPage";
import NotFound from "./components/NotFound";
import { EventPageRouterResponse } from "../../interfaces/Interfaces";

const EventPage = () => {
    const { eventData } = useRouteLoaderData("eventpage") as any;
    if (Object.keys(eventData).length > 0) {
        return <EventPageIndex eventData={eventData} />
    } else {
        return <NotFound />
    }
}


export default EventPage;