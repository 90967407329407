export function lightenColor(hex: string, percent: number) {
    hex = hex.replace(/^#/, '');
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    const factor = 1 + percent / 100;
    const newR = Math.min(255, Math.round(r * factor));
    const newG = Math.min(255, Math.round(g * factor));
    const newB = Math.min(255, Math.round(b * factor));  
    return `#${(newR < 16 ? '0' : '') + newR.toString(16)}${(newG < 16 ? '0' : '') + newG.toString(16)}${(newB < 16 ? '0' : '') + newB.toString(16)}`;
  }