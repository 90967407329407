import { Box, MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useField } from "formik";
import { MyCheckBoxInputProps } from "../../../../../../templates/Interfaces";

import OptionContainer from "./OptionContainer";
import { getTranslations } from "../../../../../../../translations/Translations";

const translations = getTranslations();

export const generateMarginString = (marginValues: number[]) => {
    if (marginValues) {
        return marginValues.join('px ') + 'px';
    }
}


enum HtmlSizeIndicators {
    PX = 'PX',
    REM = 'REM',
    EM = 'EM'
}

export const MarginSelector = (props: MyCheckBoxInputProps) => {
    const { label, name } = props;
    const [field, meta, helpers] = useField(name);
    const [valueType, setValueType] = useState<HtmlSizeIndicators>(HtmlSizeIndicators.PX);
    const fieldValues = field.value;

    const directions = ['top', 'right', 'bottom', 'left'];

    const handleValueChange = (newValue: string, index: number) => {
        const newFieldValues = [...fieldValues]
        newFieldValues[index] = Number(newValue);
        helpers.setValue(newFieldValues);
    }

    return (
        <OptionContainer sx={{ flexDirection: 'column', alignItems: 'left', padding: '0.5rem', border: '1px solid #ececec' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem' }}>
                <Typography>{label}</Typography>
                <Select
                    size={"small"}
                    value={valueType}
                    sx={{ width: '70px' }}
                    onChange={(e) => setValueType(e.target.value as HtmlSizeIndicators)}
                >
                    <MenuItem value={'PX'}>PX</MenuItem>
                    {/*}<MenuItem value={'REM'}>REM</MenuItem>
                    <MenuItem value={'EM'}>EM</MenuItem>*/}
                </Select>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                {fieldValues?.map((marginValue: number, index: number) => <Box key={index} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box>{translations['directions.' + directions[index]]}</Box>
                    <TextField type="number" value={marginValue} onChange={(e) => {
                        handleValueChange(e.target.value, index);
                    }}>
                    </TextField></Box>)}
            </Box>
        </OptionContainer>
    );
};


export default MarginSelector;