import React from "react";
import { getTranslations } from "../../../../translations/Translations";
import {
  Outlet,
  matchPath,
  useLocation,
  useRouteLoaderData,
} from "react-router-dom";
import { EventRouterResponse } from "../../../../interfaces/Interfaces";
import { Box, Tab, Tabs } from "@mui/material";
import { CustomNavLink } from "../../../../components/CustomNavLink";

function EventContacts() {
  const translations = getTranslations();
  const { event } = useRouteLoaderData("event") as EventRouterResponse;
  const location = useLocation();
  const urls = [
    "/admin/events/" + event.id + "/contacts",
    "/admin/events/" + event.id + "/contacts/registrations",
    "/admin/events/" + event.id + "/contacts/adminapproval",
    "/admin/events/" + event.id + "/contacts/adminapprovalrejected",
    "/admin/events/" + event.id + "/contacts/waitinglist",
    "/admin/events/" + event.id + "/contacts/attendance",
  ];

  const getActiveUrl = () => {
    let active = [...urls].reverse().filter((url) => {
      return matchPath(url + "/*", location.pathname);
    });
    if (active.length === 0) {
      return urls[0];
    } else {
      return active[0];
    }
  };

  return (
    <Box display="flex" flex={1} overflow="hidden" flexDirection={"column"}>
      <Box>
        <Tabs value={getActiveUrl()} className="subtabbar">
          <Tab
            label={translations["contacts.contacts"]}
            component={CustomNavLink}
            value={urls[0]}
            to={urls[0]}
            replace={false}
          />
          {event.adminapproval ? (
            <Tab
              label={translations["contacts.adminapproval"]}
              component={CustomNavLink}
              value={urls[2]}
              to={urls[2]}
              replace={false}
            />
          ) : null}
          {event.adminapproval ? (
            <Tab
              label={translations["contacts.adminapprovalrejected"]}
              component={CustomNavLink}
              value={urls[3]}
              to={urls[3]}
              replace={false}
            />
          ) : null}
          {event.waitinglist ? (
            <Tab
              label={translations["contacts.waitinglist"]}
              component={CustomNavLink}
              value={urls[4]}
              to={urls[4]}
              replace={false}
            />
          ) : null}
          <Tab
            label={translations["contacts.registrations"]}
            component={CustomNavLink}
            value={urls[1]}
            to={urls[1]}
            replace={false}
          />
          <Tab
            label={translations["contacts.attendance"]}
            component={CustomNavLink}
            value={urls[5]}
            to={urls[5]}
            replace={false}
          />
        </Tabs>
      </Box>
      <Outlet />
    </Box>
  );
}

export default EventContacts;
