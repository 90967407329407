import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { MenuObject } from "../interfaces/Interfaces";

const ContextMenu = (props: {
    config: [MenuObject, Function],
    children: React.ReactNode;
}) => {
    const { config, children } = props;
    const [menuObject, setMenuObject] = config;
    const width = 200;
    const gap = 16;
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    const [leftPosition, setLeftPosition] = useState<number>(0);
    const [topPosition, setTopPosition] = useState<number>(0);

    const containerRef = React.createRef<HTMLDivElement>();

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 50);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (containerRef.current && menuObject.location) {
            const containerHeight = containerRef.current.clientHeight;
            let newTopPosition =
            menuObject.location.top + menuObject.location.height / 2 - containerHeight / 2;

            if (newTopPosition < 0) {
                newTopPosition = 0 + gap;
            } else if (newTopPosition + containerHeight > viewportHeight) {
                newTopPosition = viewportHeight - containerHeight - gap;
            }

            let newLeftPosition =  menuObject.location.left +  menuObject.location.width + gap;

            if (newLeftPosition + width + gap > viewportWidth) {
                if ( menuObject.location.left - width - gap >= 0) {
                    newLeftPosition =  menuObject.location.left - width - gap;
                } else {
                    newLeftPosition = gap;
                }
            }

            setTopPosition(newTopPosition);
            setLeftPosition(newLeftPosition);
        }
    }, [children, viewportWidth, viewportHeight,  menuObject.location, gap]);

    const styles = {
        width: `${width}px`,
        top: `${topPosition}px`,
        left: `${leftPosition}px`,
        opacity: isVisible ? 1 : 0,
        transition: "opacity 0.3s",
        zIndex: 502
    };

    return (
        <>
            <Box style={styles} className="calendar-context-menu">
                <div ref={containerRef}>{children}</div>
            </Box>
            {isVisible ? (<Box sx={{
                width: '100vw',
                height: '100vh',
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: 501
            }} onClick={(e) => {
                //e.stopPropagation();
                console.log('clicked');
                if (isVisible) {
                    setIsVisible(false);
                    setMenuObject((prev: any)=>{
                        return ({...prev, open: false})
                    })
                }
            }}></Box>) : null}

        </>
    );
};

export default ContextMenu;