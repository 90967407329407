export const syncRequest = (data: any, callback?: Function) => {
  let url: string = "/api/attendances/batch";
  fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((result) => {
      if (callback) {
        callback(result);
      }
    })
    .catch((err: DOMException) => {
      if (err.name === "AbortError") {
      }
    });
};
