import React, { useEffect, useState } from "react";
import { Box, List, ListItem, Typography } from "@mui/material";
import { getTranslations } from "../../translations/Translations";

interface NewsRecord {
  id: number;
  translations: any;
  defaultLanguage: string;
}

function NewsList() {
  const translations = getTranslations();
  const [newsitems, setNewsitems] = useState<NewsRecord[]>([]);

  useEffect(() => {
    const controller = new AbortController();

    let url: string = "/api/news";
    fetch(url, {
      signal: controller.signal,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setNewsitems(result.data);
      })
      .catch((err: DOMException) => {
        if (err.name === "AbortError") {
        }
      });
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      minWidth={"250px"}
      className="ui-widget"
    >
      <Box display="flex" m={1} flex={1} flexDirection={"column"}>
        <Box className="titlebar" display="flex">
          <Typography>{translations["news.title"]}</Typography>
        </Box>
        <List className="list" sx={{ gap: 1 }}>
          {newsitems.map((newsrecord: NewsRecord, index: number) => {
            return (
              <ListItem key={index}>
                <Typography>
                  {newsrecord.translations[newsrecord.defaultLanguage].message}
                </Typography>
              </ListItem>
            );
          })}
        </List>
        <Box className="bottombar"></Box>
      </Box>
    </Box>
  );
}

export default NewsList;
