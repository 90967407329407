import { IconButton, ImageList, ImageListItem, ImageListItemBar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { EventRouterResponse, FileRecord, FilesRouterResponse, ParsedFile } from "../interfaces/Interfaces";
import { useRouteLoaderData } from "react-router-dom";
import './MediaFileSelect.scss';

const srcset = (image: string | undefined, size: number, rows = 1, cols = 1) => {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${size * rows
            }&fit=crop&auto=format&dpr=2 2x`,
    };
}

const MediaFileSelect = ({ onImageSelectFn, type }: { onImageSelectFn: Function, type?: string }) => {
    const { event } = useRouteLoaderData("event") as EventRouterResponse;
    const [parsedFiles, setParsedFiles] = useState<ParsedFile[]>([]);
    const [files, setFiles] = useState<FileRecord[] | null>(null);
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

    useEffect(() => {
        let searchparams: URLSearchParams = new URLSearchParams({
            filter: JSON.stringify([
                { property: "eventId", value: event.eventId },
                { property: "purpose", value: "media" },
            ]),
        });

        let url = "/api/files?" + searchparams.toString();

        fetch(url, {
            headers: {
                Accept: "application/json",
                Authorization:
                    "Bearer " + sessionStorage.getItem("jwt"),
            },
        })
            .then((res) => res.json())
            .then((files) => {
                let myFiles: FileRecord[] = files.data;
                if (type === 'video') {
                    myFiles = myFiles.filter((file: FileRecord) => file.videolink !== null)
                }
                if (type === 'pdf') {
                    myFiles = myFiles.filter((file: FileRecord) => file.ext === 'pdf')
                }
                setFiles(myFiles)
            }
            );
    }, [])

    useEffect(() => {
        if (selectedIndex !== null) {
            if (type === 'video') {
                onImageSelectFn({url: parsedFiles[selectedIndex].url, videoSrc: parsedFiles[selectedIndex].videolink})
            } else {
                onImageSelectFn({url: parsedFiles[selectedIndex].url})
            }
           
        }
    }, [selectedIndex])

    useEffect(() => {
        if (files) {
            console.log('files', files)
            setParsedFiles(parseFiles(files));
        }
    }, [files]);

    const parseFiles = (files: FileRecord[]) => {
        let result = [];

        if (type === 'video') {
            result = files.map((file: FileRecord) => {
                console.log(file);
                let fileObject: ParsedFile = {
                    videolink: file.videolink,
                    url: `/api/files/read/${file.publicId}`,
                    cols: 1,
                    rows: 1,
                    type: 'video',
                    id: file.id,
                };
                return fileObject;
            })
        } else {
            result = files.filter((file: FileRecord) => !file.videolink).map((file: FileRecord) => {
                let fileObject: ParsedFile = {
                    url: `/api/files/read/${file.publicId}`,
                    cols: 1,
                    rows: 1,
                    type: 'image',
                    id: file.id,
                };

                if (file.ext === 'pdf') {
                    fileObject.type = 'file';
                }

                if (file.videolink !== null) {
                    fileObject.type = 'video';
                    fileObject.videolink = file.videolink;
                }
                return fileObject;
            })
        }
        return result;
    }

    return (
        <ImageList variant="quilted" cols={2} rowHeight={150} sx={{ width: '100%' }}>
            {parsedFiles.map((item, index) => {
                console.log(item);
                return (
                <ImageListItem
                    key={index}
                    data-item-id={item.id}
                    cols={item.cols || 1}
                    rows={item.rows || 1}
                    sx={[{ '&:hover': { cursor: 'pointer' } }]}
                    className={selectedIndex === index ? 'quilted-image selected-image' : 'quilted-image '}
                    onMouseOver={() => setHoveredIndex(index)}
                    onMouseOut={() => setHoveredIndex(null)}
                    onClick={() => {
                        setSelectedIndex(index);
                    }}
                >
                    {item.type === 'image' || item.type === 'video' ? <img {...srcset(item.url, 200, item.rows, item.cols)} alt={item.title} loading="lazy" />: null}
                    {item.type === 'file' ? <img src='https://m.media-amazon.com/images/I/71rWRlvARvL.jpg' alt={item.title} loading="lazy" />: null}
                    {hoveredIndex === index && (
                        <ImageListItemBar
                            sx={{
                                background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                            }}
                            //title={item.title}
                            position="top"
                            actionPosition="right"
                        />
                    )}

                </ImageListItem>
            )})}
        </ImageList>)
}

export default MediaFileSelect;