import { Box, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React from "react";
import { useField } from "formik";
import { MyCheckBoxInputProps } from "../../../../../../templates/Interfaces";
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import AlignHorizontalCenterIcon from '@mui/icons-material/AlignHorizontalCenter';
import AlignHorizontalRightIcon from '@mui/icons-material/AlignHorizontalRight';
import OptionContainer from "./OptionContainer";
import { MyNumberTextField, MyTextField } from "../../Fields";

export const NumberField = (props: MyCheckBoxInputProps) => {
    const { label, name } = props;
    const [field, , { setValue }] = useField(name);

    const handleAlignmentChange = (event: React.MouseEvent<HTMLElement>, newValue: string) => {
        setValue(newValue);
    };

    return (
        <OptionContainer>
            <Typography mr={'1rem'}>{label}</Typography>
            <MyNumberTextField name={name}  />
        </OptionContainer>
    );
};

export default NumberField;