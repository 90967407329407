import { Box, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, MenuItem, Select, TextField, Typography } from "@mui/material"
import React from "react"
import { FieldRecordWidget } from "../../../../../../interfaces/Interfaces";
import FieldLabel from "./FieldLabel"
import { getTranslations } from "../../../../../../translations/Translations"

const TermsField = ({ registrationfield }: FieldRecordWidget) => {
    const translations = getTranslations();

    return (
        <Box display="flex" flexDirection="column">
            <FieldLabel field={registrationfield} />    
            <Grid item container sx={{ maxHeight: '200px', overflow: 'scroll', padding: '1rem', border: '1px solid grey' }}>
                <Box className="text" sx={{ display: 'flex', flexDirection: 'column' }} dangerouslySetInnerHTML={{ __html: registrationfield.config.termstext || translations['registrationform.field.' + registrationfield.type] }}></Box>
            </Grid>

            <FormControlLabel control={<Checkbox value={''} />} label={<FormLabel sx={{ color: 'var(--color-primary)' }}>{registrationfield.config.checkboxlabel}</FormLabel>} />
        </Box>
    )

}

export default TermsField;