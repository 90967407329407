import React, { useState } from "react";
import { getLanguage, getTranslations } from "../../translations/Translations";
import { useRevalidator, useRouteLoaderData } from "react-router-dom";
import {
  CategoriesRouterResponse,
  Category,
} from "../../interfaces/Interfaces";
import {
  Box,
  Button,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import CategoryForm from "./CategoryForm";
import { DirtyFormDialog } from "../../components/DirtyFormDialog";

function CategoriesTab() {
  const translations = getTranslations();
  const { categories } = useRouteLoaderData(
    "categories"
  ) as CategoriesRouterResponse;
  const [category, setCategory] = useState<Category | null>(categories[0]);
  const [nextCategory, setNextCategory] = useState<Category | null>(null);
  const revalidator = useRevalidator();
  const [dirty, setDirty] = useState<Boolean>(false);
  const language = getLanguage();

  const newcategory = () => {
    fetch("/api/categories", {
      method: "post",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("jwt"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        defaultLanguage: language,
        language: language,
        title: "Neue Kategorie",
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        let p: Category = result.data;
        setCategory(p);
        revalidator.revalidate();
      });
  };

  const getButtonClass = (id: number): string => {
    if (category?.id === id) {
      return "cliclalistitembutton active";
    }
    return "cliclalistitembutton";
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      className="collectionedit"
      flex="1"
      overflow="hidden"
    >
      <Box width={"260px"} className="list">
        <Box p={1}>
          <Button variant="contained" onClick={newcategory}>
            {translations["categoriestab.add"]}
          </Button>
        </Box>
        <DirtyFormDialog
          open={nextCategory !== null}
          continue={() => {
            setCategory(nextCategory);
            setNextCategory(null);
          }}
          cancel={() => {
            setNextCategory(null);
          }}
        />
        <List className="cliclalist">
          {categories.map((cat: Category, index: number) => {
            return (
              <ListItemButton
                key={cat.id}
                className={getButtonClass(cat.id)}
                onClick={() => {
                  if (dirty) {
                    setNextCategory(cat);
                  } else {
                    setCategory(cat);
                  }
                }}
              >
                <ListItemText
                  primary={cat.translations[cat.defaultLanguage].title}
                />
              </ListItemButton>
            );
          })}
        </List>
      </Box>
      {!category ? (
        <Box flex="1" p={2}>
          <Typography>{translations["categoriestab.empty"]}</Typography>
        </Box>
      ) : null}
      {!!category ? (
        <CategoryForm
          key={category.id}
          category={category}
          setDirty={setDirty}
          onDelete={() => {
            fetch("/api/categories/" + category.id, {
              method: "delete",
              headers: {
                Accept: "application/json",
                Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                "Content-Type": "application/json",
              },
            }).then(() => {
              if (category.id === categories[0].id) {
                setCategory(categories[1]);
              } else {
                setCategory(categories[0]);
              }
              revalidator.revalidate();
            });
          }}
        />
      ) : null}
    </Box>
  );
}

export default CategoriesTab;
