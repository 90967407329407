import { format, parse } from "date-fns";

export function isValidDateValue(obj: any | any[]) {
  return !(obj === null || isNaN(obj));
}

export function formatDateTime(obj: any) {
  if (isValidDateValue(obj)) {
    return format(obj, "yyyy-MM-dd HH:mm:ss");
  } else {
    return null;
  }
}

export const formatSyncDate = (timeStamp: string): string => {
  const dateObj = new Date(Number(timeStamp));
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, "0");
  const day = String(dateObj.getDate()).padStart(2, "0");
  const hours = String(dateObj.getHours()).padStart(2, "0");
  const minutes = String(dateObj.getMinutes()).padStart(2, "0");
  const seconds = String(dateObj.getSeconds()).padStart(2, "0");
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};

export function formatDate(obj: any) {
  if (isValidDateValue(obj)) {
    return format(obj, "yyyy-MM-dd");
  } else {
    return null;
  }
}

export function localeFormatDate(obj: any) {
  if (isValidDateValue(obj)) {
    return format(obj, "dd.MM.yyyy");
  } else {
    return null;
  }
}

export function localeFormatDateTime(obj: any) {
  if (isValidDateValue(obj)) {
    return format(obj, "dd.MM.yyyy HH:mm:ss");
  } else {
    return null;
  }
}

export function formatTime(obj: any) {
  if (isValidDateValue(obj)) {
    return format(obj, "HH:mm");
  } else {
    return null;
  }
}

export function formatTimeMS(obj: any) {
  if (isValidDateValue(obj)) {
    return format(obj, "HH:mm:ss");
  } else {
    return null;
  }
}

export function parseDateTime(obj: any) {
  if (obj === null || typeof obj === "undefined") {
    return null;
  }
  return parse(obj, "yyyy-MM-dd HH:mm:ss", new Date());
}

export function parseDate(obj: any) {
  if (obj === null || typeof obj === "undefined") {
    return null;
  }
  return parse(obj, "yyyy-MM-dd", new Date());
}

export function parseTime(obj: any) {
  if (obj === null || typeof obj === "undefined") {
    return null;
  }
  return parse(obj, "HH:mm", new Date());
}

const timeStringToTimestamp = (timeString: string): number => {
  const [hours, minutes] = timeString.split(":").map(Number);
  return hours * 60 * 60 * 1000 + minutes * 60 * 1000;
};

export const calculateTimeDifference = (
  timeString1: string,
  timeString2: string
): string => {
  const timestamp1 = timeStringToTimestamp(timeString1);
  const timestamp2 = timeStringToTimestamp(timeString2);
  const difference = Math.abs(timestamp2 - timestamp1);
  const hours = Math.floor(difference / (60 * 60 * 1000));
  const minutes = Math.floor((difference % (60 * 60 * 1000)) / (60 * 1000));

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
};
