import React, { useEffect, useState } from "react";
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { getTranslations } from "../../translations/Translations";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

interface FaqRecord {
  id: number;
  translations: any;
  defaultLanguage: string;
}

function FaqList() {
  const translations = getTranslations();
  const [faqitems, setFaqitems] = useState<FaqRecord[]>([]);
  const [opened, setOpened] = useState<number[]>([]);

  useEffect(() => {
    const controller = new AbortController();

    let url: string = "/api/faq";
    fetch(url, {
      signal: controller.signal,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setFaqitems(result.data);
      })
      .catch((err: DOMException) => {
        if (err.name === "AbortError") {
        }
      });
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      minWidth={"250px"}
      className="ui-widget"
    >
      <Box display="flex" m={1} flex={1} flexDirection={"column"}>
        <Box className="titlebar">
          <Typography>{translations["faq.title"]}</Typography>
        </Box>
        <Box pl="16px" pr="16px" className="subtitlebar">
          <Typography>{translations["faq.subtitle"]}</Typography>
        </Box>
        <List className="list">
          {faqitems.map((faqrecord: FaqRecord) => {
            return (
              <Box
                component="section"
                key={faqrecord.id}
                className="listitem"
                mb={1}
              >
                <ListItemButton
                  onClick={() => {
                    if (opened.includes(faqrecord.id)) {
                      setOpened(
                        opened.filter((val) => {
                          return val !== faqrecord.id;
                        })
                      );
                    } else {
                      setOpened([...opened, faqrecord.id]);
                    }
                  }}
                >
                  <ListItemText
                    className="faqtitle"
                    primary={
                      faqrecord.translations[faqrecord.defaultLanguage].title
                    }
                  />
                  {opened.includes(faqrecord.id) ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItemButton>
                <Collapse
                  in={opened.includes(faqrecord.id)}
                  timeout="auto"
                  unmountOnExit
                >
                  <Typography
                    className="faqdescription"
                    padding={"0px 16px 0px 16px"}
                  >
                    {
                      faqrecord.translations[faqrecord.defaultLanguage]
                        .description
                    }
                  </Typography>
                </Collapse>
              </Box>
            );
          })}
        </List>
        <Box className="bottombar"></Box>
      </Box>
    </Box>
  );
}

export default FaqList;
