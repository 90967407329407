import React, { FormEventHandler, useEffect, useRef, useState } from "react";
import { getTranslations } from "../../../../translations/Translations";
import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  EventRouterResponse,
  IRecord,
  MailRouterResponse,
} from "../../../../interfaces/Interfaces";
import { useFormikContext, Formik, useField, FormikValues } from "formik";
import { Editor } from "@tinymce/tinymce-react";
import SaveBar from "../../../../components/SaveBar";
import * as Yup from "yup";
import { useRevalidator, useRouteLoaderData } from "react-router-dom";
import useDebounce from "../../../../helpers/useDebounce";
import ChoiceDialog from "../../../../components/ChoiceDialog";
import { DisplayErrors } from "../../../../components/DisplayErrors";

function MailEditTab() {
  const revalidator = useRevalidator();
  const translations = getTranslations();
  const [nextTemplateId, setNextTemplateId] = useState<number>();
  const [showSaveQuestionModal, setShowSaveQuestionModal] =
    useState<boolean>(false);
  const [currentTemplate, setCurrentTemplate] = useState<IRecord>({
    id: 0,
    type: "",
    translations: {
      de: {
        subject: "",
        body: "",
      },
    },
    defaultLanguage: "de",
  });
  const editorRef = useRef<any>(null);
  const { registrationsmails, markers } = useRouteLoaderData(
    "mailedit"
  ) as MailRouterResponse;

  const [initialValues, setInitialValues] = useState<MailTemplate>({
    subject:
      currentTemplate.translations[currentTemplate.defaultLanguage].subject ||
      "",
    body:
      currentTemplate.translations[currentTemplate.defaultLanguage].body || "",
  });

  const validationSchema = {
    subject: Yup.string()
      .required(translations["errors.requiredfield"])
      .max(400, translations["errors.maxlength400"]),
    body: Yup.string().required(translations["errors.requiredfield"]),
  };

  useEffect(() => {
    setInitialValues({
      subject:
        currentTemplate.translations[currentTemplate.defaultLanguage].subject ||
        "",
      body:
        currentTemplate.translations[currentTemplate.defaultLanguage].body ||
        "",
    });
  }, [currentTemplate]);

  useEffect(() => {
    if (registrationsmails && registrationsmails.length > 0) {
      setCurrentTemplate(registrationsmails[0]);
    }
  }, []);

  const MCEEditor = ({
    name,
    isSubmitting,
  }: {
    name: string;
    isSubmitting: boolean | undefined;
  }) => {
    const [field, meta, helpers] = useField(name);

    return (
      <Grid item xs={12} flexDirection="row" display="flex">
        <Box width="260px" flexShrink={0} className="fieldlabel">
          <Typography>{translations["mailings.templateText"]}</Typography>
        </Box>
        <Editor
          onInit={(evt, editor) => (editorRef.current = editor)}
          apiKey="yrryrudygx2u6hc488a53p3fx0isfijo0rfge9bbs4kfc94w"
          disabled={isSubmitting}
          value={field.value}
          onEditorChange={(stringifiedHtmlValue) => {
            helpers.setValue(stringifiedHtmlValue);
          }}
          init={{
            resize: false,
            height: 300,
            menubar: false,
            width: "100%",
            plugins: [
              "advlist",
              "autolink",
              "lists",
              "link",
              "image",
              "charmap",
              "preview",
              "anchor",
              "searchreplace",
              "visualblocks",
              "code",
              "fullscreen",
              "insertdatetime",
              "media",
              "table",
              "code",
              "help",
              "wordcount",
            ],
            toolbar:
              "undo redo | formatselect | " +
              "bold italic backcolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat | help",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          }}
        />
      </Grid>
    );
  };

  const MyTextField = ({
    name,
    isSubmitting,
  }: {
    name: string;
    isSubmitting: boolean | undefined;
  }) => {
    const [field, meta, helpers] = useField(name);
    return (
      <TextField
        disabled={isSubmitting}
        size="small"
        fullWidth
        {...field}
        error={!!(meta.touched && meta.error)}
        helperText={meta.touched && meta.error ? meta.error : ""}
      ></TextField>
    );
  };
  interface MailTemplate {
    subject: string;
    body: string;
  }

  useEffect(() => {
    //currentTEmpalte updated
    setNextTemplateId(undefined);
  }, [currentTemplate]);

  const setTemplateFromID = (id: number) => {
    const foundTemplate = registrationsmails.find((mail) => mail.id === id);
    if (foundTemplate) {
      setCurrentTemplate(foundTemplate);
    }
  };

  const Form = ({
    handleSubmit,
    isSubmitting,
    visible,
  }: {
    handleSubmit: any;
    isSubmitting: boolean;
    visible: boolean;
  }) => {
    return (
      <form
        style={!visible ? { display: "none" } : {}}
        onSubmit={handleSubmit}
        id="mailform"
      >
        <Grid container>
          <Grid item xs={12} flexDirection="row" display="flex" mb={2}>
            <Box width="260px" flexShrink={0} className="fieldlabel">
              <Typography>{translations["mailings.templateTitle"]}</Typography>
            </Box>
            <MyTextField
              name="subject"
              isSubmitting={isSubmitting}
            ></MyTextField>
          </Grid>
          <MCEEditor name="body" isSubmitting={isSubmitting} />
        </Grid>
      </form>
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={Yup.object(validationSchema)}
      validateOnBlur={true}
      onSubmit={(values, { setSubmitting }) => {
        fetch("/api/registrationmails/" + currentTemplate.id + "/", {
          method: "PUT",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("jwt"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            language: currentTemplate.defaultLanguage,
            ...values,
          }),
        })
          .then((res) => res.json())
          .then((jsondata) => {
            if (jsondata.success) {
              revalidator.revalidate();
              setInitialValues({
                subject: values.subject,
                body: values.body,
              });
            }
          })
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({ values, handleSubmit, submitForm, isSubmitting }) => {
        return (
          <Box display="flex" flexDirection="column" sx={{flex: 1}}>
          <Grid container sx={{ flex: 1 }} display="flex">
            <Grid item sx={{ backgroundColor: "transparent" , padding: "1rem" }} xs={6}>
              <Grid container sx={{ padding: "1rem" }}>
                <Grid item xs={12} flexDirection="row" display="flex" mb={2}>
                  <Typography variant="h2" fontSize="24px" color="#006e55">
                    {translations["mailings.templates"]}
                  </Typography>
                </Grid>
                <Grid item xs={12} flexDirection="row" display="flex" mb={2}>
                  <Box width="260px" flexShrink={0} className="fieldlabel">
                    <Typography>
                      {translations["mailings.chosenTemplate"]}
                    </Typography>
                  </Box>
                  <Select
                    size="small"
                    value={currentTemplate.id}
                    autoWidth={true}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={(e) => {
                      if (
                        JSON.stringify(values) !== JSON.stringify(initialValues)
                      ) {
                        //show error and ask for permission to save or ignore changes
                        setNextTemplateId(Number(e.target.value));
                        setShowSaveQuestionModal(true);
                        return;
                      } else {
                        setTemplateFromID(Number(e.target.value));
                      }
                    }}
                  >
                    {registrationsmails.map((mailTemplate: IRecord) => {
                      return (
                        <MenuItem key={mailTemplate.id} value={mailTemplate.id}>
                          {
                            translations[
                              "mailings.templates." + mailTemplate.type
                            ]
                          }
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
                <Form
                  visible={currentTemplate.id !== 0}
                  handleSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                />
                <ChoiceDialog
                  open={[showSaveQuestionModal, setShowSaveQuestionModal]}
                  title={translations["mailings.changes"]}
                  description={translations["mailings.changesDescription"]}
                  buttons={[
                    <Button
                      variant="contained"
                      onClick={() => {
                        //save
                        submitForm()
                          .then(() => {
                            if (nextTemplateId) {
                              setTemplateFromID(nextTemplateId);
                            }
                            setShowSaveQuestionModal(false);
                          })
                          .catch(() => {
                            setShowSaveQuestionModal(false);
                          });
                      }}
                    >
                      {translations["mailings.save"]}
                    </Button>,
                    <Button
                      onClick={() => {
                        if (nextTemplateId) {
                          setTemplateFromID(nextTemplateId);
                        }
                        setShowSaveQuestionModal(false);
                      }}
                    >
                      {translations["mailings.noSave"]}
                    </Button>,
                  ]}
                />
              </Grid>
            </Grid>
            <Grid item sx={{ backgroundColor: "transparent", padding: "1rem"  }}  xs={6}>
              <Grid container sx={{ padding: "1rem" }}>
                <Grid item xs={12} flexDirection="row" display="flex" mb={2}>
                  <Box width="260px" flexShrink={0} className="fieldlabel">
                    <Typography variant="h2" fontSize="24px" color="#006e55">
                      {translations["mailings.markers"]}
                    </Typography>
                    <Typography fontSize="1rem" mr={'1rem'}>
                      {translations["mailings.markersDescription"]}
                    </Typography>
                  </Box>
                  <List
                    sx={{
                      maxHeight: '500px',
                      overflowY: 'scroll',
                      display: "flex",
                      flexDirection: "column",
                      flex: "1",
                      border: "1px solid rgba(0,0,0, .10)",
                    }}
                  >
                    {markers.map((marker, index) => (
                      <ListItem key={index}>
                        <Grid container justifyContent="space-between">
                          <Box>{marker.marker}</Box>
                          <Box>{marker.label}</Box>
                        </Grid>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
            </Grid>
            </Grid>
            <Box
              p={2}
              position={"sticky"}
              bottom={0}
              height="40px"
              sx={{ backgroundColor: "#EEE" }}
              textAlign="right"
            >
              <Button type="submit" variant="contained" form="mailform">
                {translations["mailings.save"]}
              </Button>
            </Box>
          </Box>
        );
      }}
    </Formik>
  );
}

export default MailEditTab;
