import { Box, Button, Tab, Tabs } from "@mui/material";
import React, { ReactNode, useReducer, useState } from "react";
import {
  Outlet,
  matchPath,
  useLoaderData,
  useLocation,
  useRevalidator,
  useRouteLoaderData,
} from "react-router-dom";
import {
  ContextParams,
  EventRouterResponse,
  WebpagesRouterResponse,
} from "../../../../interfaces/Interfaces";
import { CustomNavLink } from "../../../../components/CustomNavLink";
import NewWebPageModal from "./components/modals/NewWebPageModal";
import PageSettingsModal from "./components/modals/PageSettingsModal";
import { previousDay } from "date-fns";
import "./Index.scss";
import { getTranslations } from "../../../../translations/Translations";


interface ModalState {
  createPageModal: boolean,
  settingsModal: boolean
}

function Website() {
  const translations = getTranslations();
  const { event } = useRouteLoaderData("event") as EventRouterResponse;
  const { webpages } = useLoaderData() as WebpagesRouterResponse;
  const [contextParams, setContextParams] = useState<ContextParams>({
    currentTab: '',
    activeWidget: null,
    showWarningModal: false,
    pressedTab: null
  })
  const [modalState, setModalState] = useState<ModalState>({
    createPageModal: false,
    settingsModal: false
  })
  const revalidator = useRevalidator();
  const location = useLocation();
  const urls: string[] = [
    "/admin/events/" + event.id + "/website",
    ...webpages.map((webpage) => {
      return (
        "/admin/events/" +
        event.id +
        "/website/" +
        webpage.translations[webpage.defaultLanguage].title
      );
    }),
  ];

  const onTabChange = (e: React.SyntheticEvent<Element, Event>, newUrl: string) => {
    if (contextParams.activeWidget !== null) {
      //show warning ...
      e.preventDefault();
      setContextParams((prev) => { return ({ ...prev, showWarningModal: true, pressedTab: e.target as HTMLAnchorElement }) })
    } else {
      setContextParams((prev) => { return ({ ...prev, currentTab: newUrl }) })
    }
  }

  const getActiveUrl = () => {
    let active = [...urls].reverse().filter((url) => {
      return matchPath(url + "/*", decodeURI(location.pathname));
    });
    if (active.length === 0) {
      return urls[1];
    } else {
      if (active[0] === urls[0]) {
        return urls[1];
      }
      return active[0];
    }
  };

  const ButtonInTabs = ({ onClick, children }: { onClick: Function, children: ReactNode }) => {
    return <Button sx={{ color: 'white' }} onClick={() => {
      onClick();
    }} children={children} />;
  };

  const handleModalClose = (key: string) => {
    setModalState(prev => ({ ...prev, [key]: false }))
  }

  return (
    <Box display="flex" flex={1} overflow="hidden" flexDirection={"column"}>
      <Box>
        <Tabs
          value={getActiveUrl()}
          onChange={(e: React.SyntheticEvent<Element, Event>, newUrl: string) => {
            onTabChange(e, newUrl);
          }}
          className="subtabbar">
          {webpages.sort((a, b) => (a.sortorder - b.sortorder)).map((webpage, index) => {
            return (
              <Tab
                key={index}
                label={webpage.translations[webpage.defaultLanguage].title}
                component={CustomNavLink}
                value={urls[index + 1]}
                to={urls[index + 1]}
                replace={false}
              />
            );
          })}
          {contextParams.activeWidget === null && ([
            <ButtonInTabs key={0} onClick={() => { setModalState(prev => ({ ...prev, createPageModal: true })) }}>+</ButtonInTabs>,
            <ButtonInTabs key={1} onClick={() => { setModalState(prev => ({ ...prev, settingsModal: true })) }}>{translations['website.settings']}</ButtonInTabs>
            ]
          )}

        </Tabs>
      </Box>
      <NewWebPageModal open={modalState.createPageModal} webpages={webpages} closeModal={() => { handleModalClose('createPageModal')}} />
      <PageSettingsModal open={modalState.settingsModal} webpages={webpages} revalidator={revalidator} closeModal={() => { handleModalClose('settingsModal')}} />
      <Box display="flex" overflow="hidden" flex={1} flexDirection={"column"}>
        <Outlet context={[contextParams, setContextParams]} />
      </Box>
    </Box>
  );
}

export default Website;
