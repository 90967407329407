import { Box, ListItem, ListItemButton } from "@mui/material";
import React from "react";
import { ScanRecord } from "../../../../../interfaces/Interfaces";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const renderPersonStatus = (scan: ScanRecord) => {
  if (scan.deleted === true) {
    return <DeleteIcon sx={{ color: "red" }} />;
  } else {
    switch (scan.type) {
      case "checkin":
        return <ArrowDownwardIcon sx={{ color: "#006e55" }} />;
        break;
      case "checkout":
        return <ArrowUpwardIcon sx={{ color: "#ea7c15" }} />;
        break;
      default:
        return <Box sx={{ color: "#bfbfbf" }}>-</Box>;
        break;
    }
  }
};

const ScanListItem = ({
  onClick,
  scan,
}: {
  scan: ScanRecord;
  onClick: Function;
}) => {
  return (
    <ListItem
      sx={{
        padding: "0",
        borderBottom: "1px solid #dfdfdf",
      }}
    >
      <ListItemButton
        sx={{ padding: "1rem" }}
        onClick={() => {
          onClick();
        }}
      >
        <Box
          display="flex"
          flexDirection={"row"}
          flex={1}
          justifyContent={"space-between"}
        >
          <Box>{`${scan.firstname} ${scan.lastname}`}</Box>
          <Box>{renderPersonStatus(scan)}</Box>
        </Box>
      </ListItemButton>
    </ListItem>
  );
};

export default ScanListItem;
