import React from "react";
import { Box, Modal } from "@mui/material";
import { Loading } from "./Loading";

const SyncModal = ({ open }: { open: boolean }) => {
  return (
    <Modal
      open={open}
      componentsProps={{
        backdrop: { sx: { backgroundColor: "rgba(0, 0, 0, 0.2)" } },
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="small-modal">
        <Box
          display="flex"
          flexDirection="column"
          alignContent="center"
          alignItems="center"
        >
          <Loading />
          <Box sx={{ marginTop: "2rem" }}>Syncing ...</Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default SyncModal;
