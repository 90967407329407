import { Box, Grid, Typography } from "@mui/material"
import React from "react"
import { ReactWidget } from "../../Interfaces";
import { ContainedSection } from "../../apella/EventPage";
import RegistrationForm from "../registrationForm/RegistrationForm";

const RegistrationFormWidget = ({ widget }: ReactWidget) => {
    const config = widget.config || {};
    const textAlign = config['textAlign'];

    const styles: any = {};
    if (textAlign) {
        styles.textAlign = textAlign;
    }

    return (<ContainedSection id='anmelden' >
        <Grid item md={12}>
            <Typography variant="h2" color={'common.white'} textAlign={"center"} sx={{ hyphens: 'auto', wordBreak: 'break-word' }}>HIER BITTE ZUR VERANSTALTUNG ANMELDEN</Typography>
        </Grid>
        <Box>
            <RegistrationForm eventId={widget.eventId} registrationWidgetId={Number(widget.id)} />
        </Box>
    </ContainedSection>)
}

export default RegistrationFormWidget;


