import {
  Box,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useField } from "formik";
import { MyCheckBoxInputProps } from "../../../../../../templates/Interfaces";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

import OptionContainer from "./OptionContainer";
import { MyTextField } from "../../Fields";

interface ListitemProps {
  src: string;
  text: string;
}
export const ListItemManager = (props: MyCheckBoxInputProps) => {
  const { label, name } = props;
  const [field, meta, helpers] = useField(name);

  const [listItems, setListItems] = useState<ListitemProps[]>(
    field.value || []
  );

  useEffect(() => {
    if (listItems !== field.value) {
      setListItems(field.value || []);
    }
  }, [field.value]);

  useEffect(() => {
    updateField();
  }, [listItems]);

  const updateField = () => {
    helpers.setValue(listItems);
  };

  const updateListItem = (index: number, newText: string) => {
    setListItems((prev) => {
      return prev.map((item, i) => {
        if (i === index) {
          return { ...item, text: newText };
        }
        return item;
      });
    });
  };
  const onListItemRemove = (index: number) => {
    setListItems((prev) => {
      return prev.filter((_, i) => i !== index);
    });
  };

  return (
    <>
      <Box mb={"1rem"}>{label}</Box>
      <List>
        {listItems &&
          listItems.map((listItem: ListitemProps, index: number) => {
            return (
              <ListItem
                key={index}
                sx={{ padding: 0, marginTop: index !== 0 ? "1rem" : "" }}
              >
                <Box mr={"1rem"}>{"#" + (index + 1)}</Box>
                <TextField
                  multiline
                  minRows={1}
                  maxRows={10}
                  onChange={(e) => {
                    updateListItem(index, e.target.value);
                  }}
                  value={listItem.text}
                />
                <IconButton
                  disabled={listItems.length === 1}
                  onClick={() => {
                    onListItemRemove(index);
                  }}
                  sx={{ marginLeft: "1rem" }}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            );
          })}
      </List>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <IconButton
          onClick={() => {
            setListItems((prev) => [
              ...prev,
              {
                src: "test",
                text: "Item " + (prev.length + 1),
              },
            ]);
          }}
        >
          <AddIcon />
        </IconButton>
      </Box>
    </>
  );
};

export default ListItemManager;
