import { Box, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import React, { useEffect, useState } from "react";

const SearchField = ({
  searchValue,
  setSearchValue,
  inputRef,
  clearFn,
}: {
  searchValue: string;
  setSearchValue: Function;
  inputRef: React.RefObject<HTMLInputElement>;
  clearFn: Function;
}) => {
  const [showSearchField, setShowSearchField] = useState<boolean>(false);

  useEffect(() => {
    if (showSearchField === false) {
      //clearFn();
    } else {
      if (inputRef && inputRef.current !== null) {
        inputRef.current.focus();
      }
    }
  }, [showSearchField]);

  return (
    <Box
      display="flex"
      flex={1}
      alignItems="center"
      sx={showSearchField ? { paddingBottom: "1rem" } : {}}
    >
      <Box>
        <IconButton
          sx={showSearchField ? { color: "#ea7c15" } : {}}
          onClick={() => {
            setShowSearchField((prev) => !prev);
          }}
        >
          <SearchIcon />
        </IconButton>
      </Box>
      <TextField
        inputRef={inputRef}
        sx={{
          padding: 0,
          margin: 0,
          flex: 1,
          visibility: showSearchField ? "visible" : "hidden",
          "& .Mui-focused .MuiIconButton-root": {
            color: "primary.main",
          },
        }}
        onChange={(newValue) => {
          setSearchValue(newValue.target.value);
        }}
        variant="outlined"
        value={searchValue}
        InputProps={{
          endAdornment: (
            <IconButton
              sx={{
                visibility: searchValue ? "visible" : "hidden",
              }}
              onClick={() => {
                clearFn();
              }}
            >
              <ClearIcon />
            </IconButton>
          ),
        }}
      />
    </Box>
  );
};

export default SearchField;
