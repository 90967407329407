import { useEffect, useState } from 'react';
import { fetchData, processServerData } from '../Helpers';
import { ActivityGroup, IRecord, RegistrationField, SessionRecord } from '../Interfaces';

//local url 
const url = '/api/tempwebsite/'
//const url = 'https://dev.clicla.me/api/tempwebsite/';

export const useRegistrationData = (eventId: string) => {
    const [registrationFields, setRegistrationFields] = useState<RegistrationField[]>([]);
    const [blocks, setBlocks] = useState<IRecord[]>([]);
    const [filteredActivities, setFilteredActivities] = useState<SessionRecord[]>([]);
    const [groupedActivities, setGroupedActivities] = useState<ActivityGroup[]>([]);
    const [dataFetched, setDataFetched] = useState<boolean>(false);

    useEffect(() => {
        fetchData('GET', url + eventId, (serverResponse: any) => {
                processServerData(serverResponse, setRegistrationFields, setBlocks, setFilteredActivities, setGroupedActivities)
                    .then(() => {
                        setDataFetched(true);
                    })
                    .catch((error) => {
                        console.error('Error processing data:', error);
                    });
        });
    }, []);

    return { registrationFields, dataFetched, blocks, filteredActivities, groupedActivities};
};