import React, { useState, ReactNode, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


interface ChoiceDialogProps {
    open: boolean,
    title: string,
    description: string,
    confirmText?: string,
    declineText?: string,
    buttons?: ReactNode[],
    children?: (handleClose: () => void) => React.ReactNode;
}

export default function ImprovedChoiceDialog(props: ChoiceDialogProps) {
    const [open, setOpen] = useState(props.open);
    const { children } = props;

    useEffect(() => {
        setOpen(props.open);
    }, [props.open])
    
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            maxWidth='sm'
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {props.title}
            </DialogTitle>
            <DialogContent sx={{ paddingBottom: 0 }}>
                <DialogContentText id="alert-dialog-description" dangerouslySetInnerHTML={{ __html: props.description }} />
            </DialogContent>
            <DialogActions sx={{ padding: '24px' }}>
                {children && children(handleClose)}
            </DialogActions>
        </Dialog>
    );
}