import { Box, List, ListItem, Modal } from "@mui/material";
import React from "react";
import { ContainedStyledButton } from "../ContainedStyledButton";
import {
  EventRecord,
  ScanRecord,
  SessionRecord,
} from "../../../../../../interfaces/Interfaces";
import { getTranslations } from "../../../../../../translations/Translations";
import { formatTimeMS } from "../../../../../../helpers/DateFunctions";

import DeleteIcon from "@mui/icons-material/Delete";

const PersonModal = ({
  activeScan,
  event,
  selectedSession,
  setActiveScan,
  onScanRemove,
}: {
  activeScan: ScanRecord | null;
  setActiveScan: Function;
  event: EventRecord;
  selectedSession: SessionRecord | null;
  onScanRemove: Function;
}) => {
  const translations = getTranslations();

  const clearActiveScan = () => {
    setActiveScan(null);
  };

  const renderStatusScan = (scan: ScanRecord) => {
    if (scan.type === "checkin") {
      return translations["scanner.checkedIn"];
    }

    if (scan.type === "checkout") {
      return translations["scanner.checkedOut"];
    }
  };

  return (
    <Modal
      open={activeScan !== null}
      componentsProps={{
        backdrop: { sx: { backgroundColor: "rgba(0, 0, 0, 0.2)" } },
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="small-modal">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="100%"
        >
          {activeScan && activeScan !== null && (
            <>
              <List className="scanner-info-list" sx={{ whiteSpace: "pre" }}>
                <ListItem>
                  {translations["scanner.name"]}: {activeScan.firstname}{" "}
                  {activeScan.lastname}
                </ListItem>
                <ListItem>
                  {translations["scanner.status"]}:{" "}
                  <span style={{ display: "inline", fontWeight: "bold" }}>
                    {renderStatusScan(activeScan)}
                  </span>
                </ListItem>
                <ListItem>
                  {translations["scanner.scanTime"]}:{" "}
                  {formatTimeMS(Number(activeScan.date))}
                </ListItem>

                <ListItem>
                  {translations["event"]}:{" "}
                  {event.translations[event.defaultLanguage].title}
                </ListItem>
                {selectedSession && (
                  <ListItem>
                    {translations["session"]}:{" "}
                    {selectedSession.translations[event.defaultLanguage].title}
                  </ListItem>
                )}
              </List>
              <Box flex={1}></Box>
              <ContainedStyledButton
                sx={{
                  backgroundColor: "#953c3c",
                  marginBottom: "1rem",
                  padding: "1rem",
                }}
                onClick={() => {
                  onScanRemove(activeScan);
                  clearActiveScan();
                }}
              >
                <DeleteIcon sx={{ marginRight: "0.5rem" }} />
                {translations["scanner.removeScan"]}
              </ContainedStyledButton>
              <ContainedStyledButton
                sx={{ backgroundColor: "#bfbfbf", padding: "1rem" }}
                onClick={clearActiveScan}
              >
                {translations["close"]}
              </ContainedStyledButton>
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default PersonModal;
