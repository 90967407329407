import { Box, MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useField } from "formik";
import { MyCheckBoxInputProps } from "../../../../../../templates/Interfaces";

import OptionContainer from "./OptionContainer";

enum HtmlSizeIndicators {
    PX = 'PX',
    REM = 'REM',
    EM = 'EM'
}

export const HtmlNumberSelector = (props: MyCheckBoxInputProps) => {
    const { label, name } = props;
    const [field, meta, helpers] = useField(name);
    const [combinedValue, setCombinedValue] = useState<string>('');
    const [value, setValue] = useState<string>('');
    const [valueType, setValueType] = useState<HtmlSizeIndicators>(HtmlSizeIndicators.PX);

    const setInitialValue = () => {
        const fieldValue = field.value;
        if (fieldValue && fieldValue !== combinedValue) {
            const match = fieldValue.match(/^(\d+)(\D+)/);
            if (match) {
                setCombinedValue(fieldValue);
                setValue(match[1]);
                setValueType((match[2].toUpperCase()) as HtmlSizeIndicators);
            }
        }
    }

    /*useEffect(() => {
        setInitialValue();
    }, [])*/
    
    useEffect(() => {
        if (field.value !== combinedValue) {
            setInitialValue();
        }
    },[field.value])

    useEffect(() => {
        helpers.setValue(value + valueType)
    }, [value, valueType])

    return (
        <OptionContainer sx={{ flexDirection: 'column', alignItems: 'stretch' }}>
            <Typography sx={{ marginBottom: '.5rem' }}>{label}</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                <TextField

                    size={"small"}
                    sx={{ width: '70px', fontSize: '8px', flex: 1 }}
                    type="number"
                    className="text-input"
                    onChange={(e) => {
                        if (e.target) {
                            setValue(e.target.value);
                        }
                    }}
                    value={value}
                    error={!!(meta.touched && meta.error)}
                />
                <Select
                    size={"small"}
                    value={valueType}
                    sx={{ width: '70px' }}
                    onChange={(e) => setValueType(e.target.value as HtmlSizeIndicators)}
                >
                    <MenuItem value={'PX'}>PX</MenuItem>
                    <MenuItem value={'REM'}>REM</MenuItem>
                    <MenuItem value={'EM'}>EM</MenuItem>
                </Select>
            </Box>
        </OptionContainer>
    );
};

export default HtmlNumberSelector;