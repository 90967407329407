import { Box, Button, Grid } from "@mui/material"
import React from "react"
import { ReactWidget } from "../../Interfaces";

const ButtonWidget = ({ widget }: ReactWidget) => {
    const config = widget.config || {};
    const textAlign = config['textAlign'];
    const align = config['align'];

    const styles: any = {};
    const parentStyles : any = {};
    if (align) {
        parentStyles.textAlign = align;
    }
    if (textAlign) {
        styles.textAlign = textAlign;
    }

    if (widget.translations[widget.defaultLanguage] && widget.translations[widget.defaultLanguage].content) {
        return (
                <Box sx={parentStyles}><Button variant="outlined" color="primary" sx={{ ...styles, padding: '0.5rem', minWidth: '30%', fontSize: '1rem', textTransform: 'inherit' }}>{widget.translations[widget.defaultLanguage].content}</Button></Box>
        );
    } else {
        return <Box sx={styles}>{widget.type}</Box>
    }
}

export default ButtonWidget;