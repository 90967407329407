import { Box } from "@mui/material";
import React from "react";

export const OptionContainer = ({sx, children}: any) => {
    if (sx) {
        return <Box className="side-panel-option" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem', ...sx }}>
            {children}
        </Box>
    } else {
        return <Box className="side-panel-option" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
            {children}
        </Box>
    }

};

export default OptionContainer;