import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material"
import React from "react"
import { ReactWidget } from "../../Interfaces";
import Slider from "react-slick";
import './SliderWidget.scss';


const SliderWidget = ({ widget, pageWidth }: ReactWidget) => {
    console.log(pageWidth);
    const config = widget.config || {};
    const align = config['align'];
    const srcs = config['srcs'];

    let sliderWidth = '100%';
    if (pageWidth) {
        sliderWidth = pageWidth + 'px';
    }

    const settings = {
        arrows: false,
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 4000,
        cssEase: "linear",
        pauseOnHover: false,
        draggable: true,

    };
    const styles: any = {};

    if (srcs) {
        return (
            <Box style={{maxWidth: sliderWidth, margin: '0 auto'}}><Slider {...settings}>
                {srcs.map((item: any, index: number) => (
                    <div className="image-slide" key={index}>
                        <img src={item.src} />
                    </div>
                ))}
            </Slider>
            </Box>
        );
    } else {
        return <Box sx={styles}>{widget.type}</Box>
    }
}

export default SliderWidget;