import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  SxProps,
  Typography,
} from "@mui/material";
import { getTranslations } from "../translations/Translations";
import { NoteRecord } from "../interfaces/Interfaces";

interface SharednotesProps {
  eventId?: number;
  sx?: SxProps | undefined;
}

function Sharednotes(props: SharednotesProps) {
  const translations = getTranslations();
  const [items, setItems] = useState([]);
  const eventId = props.eventId;

  useEffect(() => {
    const controller = new AbortController();

    let url: string = "/api/notes";
    if (eventId) {
      let params: URLSearchParams = new URLSearchParams({
        filter: JSON.stringify([{ property: "eventId", value: eventId }]),
      });
      url = url + "?" + params.toString();
    }

    fetch(url, {
      signal: controller.signal,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setItems(result.data);
      })
      .catch((err: DOMException) => {
        if (err.name === "AbortError") {
        }
      });
    return () => {
      controller.abort();
    };
  }, [eventId]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={props.sx}
      p={1}
      className="ui-widget"
    >
      <Box display="flex" m={1} flex={1} flexDirection={"column"}>
        <Box className="titlebar">
          <Typography>{translations["sharednotes.title"]}</Typography>
        </Box>
        <List className="list">
          {items.length > 0 &&
            items.map((element: NoteRecord) => {
              return (
                <ListItem
                  sx={{ gap: 1, flexDirection: "column", alignItems: "start" }}
                  key={element.id}
                >
                  <div>
                    <Typography>
                      {element.author.firstname} {element.author.lastname}
                    </Typography>
                  </div>
                  <div>
                    <Typography>{element.createdOn}</Typography>
                  </div>
                  <div>
                    <Typography>{element.description}</Typography>
                  </div>
                </ListItem>
              );
            })}
          {items.length === 0 ? (
            <ListItem sx={{ gap: 1 }} key="-1">
              <ListItemText
                primary={translations["sharednotes.nonotesfound"]}
              />
            </ListItem>
          ) : null}
        </List>
        <Box justifyContent={"end"} display="flex" flexDirection={"row"} p={1}>
          <Button variant="contained">
            {translations["sharednotes.addnote"]}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default Sharednotes;
