import React from "react";
import styled from "@emotion/styled";
import Button, { ButtonProps as MuiButtonProps } from "@mui/material/Button";

const StyledButton = styled(Button)({
  root: {
    backgroundColor: "#006e55",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#ea7c15",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "#ea7c15",
      },
      "&$disabled": {
        backgroundColor: "grey",
      },
    },
    "&$disabled": {
      color: "grey",
    },
  },
});

export const ContainedStyledButton = (props: MuiButtonProps) => (
  <StyledButton variant="contained" {...props} />
);
