import React, { useEffect, useState, useRef } from 'react';
import { Box, Button, Typography, FormControl, TextField } from "@mui/material";
import UploadControl from './UploadControl';
import useMaskContext from '../hooks/useMaskContext';
import CloseIcon from "@mui/icons-material/Close";
import { useRevalidator, useRouteLoaderData } from "react-router-dom";
import { DisplayErrors } from "./DisplayErrors";
import { useFormik } from "formik";
import { getTranslations } from "../translations/Translations";
import * as Yup from "yup";

import {
    EventRouterResponse,
    IRecord
} from "../interfaces/Interfaces";



interface YoutubeWindowProps {
    onClose: Function
}

const YoutubeWindow = (props: YoutubeWindowProps) => {
    const translations = getTranslations();
    const { toggleMask } = useMaskContext();
    const { event } = useRouteLoaderData("event") as EventRouterResponse;
    const revalidator = useRevalidator();
    const inputRef = useRef(null);


    const getFlatObj = (obj: IRecord) => {
        return {
            id: obj.id,
            youtubeThumbnail: obj.fileId || "",
            youtubeLink: obj.youtubeLink
        };
    };

   

    const formik = useFormik({
        initialValues: getFlatObj({ ...event, youtubeThumbnail: '', youtubeLink: '' }),
        validateOnChange: false,
        validationSchema: Yup.object({
            youtubeThumbnail: Yup.mixed().required(translations["errors.requiredfield"]),
            youtubeLink: Yup.string()
                .matches(
                    /^(https?:\/\/)?(www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})$/,
                    translations['errors.youtubeLinkFormat'] + ': https://www.youtube.com/watch?v=dQw4w9WgXcQ'
                )
                .required(translations["errors.requiredfield"])
        }),
        onSubmit: (values) => {
            const youtubeUpload = (input: HTMLElement) => {
                let data = new FormData();
                let eventtarget: HTMLInputElement | null = input.firstChild as HTMLInputElement;

                if (eventtarget !== null && typeof eventtarget !== 'undefined' && eventtarget.files !== null) {
                    data.append("file", eventtarget.files[0]);
                    data.append("eventId", "" + event.id);
                    data.append("purpose", "" + "media");
                    data.append("noResize", "true");
                    data.append("videolink", values.youtubeLink);

                    fetch("/api/files/upload", {
                        method: "post",
                        headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                        },
                        body: data,
                    })
                        .then((response) => response.json())
                        .then((jsonresponse) => {
                            // because it's fileupload & browsers don't actually understand json response then
                            if (!jsonresponse.sucess && !jsonresponse.id) {
                                jsonresponse = JSON.parse(jsonresponse);
                                props.onClose();
                            }
                        })
                        .catch(() => {
                            console.log('upload error');
                            //TODO: errormessage
                        });
                }
            }

            if (inputRef && inputRef.current !== null) {
                youtubeUpload(inputRef.current); 
            }
        },
    });
    

    return (

        <Box display="flex" flexDirection={"column"} justifyContent='space-between'>
            <Box display="flex" justifyContent="space-between">
            <Box sx={{ marginBottom: '1rem'}} flexShrink={0}>
                            <Typography variant='h6'>{translations["youtubeWindow.title"]}</Typography>
                        </Box>
                <Button sx={[{ backgroundColor: 'none', color: '#006e55', borderRadius: 0, boxShadow: 'none', fontSize: '1rem', padding: '0.5rem 1rem' }, { '&:hover': { backgroundColor: 'none', color: '#ea7c15' } }]} onClick={() => { props.onClose() }}><CloseIcon /></Button>
            </Box>
            <form
                style={{ display: "flex", flexGrow: 1, overflow: "scroll" }}
            >
                <Box display="flex" flexDirection="column" sx={{ width: '100%' }}>
                    <Box display="flex" flexDirection={"row"} sx={{ marginBottom: '1rem' }} alignItems={'center'} className="fieldlabel">
                        <Box sx={{ marginRight: '1rem', minWidth: '100px'}} flexShrink={0}>
                            <Typography>{translations["youtubeWindow.thumbnail"]}:</Typography>
                        </Box>
                        <UploadControl
                            ref={inputRef}
                            name="youtubeThumbnail"
                            value={formik.values.youtubeThumbnail}
                            eventId={event.id}
                            setFieldValue={formik.setFieldValue}
                            beforeUpload={() => {
                                toggleMask(true);
                            }}
                            error={formik.errors.youtubeThumbnail}
                            onDeleteComplete={() => {
                                formik.values.youtubeThumbnail = '';
                                revalidator.revalidate()
                            }}
                            onUploadComplete={() => {
                                toggleMask(false);
                                revalidator.revalidate()
                            }}
                            onUploadError={() => {
                                console.log('ERROR ...');
                                toggleMask(false);
                            }}
                            autoUpload={false}
                            afterUploadClick={formik.validateForm}
                            purpose="media"
                            accept="image/gif, image/jpeg, image/jpg, image/png"
                        />
                    </Box>
                    <Box>
                    <Box display="flex" flexDirection={"row"} sx={{ marginBottom: '1rem' }} alignItems={'center'} className="fieldlabel">
                        <Box sx={{ marginRight: '1rem', minWidth: '100px'}} flexShrink={0}>
                            <Typography>{translations["youtubeWindow.youtubeLink"]}:</Typography>
                        </Box>
                        <FormControl fullWidth>
                            <TextField  
                                error={Boolean(formik.touched.youtubeLink && formik.errors.youtubeLink)}
                                {...formik.getFieldProps("youtubeLink")}
                            ></TextField>
                            
                        </FormControl>
                    </Box>
                    <Box sx={{marginLeft: '116px', marginBottom: '1rem'}}><DisplayErrors name="youtubeLink" errors={formik.errors.youtubeLink} /></Box>
                    </Box>
                   
                    <Button
                        variant="contained"
                        disabled={formik.isSubmitting}
                        onClick={() => {
                            formik.validateForm().then(() => {
                                console.log('isValid',formik.isValid);
                                if (formik.isValid) {
                                    formik.submitForm();
                                }
                            })

                        }}
                    >
                        {translations["savebar.save"]}
                    </Button>
                </Box>

            </form>

        </Box>
    )
}

export default YoutubeWindow;