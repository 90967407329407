import React, { useEffect, useState } from "react";
import { Badge, Box, Button } from "@mui/material";
import { getTranslations } from "../translations/Translations";
import { unstable_useBlocker } from "react-router-dom";
import type { Blocker } from "@remix-run/router";
import { DirtyFormDialog } from "./DirtyFormDialog";

interface SaveBarProps {
  onDelete?: Function;
  onCancel?: Function;
  onCopy?: Function;
  manualSubmit?: boolean;
  formik: any;
}

interface ConfirmNavigationProps {
  blocker: Blocker;
}

function ConfirmNavigation(props: ConfirmNavigationProps): any {
  if (props.blocker.state === "blocked") {
    return (
      <DirtyFormDialog
        open={true}
        cancel={() => props.blocker.reset?.()}
        continue={() => props.blocker.proceed?.()}
      />
    );
  } else {
    return null;
  }
}

export default function SaveBar(props: SaveBarProps) {
  const translations = getTranslations();
  const [dirtyValues, setDirtyValues] = useState<number>(0);
  let blocker = unstable_useBlocker(props.formik.dirty);

  useEffect(() => {
    let data = { ...props.formik.values };
    let keys = Object.keys(data);
    let dirtykeys = keys.filter(
      (key) => data[key] !== props.formik.initialValues[key]
    );
    setDirtyValues(dirtykeys.length);
  }, [props.formik.values, props.formik.initialValues]);

  return (
    <Box
      p={2}
      position={"sticky"}
      bottom={0}
      height="40px"
      sx={{ backgroundColor: "#EEE" }}
      textAlign="right"
    >
      {blocker ? <ConfirmNavigation blocker={blocker} /> : null}
      {props?.onCopy ? (
        <Button
          sx={{ marginRight: "8px" }}
          disabled={props.formik.isSubmitting}
          onClick={() => {
            if (props?.onCopy) {
              props?.onCopy();
            }
          }}
        >
          {translations["savebar.copy"]}
        </Button>
      ) : null}
      {props?.onDelete ? (
        <Button
          sx={{ marginRight: "8px" }}
          disabled={props.formik.isSubmitting}
          onClick={() => {
            if (props?.onDelete) {
              props?.onDelete();
            }
          }}
        >
          {translations["savebar.delete"]}
        </Button>
      ) : null}
      {props?.onCancel ? (
        <Button
          sx={{ marginRight: "8px" }}
          disabled={props.formik.isSubmitting}
          onClick={() => {
            if (props?.onCancel) {
              props?.onCancel();
            }
          }}
        >
          {translations["savebar.cancel"]}
        </Button>
      ) : null}

      <Badge
        badgeContent={dirtyValues}
        className="badge"
        invisible={dirtyValues === 0}
      >
        {props?.manualSubmit ? (
          <Button
            variant="contained"
            disabled={props.formik.isSubmitting || dirtyValues === 0}
            onClick={() => {
              console.log("manual submit");
              console.log(props.formik);
              props.formik.submitForm();
            }}
          >
            {translations["savebar.save"]}
          </Button>
        ) : (
          <Button
            type="submit"
            variant="contained"
            disabled={props.formik.isSubmitting || dirtyValues === 0}
          >
            {translations["savebar.save"]}
          </Button>
        )}
      </Badge>
    </Box>
  );
}
