import { Box, Grid, Typography } from "@mui/material"

import React from "react"
import { ReactWidget, ReactWidgetWithStyles } from "../../Interfaces";
import withStandardStyles from "./withStandardStyles";

const TitleWidget = ({ widget, standardStyles }: ReactWidgetWithStyles) => {
    const config = widget.config || {};
    const textAlign = config['textAlign'];
    const fontSize = config['fontSize'];
    const fontColor = config['fontColor'];

    const styles: any = {};
    if (textAlign) {
        styles.textAlign = textAlign;
    }
    if (fontSize) {
        styles.fontSize = fontSize;
    }

    if (fontColor) {
        styles.color = fontColor;
    }
    
    if (widget.translations[widget.defaultLanguage].content) {
        return (
            <Grid item md={12}>
                <Typography variant="h1primary" color="primary" sx={{ ...styles, wordBreak: 'break-word', marginBottom: 0, ...standardStyles}}>{widget.translations[widget.defaultLanguage].content}</Typography>
                {/*extraText*/}
            </Grid>
        )
    } else {
        return <Box>{widget.type}</Box>
    }
}

export default withStandardStyles(TitleWidget);