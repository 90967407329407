import React, { useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from "@mui/material";
import { getTranslations } from "../../../../../../translations/Translations";
import MediaFileSelect from "../../../../../../components/MediaFileSelect";

const FileSelectModal = ({ type, open, closeModal, onSelectImage }: { type?:string, open: boolean, closeModal: Function, onSelectImage: Function }) => {
    const translations = getTranslations();
    const [selectedImageUrl, setSelectedImageUrl] = useState<Object>({});

    return (
        <Dialog
            className="window"
            open={open}
            scroll="body"
            fullWidth={true}
        >
            <DialogTitle>{translations["event.website.fileSelect"]}</DialogTitle>
            <DialogContent dividers={false}>
                <Box flexShrink={0} className="fieldlabel" mr={2}>
                    <MediaFileSelect type={type} onImageSelectFn={setSelectedImageUrl} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => {
                        onSelectImage(selectedImageUrl)
                        closeModal();
                    }}
                >
                    {translations["confirm"]}
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        closeModal();
                    }}
                >
                    {translations["cancel"]}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
export default FileSelectModal;