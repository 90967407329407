import { Grid, Typography, Box, Paper } from "@mui/material";
import React from "react";

export const TypographyField = (props: { title: string | undefined }) => {
    const { title } = props;
    
    return (
        title !== undefined ? (
                <Paper >
                    <Box m={'1rem 0'} p={'1rem'}>
                    <Typography  m={0}  dangerouslySetInnerHTML={{__html: title}}></Typography>
                    </Box>
                </Paper>
        ) : null
    );
};
