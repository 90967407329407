import React, { useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, Typography } from "@mui/material";
import { getTranslations } from "../../../../../../translations/Translations";
import { useNavigate, useRevalidator, useRouteLoaderData } from "react-router-dom";
import { EventRouterResponse, IRecord } from "../../../../../../interfaces/Interfaces";
import { Formik, useField } from "formik";

import * as Yup from "yup";

interface WebPageTemplate {
    title: string
}

const MyTextField = ({
    name,
    isSubmitting,
}: {
    name: string;
    isSubmitting: boolean | undefined;
}) => {
    const [field, meta, helpers] = useField(name);
    return (
        <TextField
            disabled={isSubmitting}
            size="small"
            fullWidth
            {...field}
            error={!!(meta.touched && meta.error)}
            helperText={meta.touched && meta.error ? meta.error : ""}
        ></TextField>
    );
};

const NewWebPageModal = ({ open, closeModal, webpages }: { open: boolean, closeModal: Function, webpages: IRecord[]; }) => {
    const { event } = useRouteLoaderData("event") as EventRouterResponse;
    const navFn = useNavigate();

    const revalidator = useRevalidator();
    const translations = getTranslations();

    const [initialValues, setInitialValues] = useState<WebPageTemplate>({
        title: ''
    });

    Yup.addMethod(Yup.string, "unique", function (errorMessage) {
        return this.test(`test-card-length`, errorMessage, function (value) {
            const { path, createError } = this;

            return (
                (value && value.length === 16) ||
                createError({ path, message: errorMessage })
            );
        });
    });

    const validationSchema = {
        title: Yup.string()
            .required(translations["errors.requiredfield"])
            .max(400, translations["errors.maxlength400"])
            .test("webpage-unique", translations["errors.uniqueWebpages"], (value) => !webpages.some((webpage) => webpage.translations[event.defaultLanguage].title.toLowerCase() === value.toLowerCase()))
    };

    const handleAddWebPageClick = (submitFormFn: Function) => {
        submitFormFn()
    }

    const MyTextField = ({
        name,
        isSubmitting,
    }: {
        name: string;
        isSubmitting: boolean | undefined;
    }) => {
        const [field, meta] = useField(name);
        return (
            <TextField
                disabled={isSubmitting}
                size="small"
                fullWidth
                {...field}
                error={!!(meta.touched && meta.error)}
                helperText={meta.touched && meta.error ? meta.error : ""}
            ></TextField>
        );
    };


    return (
        <Dialog
            className="window"
            open={open}
            scroll="body"
            fullWidth={true}
            maxWidth="sm"
        >
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={Yup.object(validationSchema)}
                validateOnBlur={true}
                onSubmit={(values, { setSubmitting }) => {
                    fetch("/api/webpages", {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            language: event.defaultLanguage,
                            defaultLanguage: event.defaultLanguage,
                            eventId: event.id,
                            ...values
                        }),
                    })
                        .then((res) => res.json())
                        .then((jsondata) => {
                            if (jsondata.success) {
                                revalidator.revalidate();
                                navFn(`./${jsondata.data.translations[event.defaultLanguage].title}`, { replace: true });
                                closeModal();
                            }
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });

                }}
            >
                {({ values, handleSubmit, submitForm, isSubmitting }) => {
                    return (
                        <>
                            <DialogTitle>{translations["website.add"]}</DialogTitle>
                            <DialogContent dividers={false}>
                                <Grid container>
                                    <Grid item xs={12} flexDirection="row" display="flex">
                                        <Typography>{translations["website.title"]}</Typography>
                                        <Box flexShrink={0} className="fieldlabel" mr={2}>
                                        </Box>
                                        <MyTextField name='title' isSubmitting={isSubmitting} />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        handleAddWebPageClick(submitForm)
                                    }}
                                >
                                    {translations["confirm"]}
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        closeModal();
                                    }}
                                >
                                    {translations["cancel"]}
                                </Button>
                            </DialogActions>
                        </>
                    )
                }}
            </Formik>
        </Dialog>

    )
}
export default NewWebPageModal;