import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  SxProps,
  Typography,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { getTranslations } from "../../../translations/Translations";
import { TodoRecord } from "../../../interfaces/Interfaces";

interface EventTodoListProps {
  eventId?: number;
  title?: string;
  sx?: SxProps | undefined;
}

function EventTodoList(props: EventTodoListProps) {
  const translations = getTranslations();
  const [items, setItems] = useState([]);
  const [filterSelection, setFilterSelection] = useState("ALL");
  const [sortSelection, setSortSelection] = useState("first");
  const eventId = props.eventId;
  const addeventIdUrlParam = (url: String, value: number) => {
    let params: URLSearchParams = new URLSearchParams({
      filter: JSON.stringify([{ property: "eventId", value: value }]),
    });
    return url + "?" + params.toString();
  };

  useEffect(() => {
    const controller = new AbortController();

    let url: string = "/api/tasks";
    if (eventId) {
      url = addeventIdUrlParam(url, eventId);
      fetch(url, {
        signal: controller.signal,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("jwt"),
        },
      })
        .then((res) => res.json())
        .then((result) => {
          setItems(result.data);
        })
        .catch((err: DOMException) => {
          if (err.name === "AbortError") {
          }
        });
    }
    return () => {
      controller.abort();
    };
  }, [eventId, filterSelection]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={props.sx}
      className="ui-widget"
    >
      <Box display="flex" flexDirection="column" flex={1} m={1}>
        <Box
          className="titlebar"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Typography>{translations["todos.title"]}</Typography>
          <Box display="flex" flexDirection="row">
            <InputLabel
              id="todolist-filter"
              sx={{ padding: "8px 4px 0px 0px" }}
            >
              <FilterAltIcon />
            </InputLabel>
            <Select
              size="small"
              labelId="todolist-filter"
              value={filterSelection}
              onChange={(e) => {
                setFilterSelection(e.target.value);
              }}
            >
              <MenuItem value={"ALL"}>{translations["todos.all"]}</MenuItem>
            </Select>
            <InputLabel
              id="todolist-sort"
              sx={{ lineHeight: "40px", padding: "0px 4px 0px 10px" }}
            >
              {translations["todos.sort"]}
            </InputLabel>
            <Select
              size="small"
              labelId="todolist-sort"
              value={sortSelection}
              onChange={(e) => {
                setSortSelection(e.target.value);
              }}
            >
              <MenuItem value={"first"}>
                {translations["todos.closest"]}
              </MenuItem>
            </Select>
          </Box>
        </Box>
        <List className="list" sx={{ marginTop: "10px" }}>
          {items.length > 0 &&
            items.map((element: TodoRecord) => {
              return (
                <ListItem sx={{ gap: 1 }} key={element.id}>
                  <ListItemText>
                    <Box
                      justifyContent="space-between"
                      display="flex"
                      flexDirection="row"
                      className="todoevent"
                      width="320px"
                    >
                      <Box>{element.title}</Box>
                      <Box>
                        {element.assignedTo
                          ? element.assignedTo.firstname
                          : null}
                      </Box>
                    </Box>
                  </ListItemText>
                  <ListItemSecondaryAction>
                    <Button className="taskbutton" variant="contained">
                      {element.completed
                        ? translations["todos.completed"]
                        : translations["todos.open"]}
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          {items.length === 0 ? (
            <ListItem sx={{ gap: 1 }} key="-1">
              <ListItemText primary={translations["todos.notodosfound"]} />
            </ListItem>
          ) : null}
        </List>
        <Box justifyContent={"end"} display="flex" flexDirection={"row"} p={1}>
          <Button variant="contained">{translations["todos.add"]}</Button>
        </Box>
      </Box>
    </Box>
  );
}

export default EventTodoList;
