import { Box, Button, IconButton, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SessionInfo from "./SessionInfo";
import {
  Filter,
  LocationRecord,
  ModuleRecord,
  SessionRecord,
} from "../../../../../interfaces/Interfaces";
import { ContainedStyledButton } from "./ContainedStyledButton";
import { getTranslations } from "../../../../../translations/Translations";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import ClearIcon from '@mui/icons-material/Clear';

const SessionSelect = ({
  currentSelectSession,
  sessions,
  modules,
  language,
  currentLocation,
  setSelectedSession,
  setCurrentSelectSession,
  setSelectedScanBlock
}: {
  sessions: SessionRecord[];
  modules: ModuleRecord[];
  language: string;
  currentLocation: LocationRecord | undefined;
  currentSelectSession: SessionRecord | undefined;
  setCurrentSelectSession: Function;
  setSelectedScanBlock: Function;
  setSelectedSession: Function;
}) => {
  const translations = getTranslations();
  //SelectedBlock is for the filter
  const [selectedBlock, setSelectedBlock] = useState<string>('');

  //SelectedScanBlock to scan blocks instead of activity
  const [currentSelectedScanBlock, setCurrentSelectedScanBlock] = useState<ModuleRecord | null>(null);

  const [activityFilters, setActivityFilters] = useState<Filter[]>([]);
  const [filteredActivities, setFilteredActivities] = useState<SessionRecord[]>([])
  const [showModuleFilter, setShowModuleFilter] = useState<boolean>(false);

  const blockFilter = (record: any, value: string) => {
    if (value === '') {
      return true;
    } else {
      if (Number(value) < 0) {
        return record.blockId === null;
      } else {
        return record.blockId === Number(value)
      }
    }
  };

  useEffect(() => {
    if (selectedBlock !== null) {
      setActivityFilters((prevFilters) => {
        const filterIndex = prevFilters.findIndex(
          (filter) => filter.filterName === "block"
        );
        if (filterIndex !== -1) {
          prevFilters[filterIndex].filterFunction = (row: any) =>
            blockFilter(row, selectedBlock);
          return [...prevFilters];
        } else {
          return [
            ...prevFilters,
            {
              filterName: "block",
              filterFunction: (row: any) =>
                blockFilter(row, selectedBlock),
            },
          ];
        }
      });
    } else {
      setActivityFilters((prevFilters) =>
        prevFilters.filter((filter) => filter.filterName !== "block")
      );
    }
  }, [selectedBlock]);

  useEffect(() => {
    if (activityFilters.length > 0) {
      let filteredRows = [...sessions];
      activityFilters.forEach((myFilter) => {
        filteredRows = filteredRows.filter((record) =>
          myFilter.filterFunction(record)
        );
      });
      setFilteredActivities(filteredRows);
      setCurrentSelectSession(undefined);
    } else {
      setFilteredActivities([...sessions]);
      setCurrentSelectSession(undefined);
    }
  }, [activityFilters]);

  const hasNoSessionSession = sessions.some(obj => obj.blockId === null);
  const currentModule = (currentSelectSession && currentSelectSession.blockId)
    ? modules.find(module => module.id === currentSelectSession.blockId)
    : null;

  return (
    <Box display="flex" flexDirection="column" flex={1} p={"1rem"}>
      {showModuleFilter ? (modules.length > 0 ? (
        <Box mb={'1rem'} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <IconButton color="primary" onClick={() => {
            setSelectedBlock('');
            setShowModuleFilter(false)
          }}><FilterAltOffIcon /></IconButton>
          {translations["scanner.blockFilter"]}
          <Box sx={{ display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-start', marginLeft: '1rem', alignItems: 'center' }}>
            <Select
              sx={{ flex: 1, maxWidth: '165px' }}

              value={selectedBlock}
              onChange={(e: SelectChangeEvent) => {
                setSelectedBlock(e.target.value)
              }}
            >
              {modules.map((module: ModuleRecord) => (
                <MenuItem key={module.id} value={module.id}>
                  {module.translations[module.defaultLanguage].title}
                </MenuItem>
              ))}
              {hasNoSessionSession ? <MenuItem value={-1}>{translations["scanner.noBlock"]}</MenuItem> : null}
            </Select>
            {selectedBlock === '' ?
              null
              : <IconButton color="primary" sx={{ height: '100%', padding: 0, marginLeft: '1rem' }} onClick={() => {
                setSelectedBlock('');
              }}><ClearIcon /></IconButton>}
          </Box>

        </Box>
      ) : null) : (modules.length > 0 ? <Box mb={'1rem'} sx={{ minHeight: '56px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}><IconButton color="primary" onClick={() => { setShowModuleFilter(true) }}><FilterAltIcon /></IconButton></Box> : null)}
      <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center', marginBottom: '1rem' }}>
        <Typography sx={{ marginRight: '1rem' }}>
          Sitzung
        </Typography>
        <Select
          sx={{ flex: 1 }}
          value={currentSelectSession?.id.toString() || ""}
          onChange={(event: SelectChangeEvent) => {
            setCurrentSelectedScanBlock(null);
            setCurrentSelectSession(
              sessions.find(
                (session) => session.id.toString() == event.target.value
              )
            );
          }}
        >
          {filteredActivities.map((session, index) => {
            return (
              <MenuItem key={index} value={session.id}>
                {session.translations[language].title}
              </MenuItem>
            );
          })}
        </Select>
      </Box>

      {/* ZEITGRUPPE */}
      <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
        <Typography sx={{ marginRight: '1rem' }}>
          Zeitgruppe
        </Typography>
        <Select
          sx={{ flex: 1 }}

          value={currentSelectedScanBlock?.id.toString() || ""}
          onChange={(e: SelectChangeEvent) => {
            setCurrentSelectSession(undefined);
            setCurrentSelectedScanBlock(
              modules.find((module) => module.id.toString() == e.target.value) ?? null
            );
          }}
        >
          {modules.map((module: ModuleRecord) => (
            <MenuItem key={module.id} value={module.id}>
              {module.translations[module.defaultLanguage].title}
            </MenuItem>
          ))}
        </Select>
      </Box>


      {currentSelectSession && (
        <Box sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
          <SessionInfo
            session={currentSelectSession}
            currentLocation={currentLocation}
            block={currentModule!}
            defaultLanguage={language}
          />
        </Box>
      )}
      <Box flex="1"></Box>
      {currentSelectSession && (
        <Box>
          <ContainedStyledButton
            sx={[{ width: "100%" }]}
            onClick={() => {
              setSelectedSession(currentSelectSession);
              setCurrentSelectSession(undefined);
            }}
          >
            {translations["select"]}
          </ContainedStyledButton>
        </Box>
      )}
      {currentSelectedScanBlock && (
        <Box>
          <ContainedStyledButton
            sx={[{ width: "100%" }]}
            onClick={() => {
              setSelectedScanBlock(currentSelectedScanBlock);
              setCurrentSelectedScanBlock(null);
            }}
          >
            {translations["select"]}
          </ContainedStyledButton>
        </Box>
      )}
    </Box>
  );
};

export default SessionSelect;
