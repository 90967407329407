import React, { ChangeEvent, useState } from "react";
import {
  getGridTranslations,
  getTranslations,
} from "../../../../translations/Translations";
import {
  IRecord,
  ITranslatable,
  ImportSettings,
} from "../../../../interfaces/Interfaces";
import { DataGrid, GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import UploadIcon from "@mui/icons-material/Upload";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import SaveBar from "../../../../components/SaveBar";
import { useFormik } from "formik";
import * as Yup from "yup";

type UploadCompleteFunction = () => void;
interface ContactImportControlProps {
  eventId: number;
  registrationFields: ITranslatable[];
  registered: boolean;
  onUploadComplete: UploadCompleteFunction;
  widgetId?: number;
}

function ContactImportControl(props: ContactImportControlProps) {
  const translations = getTranslations();
  const gridTranslations = getGridTranslations();
  const [open, setOpen] = useState<boolean>(false);
  const [sheet, setSheet] = useState<number>(0);
  const [sheets, setSheets] = useState<string[]>([]);
  const [menuopen, setMenuopen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mappingKey, setMappingKey] = useState<string>("");
  const [columns, setColumns] = useState<GridColDef[]>([
    {
      field: "id",
      headerName: translations["import.nofields"],
      sortable: false,
    },
  ]);
  const [rows, setRows] = useState<IRecord[]>([]);
  const [columnMapping, setColumnMapping] = useState<{
    [key: string]: any;
  }>({});

  const performMapping = (e: React.MouseEvent<HTMLElement>, key: string) => {
    setMappingKey(key);
    setAnchorEl(e.currentTarget);
    setMenuopen(true);
  };
  const handleMenuChoice = (registrationField: ITranslatable | null) => {
    let obj = { ...columnMapping };
    let key = mappingKey;
    let newcolumns = [...columns];

    if (registrationField === null) {
      delete obj[key];
      newcolumns.forEach((col: GridColDef) => {
        if (col.field === key) {
          col.renderHeader = (params: GridColumnHeaderParams) => (
            <Button
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.preventDefault();
                performMapping(e, key);
              }}
            >
              {key}
            </Button>
          );
        }
      });
    } else {
      obj[key] = "field" + registrationField.id;
      newcolumns.forEach((col: GridColDef) => {
        if (col.field === key) {
          col.renderHeader = (params: GridColumnHeaderParams) => (
            <Button
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.preventDefault();
                performMapping(e, key);
              }}
            >
              {
                registrationField.translations[
                  registrationField.defaultLanguage
                ].title
              }
            </Button>
          );
        }
      });
    }

    console.log("new column mapping", obj);
    setColumns(newcolumns);
    setColumnMapping(obj);
    setMenuopen(false);
  };

  const loadPreview = (settings: ImportSettings) => {
    fetch("/api/registrationimport/preview", {
      method: "post",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("jwt"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(settings),
    })
      .then((response) => response.json())
      .then((jsonresponse) => {
        let newcols: GridColDef[] = [
          {
            field: "id",
            headerName: "row",
            sortable: false,
            valueGetter: (params) => {
              return parseInt(params.row.id, 10) + 1;
            },
          },
        ];
        let newrows: IRecord[] = jsonresponse.data;

        let obj = newrows[0];
        for (var key in obj) {
          if (obj.hasOwnProperty(key) && key !== "id") {
            let mappedName = settings.columnMapping[key] || key;
            newcols.push({
              field: key,
              renderHeader: (params: GridColumnHeaderParams) => (
                <Button
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.preventDefault();
                    performMapping(e, params.field);
                  }}
                >
                  {mappedName}
                </Button>
              ),
              flex: 1,
              minWidth: 100,
              sortable: false,
            });
          }
        }

        setColumns(newcols);
        setRows(newrows);
      });
  };

  const loadSheets = (settings: ImportSettings) => {
    fetch("/api/registrationimport/sheets", {
      method: "post",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("jwt"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(settings),
    })
      .then((response) => response.json())
      .then((jsonresponse) => {
        setSheets(jsonresponse.data);
      });
  };

  const uploadFn = (e: ChangeEvent) => {
    let data = new FormData();
    let eventtarget = e.target as HTMLInputElement;

    if (eventtarget.files !== null) {
      data.append("file", eventtarget.files[0]);
      data.append("eventId", "" + props.eventId);
      data.append("purpose", "importfile");
      data.append("noResize", "true");

      fetch("/api/files/upload", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("jwt"),
        },
        body: data,
      })
        .then((response) => response.json())
        .then((jsonresponse) => {
          // because it's fileupload & browsers don't actually understand json response then
          if (!jsonresponse.sucess && !jsonresponse.id) {
            jsonresponse = JSON.parse(jsonresponse);
          }

          let settings: ImportSettings = {
            fileId: jsonresponse.id,
            start: 0,
            sheetNr: 0,
            limit: 10,
            columnMapping: {},
          };

          formik.setFieldValue("fileId", jsonresponse.id);
          formik.setFieldValue("start", 0);
          setColumnMapping({});
          setSheet(0);
          loadSheets(settings);
          loadPreview(settings);
          //props.onUploadComplete(jsonresponse);
        })
        .catch(() => {
          //TODO: errormessage
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      fileId: 0,
      start: 0,
    },
    validationSchema: Yup.object({
      start: Yup.number()
        .transform((value) =>
          isNaN(value) || value === null || value === undefined ? 0 : value
        )
        .moreThan(-1),
    }),
    onSubmit: (values) => {
      fetch("/api/registrationimport/importData", {
        method: "post",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("jwt"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          start: values.start,
          limit: 0, // import all
          columnMapping: columnMapping,
          fileId: values.fileId,
          eventId: props.eventId,
          registered: props.registered,
          widgetId: props.widgetId,
          sheetNr: sheet,
        }),
      })
        .then((response) => response.json())
        .then((jsonresponse) => {
          props.onUploadComplete();
          setOpen(false);
          setMenuopen(false);
          setAnchorEl(null);
          setMappingKey("");
          setRows([]);
          setSheet(0);
          setColumnMapping({});
          setColumns([
            {
              field: "id",
              headerName: translations["import.nofields"],
              sortable: false,
            },
          ]);
          formik.setSubmitting(false);
          formik.resetForm();
        });
    },
  });

  const colorSheet = (index: number) => {
    if (sheet === index) {
      return "primary";
    }
    return "default";
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={() => {
          setOpen(true);
        }}
      >
        {translations["import.title"]}
      </Button>
      <Dialog
        className="window"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        scroll="body"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>{translations["import.title"]}</DialogTitle>
        <DialogContent dividers={false}>
          <form>
            <Box flex="1" display="flex" flexDirection="column" gap={1} p={2}>
              <Box display="flex" flexDirection={"row"} className="fieldlabel">
                <Box width="260px" flexShrink={0}>
                  <Typography>{translations["import.file"]}</Typography>
                </Box>
                <input
                  style={{ display: "none" }}
                  id="filebtn"
                  accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  type="file"
                  onChange={(e) => {
                    uploadFn(e);
                  }}
                />
                <label htmlFor="filebtn">
                  <Button variant="contained" component="span">
                    <UploadIcon /> {translations["import.upload"]}
                  </Button>
                </label>
              </Box>
              <Box display="flex" flexDirection={"row"} className="fieldlabel">
                <Box width="260px" flexShrink={0}>
                  <Typography>{translations["import.sheet"]}</Typography>
                </Box>
                <Box
                  //key={sheets.length}
                  display="flex"
                  flexDirection={"row"}
                  flexWrap="wrap"
                  gap={1}
                >
                  {sheets.length === 0 ? translations["import.nofile"] : null}
                  {sheets.map((sheetname, index) => {
                    return (
                      <Chip
                        key={index}
                        onClick={() => {
                          if (sheet !== index) {
                            setSheet(index);
                            setColumnMapping({});
                            formik.setFieldValue("start", 0);

                            let settings: ImportSettings = {
                              fileId: formik.values.fileId,
                              start: 0,
                              limit: 10,
                              sheetNr: index,
                              columnMapping: {},
                            };

                            loadPreview(settings);
                          }
                        }}
                        color={colorSheet(index)}
                        label={sheetname}
                      />
                    );
                  })}
                </Box>
              </Box>
              <Box display="flex" flexDirection={"row"} className="fieldlabel">
                <Box width="260px" flexShrink={0}>
                  <Typography>{translations["import.skiprows"]}</Typography>
                </Box>
                <TextField
                  size="small"
                  error={Boolean(formik.touched.start && formik.errors.start)}
                  {...formik.getFieldProps("start")}
                ></TextField>
              </Box>
              <DataGrid
                sx={{ flex: 1, minHeight: 300 }}
                rows={rows.filter((row) => {
                  return row.id >= (formik.values.start || 0);
                })}
                columns={columns}
                localeText={gridTranslations}
              ></DataGrid>
            </Box>
          </form>
          <Menu
            id="mappingmenu"
            anchorEl={anchorEl}
            open={menuopen}
            onClose={() => {
              setMenuopen(false);
            }}
          >
            {props.registrationFields.map((registrationField, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={() => {
                    handleMenuChoice(registrationField);
                  }}
                >
                  {
                    registrationField.translations[
                      registrationField.defaultLanguage
                    ].title
                  }
                </MenuItem>
              );
            })}
            <MenuItem
              onClick={() => {
                handleMenuChoice(null);
              }}
            >
              -
            </MenuItem>
          </Menu>
        </DialogContent>
        <SaveBar formik={formik} manualSubmit={true} />
      </Dialog>
    </>
  );
}

export default ContactImportControl;
