import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import { useField } from "formik";
import { MyCheckBoxInputProps } from "../../../../../../templates/Interfaces";
import OptionContainer from "./OptionContainer";
import FileSelectModal from "../../modals/FileSelectModal";
import { getTranslations } from "../../../../../../../translations/Translations";
import ClearIcon from "@mui/icons-material/Clear";



export const VideoSrcSelector = (props: MyCheckBoxInputProps) => {
    const translations = getTranslations();
    const { label, name } = props;
    const [field, meta, helpers] = useField(name);
    const [videoThumbSrc, , videoThumbSrcHelpers] = useField('jsonConfig.videoThumbSrc')
    const [showModal, setShowModal] = useState<boolean>(false);

    const onFileClear = () => {
        helpers.setValue('');
        videoThumbSrcHelpers.setValue('');
    }

    const onFileSelect = (selectedVideoObject: {url: string, videoSrc: string}) => {
        if (selectedVideoObject) {
            helpers.setValue(selectedVideoObject.videoSrc)
            videoThumbSrcHelpers.setValue(selectedVideoObject.url)
        }
    }

    return (
        <>
            <OptionContainer sx={{ flexDirection: 'column', alignContent: 'start'}}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1, width: '100%' }}>
                    <Typography sx={{ flex: 1 }} mr={'1rem'}>{label}</Typography>
                    <Button onClick={() => { setShowModal(true) }}>{translations['event.website.options.videoSelect']}</Button>
                </Box>
                {videoThumbSrc.value !== '' ? (<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1, width: '100%', justifyContent: 'space-between' }}>
                    <Box mt={2} sx={{ maxHeight: '100px', maxWidth: '100px' }} component='img' src={videoThumbSrc.value}></Box>
                    <Button onClick={onFileClear}><ClearIcon /></Button>
                </Box>) : null}
            </OptionContainer>

            <FileSelectModal type={'video'} open={showModal} onSelectImage={onFileSelect} closeModal={() => {
                setShowModal(false)
            }} />
        </>
    );
};

export default VideoSrcSelector;

