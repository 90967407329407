import React, { useEffect, useState } from "react";
import {
  Box,
  ButtonBase,
  Checkbox,
  Chip,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import ListIcon from "@mui/icons-material/List";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PieChartIcon from "@mui/icons-material/PieChart";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import MailIcon from "@mui/icons-material/Mail";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import PhotoIcon from "@mui/icons-material/Photo";
import ApartmentIcon from "@mui/icons-material/Apartment";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import EuroIcon from "@mui/icons-material/Euro";
import Sharednotes from "../../../components/Sharednotes";
import LanguageIcon from "@mui/icons-material/Language";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import IconButton from "@mui/material/IconButton";
import PersonIcon from "@mui/icons-material/Person";
import { Category, EventRouterResponse } from "../../../interfaces/Interfaces";
import { useNavigate, useRouteLoaderData } from "react-router-dom";
import EventTodoList from "./EventTodoList";
import { getTranslations } from "../../../translations/Translations";
import { getBaseHref } from "../../../helpers/Helpers";

function EventDashboard() {
  const translations = getTranslations();
  const { event } = useRouteLoaderData("event") as EventRouterResponse;
  const websiteurl = getBaseHref(window.location.href) + `/event/${event.id}`
  const [registrationCount, setRegistrationCount] = useState(0);
  const [categories, setCategories] = useState<Category[]>([]);
  const navFn = useNavigate();

  useEffect(() => {
    const controller = new AbortController();
    let params: URLSearchParams = new URLSearchParams({
      filter: JSON.stringify([{ property: "eventId", value: event.id }]),
    });
    let url = "/api/registrations?" + params.toString();
    fetch(url, {
      signal: controller.signal,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setRegistrationCount(result.total);
      })
      .catch((err: DOMException) => {
        if (err.name === "AbortError") {
        }
      });
    fetch("/api/categories", {
      signal: controller.signal,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setCategories(result.data);
      })
      .catch((err: DOMException) => {
        if (err.name === "AbortError") {
        }
      });
    return () => {
      controller.abort();
    };
  }, [event.id]);

  /*useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      let params = new URLSearchParams({
        filter: JSON.stringify([{ property: "eventId", value: event.id }]),
      });
      try {
        const registrationResponse = await fetch(
          "/api/registrations?" + params.toString(),
          {
            signal: controller.signal,
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + sessionStorage.getItem("jwt"),
            },
          }
        );
        const registrationResult = await registrationResponse.json();

        const categoriesResponse = await fetch("/api/categories", {
          signal: controller.signal,
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("jwt"),
          },
        });
        const categoriesResult = await categoriesResponse.json();

        // place them together = less refresh
        setRegistrationCount(registrationResult.total);
        setCategories(categoriesResult.data);
      } catch (error) {
        // Handle errors here
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    return () => {
      // Cleanup function
      controller.abort();
    };
  }, [event.id]);*/

  const getColor = (primary: boolean): "default" | "primary" => {
    if (primary) {
      return "primary";
    }
    return "default";
  };

  const colorCategory = (categoryId: number): "default" | "primary" => {
    let found = false;
    event.categories.forEach((cat) => {
      if (cat.id === categoryId) {
        found = true;
      }
    });

    if (found) {
      return "primary";
    }
    return "default";
  };

  const colorEventType = (eventType: string): "default" | "primary" => {
    if (event.type === eventType) {
      return "primary";
    }
    return "default";
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      position="relative"
      flex={1}
      overflow="scroll"
    >
      <Box display="flex" flexDirection="row" p={2} gap={2}>
        <Box display="flex" flexDirection="column" gap={1}>
          <ButtonBase
            className="dashboardButton"
            onClick={() => {
              navFn("/admin/events/" + event.id + "/details", {
                replace: false,
              });
            }}
          >
            <div className="stackedIcon">
              <CalendarTodayIcon />
              <Brightness1Icon className="modifier modifierbg" />
              <ModeEditIcon className="smallermodifier" htmlColor={"#FFF"} />
            </div>
            <Typography>{translations["eventdashboard.adddetails"]}</Typography>
          </ButtonBase>
          <ButtonBase
            className="dashboardButton"
            onClick={() => {
              navFn("/admin/events/" + event.id + "/sessions", {
                replace: false,
              });
            }}
          >
            <WatchLaterIcon />
            <Typography>{translations["eventdashboard.sessions"]}</Typography>
          </ButtonBase>
          {event.location ? (
            <ButtonBase
              className="dashboardButton"
              onClick={() => {
                navFn("/admin/events/" + event.id + "/details/locations", {
                  replace: false,
                });
              }}
            >
              <ApartmentIcon />
              <Typography>{translations["eventdashboard.location"]}</Typography>
            </ButtonBase>
          ) : null}
          {event.catering ? (
            <ButtonBase
              className="dashboardButton"
              onClick={() => {
                navFn("/admin/events/" + event.id + "/details/catering", {
                  replace: false,
                });
              }}
            >
              <RestaurantIcon />
              <Typography>{translations["eventdashboard.catering"]}</Typography>
            </ButtonBase>
          ) : null}
          <ButtonBase
            className="dashboardButton"
            onClick={() => {
              navFn("/admin/events/" + event.id + "/contacts", {
                replace: false,
              });
            }}
          >
            <PersonIcon />
            <Typography>{translations["eventdashboard.contacts"]}</Typography>
          </ButtonBase>
          <ButtonBase
            className="dashboardButton"
            onClick={() => {
              navFn("/admin/events/" + event.id + "/contacts/registrations", {
                replace: false,
              });
            }}
          >
            <ListIcon />
            <Typography>
              {translations["eventdashboard.registrations"]}
            </Typography>
          </ButtonBase>
          <ButtonBase
            className="dashboardButton"
            onClick={() => {
              navFn("/admin/events/" + event.id + "/budget", {
                replace: false,
              });
            }}
          >
            <EuroIcon />
            <Typography>{translations["eventdashboard.accounting"]}</Typography>
          </ButtonBase>
          <ButtonBase
            className="dashboardButton"
            onClick={() => {
              navFn("/admin/events/" + event.id + "/mailings", {
                replace: false,
              });
            }}
          >
            <MailIcon />
            <Typography>
              {translations["eventdashboard.sendmailing"]}
            </Typography>
          </ButtonBase>
          <ButtonBase
            className="dashboardButton"
            onClick={() => {
              navFn("/admin/events/" + event.id + "/statistics", {
                replace: false,
              });
            }}
          >
            <PieChartIcon />
            <Typography>{translations["eventdashboard.statistics"]}</Typography>
          </ButtonBase>
          <ButtonBase
            className="dashboardButton"
            onClick={() => {
              navFn("/admin/events/" + event.id + "/media", {
                replace: false,
              });
            }}
          >
            <PhotoIcon />
            <Typography>{translations["eventdashboard.mediapages"]}</Typography>
          </ButtonBase>
        </Box>
        <Box display="flex" flexDirection="column" gap={1} flex={1}>
          <EventTodoList eventId={event.id} />
          <Box
            display="flex"
            flexDirection="column"
            className="ui-widget"
            p={1}
          >
            <Box className="titlebar">
              <Typography>{translations["eventdashboard.view"]}</Typography>
            </Box>
            <Box className="list" p={2}>
              <LanguageIcon />{" "}
              <Link target={"_blank"} p={3} href={websiteurl} padding={0}>
                {websiteurl}
              </Link>
            </Box>
            <Box className="bottombar"></Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={1} flex={1}>
          <Sharednotes eventId={event.id} />
          <Box
            display="flex"
            flexDirection="column"
            className="ui-widget"
            p={1}
          >
            <Box className="titlebar">
              <Typography>
                {translations["eventdashboard.quickinfo"]}
              </Typography>
            </Box>
            <Box
              className="list"
              p={2}
              gap={1}
              display="flex"
              flexDirection={"column"}
            >
              <Box gap={1} display="flex" flexDirection={"row"}>
                <Box flexWrap="nowrap" flexShrink={0} className="fieldlabel">
                  <Typography>
                    {translations["eventdashboard.eventwith"]}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection={"row"}
                  flexWrap="wrap"
                  gap={1}
                >
                  <Chip
                    label={translations["eventdashboard.catering"]}
                    color={getColor(event.catering)}
                  />
                  <Chip
                    label={translations["eventdashboard.partner"]}
                    color={getColor(event.partner)}
                  />
                  <Chip
                    label={translations["eventdashboard.bill"]}
                    color={getColor(event.bill)}
                  />
                  <Chip
                    label={translations["eventdashboard.venue"]}
                    color={getColor(event.venue)}
                  />
                  <Chip
                    label={translations["eventdashboard.educationtime"]}
                    color={getColor(event.educationTime)}
                  />
                </Box>
              </Box>
              <Box display={"flex"} flexDirection="row">
                <Box flexWrap="nowrap" flexShrink={0} className="fieldlabel">
                  <Typography>
                    {translations["eventdashboard.blocker"]}
                  </Typography>
                </Box>
                <Box>
                  <Checkbox checked={event.blocker} />
                </Box>
              </Box>
              <Box display={"flex"} flexDirection="row">
                <Box
                  flexWrap="nowrap"
                  flexShrink={0}
                  width={"90px"}
                  className="fieldlabel"
                >
                  <Typography>
                    {translations["eventdashboard.category"]}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection={"row"}
                  flexWrap="wrap"
                  gap={1}
                >
                  {categories.map((value, index) => {
                    return (
                      <Chip
                        label={value.translations[value.defaultLanguage].title}
                        key={index}
                        color={colorCategory(value.id)}
                      />
                    );
                  })}
                </Box>
              </Box>
              <Box display={"flex"} flexDirection="row">
                <Box
                  flexWrap="nowrap"
                  flexShrink={0}
                  width={"90px"}
                  className="fieldlabel"
                >
                  <Typography>{translations["eventdashboard.type"]}</Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection={"row"}
                  flexWrap="wrap"
                  gap={1}
                >
                  <Chip
                    label={translations["WEBINAR"]}
                    color={colorEventType("WEBINAR")}
                  />
                  <Chip
                    label={translations["SEMINAR"]}
                    color={colorEventType("SEMINAR")}
                  />
                  <Chip
                    label={translations["TRAINING"]}
                    color={colorEventType("TRAINING")}
                  />
                  <Chip
                    label={translations["WORKSHOP"]}
                    color={colorEventType("WORKSHOP")}
                  />
                  <Chip
                    label={translations["PRESENTATION"]}
                    color={colorEventType("PRESENTATION")}
                  />
                </Box>
              </Box>
              <Box display={"flex"} flexDirection="row">
                <Box
                  width={"90px"}
                  flexWrap="nowrap"
                  flexShrink={0}
                  className="fieldlabel"
                >
                  <Typography>
                    {translations["eventdashboard.notes"]}
                  </Typography>
                </Box>
                <Box flex={1}>
                  <TextField multiline={true} minRows={3} fullWidth={true} />
                </Box>
              </Box>
            </Box>
            <Box className="bottombar"></Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Box
            display="flex"
            flexDirection="column"
            width={"250px"}
            className="ui-widget"
            p={1}
          >
            <Box className="titlebar">
              <Typography>
                {translations["eventdashboard.eventstage"]}
              </Typography>
            </Box>
            <Box className="list" p={2} flexDirection="row" display="flex">
              <LinearProgress
                variant="determinate"
                value={event.taskCompletion}
                sx={{ flex: 1, padding: "10px" }}
              />
            </Box>
            <Box className="bottombar"></Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            width={"250px"}
            className="ui-widget"
            p={1}
          >
            <Box
              className="titlebar"
              display="flex"
              flexDirection={"row"}
              justifyContent="space-between"
            >
              <Typography>Team</Typography>
              <IconButton>
                <PersonAddAlt1Icon />
              </IconButton>
            </Box>
            <List className="list">
              <ListItem>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary="Admin" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary="Member1" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary="Member2" />
              </ListItem>
            </List>
            <Box className="bottombar"></Box>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            className="ui-widget"
            p={1}
          >
            <Box className="titlebar">
              <Typography>
                {translations["eventdashboard.registrations"]}
              </Typography>
            </Box>
            <Box className="list" p={2} pb={6} textAlign={"center"}>
              {registrationCount} {translations["eventdashboard.participants"]}
            </Box>
            <Box className="bottombar"></Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default EventDashboard;
