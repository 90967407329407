import { useField } from "formik";
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, IconButton, List, ListItem, MenuItem, Paper, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import React, { useEffect, useRef, useState } from "react";
import { getTranslations } from "../../../../../translations/Translations";
import DraggableList from "../../../../../components/DraggableList/DraggableList";
import { Draggable, DropResult, ResponderProvided } from "react-beautiful-dnd";
import { ColorPicker, IColor, useColor } from "react-color-palette";
import "react-color-palette/css";
import RemoveIcon from '@mui/icons-material/Remove';
import useDebounce from "../../../../../helpers/useDebounce";
import { useRouteLoaderData } from "react-router-dom";
import { FieldRecord, WebpageRouterResponse } from "../../../../../interfaces/Interfaces";


const translations = getTranslations();

interface MyCheckBoxInputProps {
    label?: string;
    name: string;
    disabled?: boolean
}

export const MyCheckBox = (props: MyCheckBoxInputProps) => {
    const { label, disabled, ...inputProps } = props;
    const [field, meta] = useField({ ...inputProps, type: 'checkbox' });
    return (
        <FormControl error={!!(meta.touched && meta.error)}>
            <FormControlLabel control={<Checkbox {...field} disabled={disabled} />} label={<FormLabel sx={{ color: 'var(--color-primary)' }}>{label}</FormLabel>} />
            {meta.error && <FormHelperText>{meta.error}</FormHelperText>}
        </FormControl>
    )
}

export const OptionsList = ({ options }: { options: string[] }) => {
    const [currentValue, setCurrentValue] = useState<string>('');
    const [editableField, setEditableField] = useState<number | null>(null);
    const [currentOptions, setCurrentOptions] = useState<string[]>([]);
    const [field, meta, helpers] = useField({ name: 'options' });

    const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
        if (result.destination && result.destination !== null && typeof result.destination !== 'undefined') {
            const newArray = [...currentOptions];
            const [movedItem] = newArray.splice(result.source.index, 1);
            newArray.splice(result.destination!.index, 0, movedItem);
            setCurrentOptions(newArray);
            helpers.setValue(newArray);
        }
    }

    const clearActiveField = () => {
        setCurrentValue('');
        setEditableField(null);
    }

    useEffect(() => {
        setCurrentOptions(options);
    }, [options])

    return (
        <Paper sx={{ margin: '1rem 0' }}>
            <Box p={1} boxSizing={"border-box"}>
                <Box className="option-controls" sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', borderBottom: '1px solid rgba(0,0,0,0.3)', padding: '1rem 0' }}>
                    <Typography fontWeight="semi-bold" sx={{ maxWidth: 80 }} overflow={'hidden'}>{translations["registrationform.field.options"]}</Typography>
                    {editableField === null ? (<Button size="small" sx={{ minWidth: 0 }} onClick={() => {
                        setCurrentOptions((prev) => { return [...prev, translations['registrationform.field.options.newOption']] })
                        setEditableField(currentOptions.length);
                        setCurrentValue(translations['registrationform.field.options.newOption']);
                    }}><AddIcon /></Button>) : null}
                </Box>
                <DraggableList listSx={{
                    display: 'flex',
                    flexDirection: 'column',
                    '& > div': {
                        borderBottom: '1px solid rgba(0,0,0,0.1)'
                    },
                    '& > div:last-child': {
                        borderBottom: 0
                    }
                }} items={currentOptions} onDragEnd={onDragEnd}>{(helper) => {
                    const { getIndex, getItem, getDragIcon } = helper;
                    const index = getIndex();
                    const option = getItem();
                    if (editableField === index) {
                        return (
                            <Box key={index} sx={{ marginTop: '1rem' }}><TextField autoFocus={true} value={currentValue} onChange={(e) => {
                                setCurrentValue(e.target.value)
                            }}></TextField>
                                <Box display="flex" flexDirection="row" justifyContent="space-between"><Button size="small" onClick={(e) => {
                                    const newOptions = [...options];
                                    newOptions[index] = currentValue;
                                    helpers.setValue(newOptions);
                                    clearActiveField();
                                }}><CheckIcon />
                                </Button><Button onClick={(e) => {
                                    clearActiveField();
                                }}><ClearIcon /></Button>
                                </Box>
                            </Box>
                        )
                    }
                    return (
                        <Box sx={{ display: 'flex', overflow: 'hidden', padding: '1rem 0' }} key={index}>
                            <Typography sx={{ flex: '1', maxWidth: 80, overflow: 'hidden', textOverflow: 'ellipsis' }}>{option}</Typography>
                            {editableField === null ? (<Box display='flex'>
                                <Button size="small" sx={{ minWidth: 0, overflow: 'scrollable', flex: '1', margin: 0, padding: 0 }} onClick={() => {
                                    setEditableField(index);
                                    setCurrentValue(option);
                                }}><EditIcon />
                                </Button>
                                <Button
                                    size="small"
                                    sx={{ minWidth: 0, overflow: 'scrollable', flex: '1', margin: 0, padding: 0 }}
                                    onClick={() => {
                                        // show confirm
                                        const newOptions = [...options.slice(0, index), ...options.slice(index + 1)];
                                        helpers.setValue(newOptions);
                                    }}><DeleteIcon />
                                </Button>
                                {getDragIcon()}
                            </Box>) : null}
                        </Box>
                    )
                }}</DraggableList>
            </Box>
        </Paper>
    )
}

export const MyNumberTextField = (props: MyCheckBoxInputProps) => {
    const { label, ...inputProps } = props;
    const [field, meta] = useField(inputProps);
    return (
        <TextField
            sx={[{ marginBottom: '1rem' }, { input: { borderRadius: '2px', backgroundColor: 'common.white', paddingLeft: 0, paddingRight: 0, } }]}
            label={label}
            type="number"
            variant="filled"
            className="text-input"
            InputLabelProps={{ variant: 'filled', sx: { transform: 'none' } }}
            {...field}
            {...inputProps}
            error={!!(meta.touched && meta.error)}
            helperText={meta.touched && meta.error ? meta.error : ''}
        />
    );
};

export const MyTextField = (props: MyCheckBoxInputProps) => {
    const { label, ...inputProps } = props;
    const [field, meta] = useField(inputProps);
    return (
        <TextField
            sx={[{ marginBottom: '1rem' }, { input: { borderRadius: '2px', backgroundColor: 'common.white', paddingLeft: 0, paddingRight: 0, } }]}
            label={label}
            variant="filled"
            className="text-input"
            fullWidth
            InputLabelProps={{ variant: 'filled', sx: { transform: 'none' } }}
            {...field}
            {...inputProps}
            error={!!(meta.touched && meta.error)}
            helperText={meta.touched && meta.error ? meta.error : ''}
        />
    );
};

export const MyTextAreaField = (props: MyCheckBoxInputProps) => {
    const { label, ...inputProps } = props;
    const [field, meta] = useField(inputProps);
    return (
        <TextField
            sx={[{ marginBottom: '1rem' }, { input: { borderRadius: '2px', backgroundColor: 'common.white', paddingLeft: 0, paddingRight: 0, } }]}
            label={label}
            variant="filled"
            className="text-input"
            fullWidth
            multiline
            maxRows={4}
            InputLabelProps={{ variant: 'filled', sx: { transform: 'none' } }}
            {...field}
            {...inputProps}
            error={!!(meta.touched && meta.error)}
            helperText={meta.touched && meta.error ? meta.error : ''}
        />
    );
};

interface ParentSelectorProps {
    label: string;
    name: string;
    widgetId: number
}

export const ParentSelector = (props: ParentSelectorProps) => {
    const { fields } = useRouteLoaderData(
        "webpage"
    ) as WebpageRouterResponse;
    const { label, name, widgetId } = props;
    const [field, , helpers] = useField(name);
    const [parentFieldValue, , pHelpers] = useField('jsonConfig.parentValue');
    const [parentMode, , pmHelpers] = useField('jsonConfig.parentMode')

    const [elementSelected, setElementSelected] = useState<string>(field.value ? '1' : '0');

    useEffect(() => {
        setElementSelected(field.value ? '1' : '0')
    }, [field.value])


    const handleChange = (event: SelectChangeEvent) => {
        helpers.setValue(event.target.value);
        pHelpers.setValue('');
        pmHelpers.setValue('');
    };

    const selectedField = fields.find((myField) => myField.id === Number(field.value))

    console.log('selectedField', selectedField);

    const fieldMapping = (field: FieldRecord) => {
        //add fields here if you want to show them in the parent selecotor
        console.log('fieldtypes', field.type);
        switch (field.type) {
            case 'combobox':
            case 'radiobuttons':
                return field.translations[field.defaultLanguage].options!.length > 0;
                break;
            case 'checkbox':
                return true;
                break;
            case 'textfield':
            case 'telephonefield':
            case 'emailfield':
            case 'textarea':
            case 'countryfield':
            case 'datefield':
            case 'birthdatefield':
                return false;
            default:
                return false;
        }
    }

    const renderFieldByType = (selectedField: FieldRecord) => {
        switch (selectedField.type) {
            case 'combobox':
            case 'radiobuttons':
                return (
                    <>
                        <Box mb='1rem'>{translations["registrationform.field.visibility.combobox.optionIs"]}</Box>
                        <Select value={parentFieldValue.value} onChange={(event: SelectChangeEvent) => {
                            pHelpers.setValue(event.target.value);
                        }}>
                            {selectedField.translations[selectedField.defaultLanguage].options?.map((option: string, index) => (
                                <MenuItem value={option} key={index}>{option}</MenuItem>
                            ))}
                        </Select>
                        {parentFieldValue.value !== '' && (
                            <Select value={parentMode.value} onChange={(e) => {
                                pmHelpers.setValue(e.target.value);
                            }}>
                                <MenuItem value={'hide'}>{translations["hide"]}</MenuItem>
                                <MenuItem value={'show'}>{translations["show"]}</MenuItem>
                            </Select>)
                        }

                    </>
                );
            case 'checkbox':
                return (
                    <>
                        <Box mb='1rem'>{translations["registrationform.field.visibility.checkbox.whenFieldIs"]}</Box>
                        <Select value={parentFieldValue.value} onChange={(event: SelectChangeEvent) => {
                            pHelpers.setValue(event.target.value);
                        }}>
                            <MenuItem value={'0'}>{translations["registrationform.field.visibility.checkbox.checked"]}</MenuItem>
                            <MenuItem value={'1'}>{translations["registrationform.field.visibility.checkbox.unchecked"]}</MenuItem>
                        </Select>
                        {parentFieldValue.value !== '' && (
                            <Select value={parentMode.value} onChange={(e) => {
                                pmHelpers.setValue(e.target.value);
                            }}>
                                <MenuItem value={'hide'}>{translations["hide"]}</MenuItem>
                                <MenuItem value={'show'}>{translations["show"]}</MenuItem>
                            </Select>)
                        }

                    </>
                )
            case 'textfield':
            case 'telephonefield':
            case 'emailfield':
            case 'textarea':
            case 'countryfield':
            case 'datefield':
            case 'birthdatefield':
            default:
                return null; // Or handle unknown types as per your requirement
        }
    };

    return (
        <>
            <Box mb='1rem'>{translations["registrationform.field.visibility"]}</Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Select value={elementSelected} onChange={(e) => {
                    setElementSelected(e.target.value)
                }}>
                    <MenuItem value={'0'}>{translations["registrationform.field.visibility.always"]}</MenuItem>
                    <MenuItem value={'1'}>{translations["registrationform.field.visibility.otherField"]}</MenuItem>
                </Select>
                {elementSelected === '1' && (
                    <Select sx={{marginBottom: '1rem'}} value={field.value} onChange={handleChange}>
                        {fields.filter(field => (field.widgetId === widgetId && fieldMapping(field))).map((field, index) => <MenuItem key={index} value={field.id.toString()}>{field.id}: {field.type} </MenuItem>)}
                    </Select>
                )}
                {elementSelected === '1' && field.value !== '' && selectedField && (
                    renderFieldByType(selectedField)
                )}
            </Box>
        </>
    )
}



export const MyColorPicker = (props: MyCheckBoxInputProps) => {

    const { label, ...inputProps } = props;
    const [field, meta, helpers] = useField(inputProps);
    const initialColor = field.value ? field.value : '';
    const [color, setColor] = useColor(initialColor);
    const [showPicker, setShowPicker] = useState<boolean>(false);
    const bouncedColor = useDebounce(color, 500);

    const colorToRGBAString = (colorObj: any) => {
        const { r, g, b, a } = colorObj;
        return `rgba(${r}, ${g}, ${b}, ${a})`;
    };

    useEffect(() => {
        if (showPicker) {
            helpers.setValue(colorToRGBAString(color.rgb));
        }
    }, [bouncedColor, showPicker]);

    return (
        <>

            <Box mb={2} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box>{label}</Box>
                {showPicker ? (
                    <IconButton sx={{ cursor: 'pointer' }} onClick={() => { setShowPicker(false) }}>
                        <RemoveIcon />
                    </IconButton>) : (
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        {field.value ? (<><Button onClick={() => { helpers.setValue('') }}>
                            <ClearIcon />
                        </Button>
                            <Paper onClick={() => { setShowPicker(true) }} sx={{ width: '20px', height: '20px', backgroundColor: color.hex, cursor: 'pointer' }}></Paper></>) : (
                            <><Button onClick={() => { setShowPicker(true) }}>Choose color</Button></>
                        )}

                    </Box>)}
            </Box>

            {showPicker ? <ColorPicker hideInput={["rgb", "hsv"]} color={color} onChange={(setColor)} /> : null}
        </>
    );
};