import React, { useState } from "react";
import {
  Box,
  Button,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { getTranslations } from "../../../../translations/Translations";
import {
  EventRouterResponse,
  PartnerRecord,
  PartnersRouterResponse,
} from "../../../../interfaces/Interfaces";
import { useRevalidator, useRouteLoaderData } from "react-router-dom";
import PartnerInfoForm from "./PartnerInfoForm";
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd";
import { DirtyFormDialog } from "../../../../components/DirtyFormDialog";

function PartnerInfoTab() {
  const translations = getTranslations();
  const { event } = useRouteLoaderData("event") as EventRouterResponse;
  const { partners } = useRouteLoaderData("partners") as PartnersRouterResponse;
  const [partner, setPartner] = useState<PartnerRecord | null>(partners[0]);
  const revalidator = useRevalidator();
  const [dirty, setDirty] = useState<boolean>(false);
  const [nextPartner, setNextPartner] = useState<PartnerRecord | null>(null);

  const onDragEnd = (result: any) => {
    if (!result.destination) return;
    let data = partners;
    let promises: Promise<Response>[] = [];

    // reorder
    let [draggedObj] = data.splice(result.source.index, 1);
    data.splice(result.destination.index, 0, draggedObj);
    data.forEach((item, index) => {
      item.sortorder = index + 1;
      // save each item
      promises.push(
        fetch("/api/partners/" + item.id, {
          method: "put",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("jwt"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify(item),
        })
      );
    });

    Promise.all(promises).then(() => {
      revalidator.revalidate();
    });
  };

  const newpartner = () => {
    fetch("/api/partners", {
      method: "post",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("jwt"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        defaultLanguage: event.defaultLanguage,
        language: event.defaultLanguage,
        name: "Neues Partner",
        eventId: event.id,
        sortorder: partners.length + 1,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        let p: PartnerRecord = result.data;
        setPartner(p);
        revalidator.revalidate();
      });
  };

  const getButtonClass = (id: number): string => {
    if (partner?.id === id) {
      return "cliclalistitembutton active";
    }
    return "cliclalistitembutton";
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      className="collectionedit"
      flex="1"
      overflow="hidden"
    >
      <Box width={"260px"} className="list">
        <Box p={1}>
          <Button variant="contained" onClick={newpartner}>
            {translations["partnertab.add"]}
          </Button>
        </Box>
        <DirtyFormDialog
          open={nextPartner !== null}
          continue={() => {
            setPartner(nextPartner);
            setNextPartner(null);
          }}
          cancel={() => {
            setNextPartner(null);
          }}
        />
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="partners" type="partner">
            {(provided) => (
              <List
                className="cliclalist"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {partners.map((p: PartnerRecord, index: number) => {
                  return (
                    <Draggable
                      key={p.id}
                      draggableId={"partner-" + p.id}
                      index={index}
                    >
                      {(provided) => (
                        <ListItemButton
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={getButtonClass(p.id)}
                          onClick={() => {
                            if (dirty) {
                              setNextPartner(p);
                            } else {
                              setPartner(p);
                            }
                          }}
                        >
                          <ListItemText primary={p.name} />
                        </ListItemButton>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
      {!partner ? (
        <Box flex="1" p={2}>
          <Typography>{translations["partnertab.empty"]}</Typography>
        </Box>
      ) : null}
      {!!partner ? (
        <PartnerInfoForm
          key={partner.id}
          partner={partner}
          event={event}
          setDirty={setDirty}
          onDelete={() => {
            fetch("/api/partners/" + partner.id, {
              method: "delete",
              headers: {
                Accept: "application/json",
                Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                "Content-Type": "application/json",
              },
            }).then(() => {
              if (partner.id === partners[0].id) {
                setPartner(partners[1]);
              } else {
                setPartner(partners[0]);
              }
              revalidator.revalidate();
            });
          }}
        />
      ) : null}
    </Box>
  );
}

export default PartnerInfoTab;
