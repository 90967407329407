import React from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, TimeField } from "@mui/x-date-pickers";
import { getTranslations } from "../../../../translations/Translations";
import {
  EventRouterResponse,
  IRecord,
} from "../../../../interfaces/Interfaces";
import { useRouteLoaderData, useRevalidator } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import SaveBar from "../../../../components/SaveBar";
import {
  formatDateTime,
  isValidDateValue,
  parseDateTime,
} from "../../../../helpers/DateFunctions";
import { DisplayErrors } from "../../../../components/DisplayErrors";

function RegistrationSettingsTab() {
  const translations = getTranslations();
  const { event } = useRouteLoaderData("event") as EventRouterResponse;
  const revalidator = useRevalidator();

  const getFlatObj = (obj: IRecord) => {
    return {
      id: obj.id,
      registrationStart: parseDateTime(obj.registrationStart),
      registrationEnd: parseDateTime(obj.registrationEnd),
      maxRegistrations: obj.maxRegistrations || "",
      registrationPassword: obj.registrationPassword || "",
      adminapproval: obj.adminapproval,
      attendancecheck: obj.attendancecheck,
      waitinglist: obj.waitinglist,
    };
  };

  const getSubmissableData = (obj: IRecord) => {
    return {
      ...obj,
      registrationStart: formatDateTime(obj.registrationStart),
      registrationEnd: formatDateTime(obj.registrationEnd),
    };
  };

  const formik = useFormik({
    initialValues: getFlatObj(event),
    validationSchema: Yup.object({
      registrationStart: Yup.date()
        .typeError(translations["errors.invaliddate"])
        .required(translations["errors.requiredfield"]),
      registrationPassword: Yup.string().notRequired(),
      adminapproval: Yup.boolean(),
      attendancecheck: Yup.boolean(),
      waitinglist: Yup.boolean(),
      maxRegistrations: Yup.number()
        .typeError(translations["errors.invalidnumber"])
        .notRequired()
        .nullable()
        .positive()
        .integer(translations["errors.invalidnumber"]),
      registrationEnd: Yup.date()
        .typeError(translations["errors.invaliddate"])
        .required(translations["errors.requiredfield"])
        .when(["registrationStart"], ([registrationStart], schema) =>
          isValidDateValue(registrationStart)
            ? schema.min(
                registrationStart,
                translations[
                  "registrationsettingstab.errors.startdatebeforeenddate"
                ]
              )
            : schema
        ),
    }),
    onSubmit: (values) => {
      fetch("/api/events/" + event.id + "/", {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("jwt"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(getSubmissableData(values)),
      })
        .then((res) => res.json())
        .then((jsondata) => {
          if (jsondata.success) {
            revalidator.revalidate();
          }
        })
        .finally(() => {
          formik.setSubmitting(false);
          formik.resetForm({
            values: values,
          });
        });
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{ display: "flex", flexGrow: 1 }}
    >
      <Box display="flex" flexDirection="column" position="relative" flex={1}>
        <Box display="flex" flexDirection="row" flex={1}>
          <Box flex="1" display="flex" flexDirection="column" gap={1} p={2}>
            <Box display="flex" flexDirection={"row"}>
              <Box width="260px" flexShrink={0} className="fieldlabel">
                <Typography>
                  {translations["registrationsettingstab.beginregistration"]}
                </Typography>
              </Box>
              <FormControl fullWidth>
                <Box display="flex" flexDirection={"row"} gap={1}>
                  <DatePicker
                    format="dd.MM.yyyy"
                    onAccept={(eventorvalue) => {
                      formik.setFieldValue("registrationStart", eventorvalue);
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        name: "registrationStart",
                        onChange: (eventorvalue) => {
                          formik.setFieldValue(
                            "registrationStart",
                            eventorvalue
                          );
                        },
                        error: Boolean(formik.errors.registrationStart),
                        onBlur: formik.handleBlur,
                      },
                    }}
                    value={formik.values.registrationStart}
                  />
                  <TimeField
                    format="HH:mm"
                    slotProps={{
                      textField: {
                        size: "small",
                        name: "registrationStart",
                        onChange: (eventorvalue) => {
                          formik.setFieldValue(
                            "registrationStart",
                            eventorvalue
                          );
                        },
                        error: Boolean(formik.errors.registrationStart),
                        onBlur: formik.handleBlur,
                      },
                    }}
                    value={formik.values.registrationStart}
                  />
                </Box>
                <DisplayErrors
                  errors={formik.errors.registrationStart}
                  touched={Boolean(formik.touched.registrationStart)}
                  name="registrationStart"
                />
              </FormControl>
            </Box>
            <Box display="flex" flexDirection={"row"}>
              <Box width="260px" flexShrink={0} className="fieldlabel">
                <Typography>
                  {translations["registrationsettingstab.endregistration"]}
                </Typography>
              </Box>
              <FormControl fullWidth>
                <Box display="flex" flexDirection={"row"} gap={1}>
                  <DatePicker
                    format="dd.MM.yyyy"
                    onAccept={(eventorvalue) => {
                      formik.setFieldValue("registrationEnd", eventorvalue);
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        name: "registrationEnd",
                        onChange: (eventorvalue) => {
                          formik.setFieldValue("registrationEnd", eventorvalue);
                        },
                        error: Boolean(formik.errors.registrationEnd),
                        onBlur: formik.handleBlur,
                      },
                    }}
                    value={formik.values.registrationEnd}
                  />
                  <TimeField
                    format="HH:mm"
                    slotProps={{
                      textField: {
                        size: "small",
                        name: "registrationEnd",
                        onChange: (eventorvalue) => {
                          formik.setFieldValue("registrationEnd", eventorvalue);
                        },
                        error: Boolean(
                          formik.touched.registrationEnd &&
                            formik.errors.registrationEnd
                        ),
                        onBlur: formik.handleBlur,
                      },
                    }}
                    value={formik.values.registrationEnd}
                  />
                </Box>
                <DisplayErrors
                  errors={formik.errors.registrationEnd}
                  touched={Boolean(formik.touched.registrationEnd)}
                  name="registrationEnd"
                />
              </FormControl>
            </Box>
            <Box display="flex" flexDirection={"row"}>
              <Box width="260px" flexShrink={0} className="fieldlabel">
                <Typography>
                  {translations["registrationsettingstab.password"]}
                </Typography>
              </Box>
              <FormControl fullWidth>
                <TextField
                  size="small"
                  error={Boolean(formik.errors.registrationPassword)}
                  name="registrationPassword"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.registrationPassword}
                ></TextField>
                <DisplayErrors
                  errors={formik.errors.registrationPassword}
                  touched={Boolean(formik.touched.registrationPassword)}
                  name="registrationPassword"
                />
              </FormControl>
            </Box>
            <Box display="flex" flexDirection={"row"}>
              <Box width="260px" flexShrink={0} className="fieldlabel">
                <Typography>
                  {translations["registrationsettingstab.maxregistrations"]}
                </Typography>
              </Box>
              <FormControl>
                <TextField
                  size="small"
                  inputMode="numeric"
                  error={Boolean(formik.errors.maxRegistrations)}
                  name="maxRegistrations"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.maxRegistrations}
                ></TextField>
                <DisplayErrors
                  errors={formik.errors.maxRegistrations}
                  touched={Boolean(formik.touched.maxRegistrations)}
                  name="maxRegistrations"
                />
              </FormControl>
            </Box>
          </Box>
          <Box flex="1" display="flex" flexDirection="column" gap={1} p={2}>
            <Box display="flex" flexDirection={"row"}>
              <Box width="260px" flexShrink={0} className="fieldlabel">
                <Typography>
                  {translations["registrationsettingstab.registrationapproval"]}
                </Typography>
              </Box>
              <Box className="switchlabel">
                {translations["registrationsettingstab.registrationapprovalno"]}
              </Box>
              <Box flex={1}>
                <FormControlLabel
                  control={
                    <Switch
                      name="adminapproval"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.adminapproval}
                    />
                  }
                  label={
                    translations[
                      "registrationsettingstab.registrationapprovalyes"
                    ]
                  }
                />
              </Box>
            </Box>
            <Box display="flex" flexDirection={"row"}>
              <Box width="260px" flexShrink={0} className="fieldlabel">
                <Typography>
                  {translations["registrationsettingstab.attendancecheck"]}
                </Typography>
              </Box>
              <Box className="switchlabel">
                {translations["registrationsettingstab.attendancecheckno"]}
              </Box>
              <Box flex={1}>
                <FormControlLabel
                  control={
                    <Switch
                      name="attendancecheck"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.attendancecheck}
                    />
                  }
                  label={
                    translations["registrationsettingstab.attendancecheckyes"]
                  }
                />
              </Box>
            </Box>
            <Box display="flex" flexDirection={"row"}>
              <Box width="260px" flexShrink={0} className="fieldlabel">
                <Typography>
                  {translations["registrationsettingstab.waitinglist"]}
                </Typography>
              </Box>
              <Box className="switchlabel">
                {translations["registrationsettingstab.waitinglistno"]}
              </Box>
              <Box flex={1}>
                <FormControlLabel
                  control={
                    <Switch
                      name="waitinglist"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.waitinglist}
                    />
                  }
                  label={translations["registrationsettingstab.waitinglistyes"]}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <SaveBar formik={formik} />
      </Box>
    </form>
  );
}

export default RegistrationSettingsTab;
