import { CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../AuthProvider";

export function ProtectedRoute({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  let auth = useAuth();
  let location = useLocation();

  if (!auth?.user) {
    return <Navigate to="/logout" state={{ from: location }} replace={true} />;
  }

  return children;
}

export function ProtectedAdminRoute({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  let auth = useAuth();

  if (!auth?.user || (auth?.user.role.id !== 1 && auth?.user.role.id !== 2)) {
    return <Navigate to="/logout" replace={true} />;
  }

  return children;
}

export function LogoutRoute(): JSX.Element {
  let auth = useAuth();
  let navigate = useNavigate();

  useEffect(() => {
    auth?.logout();
  }, [auth]);

  useEffect(() => {
    if (!auth?.user) {
      navigate("/login", { replace: true });
    }
  }, [auth?.user, navigate]);

  // reloading
  return <CircularProgress color="inherit" />;
}
