import { Box, Typography } from "@mui/material"
import { ReactWidget, ReactWidgetWithStyles } from "../../../../../../interfaces/Interfaces"
import React from "react"
import withStandardProps from "./HOCs/withStandardStyles";


const TitleField = ({ widget, standardStyles }: ReactWidgetWithStyles) => {
    const config = widget.config || {};
    const textAlign = config['textAlign'];
    const fontSize = config['fontSize'];
    const fontColor = config['fontColor'];
    const styles: any = {};
    
    if (textAlign) {
        styles.textAlign = textAlign;
    }

    if (fontSize) {
        styles.fontSize = fontSize;
    }

    if (fontColor) {
        styles.color = fontColor;
    }
    
    if (widget.translations[widget.defaultLanguage].content) {
        return <Typography variant="h1primary" style={{...styles, ...standardStyles}}>{widget.translations[widget.defaultLanguage].content}</Typography>
    } else {
        return <Box>{widget.type}</Box>
    }
}

export default withStandardProps(TitleField);