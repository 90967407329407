import {
  Box,
  Checkbox,
  InputAdornment,
  TextField,
  experimentalStyled,
} from "@mui/material";
import React, { useState } from "react";
import {
  BudgetExpense,
  BudgetIncomeFixed,
  BudgetRouterResponse,
  EventRouterResponse,
} from "../../../../interfaces/Interfaces";
import { useRouteLoaderData } from "react-router-dom";
import { getTranslations } from "../../../../translations/Translations";

interface EventBudgetExpenseProps {
  expense: BudgetExpense;
}
function EventBudgetExpense(props: EventBudgetExpenseProps) {
  const translations = getTranslations();
  const [active, setActive] = useState<boolean>(props.expense.active);
  const [estimatedAmount, setEstimatedAmount] = useState<number | undefined>(
    props.expense.estimatedAmount
  );
  const [amount, setAmount] = useState<number | undefined>(
    props.expense.amount
  );
  const [nrOfTimes, setNrOfTimes] = useState<number | undefined>(
    props.expense.nrOfTimes
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      className={"budgetexpensegroup" + props.expense.groupNr}
    >
      <Box display="flex" flexDirection="row" gap={1}>
        <Box width={300}>
          <Checkbox
            checked={active}
            onChange={(event: React.ChangeEvent) => {}}
          />
          {props.expense.name}
        </Box>
        <TextField
          size="small"
          value={estimatedAmount}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">&euro;</InputAdornment>
            ),
          }}
        />
        <TextField
          size="small"
          value={amount}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">&euro;</InputAdornment>
            ),
          }}
        />
        {props.expense.useTimes ? (
          <>
            <TextField
              size="small"
              label={translations["budget.nr"]}
              value={nrOfTimes}
            />
          </>
        ) : null}
      </Box>
      {props.expense.useTimes ? (
        <Box display="flex" flexDirection="row" gap={1}>
          <Box width={300}>{translations["budget.total"]}</Box>
          <Box width={212}>{(nrOfTimes || 0) * (estimatedAmount || 0)}</Box>
          <Box width={212}>{(nrOfTimes || 0) * (amount || 0)}</Box>
        </Box>
      ) : null}
    </Box>
  );
}

function EventBudgetCalculation() {
  const { event } = useRouteLoaderData("event") as EventRouterResponse;
  const { budgetExpenses, budgetIncomes, budgetIncomeFixeds } =
    useRouteLoaderData("budget") as BudgetRouterResponse;
  const makeBudgetExpense = (
    id: number,
    name: string,
    groupNr: number,
    groupName: string,
    useTimes: boolean
  ) => {
    return {
      id: id,
      name: name,
      estimatedAmount: undefined,
      amount: undefined,
      nrOfTimes: undefined,
      useTimes: useTimes,
      active: true,
      eventId: event.id,
      sortorder: id,
      groupNr: groupNr,
    };
  };
  const expenses: BudgetExpense[] = [
    makeBudgetExpense(1, "Raummiete", 1, "", false),
    makeBudgetExpense(2, "Technikmiete", 1, "", false),
    makeBudgetExpense(3, "Deko", 1, "", false),
    makeBudgetExpense(4, "Sprecher", 1, "", false),
    makeBudgetExpense(5, "Referent", 1, "", false),
    makeBudgetExpense(6, "Teilnehmerpauschale", 1, "", false),
    makeBudgetExpense(7, "Schulungsmaterial", 1, "", false),
    makeBudgetExpense(8, "Marketingmaterial", 1, "", false),
    makeBudgetExpense(9, "Übernachtung Typ 1", 2, "", true),
    makeBudgetExpense(10, "Übernachtung Typ 2", 2, "", true),
    makeBudgetExpense(11, "Pausenverpflegung Pauschal", 2, "", false),
    makeBudgetExpense(12, "Pausenverpflegung pro Gast", 2, "", true),
    makeBudgetExpense(13, "Frühstuck pro Gast", 2, "", true),
    makeBudgetExpense(14, "Mitagessen pro Gast", 2, "", true),
    makeBudgetExpense(15, "Abendessen pro Gast", 2, "", true),
    makeBudgetExpense(16, "Übernachtung", 3, "Begleitperson", true),
    makeBudgetExpense(19, "Frühstuck pro Gast", 3, "Begleitperson", true),
    makeBudgetExpense(20, "Mitagessen pro Gast", 3, "Begleitperson", true),
    makeBudgetExpense(21, "Abendessen pro Gast", 3, "Begleitperson", true),
    makeBudgetExpense(22, "Gebühren Aktivität", 3, "Begleitperson", false),
    makeBudgetExpense(23, "Shuttledienst", 4, "", false),
    makeBudgetExpense(24, "Emfangstätigkeiten", 4, "", false),
    makeBudgetExpense(25, "Abendverantstaltung", 4, "", false),
  ];

  return (
    <Box
      display="flex"
      flexDirection="column"
      position="relative"
      flex={1}
      overflow="scroll"
    >
      <Box display="flex" flexDirection="row">
        <Box display="flex" flexDirection="column" gap={1} pt={1} pb={1}>
          <Box display="flex" flexDirection="row" gap={1}>
            <Box width={300}>&nbsp;</Box>
            <Box width={212}>Voranschlag</Box>
            <Box width={212}>Tatsächliche Kosten</Box>
          </Box>
          {expenses.map((expense) => (
            <EventBudgetExpense key={expense.id} expense={expense} />
          ))}
          <hr />
        </Box>
        <Box display="flex" flexDirection="column"></Box>
      </Box>
    </Box>
  );
}

export default EventBudgetCalculation;
