import { Checkbox, FormControl, FormControlLabel, FormHelperText, Grid } from "@mui/material";
import { ReactNode, useRef, useState, useEffect } from "react";
import { useField, } from "formik";
import React from "react";

const ScrollContainer = ({ noScroll, children }: { noScroll: boolean, children: ReactNode }) => {
  const [scrolledToBottom, setScrolledToBottom] = useState<boolean>(false);
  const gridRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const gridContainer = gridRef.current;
    if (gridContainer) {
      if (gridContainer.scrollTop + gridContainer.clientHeight >= gridContainer.scrollHeight) {
        setScrolledToBottom(true);
      }
    }
  };

  useEffect(() => {
    if (noScroll) {
      setScrolledToBottom(true)
    }
  },[])


  return (
    <>
      <Grid item ref={gridRef} onScroll={handleScroll} container sx={{ maxHeight: '200px', overflow: 'scroll', paddingBottom: '1rem' }}>
        {children}
      </Grid>
    </>
  );
};

export default ScrollContainer;