import React, { createContext, useCallback, useContext, useMemo } from "react";
import { AuthContextData, UserRecord } from "./interfaces/Interfaces";
import { useSessionStorage } from "./helpers/useSessionStorage";
export const AuthContext = createContext<AuthContextData | null>(null);

export const AuthContextProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const [user, setUser] = useSessionStorage("user", null);

  // call this function when you want to authenticate the user
  const login = useCallback(
    (data: UserRecord) => {
      //set the correct language
      console.log("data", data);
      document.documentElement.lang = "de";
      sessionStorage.setItem("jwt", data.jwt);
      setUser(data);
    },
    [setUser]
  );

  // call this function to sign out logged in user
  const logout = useCallback(() => {
    //remove the language
    document.documentElement.lang = "";
    sessionStorage.removeItem("jwt");
    setUser(null);
  }, [setUser]);

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user, login, logout]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
