import { Font } from "../interfaces/Interfaces";

export const fontList: Font[] = [
    { name: 'Alata', value: 'Alata, sans-serif', type: 'sans-serif', google: true },
    { name: 'Arial', value: 'Arial, sans-serif', type: 'sans-serif',},
    { name: 'Cardo', value: 'Cardo, sans-serif', type: 'sans-serif', google: true },
    { name: 'Josefin Sans', value: 'Josefin Sans, sans-serif', type: 'sans-serif', google: true },
    { name: 'Lato', value: 'Lato, sans-serif', type: 'sans-serif', google: true },
    { name: 'Times New Roman', value: 'Times New Roman, serif', type: 'serif' }
];
