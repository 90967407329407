import React from "react";
import { List, ListItemButton, Tooltip } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ListIcon from "@mui/icons-material/List";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PieChartIcon from "@mui/icons-material/PieChart";
import { CustomNavLink } from "./CustomNavLink";
import { getTranslations } from "../translations/Translations";
import SellIcon from "@mui/icons-material/Sell";
import KeyIcon from "@mui/icons-material/Key";

function LeftToolbar() {
  const translations = getTranslations();

  return (
    <List className="leftToolbar" sx={{ padding: 0 }}>
      <Tooltip title={translations["lefttoolbar.home"]} placement="right">
        <ListItemButton
          component={CustomNavLink}
          to="/admin/"
          sx={{ height: "60px" }}
        >
          <HomeIcon />
        </ListItemButton>
      </Tooltip>
      <Tooltip title={translations["lefttoolbar.events"]} placement="right">
        <ListItemButton
          component={CustomNavLink}
          to="/admin/events"
          sx={{ height: "60px" }}
        >
          <ListIcon />
        </ListItemButton>
      </Tooltip>
      <Tooltip title={translations["lefttoolbar.calendar"]} placement="right">
        <ListItemButton
          component={CustomNavLink}
          to="/admin/calendar"
          sx={{ height: "60px" }}
        >
          <CalendarTodayIcon />
        </ListItemButton>
      </Tooltip>
      {/*}<Tooltip title={translations["lefttoolbar.statistics"]} placement="right">
        <ListItemButton
          component={CustomNavLink}
          to="/admin/statistics"
          sx={{ height: "60px" }}
        >
          <PieChartIcon />
        </ListItemButton>
  </Tooltip>*/}
      <Tooltip title={translations["lefttoolbar.attributes"]} placement="right">
        <ListItemButton
          component={CustomNavLink}
          to="/admin/attributes"
          sx={{ height: "60px" }}
        >
          <SellIcon />
        </ListItemButton>
      </Tooltip>
      <Tooltip
        title={translations["lefttoolbar.usermanagement"]}
        placement="right"
      >
        <ListItemButton
          component={CustomNavLink}
          to="/admin/usermanagement"
          sx={{ height: "60px" }}
        >
          <KeyIcon />
        </ListItemButton>
      </Tooltip>
    </List>
  );
}

export default LeftToolbar;
