import React from "react";
import {
  Box,
  ButtonBase,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ListIcon from "@mui/icons-material/List";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PieChartIcon from "@mui/icons-material/PieChart";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import MailIcon from "@mui/icons-material/Mail";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import PhotoIcon from "@mui/icons-material/Photo";
import ApartmentIcon from "@mui/icons-material/Apartment";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import PersonIcon from "@mui/icons-material/Person";
import HubIcon from "@mui/icons-material/Hub";
import GroupsIcon from "@mui/icons-material/Groups";
import EuroIcon from "@mui/icons-material/Euro";
import TodoList from "../../components/TodoList";
import EventStageList from "./EventStageList";
import NewsList from "./NewsList";
import FaqList from "./FaqList";
import { getTranslations } from "../../translations/Translations";
import TopToolbar from "../../components/TopToolbar";
import { EventRecord } from "../../interfaces/Interfaces";
import { useAuth } from "../../AuthProvider";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../helpers/DateFunctions";

function Dashboard() {
  const translations = getTranslations();
  const auth = useAuth();
  const navFn = useNavigate();
  const theme = useTheme();
  const xlmatches = useMediaQuery(theme.breakpoints.up("xl"));

  const dofetch = (
    url: string,
    method: string,
    bodyjson: { [key: string]: any }
  ) => {
    return fetch(url, {
      method: method,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("jwt"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyjson),
    }).then((res) => res.json());
  };

  const newEvent = () => {
    dofetch("/api/events/setup", "post", {
      defaultLanguage: "de",
      language: "de",
      title: "Neues Event",
      theme: "Apella",
      type: "SEMINAR",
      startdate: formatDate(new Date()),
      enddate: formatDate(new Date()),
      contextId: auth?.user.contextId,
    }).then((result) => {
      let p: EventRecord = result.data;
      navFn("/admin/events/" + p.id + "/", {
        replace: false,
      });
    });
  };

  return (
    <>
      <TopToolbar
        title={
          <Typography fontSize={"24px"} mt={1} ml={2}>
            {translations["dashboard.title"]}
          </Typography>
        }
      />
      <Box
        display="flex"
        flexDirection="column"
        position="relative"
        flex={1}
        overflow="scroll"
      >
        <Box display="flex" flexDirection="row" m={2} mt={1} gap={2}>
          <Box display="flex" flexDirection="column" gap={1}>
            <ButtonBase className="dashboardButton action" onClick={newEvent}>
              <div className="stackedIcon">
                <CalendarTodayIcon />
                <AddCircleIcon className="modifier modifierbg" />
              </div>
              <Typography>{translations["dashboard.newevent"]}</Typography>
            </ButtonBase>
            <ButtonBase className="dashboardButton">
              <div className="stackedIcon">
                <CalendarTodayIcon />
                <Brightness1Icon className="modifier modifierbg" />
                <ModeEditIcon className="smallermodifier" htmlColor={"#FFF"} />
              </div>
              <Typography>
                {translations["dashboard.addeventdetails"]}
              </Typography>
            </ButtonBase>
            <ButtonBase className="dashboardButton">
              <MailIcon />
              <Typography>{translations["dashboard.sendmailing"]}</Typography>
            </ButtonBase>
            <ButtonBase className="dashboardButton">
              <EuroIcon />
              <Typography>{translations["dashboard.budget"]}</Typography>
            </ButtonBase>
            <ButtonBase className="dashboardButton">
              <PieChartIcon />
              <Typography>
                {translations["dashboard.viewstatistics"]}
              </Typography>
            </ButtonBase>
            <ButtonBase className="dashboardButton">
              <GroupsIcon />
              <Typography>
                {translations["dashboard.viewregistrationlist"]}
              </Typography>
            </ButtonBase>
            <ButtonBase className="dashboardButton">
              <ListIcon />
              <Typography>{translations["dashboard.allevents"]}</Typography>
            </ButtonBase>
          </Box>
          <Box display="flex" flexDirection="column" gap={1}>
            <ButtonBase className="dashboardButton">
              <PersonIcon />
              <Typography>
                {translations["dashboard.managecoworkers"]}
              </Typography>
            </ButtonBase>
            <ButtonBase className="dashboardButton gotowebinar">
              <HubIcon />
              <Typography>
                {translations["dashboard.importgotowebinar"]}
              </Typography>
            </ButtonBase>
            <ButtonBase className="dashboardButton">
              <WatchLaterIcon />
              <Typography>
                {translations["dashboard.addeventschedule"]}
              </Typography>
            </ButtonBase>
            <ButtonBase className="dashboardButton">
              <PhotoIcon />
              <Typography>{translations["dashboard.makemediapage"]}</Typography>
            </ButtonBase>
            <ButtonBase className="dashboardButton">
              <ApartmentIcon />
              <Typography>
                {translations["dashboard.addvenuedetails"]}
              </Typography>
            </ButtonBase>
            <ButtonBase className="dashboardButton">
              <RestaurantIcon />
              <Typography>
                {translations["dashboard.addcateringdetails"]}
              </Typography>
            </ButtonBase>
          </Box>
          {xlmatches ? (
            <Box display="flex" flexDirection="row" gap={2}>
              <Box
                display="flex"
                flexDirection="column"
                flex={1}
                minWidth={480}
              >
                <TodoList />
              </Box>
              <Box display="flex" flexDirection="column" minWidth={240}>
                <EventStageList />
              </Box>
              <Box gap={1} display="flex" flexDirection="column" minWidth={240}>
                <NewsList />
                <FaqList />
              </Box>
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              flex={1}
              minWidth={480}
              gap={1}
            >
              <TodoList />
              <Box display="flex" flexDirection="row" gap={2}>
                <Box
                  display="flex"
                  flexDirection="column"
                  minWidth={240}
                  flex={1}
                >
                  <EventStageList />
                </Box>
                <Box
                  gap={1}
                  display="flex"
                  flexDirection="column"
                  minWidth={240}
                  flex={1}
                >
                  <NewsList />
                  <FaqList />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

export default Dashboard;
