import { Box } from "@mui/material"
import React from "react"
import { ReactWidget } from "../../Interfaces"
import { addConfigToStyle } from "../../../../helpers/Helpers";
import { generateMarginString } from "../../../events/event/website/components/widgets/options/MarginSelector";

const ImageField = ({ widget }: ReactWidget) => {
    const config = widget.config || {};
    const styles: any = {
        maxWidth: '100%', 
        Height: 'auto'
    }
    const parentStyles: any = {};
    const width = config['width'];
    if (width) {
        styles.width = width;
    }

    const margin = config['margin'];
    if (margin) {
        parentStyles.margin = generateMarginString(margin);
    }

    addConfigToStyle('textAlign', config, parentStyles);

    if (widget.config.src) {
        return <Box sx={parentStyles}><Box component="img"  sx={styles} src={widget.config.src}></Box></Box>
    } else {
        return <Box sx={styles}>{widget.type}</Box>
    }
}

export default ImageField;