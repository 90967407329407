import { Box, Typography } from "@mui/material"
import { ReactWidgetWithStyles } from "../../../../../../interfaces/Interfaces"
import React from "react"
import withStandardProps from "./HOCs/withStandardStyles";

const ParagraphField = ({ widget, standardStyles }: ReactWidgetWithStyles) => {
    const config = widget.config || {};
    const textAlign = config['textAlign'];
    const fontSize = config['fontSize'];
    
    const styles: any = {};
    if (textAlign) {
        styles.textAlign = textAlign;
    }

    if (fontSize) {
        styles.fontSize = fontSize;
    }

    if (widget.translations[widget.defaultLanguage] && widget.translations[widget.defaultLanguage].content) {
        return (
            <Typography variant="body1" sx={{...styles, ...standardStyles}} dangerouslySetInnerHTML={{__html: widget.translations[widget.defaultLanguage].content || ''}}>
            </Typography>
        );
    } else {
        return <Box sx={styles}>{widget.type}</Box>
    }
}

export default withStandardProps(ParagraphField);