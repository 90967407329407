import { Box, Grid } from "@mui/material"
import { ReactWidget } from "../../../../../../interfaces/Interfaces"
import React from "react"
import withStandardProps from "./HOCs/withStandardStyles"
import { addConfigToStyle } from "../../../../../../helpers/Helpers"
import Slider from "react-slick";
/*import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";*/
import './SliderWidget.scss';

const SliderWidget = ({ widget }: ReactWidget) => {
    const settings = {
        arrows: false,
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        //autoplay: false,
        autoplay: false,
        speed: 1000,
        autoplaySpeed: 2000,
        cssEase: "linear",
        pauseOnHover: false,
        draggable: false,
        /*responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 815,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1
                }
            }
        ]*/
    };

    const srcs = widget.config.srcs;

    if (srcs && widget.config.srcs.length > 0) {
        return <Box style={{maxWidth: '1070px'}}><Slider {...settings}>
                {srcs.map((item: any, index: number) => (
                    <div className="image-slide" key={index}>
                        <img src={item.src} />
                    </div>
                ))}
            </Slider>
            </Box>
    } else {
        return <Box>{widget.type}</Box>
    }
}

export default SliderWidget;

