import { Box, FormControl, TextField, Typography } from "@mui/material"
import React from "react"
import { FieldRecordWidget } from "../../../../../../interfaces/Interfaces";
import FieldLabel from "./FieldLabel"

const RegTextField = ({ registrationfield }: FieldRecordWidget) => {
  return (
    <Box display="flex" flexDirection="column">
      <FieldLabel field={registrationfield} />
      <FormControl sx={{ minWidth: "300px" }}>
        <TextField size="small"></TextField>
      </FormControl>
    </Box>
  )
}

export default RegTextField;