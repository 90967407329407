import React from "react";
import { getTranslations } from "../../../../translations/Translations";
import TopToolbar from "../../../../components/TopToolbar";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { Outlet, matchPath, useLocation, useRouteLoaderData } from "react-router-dom";
import { CustomNavLink } from "../../../../components/CustomNavLink";
import { EventRouterResponse } from "../../../../interfaces/Interfaces";

function Mailings() {
  const { event } = useRouteLoaderData("event") as EventRouterResponse;
  const translations = getTranslations();
  const location = useLocation();
  const urls = [
    "/admin/events/" + event.id + "/mailings",
  ];

  const getActiveUrl = () => {
    let active = [...urls].reverse().filter((url) => {
      return matchPath(url + "/*", location.pathname);
    });
    if (active.length === 0) {
      return urls[0];
    } else {
      return active[0];
    }
  };

  return (
    <Box display="flex" flex={1} overflow="hidden" flexDirection={"column"}>
      <Box>
        <Tabs value={getActiveUrl()} className="subtabbar">
          <Tab
            label={translations["mailings.editMailTemplate"]}
            component={CustomNavLink}
            value={urls[0]}
            to={urls[0]}
            replace={false}
          />
          {/*}<Tab
            label={translations["mailings.sendMails"]}
            component={CustomNavLink}
            value={urls[1]}
            to={urls[1]}
            replace={false}
      />*/}
        </Tabs>
      </Box>
      <Box display="flex" overflow="hidden" flex={1} flexDirection={"column"}>
        <Outlet />
      </Box>
    </Box>
  );
}

export default Mailings;
