import React from "react";
import { getTranslations } from "../../translations/Translations";
import TopToolbar from "../../components/TopToolbar";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { Outlet, matchPath, useLocation } from "react-router-dom";
import { CustomNavLink } from "../../components/CustomNavLink";
import { useAuth } from "../../AuthProvider";

function Usermanagement() {
  const translations = getTranslations();
  const auth = useAuth();

  const location = useLocation();
  const urls = [
    "/admin/usermanagement/",
    "/admin/usermanagement/roles",
    "/admin/usermanagement/contexts",
  ];
  const getActiveUrl = () => {
    let active = [...urls].reverse().filter((url) => {
      return matchPath(url + "/*", location.pathname);
    });
    if (active.length === 0) {
      return urls[0];
    } else {
      return active[0];
    }
  };

  return (
    <>
      <TopToolbar
        title={
          <Typography fontSize={"24px"} mt={1} ml={2}>
            {auth?.user?.role?.id === 1
              ? translations["usermanagement.titleMSADMIN"]
              : translations["usermanagement.title"]}
          </Typography>
        }
      />
      <Box display="flex" flex={1} overflow="hidden" flexDirection={"column"}>
        <Box>
          <Tabs value={getActiveUrl()} className="tabs">
            <Tab
              label={translations["usermanagement.logins"]}
              component={CustomNavLink}
              value={urls[0]}
              to={urls[0]}
              replace={false}
            />
            <Tab
              label={translations["usermanagement.roles"]}
              component={CustomNavLink}
              value={urls[1]}
              to={urls[1]}
              replace={false}
            />
            {auth?.user?.role?.id === 1 ? (
              <Tab
                label={translations["usermanagement.contexts"]}
                component={CustomNavLink}
                value={urls[2]}
                to={urls[2]}
                replace={false}
              />
            ) : null}
          </Tabs>
        </Box>
        <Box display="flex" overflow="hidden" flex={1} flexDirection={"column"}>
          <Outlet />
        </Box>
      </Box>
    </>
  );
}

export default Usermanagement;
