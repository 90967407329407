import React, { useEffect, useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, Icon, IconButton, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";

import { getTranslations } from "../../../../../translations/Translations";
import { useRouteLoaderData } from "react-router-dom";
import { EventRouterResponse, FieldRecord, Filter, IRecord } from "../../../../../interfaces/Interfaces";
import { DataGrid, GridActionsCellItem, GridColDef, GridColumnHeaderParams, GridColumnVisibilityModel, GridLocaleText, GridRowParams } from "@mui/x-data-grid";

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import useDebounce from "../../../../../helpers/useDebounce";
import useId from "@mui/material/utils/useId";
import { previousDay } from "date-fns";
import { Label } from "@mui/icons-material";

interface RadioFilterState {
    radioFilter: string | undefined,
    radioFilterValue: string | undefined
}

const SelectRegistrationsModal = ({ columns, localText, open, closeModal, fields, sessionId }: { sessionId: number, fields: FieldRecord[], open: boolean, closeModal: Function, columns: GridColDef<IRecord>[], localText: Partial<GridLocaleText> }) => {
    const { event } = useRouteLoaderData("event") as EventRouterResponse;
    const translations = getTranslations();
    const [registrations, setRegistrations] = useState<IRecord[]>([]);
    const [filteredRegistrations, setFilteredRegistrations] = useState<IRecord[]>([]);
    const [selectedIds, setSelectedIds] = useState<number[]>([]);
    const [search, setSearch] = useState<string>("");
    const debouncedSearch = useDebounce(search, 500);
    const [allSelected, setAllSelected] = useState<boolean>(false);
    const [filters, setFilters] = useState<Filter[]>([]);
    const [radioFilter, setRadioFilter] = useState<RadioFilterState>({
        radioFilter: '',
        radioFilterValue: ''
    })

    const modalClose = () => {
        setRadioFilter({
            radioFilter: '',
            radioFilterValue: ''
        })
        closeModal();
    };

    const saveSelected = () => {
        if (selectedIds && selectedIds.length > 0) {
            fetch("/api/registrations/addActivity", {
                method: 'POST',
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization:
                        "Bearer " + sessionStorage.getItem("jwt"),
                },
                body: JSON.stringify({
                    activityId: sessionId,
                    registrationIds: selectedIds
                })
            }).then((res) => res.json()).then((response) => {
                if (response.success) {
                    setSelectedIds([]);
                    modalClose();
                }
            });
        } else {
            setSelectedIds([]);
            modalClose();
        }
    }

    const updateAllSelected = () => {
        if (filteredRegistrations.length > 0) {
            const filteredIds = filteredRegistrations.map(reg => reg.id);
            const allSelected = filteredIds.every(id => selectedIds.includes(id));
            setAllSelected(allSelected);
        } else {
            setAllSelected(false);
        }
    }

    useEffect(() => {
        updateAllSelected();
    }, [selectedIds, filteredRegistrations]);

    const selectAll = () => {
        setSelectedIds((prev) => {
            const newIds = filteredRegistrations.map(reg => reg.id);
            const uniqueNewIds = newIds.filter(id => !prev.includes(id));
            return [...prev, ...uniqueNewIds];
        });
    }

    const deselectAll = () => {
        setSelectedIds((prev) => {
            const filteredIds = filteredRegistrations.map(registration => registration.id);
            const updatedSelectedIds = prev.filter((id) => !filteredIds.includes(id));
            return updatedSelectedIds;
        });
    }

    const toggleSelected = (regId: any) => {
        setSelectedIds(prev => (
            prev.includes(regId)
                ? prev.filter(id => id !== regId)
                : [...prev, regId]
        ));
    }

    const extendedColumns = [{
        field: "actions",
        type: "actions",
        width: 50,
        renderHeader: () => {
            return <Icon sx={{ width: 'auto', height: 'auto' }} onClick={() => {
                if (allSelected) {
                    deselectAll()
                } else {
                    selectAll();
                }
            }}>{allSelected ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}</Icon>
        },
        getActions: (params: GridRowParams) => [
            <GridActionsCellItem
                icon={selectedIds.includes(params.row.id) ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                onClick={() => {
                    toggleSelected(params.row.id);
                }}
                label={''}
            />,
        ],
    }, ...columns];

    const nameFilter = (record: IRecord, value: string) => {
        const firstnameField = fields.find(
            (field) => field.defaultfield === "firstname"
        );
        const lastnameField = fields.find(
            (field) => field.defaultfield === "lastname"
        );
        const searchLower = value.toLocaleLowerCase();
        return (
            (firstnameField &&
                record.values["field" + firstnameField.id]
                    ?.toLocaleLowerCase()
                    .includes(searchLower)) ||
            (lastnameField &&
                record.values["field" + lastnameField.id]
                    ?.toLocaleLowerCase()
                    .includes(searchLower))
        );
    };

    const radioButtonsfilter = (field: number, record: IRecord, value: string) => {
        return (record.values["field" + field] === value)
    }

    useEffect(() => {
        if (radioFilter.radioFilter !== '' && radioFilter.radioFilterValue !== '') {
          setFilters((prevFilters: Filter[]) => {
            const filterIndex = prevFilters.findIndex(
              (filter) => filter.filterName === 'radiofilter'
            );
            const updatedFilter: Filter = {
              filterName: 'radiofilter',
              filterFunction: (row: IRecord) => {
                const filterValue = radioFilter.radioFilterValue || '';
                return radioButtonsfilter(Number(radioFilter.radioFilter), row, filterValue);
              },
            };
      
            if (filterIndex !== -1) {
              prevFilters[filterIndex] = updatedFilter;
              return [...prevFilters];
            } else {
              return [...prevFilters, updatedFilter];
            }
          });
        } else {
          setFilters((prevFilters: Filter[]) =>
            prevFilters.filter((filter) => filter.filterName !== 'radiofilter')
          );
        }
      }, [radioFilter]);

    useEffect(() => {
        if (open === true) {
            let searchparams: URLSearchParams =
                new URLSearchParams({
                    filter: JSON.stringify([
                        { property: "eventId", value: event.id }
                    ]),
                });

            fetch("/api/registrations?" + searchparams.toString(), {
                headers: {
                    Accept: "application/json",
                    Authorization:
                        "Bearer " + sessionStorage.getItem("jwt"),
                },
            }).then((res) => res.json()).then((response) => {
                //filter the records that have the activity already ...
                setRegistrations(response.data.filter((record: IRecord) => record.activities.every((activity: any) => activity.id !== sessionId)
                ));
            });
        }
    }, [open])

    useEffect(() => {
        if (debouncedSearch !== "") {
            setFilters((prevFilters) => {
                const filterIndex = prevFilters.findIndex(
                    (filter) => filter.filterName === "search"
                );
                if (filterIndex !== -1) {
                    prevFilters[filterIndex].filterFunction = (row: IRecord) =>
                        nameFilter(row, debouncedSearch);
                    return [...prevFilters];
                } else {
                    return [
                        ...prevFilters,
                        {
                            filterName: "search",
                            filterFunction: (row: IRecord) =>
                                nameFilter(row, debouncedSearch),
                        },
                    ];
                }
            });
        } else {
            setFilters((prevFilters) =>
                prevFilters.filter((filter) => filter.filterName !== "search")
            );
        }
    }, [debouncedSearch]);

    useEffect(() => {
        if (filters.length > 0) {
            //shallow copy of orginals rows
            let filteredRows = [...registrations];

            filters.forEach((myFilter) => {
                filteredRows = filteredRows.filter((record) =>
                    myFilter.filterFunction(record)
                );
            });

            setFilteredRegistrations(filteredRows)
        } else {
            setFilteredRegistrations(registrations);
        }
    }, [filters]);


    useEffect(() => {
        setFilteredRegistrations(registrations);
    }, [registrations])

    const returnFieldOptions = () => {
        const selectedField = fields.find((field) => field.id === Number(radioFilter.radioFilter));
        if (selectedField) {
            if (selectedField.translations && selectedField.translations[event.defaultLanguage] && selectedField.translations[event.defaultLanguage].options) {
                return selectedField.translations[event.defaultLanguage].options?.map((fieldOption, index) => <MenuItem key={index} value={fieldOption}>{fieldOption}</MenuItem>);
            }
        } else {
            return [];
        }
    }



    return (
        <Dialog
            className="window"
            open={open}
            fullWidth={true}
            fullScreen={true}
            sx={{ "&": { padding: '1rem' }, "& .MuiDialog-paperFullScreen": { display: 'flex', flexDirection: 'column' } }}
        >
            <DialogTitle sx={{paddingBottom: 0}}>{translations['sessions.selectRegistrations']}</DialogTitle>
            <DialogContent sx={{ display: 'flex', flex: 1, flexDirection: 'column' }} dividers={false}>
                <Box p={'1rem 0 1rem 0'} sx={{ display: 'flex', flexDirection: 'row'}}>
                    <TextField
                        sx={{ width: 300, marginRight: '1rem' }}
                        value={search}
                        label={translations["contacts.search"]}
                        onChange={(event: any) => {
                            setSearch(event.target.value);
                        }}
                    />

                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Box  sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <Typography sx={{marginRight: '1rem'}}>{translations["sessions.filterBy"]}</Typography>
                            <Select
                                value={radioFilter.radioFilter}
                            
                                sx={{marginRight: radioFilter.radioFilter !== '' ? '1rem': '', maxWidth: '200px'}}
                                onChange={(e: SelectChangeEvent) => {
                                    setRadioFilter({
                                        radioFilter: e.target.value,
                                        radioFilterValue: ''
                                    })
                                }}
                            >
                                {fields
                                    .filter((field) => field.type === 'radiobuttons')
                                    .map((field: FieldRecord) => (
                                        <MenuItem key={field.id} value={field.id}>
                                            {field.translations[event.defaultLanguage].title}
                                        </MenuItem>
                                    ))}
                            </Select>
                            {radioFilter.radioFilter !== '' && (
                                <Select
                                    sx={{marginRight: radioFilter.radioFilterValue !== '' ? '1rem': '', maxWidth: '200px'}}
                                    value={radioFilter.radioFilterValue}
                                    onChange={(e: SelectChangeEvent) => {
                                        setRadioFilter((prev) => { return { ...prev, radioFilterValue: String(e.target.value) } })
                                    }}
                                >
                                    {returnFieldOptions()}
                                </Select>
                            )}
                            {radioFilter.radioFilter !== '' && radioFilter.radioFilterValue !== '' && (
                                <Button variant="contained" sx={{ flex: 1 }} onClick={() => {
                                    setRadioFilter({
                                        radioFilter: '',
                                        radioFilterValue: ''
                                    })
                                }}>{translations['remove']}</Button>
                            )}
                            
                        </Box>
                    </Box>
                </Box>
                {filteredRegistrations && (<DataGrid
                    disableRowSelectionOnClick={true}
                    sx={{
                        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                            outline: "none !important",
                        }, "&": { flex: 1 }
                    }}
                    onRowClick={(data) => {
                        toggleSelected(data.row.id);
                    }}
                    rows={filteredRegistrations}
                    columns={extendedColumns}
                    localeText={localText}
                ></DataGrid>)}
            </DialogContent>
            <DialogActions sx={{ padding: "24px", paddingTop: "0", display: 'flex', justifyContent: "space-between" }}>
                <Button
                    variant="contained"
                    onClick={() => {
                        saveSelected();
                    }}
                >
                    {translations["add"]}
                </Button>
                <Button
                    onClick={() => {
                        modalClose();
                    }}
                >
                    {translations["cancel"]}
                </Button>
            </DialogActions>
        </Dialog>

    )
}
export default SelectRegistrationsModal;