import {
  Box,
  IconButton,
  List,
  ListItem,
} from "@mui/material";
import React, { useState } from "react";
import { useRevalidator, useRouteLoaderData } from "react-router-dom";
import { getTranslations } from "../../../../translations/Translations";
import {
  EventRouterResponse,
  FieldRecord,
  SidePanelConfig,
  WebpageRouterResponse,
  WidgetRecord,
} from "../../../../interfaces/Interfaces";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import EditIcon from "@mui/icons-material/Edit";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import DeleteIcon from "@mui/icons-material/Delete";

import WidgetBar from "./components/WidgetBar";
import RegistrationFormSidePanel, { getRegistrationFieldSettingsByType, returnDefaultJsonConfig } from "./components/RegistrationFormSidePanel";
import RegistrationField, { registrationfields } from "./components/RegistrationFields";

function DraggableSetupItem(props: any) {
  return (
    <Draggable
      key={props.fieldtype}
      draggableId={props.fieldtype}
      index={props.index}
      disableInteractiveElementBlocking={true}
    >
      {(provided) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{ padding: 0 }}
          data-fieldtype={props.fieldtype}
        >
          <Box flex={1} p={1}>
            {props.children}
          </Box>
        </ListItem>
      )}
    </Draggable>
  );
}

const RegistrationForm = ({ widget, onBackClick }: { widget: WidgetRecord, onBackClick: Function }) => {
  const translations = getTranslations();
  const revalidator = useRevalidator();
  const [sidePanelConfig, setSidePanelConfig] = useState<SidePanelConfig>({
    activeField: null
  })
  const { event } = useRouteLoaderData("event") as EventRouterResponse;
  const { fields } = useRouteLoaderData(
    "webpage"
  ) as WebpageRouterResponse;

  const filteredFields = fields.filter((field) => field.widgetId === widget.id);

  const onDelete = (field: FieldRecord) => {
    fetch("/api/registrationfields/" + field.id, {
      method: "delete",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("jwt"),
        "Content-Type": "application/json",
      },
    }).then(() => {
      fields.splice(fields.indexOf(field), 1);
      revalidator.revalidate();
    });
  };

  const onEditClick = (field: FieldRecord) => {
    //show the side panel with configs
    setSidePanelConfig((prev) => ({
      ...prev,
      activeField: field
    }))
  }

  const getRequired = (type: string) => {
    const settings = getRegistrationFieldSettingsByType(type);
    console.log(settings);


    if (settings && typeof settings.required !== 'undefined') {
      return settings.required;
    } else {
      return false;
    }
  }

  const onDragEnd = (result: any) => {
    if (!result.destination) return;
    if (result.destination.droppableId === "form") {
      if (result.source.droppableId === "fields") {
        

        let config = returnDefaultJsonConfig(registrationfields[result.source.index].type, {});

        let promises: Promise<Response>[] = [];
        promises.push(
          fetch("/api/registrationfields/", {
            method: "post",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + sessionStorage.getItem("jwt"),
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              eventId: event.id,
              defaultLanguage: event.defaultLanguage,
              language: event.defaultLanguage,
              title: translations["registrationform." + registrationfields[result.source.index].type],
              options: translations["registrationform.defaultoptions"].split('<>'),
              jsonConfig: JSON.stringify(config),
              required: getRequired(registrationfields[result.source.index].type),
              type: registrationfields[result.source.index].type,
              widgetId: widget.id,
              sortorder: result.destination.index,
            }),
          })
            .then((res) => {
              if (res.ok) {
                return res.json();
              } else {
                throw new Error("Failed to post data");
              }
            })
            .then((postData) => {
              //set postData
              setSidePanelConfig((prev) => ({
                ...prev,
                activeField: postData.data
              }));

              filteredFields.forEach((item, index) => {
                if (index >= result.destination.index) {
                  item.sortorder = item.sortorder + 1;
                  promises.push(
                    fetch("/api/registrationfields/" + item.id, {
                      method: "put",
                      headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                        "Content-Type": "application/json",
                      },
                      body: JSON.stringify(item),
                    })
                  );
                }
              });

              return postData;
            }));

        Promise.all(promises).then(() => {
          revalidator.revalidate();
        })
      } else if (result.source.droppableId === "form") {
        let data = filteredFields;
        let promises: Promise<Response>[] = [];

        // reorder
        let [draggedObj] = data.splice(result.source.index, 1);
        data.splice(result.destination.index, 0, draggedObj);
        data.forEach((item, index) => {
          item.sortorder = index + 1;
          // save each item
          promises.push(
            fetch("/api/registrationfields/" + item.id, {
              method: "put",
              headers: {
                Accept: "application/json",
                Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                "Content-Type": "application/json",
              },
              body: JSON.stringify(item),
            })
          );
        });

        Promise.all(promises).then(() => {
          revalidator.revalidate();
        });
      }
    } else {
      return;
    }
  };

  return (
    <Box flex={1} display="flex" flexDirection="column" overflow="hidden">
      <WidgetBar onBackClick={onBackClick} />
      <Box flex={1} display="flex" flexDirection="row" overflow="hidden">
        <DragDropContext onDragEnd={onDragEnd}>
          <Box width="200px" overflow="scroll" className="list">
            <Droppable droppableId="fields">
              {(provided) => (
                <List
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="fieldtypelist"
                >
                  {registrationfields.map((registrationFieldObj, index) => (
                    <DraggableSetupItem
                      fieldtype={registrationFieldObj.type}
                      key={registrationFieldObj.type}
                      index={index}
                    >
                      {registrationFieldObj.listElement}
                    </DraggableSetupItem>
                  ))}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </Box>

          <Box
            flex="1"
            overflow="hidden"
            bgcolor={"#AAA"}
            className="whatsthis"

          >
            <Droppable droppableId="form">
              {(provided) => (
                <Box
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  height="100%"
                  width="80%"
                  ml="auto"
                  mr="auto"
                  bgcolor={"#FFF"}
                  p={2}
                  sx={{ boxSizing: 'border-box' }}
                  overflow="scroll"
                >
                  {filteredFields.map((field: FieldRecord, index: number) => {
                    return (
                      <Draggable
                        key={field.id}
                        draggableId={"field-" + field.id}
                        index={index}
                      >
                        {(provided) => (
                          <Box
                            display="flex"
                            flexDirection={"row"}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className={sidePanelConfig.activeField && sidePanelConfig.activeField.id === field.id ? 'registration-field active-field' : 'registration-field'}
                          >
                            <RegistrationField field={field} />
                            <Box ml={'1rem'}>
                              <IconButton
                                onClick={() => {
                                  onEditClick(field);
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  onDelete(field);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                              <IconButton {...provided.dragHandleProps}>
                                <DragIndicatorIcon />
                              </IconButton>
                            </Box>
                          </Box>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </Box>

          <RegistrationFormSidePanel config={sidePanelConfig} setConfig={setSidePanelConfig} />
        </DragDropContext>
      </Box>
    </Box>
  );
}

export default RegistrationForm;
