import React, { useEffect, useState } from "react";
import {
  getGridTranslations,
  getTranslations,
} from "../../translations/Translations";
import {
  DataGrid,
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
  GridRowParams,
} from "@mui/x-data-grid";
import {
  useRevalidator,
  useRouteLoaderData,
  useSearchParams,
} from "react-router-dom";
import * as Yup from "yup";
import {
  INewRecord,
  IRecord,
  LoginsRouterResponse,
  UserRecord,
} from "../../interfaces/Interfaces";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import useDebounce from "../../helpers/useDebounce";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { DisplayErrors } from "../../components/DisplayErrors";
import SaveBar from "../../components/SaveBar";
import { useAuth } from "../../AuthProvider";
import AddActivityModal from "./modals/AddActivityModal";
import ImprovedChoiceDialog from "../../components/ImprovedDialog";
import { previousDay } from "date-fns";
import './Logins.scss';
import NewReleasesIcon from '@mui/icons-material/NewReleases';

interface AddActivityModalProps {
  open: boolean,
  loginSettings: {
    loginId: number | null,
    loginAccess: IRecord[]
  }
}

interface ModalState {
  addActivityModal: AddActivityModalProps,
  removeConfirm: {
    open: boolean,
    loginAccessId: number | null
  }
}

function Logins() {
  const auth = useAuth();
  const translations = getTranslations();
  const gridtranslations = getGridTranslations();
  const { logins, contexts, roles, events } = useRouteLoaderData(
    "logins"
  ) as LoginsRouterResponse;
  const [open, setOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce(search, 500);
  const [myDialogTitle, setMyDialogTitle] = useState<string>(
    translations["logins.newlogintitle"]
  );
  const [modalState, setModalState] = useState<ModalState>({
    addActivityModal: {
      open: false,
      loginSettings: {
        loginId: null,
        loginAccess: []
      }
    },
    removeConfirm: {
      open: false,
      loginAccessId: null
    }
  })
  const [selectedLoginId, setSelectedLoginId] = useState<number | null>(null);
  const revalidator = useRevalidator();
  const [contextId, setContextId] = useState<number | string>("");
  const setSearchParams = useSearchParams()[1];
  const getFlatObj = (obj: IRecord) => {
    return {
      id: obj.id,
      firstname: obj.firstname || "",
      password: obj.password || "",
      lastname: obj.lastname || "",
      username: obj.username || "",
      email: obj.email || "",
      roleId: obj.role ? obj.role.id : "",
      contextId: obj.contextId || "",
      loginAccess: obj.loginAccess || [],
    };
  };
  const [initialValues, setInitialValues] = useState<IRecord>(
    getFlatObj({ id: -1 })
  );
  const [loginAccessData, setLoginAccessData] = useState<IRecord[]>([]);

  const handleModalClose = <T extends keyof ModalState>(key: T) => {
    setModalState((prev) => ({ ...prev, [key]: { ...prev[key], open: false } }));
  };

  useEffect(() => {
    if (open === true && selectedLoginId !== null) {
      let login = logins.find((login) => login.id === selectedLoginId);
      let obj = getFlatObj(login as IRecord);
      formik.resetForm({
        touched: {},
        errors: {},
      });
      setInitialValues(obj);
      formik.setValues(obj, false);
      if (obj.roleId > 2) {
        fetchLoginAccess(formik.values.id);
      }
    }
  }, [logins])


  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      username: Yup.string().required(translations["errors.requiredfield"]),
      contextId: Yup.number().when(["id"], ([id], schema) => {
        if (id !== 1) {
          return schema.required(translations["errors.requiredfield"]);
        } else {
          return schema;
        }
      }),
      roleId: Yup.number().when(["id"], ([id], schema) => {
        if (id !== 1) {
          return schema.required(translations["errors.requiredfield"]);
        } else {
          return schema.equals([1], translations["errors.requiredfield"]);
        }
      }),
      password: Yup.string().when(["id"], ([id], schema) => {
        if (typeof id === "undefined") {
          return schema.required(translations["errors.requiredfield"]);
        } else {
          return schema.notRequired();
        }
      }),
    }),
    onSubmit: (values) => {
      if (values.id === -1) {
        var formvalues: INewRecord = { ...values };
        delete formvalues.id;

        fetch("/api/logins/", {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("jwt"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formvalues),
        })
          .then((res) => res.json())
          .then((jsondata) => {
            if (jsondata.success) {
              values.id = jsondata.data.id;
              revalidator.revalidate();
            }
          })
          .finally(() => {
            formik.setSubmitting(false);
            if (formik.values.roleId > 2) {
              setSelectedLoginId(formik.values.id);
              setOpen(true);
            } else {
              setSelectedLoginId(null);
              setOpen(false);
            }
          });
      } else {
        fetch("/api/logins/" + values.id + "/", {
          method: "PUT",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("jwt"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        })
          .then((res) => res.json())
          .then((jsondata) => {
            if (jsondata.success) {
              revalidator.revalidate();
            }
          })
          .finally(() => {
            formik.setSubmitting(false);
            if (formik.initialValues.roleId !== formik.values.roleId && formik.values.roleId >2) {
              setSelectedLoginId(prev => prev);
              setOpen(true);
            } else {
              setSelectedLoginId(null);
              setOpen(false);
            }
          });
      }
    },
  });

  const fetchLoginAccess = (loginId: number) => {
    fetch("/api/loginAccess?" + new URLSearchParams({
      filter: JSON.stringify([
        {
          property: "loginId",
          value: loginId,
        },
      ])
    }).toString(), {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("jwt"),
        "Content-Type": "application/json",
      }
    })
      .then((res) => res.json())
      .then((jsonData) => {
        setLoginAccessData(jsonData.data)
        //parseLoginAccessData(jsonData.data);
      })
  }

  useEffect(() => {
    console.log('EXEC');
    if (open === true) {
      if (formik.values.roleId > 2) {
        fetchLoginAccess(formik.values.id);
      }
    }
  }, [open, formik.values.roleId])

  const removeLoginAccessRecord = (recordId: number, callback: Function) => {
    fetch('/api/loginAccess/' + recordId, {
      method: 'DELETE',
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("jwt"),
        "Content-Type": "application/json",
      },
    }).then((res) => res.json())
      .then((jsondata) => {
        if (jsondata.success) {
          //formik.setFieldValue("loginAccess", [...formik.values.loginAccess, nieuwe loginAccess]);
          revalidator.revalidate();
          callback();
        }
      })
  }

  useEffect(() => {
    let obj: INewRecord = {};
    if (debouncedSearch !== "") {
      obj.search = debouncedSearch;
    }
    if (contextId !== "") {
      obj.contextId = contextId;
    }
    setSearchParams(obj);
  }, [debouncedSearch, contextId, setSearchParams]);

  let columns: (GridColDef | GridActionsColDef)[] = [
    {
      field: "id",
      headerName: translations["logins.id"],
    },
    {
      field: "username",
      headerName: translations["logins.username"],
      flex: 1,
    },
    {
      field: "firstname",
      headerName: translations["logins.firstname"],
      flex: 1,
    },
    {
      field: "lastname",
      headerName: translations["logins.lastname"],
      flex: 1,
    },
    {
      field: "email",
      headerName: translations["logins.email"],
      flex: 1,
      renderCell: (params) => (
        <Link href={"mailto:" + params.value}>{params.value}</Link>
      ), // using mui link
    },
    {
      field: "role",
      headerName: translations["logins.role"],
      valueFormatter(params) {
        return params.value.name;
      },
    },
    {
      field: "contextId",
      headerName: translations["logins.context"],
      valueFormatter(params) {
        return contexts.find((context) => context.id === params.value)?.name;
      },
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          onClick={() => {
            let login = logins.find((login) => login.id === params.id);
            if (typeof login !== "undefined") {
              setSelectedLoginId(params.row.id);
              let obj = getFlatObj(login as IRecord);
              formik.resetForm({
                touched: {},
                errors: {},
              });
              setInitialValues(obj);
              formik.setValues(obj, false);
              setMyDialogTitle(translations["logins.editlogintitle"]);
              setOpen(true);
            }
          }}
          label="Edit"
        />,
      ],
    },
  ];



  let rows = logins;

  return (
    <>
      <Box p={1}>
        <TextField
          label={translations["logins.search"]}
          value={search}
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        ></TextField>
        {auth?.user?.role?.id === 1 ? (
          <FormControl
            sx={{ minWidth: "231px" }}
            size="small"
            style={{ marginLeft: 8 }}
          >
            <InputLabel id="contextlabel">
              {translations["roles.context"]}
            </InputLabel>
            <Select
              labelId="contextlabel"
              value={contextId}
              onChange={(e) => {
                setContextId(e.target.value);
              }}
            >
              <MenuItem value="" key={0}>
                -
              </MenuItem>
              {contexts.map((cat) => {
                return (
                  <MenuItem key={cat.id} value={cat.id}>
                    {cat.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        ) : null}
        <Button
          style={{ marginLeft: 8 }}
          variant="contained"
          onClick={() => {
            let obj = getFlatObj({ id: -1 });
            formik.resetForm({
              values: obj,
              touched: {},
              errors: {},
            });
            setInitialValues(obj);
            formik.setValues(obj);
            setMyDialogTitle(translations["logins.newlogintitle"]);
            setOpen(true);
          }}
        >
          {translations["logins.add"]}
        </Button>
      </Box>
      <DataGrid
        rows={rows}
        columns={columns}
        localeText={gridtranslations}
      ></DataGrid>
      <Dialog
        className="window"
        open={open}
        onClose={() => {
          setSelectedLoginId(null);
          setOpen(false);
        }}
        scroll="body"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>{myDialogTitle}</DialogTitle>
        <DialogContent dividers={false}>
          <form onSubmit={formik.handleSubmit}>
            <Box flex="1" display="flex" flexDirection="column" gap={1} p={2}>
              <Box display="flex" flexDirection={"row"} className="fieldlabel">
                <Box width="260px" flexShrink={0}>
                  <Typography>{translations["logins.username"]}</Typography>
                </Box>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    error={Boolean(
                      formik.touched.username && formik.errors.username
                    )}
                    {...formik.getFieldProps("username")}
                  ></TextField>
                  <DisplayErrors
                    name="username"
                    touched={Boolean(formik.touched.username)}
                    errors={formik.errors.username}
                  />
                </FormControl>
              </Box>
              <Box display="flex" flexDirection={"row"} className="fieldlabel">
                <Box width="260px" flexShrink={0}>
                  <Typography>{translations["logins.password"]}</Typography>
                </Box>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    error={Boolean(
                      formik.touched.password && formik.errors.password
                    )}
                    {...formik.getFieldProps("password")}
                  ></TextField>
                  <DisplayErrors
                    name="password"
                    touched={Boolean(formik.touched.password)}
                    errors={formik.errors.password}
                  />
                </FormControl>
              </Box>
              <Divider />
              <Box display="flex" flexDirection={"row"} className="fieldlabel">
                <Box width="260px" flexShrink={0}>
                  <Typography>{translations["logins.firstname"]}</Typography>
                </Box>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    error={Boolean(
                      formik.touched.firstname && formik.errors.firstname
                    )}
                    {...formik.getFieldProps("firstname")}
                  ></TextField>
                  <DisplayErrors
                    name="firstname"
                    touched={Boolean(formik.touched.firstname)}
                    errors={formik.errors.firstname}
                  />
                </FormControl>
              </Box>
              <Box display="flex" flexDirection={"row"} className="fieldlabel">
                <Box width="260px" flexShrink={0}>
                  <Typography>{translations["logins.lastname"]}</Typography>
                </Box>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    error={Boolean(
                      formik.touched.lastname && formik.errors.lastname
                    )}
                    {...formik.getFieldProps("lastname")}
                  ></TextField>
                  <DisplayErrors
                    name="lastname"
                    touched={Boolean(formik.touched.lastname)}
                    errors={formik.errors.lastname}
                  />
                </FormControl>
              </Box>
              <Box display="flex" flexDirection={"row"} className="fieldlabel">
                <Box width="260px" flexShrink={0}>
                  <Typography>{translations["logins.email"]}</Typography>
                </Box>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    error={Boolean(formik.touched.email && formik.errors.email)}
                    {...formik.getFieldProps("email")}
                  ></TextField>
                  <DisplayErrors
                    name="email"
                    touched={Boolean(formik.touched.email)}
                    errors={formik.errors.email}
                  />
                </FormControl>
              </Box>
              <Divider />
              <Box display="flex" flexDirection={"row"} className="fieldlabel">
                <Box width="260px" flexShrink={0}>
                  <Typography>{translations["logins.context"]}</Typography>
                </Box>
                <FormControl fullWidth>
                  <Select
                    name="contextId"
                    size="small"
                    value={formik.values.contextId}
                    error={Boolean(
                      formik.touched.contextId && formik.errors.contextId
                    )}
                    onChange={(e) => {
                      formik.setFieldValue("contextId", e.target.value);
                    }}
                  >
                    {contexts.map((context) => {
                      return (
                        <MenuItem key={context.id} value={context.id}>
                          {context.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <DisplayErrors
                    name="role"
                    touched={Boolean(formik.touched.contextId)}
                    errors={formik.errors.contextId}
                  />
                </FormControl>
              </Box>
              <Box display="flex" flexDirection={"row"} className="fieldlabel">
                <Box width="260px" flexShrink={0}>
                  <Typography>{translations["logins.role"]}</Typography>
                </Box>
                <FormControl fullWidth>
                  <Select
                    name="roleId"
                    size="small"
                    value={formik.values.roleId}
                    error={Boolean(formik.touched.role && formik.errors.role)}
                    onChange={(e) => {
                      formik.setFieldValue("roleId", e.target.value);
                    }}
                  >
                    {roles.map((role) => {
                      return (
                        <MenuItem key={role.id} value={role.id}>
                          {role.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <DisplayErrors
                    name="role"
                    touched={Boolean(formik.touched.roleId)}
                    errors={formik.errors.roleId}
                  />
                </FormControl>
              </Box>
              {formik.values.roleId > 2 ?
                formik.initialValues.roleId !== formik.values.roleId ?
                  (<Typography sx={{alignItems:'center', flexDirection:'row', display: 'flex', lineHeight:0}} color="red"><NewReleasesIcon sx={{marginRight: '0.5rem'}}/>{translations["logins.roleChangeSave"]}</Typography>) :
                  (
                    <>
                      <Box mt={'2rem'}>
                        {" "}
                        <Button
                          style={{}}
                          variant="contained"
                          onClick={() => {
                            /*let obj = getFlatObj({ id: -1 });
                            formik.resetForm({
                              values: obj,
                              touched: {},
                              errors: {},
                            });
                            setInitialValues(obj);
                            formik.setValues(obj);
                            setMyDialogTitle(translations["logins.newlogintitle"]);*/

                            //add edge case where the button is clicked but was another role originally ...
                            //do an intermediate save

                            setModalState(prev => ({
                              ...prev, addActivityModal: {
                                open: true,
                                loginSettings: {
                                  loginId: formik.values.id,
                                  loginAccess: loginAccessData
                                }

                              }
                            }))
                          }}
                        >
                          {translations["logins.addAccess"]}
                        </Button>
                      </Box>
                      {(loginAccessData.length > 0) ? (
                        <DataGrid
                          rows={loginAccessData}
                          className={'login-access-data-grid'}
                          columns={[
                            {

                              field: "access",
                              headerName: translations["logins.access"],
                              flex: 1,
                              valueGetter: (params) => {

                                const getEvent = () => {
                                  return "Event: " + params.row.event.translations[params.row.event.defaultLanguage].title;
                                }

                                if (params.row.activityId) {
                                  return "Activity: " + params.row.activity.translations[params.row.activity.defaultLanguage].title + "\n" + getEvent();
                                }
                                if (params.row.blockId) {
                                  return "Block: " + params.row.block.translations[params.row.block.defaultLanguage].title;
                                }
                                if (params.row.eventId) {
                                  getEvent();
                                }
                                return "";
                              },
                            },
                            {
                              field: "actions",
                              type: "actions",
                              getActions: (params: GridRowParams) => [
                                <GridActionsCellItem
                                  icon={<ClearIcon />}
                                  onClick={() => {
                                    setModalState((prev) => ({
                                      ...prev,
                                      removeConfirm: { ...prev.removeConfirm, open: true, loginId: params.row.loginId, loginAccessId: params.row.id }
                                    }))
                                  }}
                                  label="Edit"
                                />,
                              ]
                            },
                          ]}
                          localeText={gridtranslations}
                        ></DataGrid>
                      ) : null}
                    </>
                  )
                : null}
            </Box>
          </form>
        </DialogContent>
        <SaveBar
          formik={formik}
          onCancel={() => {
            setSelectedLoginId(null);
            setOpen(false);
          }}
          manualSubmit={true}
        />
      </Dialog>
      <AddActivityModal events={events} loginSettings={modalState.addActivityModal.loginSettings} open={modalState.addActivityModal.open} closeModal={() => { handleModalClose('addActivityModal') }} localText={gridtranslations} />
      <ImprovedChoiceDialog
        open={modalState.removeConfirm.open}
        title={translations["logins.access.removeConfirm"]}
        description={translations["logins.access.removeConfirmDescription"]}
      >
        {() => (
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <Button
              variant="contained"
              onClick={() => {
                if (modalState.removeConfirm.loginAccessId) {
                  removeLoginAccessRecord(modalState.removeConfirm.loginAccessId, () => {

                    setModalState((prev) => ({
                      ...prev,
                      removeConfirm: {
                        ...prev.removeConfirm,
                        open: false,
                        loginAccessId: null
                      }
                    }))
                  });
                }
              }}
            >
              {translations["confirm"]}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleModalClose('removeConfirm')
              }}
            >
              {translations["cancel"]}
            </Button>
          </Box>
        )
        }
      </ImprovedChoiceDialog>
    </>
  );
}

export default Logins;
