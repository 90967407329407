import {
    Box,
    Button,
    IconButton,
    List,
    ListItem,
    MenuItem,
    Select,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useField } from "formik";
import { MyCheckBoxInputProps } from "../../../../../../templates/Interfaces";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";


import OptionContainer from "./OptionContainer";
import { MyTextField } from "../../Fields";
import FileSelectModal from "../../modals/FileSelectModal";
import { getTranslations } from "../../../../../../../translations/Translations";

interface SrcItemProps {
    src: string;
}
export const MultiSrcSelector = (props: MyCheckBoxInputProps) => {
    const { label, name } = props;
    const [field, meta, helpers] = useField(name);
    const translations = getTranslations();

    const [srcs, setSrcs] = useState<SrcItemProps[]>(
        field.value || []
    );

    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
    const [showModal, setShowModal] = useState<boolean>(false);

    useEffect(() => {
        if (srcs !== field.value) {
            setSrcs(field.value || []);
        }
    }, [field.value]);

    useEffect(() => {
        updateField();
    }, [srcs]);

    const updateField = () => {
        helpers.setValue(srcs);
    };

    const onFileSelect = (selectedImageUrl: {url: string, videoSrc: string}) => {
        if (selectedIndex !== null) {
            updateSrc(selectedIndex, selectedImageUrl.url)
        }
    }

    const updateSrc = (index: number, src: string) => {

        setSrcs((prev) => {
            return prev.map((item, i) => {
                if (i === index) {
                    return { src: src };
                }
                return item;
            });
        });
    };

    const onListItemRemove = (index: number) => {
        setSrcs((prev) => {
            return prev.filter((_, i) => i !== index);
        });
    };

    return (
        <>
            <Box mb={"1rem"}>{label}</Box>
            <List>
                {srcs && srcs.map((srcItem: SrcItemProps, index: number) => {
                    return (<ListItem key={index}>
                        {srcItem.src === '' ? (
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1, width: '100%' }}>
                                <Button onClick={() => {
                                    setSelectedIndex(index);
                                    setShowModal(true);
                                }}>{translations['event.website.options.fileSelect']}</Button>
                            </Box>
                        ) : (
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1, width: '100%', justifyContent: 'space-between' }}>
                                <Box mt={2} sx={{ maxHeight: '100px', maxWidth: '100px' }} component='img' src={srcItem.src}></Box>
                                <Button onClick={() => {
                                    onListItemRemove(index)
                                }}><ClearIcon /></Button>
                            </Box>
                        )}
                    </ListItem>)
                })}
            </List>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <IconButton
                    onClick={() => {
                        setSrcs((prev) => [
                            ...prev,
                            {
                                src: ""
                            },
                        ]);
                    }}
                >
                    <AddIcon />
                </IconButton>
            </Box>
            <FileSelectModal open={showModal} onSelectImage={onFileSelect} closeModal={() => {
                setSelectedIndex(null);
                setShowModal(false)
            }} />
        </>
    );
};

export default MultiSrcSelector;
