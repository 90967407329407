import { Box } from "@mui/material"
import React, { useState } from "react";
import { ReactWidget, ReactWidgetWithStyles, WidgetRecord } from "../../Interfaces";
import { ContainedImgSection, ContainedSection, WidgetField } from "../../apella/EventPage";
import withStandardStyles from "./withStandardStyles";
import { generateMarginString } from "../../../events/event/website/components/widgets/options/MarginSelector";


const SectionWidget = ({ widget, childWidgets, allWidgets, standardStyles }: ReactWidgetWithStyles) => {
    const config = widget.config || {};
    const backgroundColor = config['backgroundColor'] || 'transparent';
    const backgroundImageSrc = config['backgroundImageSrc'];
    const slantedTop = config['slantedTop'] === "true";
    const slantedBottom = config['slantedBottom'] === 'true';
    const alignItems = config['alignItems'];
    const margin = config['margin'];
    const padding = config['padding'];

    const styles: any = {};

    if (backgroundColor) {
        console.log('backgroundColor', backgroundColor);
        styles.backgroundColor = backgroundColor;
    }

    if (backgroundImageSrc) {
        styles.backgroundImage = `url("${backgroundImageSrc}")`;
        styles.backgroundPosition = 'center center';
        styles.backgroundSize = 'cover';
    }

    if (margin) {
        styles.margin = generateMarginString(margin);
    }

    if (padding) {
        styles.padding = generateMarginString(margin);
    }

    if (alignItems) {
        styles.alignItems = alignItems;
    }


    /*column config */
    const columnStyles: any = {};
    const columnPadding = config['columnPadding'];
    const columnMargin = config['columnMargin'];
    const columnBackgroundColor = config['columnBackgroundColor'];

    if (columnMargin) {
        columnStyles.margin = generateMarginString(columnMargin);
    }
    if (columnPadding) {
        columnStyles.padding = generateMarginString(columnPadding);
    }

    if (columnBackgroundColor) {
        columnStyles.backgroundColor = columnBackgroundColor;
    }

    const renderChild = (i: number, columns: number) => {

        /*if (i === (columns - 1)) {
            if (columnMargin) {
                const newMargin = [...columnMargin];
                newMargin[1] = 0;
                columnStyles.margin = generateMarginString(newMargin);
            }
        }*/
        return (
            <Box key={i} sx={{ flex: 1 }} >
                <Box sx={{ ...columnStyles }}>
                    {childWidgets?.filter((childWidget: WidgetRecord) => childWidget.config.column === i).sort((a, b) => a.config.sortorder - b.config.sortorder).map((childWidget: WidgetRecord, index: number, filteredChildWidgetArray: WidgetRecord[]) => {
                        if (allWidgets) {
                            const children = allWidgets.filter((filterWidget: WidgetRecord) => filterWidget.parentId === childWidget.id);
                            return (<WidgetField key={index} widget={childWidget} widgetChildren={children} allWidgets={allWidgets} />)
                        }

                    })}
                </Box>
            </Box>
        );
    };
    const columns = [];
    const columnsCount = parseInt(widget.config.columns);
    if (!isNaN(columnsCount) && columnsCount > 0) {

        for (let i = 0; i < widget.config.columns; i++) {
            columns.push(renderChild(i, widget.config.columns));
        }

        if (backgroundImageSrc && columns.length < 1) {
            return (
                <ContainedImgSection img={backgroundImageSrc} maxHeight={800} slopedTop={slantedTop} slopedBottom={slantedBottom} />
            );
        } else {
            return (
                <ContainedSection img={backgroundImageSrc} passedStyles={{ ...standardStyles }} color={backgroundColor} slopedTop={slantedTop} slopedBottom={slantedBottom} >
                    <Box sx={{ ...styles, flexWrap: 'wrap' }} display="flex" >
                        {columns}
                    </Box>
                </ContainedSection>
            )
        }
    } else {
        return (
            <Box>{widget.type}</Box>
        );
    }
};

export default withStandardStyles(SectionWidget);