import { Box } from "@mui/material"
import React from "react"
import { FieldRecordWidget } from "../../../../../../interfaces/Interfaces";

const Text = ({ registrationfield }:  FieldRecordWidget ) => {
    console.log(registrationfield);
    return (
        <Box className="text" sx={{display: 'flex',flexDirection: 'column'}} dangerouslySetInnerHTML={{__html: registrationfield.translations[registrationfield.defaultLanguage].title || 'Text'}}></Box>
    )
}

export default Text;