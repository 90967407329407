import { FormikErrors } from "formik";
import React from "react";

interface DisplayErrorsProps {
  name: string;
  touched?: boolean;
  errors:
    | string
    | string[]
    | FormikErrors<any>
    | FormikErrors<any>[]
    | undefined;
}

interface DisplayErrorProps {
  error: string | FormikErrors<any>;
  name: string;
}

export function DisplayError(props: DisplayErrorProps): any {
  let error = props.error;
  if (typeof error === "string") {
    return <div className="errormessage">{error}</div>;
  } else {
    if ("error" in error && typeof error.error === "string") {
      return <div className="errormessage">{error.error}</div>;
    } else {
      console.log("problem with <DisplayErrors> for field: " + props.name);
      return <div className="errormessage">Something happened</div>;
    }
  }
}
export function DisplayErrors(props: DisplayErrorsProps): any {
  if (props.touched === false) {
    return null;
  }

  if (typeof props.errors === "undefined") {
    return null;
  } else if (
    typeof props.errors === "object" &&
    "error" in props.errors &&
    typeof props.errors.error === "undefined"
  ) {
    return null;
  }

  if (Array.isArray(props.errors)) {
    return (
      <div>
        {props.errors.map((error, index) => (
          <DisplayError key={index} name={props.name} error={error} />
        ))}
      </div>
    );
  } else {
    return <DisplayError name={props.name} error={props.errors} />;
  }
}
