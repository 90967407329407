import React from 'react';

import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import { getTranslations, getWeekDays } from "../../translations/Translations";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { isWeekend } from 'date-fns';

import './Calendar.scss';

dayjs.extend(weekday);
//helper functions

interface Day {
    dateString: string,
    dayOfMonth: number,
    isCurrentMonth: boolean,
    isPreviousMonth?: boolean,
    isNextMonth?: boolean,
    isToday?: boolean
}

const today = new Date();
const todayDateString = dayjs(`${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`).format("YYYY-MM-DD");

const translations = getTranslations();

const getWeekday = (dateString: string) => {
    return dayjs(dateString).weekday();
}

const getNumberOfDaysInMonth = (year: number, month: number) => {
    return dayjs(`${year}-${month}-01`).daysInMonth();
}

const generateDayObjects = (year: number, month: number, dayIndex: number, isCurrentMonth: boolean, isPreviousMonth?: boolean, isNextMonth?: boolean,): Day => {
    const dateString = `${year}-${month}-${dayIndex}`;
    const formattedDate = dayjs(dateString).format("YYYY-MM-DD");

    return {
        dateString: formattedDate,
        dayOfMonth: dayIndex,
        isCurrentMonth: isCurrentMonth,
        isPreviousMonth: isPreviousMonth,
        isNextMonth: isNextMonth,
        isToday: formattedDate === todayDateString
    };
}

const createDaysForCurrentMonth = (year: number, month: number): Day[] => {
    const numberOfDays = getNumberOfDaysInMonth(year, month);
    const daysArray: Day[] = [];


    for (let dayIndex = 1; dayIndex <= numberOfDays; dayIndex++) {
        daysArray.push(generateDayObjects(year, month, dayIndex, true));
    }

    return daysArray;
}

const createDaysForPreviousMonth = (year: number, month: number, currentMonthDays: Day[]): Day[] => {
    const firstDayOfTheMonthWeekday = getWeekday(currentMonthDays[0].dateString);
    const previousMonthLastMondayDayOfMonth = dayjs(currentMonthDays[0].dateString)
        .subtract(firstDayOfTheMonthWeekday, "day")
        .date();
    const previousMonth = dayjs(`${year}-${month}-01`).subtract(1, "month");

    const visibleDaysFromPreviousMonth: Day[] = [];

    for (let index = 0; index < firstDayOfTheMonthWeekday; index++) {
        const dayOfMonth = previousMonthLastMondayDayOfMonth + index;
        visibleDaysFromPreviousMonth.push(generateDayObjects(previousMonth.year(), previousMonth.month() + 1, dayOfMonth, false, true));
    }

    return visibleDaysFromPreviousMonth;
}

const createDaysForNextMonth = (year: number, month: number, currentMonthDays: Day[]): Day[] => {
    const lastDayOfTheMonthWeekday = getWeekday(currentMonthDays[currentMonthDays.length - 1].dateString);
    const nextMonth = dayjs(`${year}-${month}-01`).add(1, "month");
    const visibleNumberOfDaysOfNextMonth = 6 - lastDayOfTheMonthWeekday;
    const visibleDaysOfNextMonth: Day[] = [];

    for (let index = 0; index < visibleNumberOfDaysOfNextMonth; index++) {
        const dayOfMonth = index + 1;
        visibleDaysOfNextMonth.push(generateDayObjects(nextMonth.year(), nextMonth.month() + 1, dayOfMonth, false, false, true));
    }

    return visibleDaysOfNextMonth;
}
//END HELPERS

/* ACTUAL CMP */
interface CalendarProps {
    yearAndMonth: number[],
    onYearAndMonthChange: Function,
    renderDay: Function
}

export const CalendarComponent: React.FC<CalendarProps> = ({
    onYearAndMonthChange,
    yearAndMonth,
    renderDay
  }) => {
    const [year, month] = yearAndMonth;

    let currentMonthDays = createDaysForCurrentMonth(year, month);
    let previousMonthDays = createDaysForPreviousMonth(year, month, currentMonthDays);
    let nextMonthDays = createDaysForNextMonth(year, month, currentMonthDays);

   
    let calendarGridDaysObject = [
        ...previousMonthDays,
        ...currentMonthDays,
        ...nextMonthDays
    ]

    const generateClassNames = (day: Day) => {
        let classNameString = 'day-grid-item-container';

        if (day.isCurrentMonth) {
            classNameString += ' current-month';
        }

        if (day.isToday) {
            classNameString += ' today';
        }

        return classNameString;
    }

    return (
        <div className="calendar-root">
            <div className="days-of-week">
                {getWeekDays().map((day) => (
                    <div
                        key={day}
                    >
                        {day}
                    </div>
                ))}
            </div>
            <div className="days-grid">
                {calendarGridDaysObject.map((day) => (
                    renderDay(day, generateClassNames)
                ))}
            </div>
        </div>
    )
}

export const DayHeader = (props: {day: Day}) => {
    const {day} = props;
    const className = `day-grid-item-header ${day.isToday ? "today" : ""}`;

    return <div className={className}>{day.dayOfMonth} {day.isToday ? translations['calendar.today'] : ""}</div>
}


