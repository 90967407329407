import React, { useState, useEffect } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, List, ListItem, MenuItem, Paper, Select, SelectChangeEvent, Tab, Tabs, TextField, Typography } from "@mui/material";
import { getTranslations } from "../../../../../../translations/Translations";
import { EventRecord, EventRouterResponse, Font, IRecord, WebpageRecord } from "../../../../../../interfaces/Interfaces";
import DraggableList from "../../../../../../components/DraggableList/DraggableList";
import { DropResult } from "react-beautiful-dnd";
import { removeAllListeners } from "process";
import { useNavigate, useRevalidator, useRouteLoaderData } from "react-router-dom";
import AlertDialog from "../../../../../../components/AlertDialog";
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from '@mui/icons-material/Check';
import { fontList } from "../../../../../../fonts/fonts";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box mt={'1rem'}>{children}
                </Box>
            )}
        </div>
    );
}


const TypographyWIndowOption = ({ group, currentFont, onSave }: { group: string, currentFont: string, onSave: Function }) => {
    const translations = getTranslations();
    const [edit, setEdit] = useState<boolean>(false);
    const [activeFont, setFont] = useState<number>(fontList.findIndex(font => font.name === currentFont));
    const handleChange = (event: SelectChangeEvent) => {
        setFont(Number(event.target.value));
    }
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography sx={{ fontFamily: fontList[activeFont].value }}>{translations[`website.settings.tab.fonts.${group}`]}</Typography>
            {edit ? (
                <>
                    <Select value={activeFont.toString()} sx={{ flex: 1, margin: '0 1rem' }} id="font" onChange={handleChange}>
                        {fontList.map((font: Font, index) => <MenuItem sx={{ fontFamily: font.value }} key={index} value={index.toString()}>{font.name}</MenuItem>)}
                    </Select>
                    <IconButton onClick={() => {
                        onSave(group, activeFont);
                        setEdit(false)
                    }}>
                        <CheckIcon />
                    </IconButton>
                </>) : (<IconButton onClick={() => { setEdit(true) }}>
                    <EditIcon></EditIcon>
                </IconButton>)}
        </Box>
    )
}

const TypographyWindow = () => {
    const { event } = useRouteLoaderData("event") as EventRouterResponse;
    const revalidator = useRevalidator();

    const saveFont = (group: string, font: Font, event: EventRecord) => {
        const eventConfig = JSON.parse(event.jsonConfig) || {};
        eventConfig.fonts = {
            ...eventConfig.fonts,
            [group]: font.name
        }
        console.log('eventConfig', eventConfig);
        fetch("/api/events/" + event.id + '/', {
            method: "put",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                jsonConfig: JSON.stringify(eventConfig)
            })
        }).then(() => {
            revalidator.revalidate();
        })
    }

    const handleSaveFont = (group: string, font: number) => {
        saveFont(group, fontList[font], event);
    };

    const parsedJsonConfig = JSON.parse(event.jsonConfig);

    let usedFonts = {
        primary: 'Arial',
        secondary: 'Arial',
        accent: 'Arial'
    };

    if (parsedJsonConfig && parsedJsonConfig.fonts) {
        usedFonts = {
            ...usedFonts,
            ...parsedJsonConfig.fonts
        }
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <TypographyWIndowOption group={'primary'} currentFont={usedFonts.primary} onSave={handleSaveFont} />
            <TypographyWIndowOption group={'secondary'} currentFont={usedFonts.secondary} onSave={handleSaveFont} />
            <TypographyWIndowOption group={'accent'} currentFont={usedFonts.accent} onSave={handleSaveFont} />
        </Box>
    );
};

const PageSettingsModal = ({ open, closeModal, webpages, revalidator }: { open: boolean, closeModal: Function, webpages: IRecord[]; revalidator: { state: string, revalidate: Function } }) => {

    const translations = getTranslations();
    const [localWebpages, setLocalWebpages] = useState<IRecord[]>([]);
    const [activeWebPage, setActiveWebPage] = useState<IRecord | null>(null);
    const [alertShown, setAlertShown] = useState<boolean>(false);
    const [editFieldValue, setEditFieldValue] = useState<string>('');
    const [editMode, setEditMode] = useState<boolean>(false);
    const navFn = useNavigate();

    useEffect(() => {
        setLocalWebpages([...webpages]);
        if (activeWebPage) {
            const currentActiveWebpageId = activeWebPage.id;
            setActiveWebPage(() => {
                const currentActivePage = webpages.find((page) => page.id === currentActiveWebpageId);
                if (currentActivePage) {
                    return currentActivePage
                } else {
                    return null;
                }
            })
        }
    }, [webpages])

    useEffect(() => {
        if (open === true) {
            setLocalWebpages([...webpages]);
            setActiveWebPage(null);
        }
    }, [open])

    const onDragEnd = ({ destination, source }: DropResult) => {
        // dropped outside the list
        //check if activePage is the one we're dragging ...
        if (!destination) return;

        const newList = [...localWebpages];
        const [removed] = newList.splice(source.index, 1);
        newList.splice(destination.index, 0, removed)
        setLocalWebpages(newList);

        let promises: Promise<Response>[] = [];
        newList.forEach((val, index) => promises.push(updateWebPagePromise({
            sortorder: index
        }, val.id)))

        Promise.all(promises).then(() => {
            revalidator.revalidate();
        });
    };

    const onRemoveWebPageClick = (id: number) => {
        //check if we have atleast one page ... (can't remove last page) 
        if (localWebpages.length > 1) {
            fetch("/api/webpages/" + id, {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                    "Content-Type": "application/json",
                }
            })
                .then((res) => res.json())
                .then((jsondata) => {
                    if (jsondata.success) {
                        navFn(`./`, { replace: true });
                        revalidator.revalidate();
                        closeModal();
                    }
                })
        } else {
            setAlertShown(true);
        }
    }

    const updateWebPagePromise = (values: any, id: number) => {
        return fetch("/api/webpages/" + id + "/", {
            method: "PUT",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                "Content-Type": "application/json",
            },
            body: JSON.stringify(values)
        })
    }

    const updateWebPage = (values: any) => {
        //check if current webpage is the one loaded ... if so / we need to update window.location aswell.
        if (activeWebPage) {
            let onActiveWebpage = false;
            if (window.location.pathname.includes(activeWebPage.translations["de"].title)) {
                onActiveWebpage = true;
            }
            updateWebPagePromise(values, activeWebPage.id).then(() => {
                if (onActiveWebpage) {
                    const pathParts = window.location.pathname.split('/');
                    const newPath = pathParts.slice(0, -1).join('/') + '/' + values.title;
                    navFn(newPath, { replace: false });
                    revalidator.revalidate();
                } else {
                    revalidator.revalidate();
                }

            })
        }
    }

    const [currentTab, setCurrentTab] = useState<number>(0);
    const handleChange = function (e: any, newValue: number) {
        setCurrentTab(newValue);
    };

    return (
        <>
            <Dialog
                className="window"
                open={open}
                scroll="body"
                fullWidth={true}
                maxWidth="sm"
            >
                <DialogTitle>{translations["website.settings.title"]}</DialogTitle>
                <DialogContent dividers={false}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={currentTab} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label={translations["website.settings.tab.pagesettings"]} id="tab-0" />
                            <Tab label={translations["website.settings.tab.fonts"]} id="tab-1" />
                        </Tabs>
                    </Box>
                    <CustomTabPanel value={currentTab} index={0}>
                        <Box display="flex" flexDirection="row" sx={{ border: '1px solid rgb(235,235,235)', padding: '1rem' }}>
                            <Box flexDirection="row" display="flex" sx={{ borderRight: '1px solid rgb(235,235,235)', paddingRight: '1rem' }}>
                                <DraggableList items={localWebpages.map((webpage, index) => { return { title: webpage.translations["de"].title, id: index.toString() } })} onItemClick={(webpageObj: any) => {
                                    setEditMode(false);
                                    setActiveWebPage(localWebpages[webpageObj.id]);
                                }} onDragEnd={onDragEnd} />
                            </Box>
                            <Box flex={1} flexDirection="row" display="flex" sx={{ paddingLeft: '1rem' }}>
                                {activeWebPage && (
                                    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: "space-between" }}>
                                        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} >
                                            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <Box mr={'1rem'} style={{ fontWeight: 'bold' }}>{translations["website.title"]}:</Box>
                                                {editMode ? (<TextField value={editFieldValue} onChange={(e) => setEditFieldValue(e.target.value)} />) : (<Box>{activeWebPage.translations["de"].title}</Box>)}
                                            </Box>


                                            {editMode ? (<>
                                                <IconButton onClick={() => {
                                                    if (editFieldValue !== '') {
                                                        updateWebPage({
                                                            language: activeWebPage.defaultLanguage,
                                                            title: editFieldValue
                                                        });
                                                        setEditMode(false)
                                                    }
                                                }}><CheckIcon /></IconButton>
                                                <IconButton onClick={() => { setEditMode(false) }}><ClearIcon /></IconButton>
                                            </>) : (<IconButton onClick={() => {
                                                setEditFieldValue(activeWebPage.translations["de"].title);
                                                setEditMode(true);
                                            }
                                            }><EditIcon /></IconButton>)}
                                        </Box>
                                        <Box ><Button variant="contained" onClick={() => {
                                            onRemoveWebPageClick(activeWebPage.id);
                                        }}>{translations['remove']}</Button></Box>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </CustomTabPanel>
                    <CustomTabPanel value={currentTab} index={1}>
                        <List>
                            <ListItem sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <TypographyWindow />
                            </ListItem>
                        </List>
                    </CustomTabPanel>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={() => {
                            closeModal();
                        }}
                    >
                        {translations["cancel"]}
                    </Button>
                </DialogActions>
            </Dialog>
            <AlertDialog
                open={[alertShown, setAlertShown]}
                title={translations["website.settings.lastItemTitle"]}
                description={translations["website.settings.lastItemMessage"]}
            />
        </>

    )
}
export default PageSettingsModal;