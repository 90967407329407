import React, { useEffect, useRef } from 'react';

const CustomAudio = (props: { audioUrl: string, play: boolean}) => {
  const { audioUrl, play } = props;
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    if (audioRef.current && play) {
      audioRef.current.play()
        .catch((error) => {
          console.error('Audio play error:', error);
        });
    }
  }, [play]);

  return (
    <div>
      <audio src={audioUrl} ref={audioRef} preload="auto" />
    </div>
  );
};

export default CustomAudio;