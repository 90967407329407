import * as React from 'react';
import './DraggableList.scss';
import DraggableListItem from './DraggableListItem';
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder
} from 'react-beautiful-dnd';
import { useEffect, useState } from 'react';
import { Box, List, ListItem } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

export type Item = {
  id: string;
  title: string;
};

export type DraggableListProps = {
  listSx?: {},
  items: any[];
  onDragEnd: OnDragEndResponder;
  onItemClick?: Function
  draggableItem?: () => JSX.Element,
  children?: (helper: {
    getIndex: () => number;
    getItem: () => any,
    getSnapShot: () => any,
    getProvided: () => any,
    getDragIcon: () => any
  }
  ) => React.ReactNode;
};


const DraggableList = React.memo(({ listSx, children, draggableItem, items, onDragEnd, onItemClick }: DraggableListProps) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const draggableListChildren = children;
  return (
    <DragDropContext onDragEnd={(result, provided) => {
      if (activeIndex !== null) {
        const { destination, source } = result;
        if (destination) {
          if (source.index === activeIndex) {
            setActiveIndex(destination.index);
          } else if (source.index < activeIndex && destination.index >= activeIndex) {
            setActiveIndex((prev) => prev !== null ? prev - 1 : prev);
          } else if (source.index > activeIndex && destination.index <= activeIndex) {
            setActiveIndex((prev) => prev !== null ? prev + 1 : prev);
          }
        }
      }

      onDragEnd(result, provided);
    }}>
      <Droppable droppableId="droppable-list">
        {provided => (
          <Box sx={listSx ? listSx : null} ref={provided.innerRef} {...provided.droppableProps}>
            {items.map((item, index) => {
              if (draggableListChildren) {
                return (
                  <Draggable key={index} draggableId={index.toString()} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="draggable-item"
                      >
                        <Box>
                          {draggableListChildren({
                            getIndex: () => index,
                            getItem: () => item,
                            getProvided: () => provided,
                            getSnapShot: () => snapshot,
                            getDragIcon: () => <DragIndicatorIcon />
                          })}
                        </Box>
                      </div>
                    )}
                  </Draggable>
                )
              } else {
                return (
                  <DraggableListItem item={item} activeIndex={activeIndex} index={index} key={index} onDraggableListItemClick={(item: Item) => {
                    if (onItemClick) {
                      setActiveIndex(index);
                      onItemClick(item);
                    }
                  }} />
                )
              }
            })}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
});

export default DraggableList;
