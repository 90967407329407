import { Box } from "@mui/material"
import { ReactWidget, ReactWidgetWithStyles, WebpageRouterResponse, WidgetRecord } from "../../../../../../interfaces/Interfaces"
import React, { useState } from "react"
import { Draggable, Droppable } from "react-beautiful-dnd"
import { isDroppableSignal } from "../../../../../../DroppableSignal";
import { WidgetContainer } from "../../Webpage";
import { useRouteLoaderData } from "react-router-dom";
import withStandardProps from "./HOCs/withStandardStyles";
import { generateMarginString } from "./options/MarginSelector";


const SectionWidget = ({ widget, childWidgets, childWidgetFunctions, standardStyles }: ReactWidgetWithStyles) => {
    const { webpage } = useRouteLoaderData(
        "webpage"
    ) as WebpageRouterResponse;

    const config = widget.config || {};
    const backgroundColor = config['backgroundColor'];
    const backgroundImageSrc = config['backgroundImageSrc'];
    const slantedTop = config['slantedTop'];

    const styles: any = {};

    if (backgroundColor) {
        styles.backgroundColor = backgroundColor;
    }

    if (backgroundImageSrc) {
        styles.backgroundImage = `url("${backgroundImageSrc}")`;
        styles.backgroundPosition = 'center center';
        styles.backgroundSize = 'cover';
    }

    /*column config */
    const columnStyles: any = {};
    const columnPadding = config['columnPadding'];
    const columnMargin = config['columnMargin'];
    const columnBackgroundColor = config['columnBackgroundColor'];

    const alignItems = config['alignItems'];

    if (alignItems) {
        styles.alignItems = alignItems;
        columnStyles.alignItems = alignItems;
    }

    if (columnMargin) {
        columnStyles.margin = generateMarginString(columnMargin);
    }
    if (columnPadding) {
        columnStyles.padding = generateMarginString(columnPadding);
    }

    if (columnBackgroundColor) {
        columnStyles.backgroundColor = columnBackgroundColor;
    }

    const [isDropDisabled, setIsDropDisabled] = useState<boolean>(false);
    const renderDroppable = (i: number, columns: number) => {

        //last child no margin right
        /*if (i === (columns - 1)) {
            if (columnMargin) {
                
                const newMargin = [...columnMargin];
                //newMargin[1] = 0;
                columnStyles.margin = generateMarginString(newMargin);
            }
        }*/ 

        return (
            <Box sx={{ ...columnStyles, flex: 1 }} key={i}>
                <Droppable key={`droppable-${widget.id}-${i}`} droppableId={`droppable-id${widget.id}c${i}`} isDropDisabled={isDropDisabled} >
                    {(provided, childSnapShot) => {
                        return (
                            <Box
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                flex="1"
                                onMouseOut={(e) => {
                                    isDroppableSignal.value = false;
                                    if (childWidgetFunctions?.nestedDropFn) {
                                        childWidgetFunctions?.nestedDropFn(false);
                                    }
                                }}
                                onMouseOver={(e) => {
                                    isDroppableSignal.value = true;
                                    if (childWidgetFunctions?.nestedDropFn) {
                                        childWidgetFunctions?.nestedDropFn(true);
                                    }
                                }}
                                overflow="hidden"
                                className={`section-drop-container ${childSnapShot.isDraggingOver ? 'dragging-over' : ''}`}
                                sx={{
                                    minHeight: '300px'
                                }}
                            >
                                {childWidgets?.filter((childWidget: WidgetRecord) => childWidget.config.column === i).sort((a, b) => a.config.sortorder - b.config.sortorder).map((childWidget: WidgetRecord, index: number, filteredChildWidgetArray: WidgetRecord[]) => <Draggable
                                    key={childWidget.id}
                                    draggableId={childWidget.id.toString()}
                                    index={index}
                                >{(provided) => {
                                    const children = webpage.widgets.filter((filterWidget: WidgetRecord) => filterWidget.parentId === childWidget.id);
                                    return (
                                        <WidgetContainer
                                            isBeingDragged={childSnapShot.isDraggingOver}
                                            isDraggable={true}
                                            widgetChildren={children}
                                            isActiveWidget={false}
                                            widget={childWidget}
                                            editWidget={(widget: WidgetRecord) => {
                                                childWidgetFunctions?.edit(widget)
                                            }} removeWidget={(widget: WidgetRecord) => {
                                                childWidgetFunctions?.remove(widget)
                                            }} provided={provided} index={0}
                                            firstChild={index === 0}
                                            lastChild={filteredChildWidgetArray.length - 1 === index}
                                            onResortFn={childWidgetFunctions?.sortFn}
                                            nestedDropFn={setIsDropDisabled}
                                        />
                                    )
                                }}
                                </Draggable>)}
                                {provided.placeholder}
                            </Box>
                        );
                    }}
                </Droppable>
            </Box>
        );
    };
    const columns = [];
    const columnsCount = parseInt(widget.config.columns);
    if (!isNaN(columnsCount) && columnsCount > 0) {

        for (let i = 0; i < widget.config.columns; i++) {
            columns.push(renderDroppable(i, widget.config.columns));
        }

        return (
            <section>
                <Box sx={{ ...styles, ...standardStyles }} display="flex">
                    {columns}
                </Box>
            </section>
        );
    } else {
        return (
            <Box>{widget.type}</Box>
        );
    }
};

export default withStandardProps(SectionWidget);