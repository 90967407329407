import React, { ReactNode, useState, useEffect } from 'react';

import './style/Style.scss';
import { Paper, Box, Typography, Grid, Palette, ButtonProps, SxProps, Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material';
import { CSSProperties } from '@mui/material/styles/createTypography';
import { useColorFromThemePalette } from '../Helpers';
import RegistrationForm from '../components/registrationForm/RegistrationForm';


//image imports
import logo from './images/logo.png';
import wann from './images/wann.png';
import wo from './images/wo.png';
import building from './images/holidayinn.webp';
import gregolis from './images/Gregorellis.jpg';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Link as ScrollLink } from 'react-scroll';
import { scroller } from 'react-scroll';
import { Helmet } from 'react-helmet';
import TitleWidget from '../components/Fields/TitleWidget';
import SubtitleWidget from '../components/Fields/SubTitleWidget';
import ParagraphWidget from '../components/Fields/ParagraphWidget';
import SectionWidget from '../components/Fields/SectionWidget';
import ImageWidget from '../components/Fields/ImageWidget';
import ButtonWidget from '../components/Fields/ButtonWidget';
import { EventRecord, WidgetRecord } from '../Interfaces';
import RegistrationFormWidget from '../components/Fields/RegistrationFormWidget';
import IconListWidget from '../components/Fields/IconListWidget';
import { createThemeObjectFromConfig, stripUnits } from '../../../helpers/Helpers';
import SliderWidget from '../components/Fields/SliderWidget';
import VideoWidget from '../components/Fields/VideoWidget';

let pageWidth = 1170;
const registrierungAnchor = "registrierungAnchor"

const Navigation = (props: { color?: string, justifyContent: string, children: ReactNode }) => {
  const { justifyContent, children, color } = props;
  return (<Box sx={{ backgroundColor: color ? color : "#1f4034", padding: '0 1rem' }}>
    <Grid container justifyContent={justifyContent} sx={{ maxWidth: pageWidth + 'px', margin: '0 auto', padding: '1rem 0' }}>
      {children}
    </Grid>
  </Box>
  )
}

const ScrollIndicator = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [atBottom, setAtBottom] = useState(false);

  const indicatorHeight = 140;
  useEffect(() => {

    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }

      if (((window.innerHeight + Math.round(window.scrollY)) >= document.body.offsetHeight - indicatorHeight) && (pageWidth > window.innerWidth - indicatorHeight)) {
        setAtBottom(true)
      } else {
        setAtBottom(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Paper
      className={`scroll-indicator ${isVisible ? 'visible' : ''}`}
      onClick={() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }}
      sx={{ marginBottom: atBottom ? '4.5rem' : '1rem' }}
    >
      <ScrollLink
        to="event-page"
        spy={true}
        smooth={true}
        duration={500}
      >
        <ArrowUpwardIcon />
      </ScrollLink>
    </Paper>
  );
};

const HeaderNavigation = () => {
  return (
    <Navigation justifyContent="space-between">
      <Grid item sm={true} xs={12} display="flex" sx={{ alignItems: { xs: 'center' }, marginBottom: { sm: '0', xs: '2rem' } }}>

        <img
          src={logo}
          alt="Logo"
          style={{ maxWidth: '100%', maxHeight: '60px' }}
        />
      </Grid>
      <Grid item sm={true} xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <nav>
          <ul>
            <li>
              <ScrollLink
                to="anmelden"
                spy={true}
                smooth={true}
                duration={500}
              >
                Anmelden
              </ScrollLink>
            </li>
          </ul>
        </nav>
      </Grid>
    </Navigation>
  );
}

const FooterNavigation = () => {
  return (
    <Navigation color={'black'} justifyContent="flex-end">
      <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
        <nav>
          <ul>
            <li><a href="https://podcast.apella.de/impressum" target="_blank">Impressum</a></li>
            <li><a href="https://podcast.apella.de/datenschutzhinweise" target="_blank">Datenschutzhinweise</a></li>
          </ul>
        </nav>
      </Grid>
    </Navigation>
  );
}

interface StyledIconButtonProps extends ButtonProps {
  icon: React.ReactElement,
  text: string
}

interface StyledBoxProps {
  imgSrc: string,
  title: string,
  md?: number,
  xs?: number,
  children: ReactNode
}

interface ContainedSectionProps {
  id?: string,
  children?: ReactNode,
  colorName?: keyof Palette,
  color?: string,
  slopedBottom?: boolean,
  slopedTop?: boolean
  sx?: SxProps,
  siblingHasSlopedBottom?: boolean,
  passedStyles?: any
  img?: string
}

interface ContainedImgSectionProps {
  children?: ReactNode,
  maxHeight?: number,
  img?: string,
  slopedBottom?: boolean,
  slopedTop?: boolean
}


const StyledBox = (props: StyledBoxProps) => {
  const { imgSrc, title, md, xs, children } = props;
  return (
    <Grid item md={md} xs={xs} sx={{ display: 'flex', justifyContent: 'flex-start;', alignContent: 'space-around', alignItems: 'center', flexDirection: 'column', textAlign: 'center', marginBottom: { xs: '2rem' } }}>
      <Box component="img" src={imgSrc} alt="test" sx={{ maxWidth: '150px', height: 'auto' }} />
      <Typography color="primary" variant="h3" >{title}</Typography>
      {children}
    </Grid>
  );
}

export const ContainedSection = (props: ContainedSectionProps) => {
  const { id, color, colorName, slopedBottom, slopedTop, children, sx, siblingHasSlopedBottom, passedStyles, img } = props
  const backgroundColor = useColorFromThemePalette(colorName, color || 'transparent');
  let className: string = 'containedSection ';
  if (slopedBottom) {
    className += 'slopedBottom '
  }
  if (slopedTop) {
    className += 'slopedTop '
  }

  //If last child had a slopedBottom - need to adjust the slopedtop so they overlap

  const gridSx = {
    position: 'relative',
    marginBottom: 0 as number | string,
    top: 0 as number | string,
  }

  if (siblingHasSlopedBottom) {
    gridSx.top = 'calc(-100vw * tan(var(--angle) / 2))';
    gridSx.marginBottom = 'calc(-100vw * tan(var(--angle) / 2))';
  }
  let defaultPadding = '64px 16px 64px 16px';

  if (passedStyles) {
    let { padding } = passedStyles;
    if (padding) {
      let addedPadding = padding.split(/\s+/).map((value: string, index: number) => parseInt(value) + parseInt(defaultPadding.split(/\s+/)[index])).join('px ') + 'px';
      defaultPadding = addedPadding;
    }
  }

  let backgroundImgProps = {};

  if (img) {
    backgroundImgProps = {
      backgroundImage: 'url("' + img + '")',
      backgroundPosition: 'center',
      backgroundSize: 'cover'
    }
  }



  return (
    <Grid id={id} className={className} sx={{ ...gridSx }}>
      {slopedTop && (
        <Box sx={{ width: '100%', backgroundColor: backgroundColor, height: 'calc((100vw * tan(var(--angle)) / 2))' }} />
      )}
      <Grid container sx={{ backgroundColor: backgroundColor, padding: defaultPadding, ...backgroundImgProps }}>
        <Grid sx={{ maxWidth: pageWidth + 'px', width: '100%', padding: '0', margin: '0 auto' }}>
          {children}
        </Grid>

      </Grid>
      {slopedBottom && (
        <Box sx={{ width: '100%', backgroundColor: backgroundColor, height: 'calc(100vw*(tan(var(--angle))) / 2)' }} />
      )}
    </Grid>
  );
};

export const ContainedImgSection = (props: ContainedImgSectionProps) => {
  const { img, maxHeight, slopedBottom, slopedTop } = props;
  let className: string = 'imgSection ';
  if (slopedBottom) {
    className += 'slopedBottom '
  }
  if (slopedTop) {
    className += 'slopedTop '
  }
  return (
    <Box className={className} sx={{ width: '100%', height: 'auto', backgroundImage: 'url("' + img + '")', backgroundPosition: 'center', backgroundSize: 'cover' }} />
  )
}

const SectionTitle = (props: { text: string, color?: string, extraText?: ReactNode }) => {
  const { text, color, extraText } = props;
  return (
    <Grid item md={12}>
      <Typography variant="h2" color={color} textAlign={"center"} sx={{ hyphens: 'auto', wordBreak: 'break-word' }}>{text}</Typography>
      {extraText}
    </Grid>
  )
}

interface StyledButtonProps extends ButtonProps {
  text: string
}

const StyledButton = (props: StyledButtonProps) => {
  const { text, ...restProps } = props;
  return (
    <Button variant="outlined" color="primary" sx={{ padding: '0.5rem', minWidth: '30%', fontSize: '1rem', textTransform: 'inherit' }} {...restProps}>
      {text}
    </Button>
  )
}


const AnmeldenButton = () => {
  return <StyledButton onClick={() => {
    scroller.scrollTo(registrierungAnchor, {
      duration: 500,
      delay: 0,
      smooth: true,
      offset: -100
    });
  }} text="Anmelden!" />
}

const Header = () => {
  return (
    <Grid container>
      <Grid item md={12} xs={12} textAlign={"center"}>
        <Typography variant="h1primary" color="primary" sx={{ wordBreak: 'break-word', marginBottom: 0 }}>Blick hinter die Kulissen</Typography>
        <Typography variant="h1primary" color="primary" sx={{ wordBreak: 'break-word', fontSize: '30px' }}>Auch 2024 wird unsere hochkarätige Veranstaltung „Blick hinter die Kulissen“ wieder in Frankfurt stattfinden!</Typography>
        <Grid container alignContent={"center"}>
          <Grid item xs={12} mb={'3rem'}>
            <Typography variant="body1" color="primary" mb={2} textAlign="center">Wie schon in den Jahren zuvor, werden auch dieses Mal - getreu unserem Motto: „Blick hinter die Kulissen“ - ausgewählte Fondsmanager und Portfolio-Experten Ihnen hervorragende Investmentfonds detailliert und ausführlich vorstellen.</Typography>
            <Typography variant="body1" color="primary" mb={2} textAlign="center">Beim gemeinsamen Abendessen am 22. Mai im von uns inzwischen sehr geschätztem Restaurant Gregorellis haben Sie dann Gelegenheit mit den Vertretern der verschiedenen Fondsgesellschaften zu diskutieren und sich mit Ihren Kollegen auszutauschen.</Typography>
          </Grid>
          <Grid item xs={12}>
            <AnmeldenButton />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const Content = () => {
  return (
    <ContainedSection color="#e5e5e5" slopedTop >
      <Grid container mb={'2rem'}>
        <StyledBox md={6} xs={12} imgSrc={wann} title="WANN?">
          <Typography variant="body1" color="primary" sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>22. - 23. Mai 2024</Typography>
        </StyledBox>
        <StyledBox md={6} xs={12} imgSrc={wo} title="WO?">
          <Typography variant="body1" color="primary" sx={{ fontSize: '1.25rem' }}>DWS, Mainzer Landstr. 11-17<br /> 60329 FrankfurtGneisstraße 10<br /></Typography>
        </StyledBox>
        {/*<StyledBox md={4} xs={12} imgSrc="./images/sprecher.png" title="SPRECHER">
      <Typography variant="body1" color="primary" sx={{ fontSize: '1.25rem' }}>Matthias Sprenger{*//*}<br />unreadable*//*}</Typography>
</StyledBox>*/}
      </Grid>
      <Grid item xs={12} mb={'3rem'}>
        <Typography color="primary" variant="h3" textAlign="center">Praktische Informationen</Typography>
        <Typography variant="body1" color="primary" mb={2} textAlign="center"><span style={{ fontWeight: 'bold' }}>Beginn der Veranstaltung</span>: 22. Mai 2024 um 12:30<br /><span style={{ fontWeight: 'bold' }}>Treffpunkt:</span> Rezeption Holiday Inn<br />
          <span style={{ fontWeight: 'bold' }}>Ende der Veranstaltung:</span> 23. Mai 2024 um 13:30<br />
          <span style={{ fontWeight: 'bold' }}>Übernachten:</span> Übernachten werden alle Teilnehmer wieder im fußläufig entfernten <br />Hotel Holiday Inn Alte Oper, Mainzer Landstr. 27, 60329 Frankfurt</Typography>
        <Typography color="primary" variant="h3" textAlign="center">Hinweise</Typography>
        <Typography variant="body1" color="primary" mb={2} textAlign="center">Bei der letzten Veranstaltung haben etliche Teilnehmer die Veranstaltung vorzeitig verlassen, um Züge für die Rückreise zu erreichen.
          Naturgemäß waren diejenigen Gesellschaften, die am 2. Tag referierten, darüber nicht sehr erfreut.
          Wir möchten Sie daher bitten, Ihre Reise zu dieser Veranstaltung dieses Mal so zu planen, dass Sie unbedingt bis zum Ende der Veranstaltung anwesend sein können!</Typography>
        <Typography variant="body1" color="primary" mb={2} textAlign="center">
          Erfahrungsgemäß ist die Veranstaltung aufgrund der limitierten Teilnehmerzahl schnell ausgebucht.<br />Wir empfehlen Ihnen also, sich möglichst bald anzumelden!</Typography>
      </Grid>
    </ContainedSection>
  )
}

const Anmelden = ({ eventData }: { eventData: EventRecord }) => {
  return (
    <ContainedSection color="#1f4034" id='anmelden' slopedBottom >
      <SectionTitle color="common.white" text="HIER BITTE ZUR VERANSTALTUNG ANMELDEN" />
      <Box>
        {eventData.event.id && (
          <RegistrationForm eventId={eventData.event.id.toString()} anchorName={registrierungAnchor} registrationWidgetId={eventData.registrationWidgetId} />
        )}
      </Box>
    </ContainedSection>
  )
}

const DecImage = () => {
  return (
    <ContainedImgSection img={building} maxHeight={800} slopedTop />
  )
}

export const widgettypes = [
  {
    type: "title",
    htmlElement: TitleWidget
  },
  {
    type: "subtitle",
    htmlElement: SubtitleWidget
  },
  {
    type: "paragraph",
    htmlElement: ParagraphWidget
  },
  {
    type: "section",
    htmlElement: SectionWidget
  },
  {
    type: "image",
    htmlElement: ImageWidget
  },
  {
    type: "button",
    htmlElement: ButtonWidget
  },
  {
    type: "registrationform",
    htmlElement: RegistrationFormWidget
  },
  {
    type: 'iconlist',
    htmlElement: IconListWidget
  },
  {
    type: 'slider',
    htmlElement: SliderWidget
  },
  {
    type: "video",
    htmlElement: VideoWidget
  },
]

export const WidgetField = ({ widget, widgetChildren, allWidgets, pageWidth }: {
  widget: WidgetRecord,
  allWidgets: WidgetRecord[],
  widgetChildren?: WidgetRecord[],
  pageWidth?: Number
}) => {
  const { type } = widget;
  const widgetConfig = widgettypes.find(w => w.type === type);
  const WidgetComponent = widgetConfig && widgetConfig.htmlElement;
  return WidgetComponent ? <WidgetComponent widget={widget} childWidgets={widgetChildren} allWidgets={allWidgets} pageWidth={pageWidth} /> : <Box>{widget.type}</Box>;
}


const EventPage = ({ eventData }: { eventData: EventRecord }) => {

  let webpageConfig: any = {}
  if (eventData.webpages[0] && eventData.webpages[0].jsonConfig) {
    webpageConfig = JSON.parse(eventData.webpages[0].jsonConfig);
  }
  
  let showNavigation = true;
  /* SOME MAGIC TO MAKE SCROLLING WORK ... */
  useEffect(() => {
    const appElement = document.querySelector('.app') as HTMLElement;
    if (appElement) {
      appElement.style.overflow = 'visible';
    }
  });

  if (webpageConfig.containerWidth) {
    const strippedPageWidth = stripUnits(webpageConfig.containerWidth);
    if (strippedPageWidth) {
      pageWidth = strippedPageWidth;
    }
  }

  if (typeof webpageConfig.showNavigation !== 'undefined') {
    showNavigation = webpageConfig.showNavigation
  }

  function renderEventContent(eventData: any) {
    const { id, translations, defaultLanguage } = eventData.event;
    const widgets = eventData.webpages[0].widgets;


    const commonContent = (
      <>
        <ContainedSection>
          {/* some content here */}
          <Helmet>
            <title>Apella: {translations[defaultLanguage].title}</title>
            <meta name="description" content="Veranstaltungs site" />
          </Helmet>
          <Header />
        </ContainedSection>
        <Content />
      </>
    );

    switch (id) {
      case 22:
        return (
          <>
            <ContainedSection>
              <Helmet>
                <title>Apella: {translations[defaultLanguage].title}</title>
                <meta name="description" content="Veranstaltungs site" />
              </Helmet>
              <Grid container>
                <Grid item md={12} xs={12} textAlign={"center"}>
                  <Typography variant="h1primary" color="primary" sx={{ wordBreak: 'break-word', marginBottom: 0 }}>Veranstaltung</Typography>
                  <Typography variant="h1primary" color="primary" sx={{ wordBreak: 'break-word', fontSize: '30px' }}>Veranstaltung Description</Typography>
                  <Grid container alignContent={"center"}>
                    <Grid item xs={12}>
                      <AnmeldenButton />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </ContainedSection>
            <ContainedSection color="#1f4034" id='anmelden' >
              <SectionTitle color="common.white" text="HIER BITTE ZUR VERANSTALTUNG ANMELDEN" />
              <Box>
                {eventData.event.id && (
                  <RegistrationForm eventId={eventData.event.id.toString()} anchorName={registrierungAnchor} registrationWidgetId={eventData.registrationWidgetId} />
                )}

              </Box>
            </ContainedSection>
          </>
        );
      case 21:
        return <>
          {commonContent}
          <Anmelden eventData={eventData} />
          <DecImage />
        </>;
      default:
        return (<>
          <Helmet>
            <title>Apella: {translations[defaultLanguage].title}</title>
            <meta name="description" content="Veranstaltungs site" />
            
          </Helmet>
          {widgets.sort((a: WidgetRecord, b: WidgetRecord) => a.sortorder - b.sortorder).filter((widget: WidgetRecord) => widget.parentId === null).map(
            (widget: WidgetRecord, index: number) => {
              const children = widgets.filter((filterWidget: WidgetRecord) => filterWidget.parentId === widget.id);
              return (<WidgetField key={index} widget={widget} allWidgets={widgets} widgetChildren={children} pageWidth={pageWidth}/>)
            }
          )}
        </>)
    }
  }

  console.log('showNavigation', showNavigation);
  

  return (
    <ThemeProvider theme={createTheme(createThemeObjectFromConfig(eventData.event.jsonConfig))}>
      <Helmet>
        <style>{
        `body { background-color: ${(webpageConfig && webpageConfig.backgroundColor) ? webpageConfig.backgroundColor : 'white'}; }`
        }</style>
      </Helmet>
      <Box id="event-page" className="eventpage">
        <ScrollIndicator />
        {showNavigation && <HeaderNavigation />}
        {renderEventContent(eventData)}
        <FooterNavigation />
      </Box>
    </ThemeProvider >
  )
}

export default EventPage;
