import React from "react";
import { Box, IconButton } from "@mui/material";
import CloudSyncIcon from "@mui/icons-material/CloudSync";
import SearchField from "./SearchField";

const ScanListControls = ({
  searchValue,
  setSearchValue,
  inputRef,
  clearFn,
  syncModalShowFn,
}: {
  searchValue: string;
  setSearchValue: Function;
  inputRef: React.RefObject<HTMLInputElement>;
  clearFn: Function;
  syncModalShowFn: Function;
}) => {
  return (
    <Box padding={"1rem"} display={"flex"} flexDirection="column">
      <Box
        display="flex"
        flex={1}
        flexDirection="row"
        justifyContent="space-between"
      >
        <SearchField
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          inputRef={inputRef}
          clearFn={() => {
            setSearchValue('')
          }}
        />
        <Box display={"flex"} flexDirection={"row"} alignItems="center">
          <Box>
            <IconButton
              onClick={() => {
                syncModalShowFn(true);
              }}
            >
              <CloudSyncIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ScanListControls;
