import { Box, Typography } from "@mui/material"
import React from "react"
import { ReactWidget } from "../../Interfaces";
import { addConfigToStyle } from "../../../../helpers/Helpers";
import { generateMarginString } from "../../../events/event/website/components/widgets/options/MarginSelector";

const SubtitleWidget = ({ widget }: ReactWidget) => {
    const config = widget.config || {};
    const textAlign = config['textAlign'];
    const fontColor = config['fontColor'];
    const fontSize = config['fontSize'];
    
    
    const styles: any = {};
    if (textAlign) {
        styles.textAlign = textAlign;
    }

    if (fontColor) {
        styles.color = fontColor;
    }

    if (fontSize) {
        styles.fontSize = fontSize;
    }

    const margin = config['margin'];
    if (margin) {
        styles.margin = generateMarginString(margin);
    }

    
    if (widget.translations[widget.defaultLanguage].content) {
        return <Typography variant="h3" style={{ ...styles}}>{widget.translations[widget.defaultLanguage].content}</Typography>
    } else {
        return <Box>{widget.type}</Box>
    }
}

export default SubtitleWidget;