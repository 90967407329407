import { createTheme } from '@mui/material';
import { CSSProperties } from '@mui/material/styles/createTypography';
import * as Yup from 'yup';
import { fontList } from '../fonts/fonts';

export interface ValidationTypes {
  required?: boolean;
  requiredText?: string;
  max?: number;
  maxText?: string
  email?: boolean;
  emailText?: string;
  type?: string;
  min?: number;
  minText?: string
  depends?: string
}

export const stripUnits = (string: string) => {
  const match = /([0-9.]+)(PX|REM|EM)$/.exec(string);
  if (match) {
    return Number(match[1])
  } else {
    return null;
  }
}

export const buildYupStringSchema = (config: ValidationTypes): any => {
  const {
    required = false,
    requiredText = 'pflichtfeld',
    max,
    type,
    emailText = 'ungültige E-Mail-Adresse',
    min,
  } = config;

  const maxText = typeof max === 'number' ? `Bitte wählen Sie maximal ${max} Aktivitäten aus` : 'Bitte wählen Sie maximal 2 Aktivitäten aus';
  const minText = typeof max === 'number' ? `Bitte wählen Sie mindestens ${min} Aktivitäten aus` : 'Bitte wählen Sie mindestens 2 Aktivitäten aus'
  let schema;


  if (type === 'multivalues') {
    schema = Yup.array();
  } else {
    schema = Yup.string();

    if (type === 'emailfield') {
      schema = schema.email(emailText)
    }
  }

  if (required) {
    schema = schema.required(requiredText)
  }

  if (max) {
    schema = schema.max(max, maxText);
  }

  if (min) {
    schema = schema.min(min, minText)
  }

  //Override everything if checkbox
  if (type === 'checkbox') {
    schema = Yup.boolean()
  }

  return schema;
};

export function getBaseHref(url: string) {
  const parsedUrl = new URL(url);
  return parsedUrl.origin;
}

export function addMultiConfigToStyle(keyString: string[], configObject: any) {
  let stylesObject = {};
  keyString.forEach((keyString) => {
    addConfigToStyle(keyString, configObject, stylesObject);
  });
  return stylesObject;
}

export function addConfigToStyle(key: string, configObject: any, stylesObject: any) {
  configObject[key] && (stylesObject[key] = configObject[key]);
}

export const createThemeObjectFromConfig = (config: string) => {
  if (config) {
    const parsedConfig = JSON.parse(config);
    const defaultMuiTheme = createTheme();

    const findFont = (group: string) => {
      if (parsedConfig) {
        const foundFont = fontList.find((font) => font.name === parsedConfig.fonts[group]);

        if (foundFont) {

          if (foundFont.google) {
            // Create a new style element
            const style = document.createElement('style');

            style.textContent = `@import url('https://fonts.googleapis.com/css2?family=${foundFont.name.replace(/ /g, '+')}&display=swap')`;
            //style.textContent = `@import url('https://fonts.googleapis.com/css2?family=:ital,wght@0,100..700;1,100..700&display=swap');`;
            document.head.appendChild(style);
          }
          return foundFont.value;
        } else {
          return 'Arial, sans-serif';
        }
      } else {
        return 'Arial, sans-serif';
      }
    }

    const h1Styling: CSSProperties = {
      fontSize: '3.875rem',
      textAlign: 'center',
      marginBottom: '3rem'
    }

    const bodyStyling: CSSProperties = {
      lineHeight: '1.5rem',
      fontSize: '1rem',
      fontWeight: 'normal',
      textAlign: 'left',
      fontFamily: findFont('secondary')
    }

    const defaultFonts = ["'Lato',sans-serif", "'Cardo',sans-serif"]

    return {
      typography: {
        h1: h1Styling,
        h1primary: { ...h1Styling, fontSize: '3.125rem', fontFamily: findFont('primary') },
        h2: {
          fontSize: '2.25rem',
          lineHeight: '2.25rem',
          fontWeight: 'normal',
          marginBottom: '2.25rem'
        },
        h3: {
          fontSize: '1.5rem',
          fontWeight: 'bold',
          marginBottom: '1.5rem'
        },
        body1: bodyStyling,
        body2: {
          ...bodyStyling,
          fontWeight: 300
        },
        fontFamily: defaultFonts.join(','),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            iconSizeMedium: {
              '& > *:first-of-type': {
                fontSize: '1.5rem',
                fontWeight: 'bold',
                stroke: 'white',
                strokeWidth: 2
              },
            },
          },
        },
        MuiTypography: {
          defaultProps: {
            variantMapping: {
              h1primary: 'h1',
            },
          }
        },
      },
      root: {
        'div p': {
          padding: 0,
        },
      },
      palette: {
        primary: {
          main: '#333',
        },
        secondary: {
          main: '#FF6300',
          contrastText: 'white'
        },
        apellagreen: defaultMuiTheme.palette.augmentColor({
          color: {
            main: '#1F4034',
          }
        })
      }
    }
  }
}