import React, { useEffect, useState } from "react";
import {
  getGridTranslations,
  getTranslations,
} from "../../../../translations/Translations";
import { useRevalidator, useRouteLoaderData } from "react-router-dom";
import {
  ContactsRouterResponse,
  INewRecord,
  IRecord,
} from "../../../../interfaces/Interfaces";
import {
  DataGrid,
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
  GridRowParams,
} from "@mui/x-data-grid";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import ContactImportControl from "./ContactImportControl";
import { useFormik } from "formik";
import Registrationfield from "../../../../components/Registrationfield";
import SaveBar from "../../../../components/SaveBar";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";

interface ImportedContactsProps {
  routeLoader: string;
  registered: boolean;
}

function ImportedContacts() {
  const props: ImportedContactsProps = {
    registered: false,
    routeLoader: "importedcontacts",
  };

  const translations = getTranslations();
  const gridtranslations = getGridTranslations();
  const {
    registrations,
    registrationsCount,
    fields,
    eventId,
    registrationWidgetId,
  } = useRouteLoaderData(props.routeLoader) as ContactsRouterResponse;
  const revalidator = useRevalidator();
  const [open, setOpen] = useState<boolean>(false);
  const [confirmtitle, setConfirmtitle] = useState<string>("");
  const [confirmmessage, setConfirmmessage] = useState<string>("");
  const [confirmFunction, setConfirmFunction] = useState<Function>(
    () => () => {}
  );
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [rowCountState, setRowCountState] =
    useState<number>(registrationsCount);
  const [id, setId] = useState<number>(-1);
  const [myDialogTitle, setMyDialogTitle] = useState<string>(
    translations["contacts.newcontacttitle"]
  );
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });
  const [rows, setRows] = useState<IRecord[]>(
    registrations.map((reg) => {
      return {
        id: reg.id,
        ...reg.values,
      };
    })
  );

  useEffect(() => {
    setRows(
      registrations.map((reg) => {
        return {
          id: reg.id,
          ...reg.values,
        };
      })
    );
  }, [registrations]);

  const getFlatObj = (obj: INewRecord) => {
    var result: INewRecord = {};
    fields.forEach((field) => {
      result["field" + field.id] = obj["field" + field.id] || "";
    });
    return result;
  };
  const [initialValues, setInitialValues] = useState<INewRecord>(
    getFlatObj({})
  );

  const generateRegistration = (values: INewRecord) => {
    var result: INewRecord = {};
    if (id > -1) {
      result.id = id;
    }
    result.registrationfieldValues = { ...values };
    result.eventId = eventId;
    result.widgetId = registrationWidgetId;
    result.attending = true;
    result.activityIds = [];
    result.contact = !props.registered;
    //result.contactlistId = props.contactlistId;
    var emailfieldId = 0;
    fields.forEach((field) => {
      if (field.defaultfield === "email") {
        emailfieldId = field.id;
      }
    });
    result.email = values["field" + emailfieldId];
    return result;
  };

  const getRegistrationShort = (registration: IRecord) => {
    const firstnameField = fields.find(
      (field) => field.defaultfield === "firstname"
    );
    const lastnameField = fields.find(
      (field) => field.defaultfield === "lastname"
    );
    const emailField = fields.find((field) => field.defaultfield === "email");

    let str = "\n\n";
    if (firstnameField) {
      str =
        str +
        firstnameField.translations[firstnameField.defaultLanguage].title +
        ": " +
        registration.values["field" + firstnameField.id] +
        "\n";
    }
    if (lastnameField) {
      str =
        str +
        lastnameField.translations[lastnameField.defaultLanguage].title +
        ": " +
        registration.values["field" + lastnameField.id] +
        "\n";
    }
    if (emailField) {
      str =
        str +
        emailField.translations[emailField.defaultLanguage].title +
        ": " +
        registration.values["field" + emailField.id] +
        "\n";
    }
    return str;
  };

  const deleteRegistration = (registration: IRecord) => {
    setConfirmtitle(translations["contacts.confirmDeleteTitle"]);
    setConfirmmessage(
      translations["contacts.confirmDeleteMessage"] +
        getRegistrationShort(registration)
    );
    setConfirmFunction(() => () => {
      setOpenConfirm(false);
      fetch("/api/registrations/" + registration.id, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("jwt"),
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((jsondata) => {
          if (jsondata.success) {
            revalidator.revalidate();
          }
        });
    });
    setOpenConfirm(true);
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (id > -1) {
        fetch("/api/registrations/" + id, {
          method: "PUT",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("jwt"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify(generateRegistration(values)),
        })
          .then((res) => res.json())
          .then((jsondata) => {
            if (jsondata.success) {
              revalidator.revalidate();
            }
          })
          .finally(() => {
            formik.setSubmitting(false);
            formik.resetForm({
              values: getFlatObj(values),
            });
            setOpen(false);
          });
      } else {
        fetch("/api/registrations/manual", {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("jwt"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify(generateRegistration(values)),
        })
          .then((res) => res.json())
          .then((jsondata) => {
            if (jsondata.success) {
              setId(jsondata.data.id);
              revalidator.revalidate();
            }
          })
          .finally(() => {
            formik.setSubmitting(false);
            formik.resetForm({
              values: getFlatObj(values),
            });
            setOpen(false);
          });
      }
    },
  });

  let columns: (GridColDef | GridActionsColDef)[] = [
    {
      field: "id",
      headerName: translations["contacts.nofields"],
      flex: 1,
      sortable: false,
    },
  ];

  if (fields.length > 0) {
    columns = fields
      .filter(
        (field) =>
          field.type !== "divider" &&
          field.type !== "text" &&
          field.type !== "title"
      )
      .map((field) => {
        return {
          field: "field" + field.id,
          headerName: field.translations[field.defaultLanguage].title,
          flex: 1,
        };
      });
    columns.push({
      field: "actions",
      type: "actions",
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          onClick={() => {
            let registration = registrations.find(
              (registration) => registration.id === params.id
            );
            if (typeof registration !== "undefined") {
              setId(registration.id);
              let obj = getFlatObj(registration.values as INewRecord);
              formik.resetForm({
                touched: {},
                errors: {},
              });
              setInitialValues(obj);
              formik.setValues(obj, false);
              setMyDialogTitle(translations["contacts.editcontacttitle"]);
              setOpen(true);
            }
          }}
          label={translations["contacts.edit"]}
        />,
        <GridActionsCellItem
          icon={<ClearIcon />}
          onClick={() => {
            let registration = registrations.find(
              (registration) => registration.id === params.id
            );
            if (typeof registration !== "undefined") {
              deleteRegistration(registration);
            }
          }}
          label={translations["contacts.delete"]}
        />,
      ],
    });
  }

  return (
    <>
      <Box p={1} gap={1} display="flex" flexDirection="row">
        <ContactImportControl
          registered={props.registered}
          onUploadComplete={() => {
            revalidator.revalidate();
          }}
          eventId={eventId}
          registrationFields={fields.filter(
            (field) =>
              field.type !== "divider" &&
              field.type !== "text" &&
              field.type !== "title"
          )}
        />
        <Button
          variant="contained"
          onClick={() => {
            setId(-1);
            let obj = getFlatObj({});
            formik.resetForm({
              values: obj,
              touched: {},
              errors: {},
            });
            setInitialValues(obj);
            formik.setValues(obj);
            setMyDialogTitle(translations["contacts.newcontacttitle"]);
            setOpen(true);
          }}
        >
          {translations["contacts.add"]}
        </Button>
      </Box>
      <DataGrid
        rowCount={rowCountState}
        rows={rows}
        columns={columns}
        localeText={gridtranslations}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={(m) => {
          console.log(m);
        }}
      ></DataGrid>
      <Dialog
        className="window"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        scroll="body"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>{myDialogTitle}</DialogTitle>
        <DialogContent dividers={false}>
          <form>
            <Box display="flex" flexDirection="column">
              {fields.map((value) => (
                <Registrationfield
                  field={value}
                  formik={formik}
                  key={value.id}
                />
              ))}
            </Box>
          </form>
        </DialogContent>
        <SaveBar
          formik={formik}
          manualSubmit={true}
          onCancel={() => {
            setOpen(false);
          }}
        ></SaveBar>
      </Dialog>
      <Dialog
        className="window"
        open={openConfirm}
        onClose={() => {
          setOpenConfirm(false);
        }}
        scroll="body"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>{confirmtitle}</DialogTitle>
        <DialogContent dividers={false}>
          <DialogContentText style={{ whiteSpace: "pre-line" }}>
            {confirmmessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              confirmFunction();
            }}
          >
            {translations["contacts.confirm"]}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setOpenConfirm(false);
            }}
          >
            {translations["contacts.cancel"]}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ImportedContacts;
