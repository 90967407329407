import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material"
import React from "react"
import { ReactWidgetWithStyles } from "../../Interfaces";
import withStandardStyles from "./withStandardStyles";

const IconListItem = ({src, text}:{src: string, text: string}) => {
    return (
        <ListItem sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', padding: 0, margin: 0, marginBottom: '1rem'}}>
            <ListItemIcon sx={{minWidth: '0', mr: '1rem'}}>
                <img src={src} height={25} width={25} />
            </ListItemIcon>
            <ListItemText sx={{margin: 0}}>
                {text}
            </ListItemText>
        </ListItem>
    )
}

const IconListWidget = ({ widget, standardStyles }: ReactWidgetWithStyles) => {
    const config = widget.config || {};
    const align = config['align'];
    const textAlign = config['textAlign'];
    const listItems = config['listitems'];
    const listImage = config['listimage'] || 'test';
    const fontColor = config['fontColor'];

    const styles: any = { };

    if (textAlign) {
        styles.textAlign = textAlign;
    }

    if (fontColor) {
        styles.color = fontColor;
    }


    if (listItems ) {
        return (
            <Box sx={{ textAlign: align }}>
                <List sx={{...styles}}>
                    {listItems.map((item: any, index: number) => <IconListItem key={index} src={listImage} text={item.text}/>)}
                </List>
            </Box>
        );
    } else {
        return <Box sx={styles}>{widget.type}</Box>
    }
}

export default withStandardStyles(IconListWidget);