import React from "react";
import {
  Outlet,
  matchPath,
  useLocation,
  useNavigate,
  useRouteLoaderData,
} from "react-router-dom";
import { Box, IconButton, Tab, Tabs, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import {
  EventRecord,
  EventRouterResponse,
} from "../../../interfaces/Interfaces";
import BlockIcon from "@mui/icons-material/Block";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { getTranslations } from "../../../translations/Translations";
import TopToolbar from "../../../components/TopToolbar";
import { CustomNavLink } from "../../../components/CustomNavLink";
import {
  formatTime,
  localeFormatDate,
  parseDate,
  parseTime,
} from "../../../helpers/DateFunctions";

function Event() {
  const translations = getTranslations();
  const navigate = useNavigate();
  const location = useLocation();
  const { event } = useRouteLoaderData("event") as EventRouterResponse;
  const urls = [
    "/admin/events/" + event.id,
    "/admin/events/" + event.id + "/details",
    "/admin/events/" + event.id + "/sessions",
    "/admin/events/" + event.id + "/website",
    "/admin/events/" + event.id + "/contacts",
    "/admin/events/" + event.id + "/mailings",
    "/admin/events/" + event.id + "/chat",
    "/admin/events/" + event.id + "/budget",
    "/admin/events/" + event.id + "/statistics",
    "/admin/events/" + event.id + "/media",
  ];
  const getActiveUrl = () => {
    let active = [...urls].reverse().filter((url) => {
      return matchPath(url + "/*", location.pathname);
    });
    if (active.length === 0) {
      return urls[0];
    } else {
      return active[0];
    }
  };

  const getEventDetails = (event: EventRecord) => {
    let startdate = localeFormatDate(parseDate(event.startdate));
    let enddate = localeFormatDate(parseDate(event.enddate));
    let starttime = formatTime(parseTime(event.starttime));
    let endtime = formatTime(parseTime(event.endtime));

    return (
      <>
        <Typography fontSize={"24px"} component="span">
          #{event.id}: {event.translations[event.defaultLanguage].title}
        </Typography>
        <span className="eventType">{translations[event.type]}</span>
        {event.blocker ? (
          <>
            <BlockIcon />
            {" " + translations["events.blocker"] + " "}
          </>
        ) : (
          ""
        )}
        <CalendarTodayIcon />
        {" " + startdate}
        {startdate !== enddate ? "-" + enddate : ""}
        {starttime !== null ? (
          <>
            {" "}
            <AccessTimeIcon />
            {" " + starttime}
            {starttime !== endtime && endtime !== null ? "-" + endtime : ""}
          </>
        ) : null}
      </>
    );
  };

  return (
    <>
      <TopToolbar
        title={
          <Box
            flex={1}
            display={"flex"}
            flexDirection="row"
            gap={1}
            pl={2}
            pr={2}
            pt={1}
          >
            <IconButton
              onClick={() => {
                navigate("/admin/events", { replace: false });
              }}
            >
              <ArrowBack />
            </IconButton>
            <Box className="eventdetails" flex={1}>
              {getEventDetails(event)}
            </Box>
          </Box>
        }
      />
      <Box>
        <Tabs className="tabs" value={getActiveUrl()}>
          <Tab
            label={translations["event.dashboard"]}
            component={CustomNavLink}
            to={urls[0]}
            value={urls[0]}
            replace={false}
          />
          <Tab
            label={translations["event.details"]}
            component={CustomNavLink}
            to={urls[1]}
            value={urls[1]}
            replace={false}
          />
          <Tab
            label={translations["event.activities"]}
            component={CustomNavLink}
            to={urls[2]}
            value={urls[2]}
            replace={false}
          />
          <Tab
            label={translations["event.website"]}
            component={CustomNavLink}
            to={urls[3]}
            value={urls[3]}
            replace={false}
          />
          <Tab
            label={translations["event.contacts"]}
            component={CustomNavLink}
            to={urls[4]}
            value={urls[4]}
            replace={false}
          />
          <Tab
            label={translations["event.mailtexts"]}
            component={CustomNavLink}
            to={urls[5]}
            value={urls[5]}
            replace={false}
          />
          {/*}<Tab
            label={translations["event.chat"]}
            component={CustomNavLink}
            to={urls[6]}
            value={urls[6]}
            replace={false}
      />*/}
          <Tab
            label={translations["event.accounting"]}
            component={CustomNavLink}
            to={urls[7]}
            value={urls[7]}
            replace={false}
          />
          {/*}<Tab
            label={translations["event.statistics"]}
            component={CustomNavLink}
            to={urls[8]}
            value={urls[8]}
            replace={false}
    />*/}
          <Tab
            label={translations["event.media"]}
            component={CustomNavLink}
            to={urls[9]}
            value={urls[9]}
            replace={false}
          />
        </Tabs>
      </Box>
      <Box display="flex" overflow="hidden" flex={1}>
        <Outlet />
      </Box>
    </>
  );
}

export default Event;
