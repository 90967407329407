import React, { useState, ReactNode  } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


interface ChoiceDialogProps {
    open: [boolean, Function]
    title: string,
    description: string,
    confirmText?: string,
    declineText?: string,
    buttons: ReactNode[];
}

export default function ChoiceDialog(props: ChoiceDialogProps) {
    const [open, setOpen] = props.open;
    const {buttons} = props;
  
    const handleClose = () => {
      setOpen(false);
    };
  
    return (
        <Dialog
          maxWidth='sm'
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {props.title}
          </DialogTitle>
          <DialogContent sx={{paddingBottom: 0}}>
            <DialogContentText id="alert-dialog-description" dangerouslySetInnerHTML={{ __html: props.description }} />
          </DialogContent>
          <DialogActions sx={{padding: '24px'}}>
            {buttons.map((button, index) => (
            <React.Fragment key={index}>
                {button}
            </React.Fragment>
            ))}
          </DialogActions>
        </Dialog>
    );
  }