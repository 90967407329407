import { Box } from "@mui/material"
import React from "react"
import { FieldRecordWidget } from "../../../../../../interfaces/Interfaces";

const Divider = ({ registrationfield }: FieldRecordWidget) => {
    return (
        <Box className="divider"></Box>
    )
}

export default Divider;