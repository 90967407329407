import React, { ReactNode } from "react";
import { Box, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';


const SidePanel = ({ onClose, children, sx }: { onClose: Function, children: ReactNode, sx?: any }) => {
    return (
        <Box sx={sx} className="website-side-panel" overflow="scroll">
            <Box className="website-side-panel-controls" sx={{position: 'fixed', right: '1rem'}}>
                <IconButton sx={{ padding: 0 }} onClick={() => { onClose() }}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Box sx={{height: '100%', boxSizing: 'border-box'}}>
                {children}
            </Box>
        </Box>
    )
}

export default SidePanel;