import React from "react";
import {
  Box,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { getTranslations } from "../../../../translations/Translations";
import {
  EventRouterResponse,
  IRecord,
} from "../../../../interfaces/Interfaces";
import { useRevalidator, useRouteLoaderData } from "react-router-dom";
import { useFormik } from "formik";
import SaveBar from "../../../../components/SaveBar";
import UploadControl from "../../../../components/UploadControl";
import * as Yup from "yup";

function VenueTab() {
  const translations = getTranslations();
  const { event } = useRouteLoaderData("event") as EventRouterResponse;
  const revalidator = useRevalidator();

  const getFlatObj = (obj: IRecord) => {
    return {
      id: obj.id,
      venueOrdered: obj.venueOrdered,
      venueDone: obj.venueDone,
      venueTech: obj.venueTech,
      venueInfo: obj.venueInfo || "",
      venueFileId: obj.venueFileId,
    };
  };

  const formik = useFormik({
    initialValues: getFlatObj(event),
    validationSchema: Yup.object({
      venueOrdered: Yup.boolean(),
      venueDone: Yup.boolean(),
      venueTech: Yup.boolean(),
      venueInfo: Yup.string().notRequired(),
      venueFileId: Yup.string().notRequired(),
    }),
    onSubmit: (values) => {
      fetch("/api/events/" + event.id + "/", {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("jwt"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
        .then((res) => res.json())
        .then((jsondata) => {
          if (jsondata.success) {
            revalidator.revalidate();
          }
        })
        .finally(() => {
          formik.setSubmitting(false);
          formik.resetForm({
            values: values,
          });
        });
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{ display: "flex", flexGrow: 1, overflow: "scroll" }}
    >
      <Box display="flex" flexDirection="column" flex={1}>
        <Box display="flex" flexDirection="row">
          <Box flex="1" display="flex" flexDirection="column" gap={1} p={2}>
            <Box display="flex" flexDirection={"row"}>
              <Box width="260px" flexShrink={0} className="fieldlabel">
                <Typography>{translations["venuetab.ordered"]}</Typography>
              </Box>
              <Box className="switchlabel">
                {translations["venuetab.orderedno"]}
              </Box>
              <Box flex={1}>
                <FormControlLabel
                  control={
                    <Switch
                      name="venueOrdered"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.venueOrdered}
                    />
                  }
                  label={translations["venuetab.orderedyes"]}
                />
              </Box>
            </Box>
            <Box display="flex" flexDirection={"row"}>
              <Box width="260px" flexShrink={0} className="fieldlabel">
                <Typography>{translations["venuetab.tech"]}</Typography>
              </Box>
              <Box className="switchlabel">
                {translations["venuetab.techno"]}
              </Box>
              <Box flex={1}>
                <FormControlLabel
                  control={
                    <Switch
                      name="venueTech"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.venueTech}
                    />
                  }
                  label={translations["venuetab.techyes"]}
                />
              </Box>
            </Box>
          </Box>
          <Box flex="1" display="flex" flexDirection="column" gap={1} p={2}>
            <Box display="flex" flexDirection={"row"}>
              <Box width="260px" flexShrink={0} className="fieldlabel">
                <Typography>{translations["venuetab.done"]}</Typography>
              </Box>
              <Box className="switchlabel">
                {translations["venuetab.doneno"]}
              </Box>
              <Box flex={1}>
                <FormControlLabel
                  control={
                    <Switch
                      name="venueDone"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.venueDone}
                    />
                  }
                  label={translations["venuetab.doneyes"]}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection={"row"} p={2}>
          <Box width="260px" flexShrink={0} className="fieldlabel">
            <Typography>{translations["venuetab.locationinfo"]}</Typography>
          </Box>
          <TextField fullWidth multiline={true} minRows={3}></TextField>
        </Box>
        <Box display="flex" flexDirection={"row"} p={2} flex={1}>
          <Box width="260px" flexShrink={0} className="fieldlabel">
            <Typography>{translations["venuetab.offer"]}</Typography>
          </Box>
          <UploadControl
            name="venueFileId"
            purpose="venue"
            value={formik.values.venueFileId}
            eventId={event.id}
            setFieldValue={formik.setFieldValue}
            onUploadComplete={(response) => {
              fetch("/api/events/" + event.id + "/", {
                method: "PUT",
                headers: {
                  Accept: "application/json",
                  Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  id: event.id,
                  language: event.language,
                  defaultLanguage: event.defaultLanguage,
                  venueFileId: response.publicId,
                }),
              })
                .then((res) => res.json())
                .then((jsondata) => {
                  if (jsondata.success) {
                    revalidator.revalidate();
                  }
                });
            }}
            onDeleteComplete={() => {
              fetch("/api/events/" + event.id + "/", {
                method: "PUT",
                headers: {
                  Accept: "application/json",
                  Authorization: "Bearer " + sessionStorage.getItem("jwt"),
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  id: event.id,
                  language: event.language,
                  defaultLanguage: event.defaultLanguage,
                  venueFileId: "",
                }),
              })
                .then((res) => res.json())
                .then((jsondata) => {
                  if (jsondata.success) {
                    revalidator.revalidate();
                  }
                });
            }}
          />
        </Box>

        <SaveBar formik={formik} />
      </Box>
    </form>
  );
}

export default VenueTab;
