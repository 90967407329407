import { AuthContextData, UserRecord } from "../interfaces/Interfaces";

const dofetch = async (
  url: string,
  method: string,
  bodyjson: { [key: string]: any }
): Promise<any> => {
  const response = await fetch(url, {
    method,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(bodyjson),
  });
  return await response.json();
};

interface EventRecord {
  id: string;
}

interface WebpageRecord {
  id: string;
}

interface WidgetRecord {
  id: string;
}

const formatDate = (date: Date): string => {
  return date.toISOString();
};

interface CreateEventOptions {
  defaultLanguage: string,
  language: string,
  title: string,
  theme: string,
  type: string,
  startdate: string | null,
  enddate: string | null,
  starttime: string | null,
  endtime: string | null
}

const defaultCreateOptions = {
  defaultLanguage: "de",
  language: "de",
};

const handleRequestError = (error: any) => {
  console.error(error);
  throw error;
}



export const createEvent = async (auth: AuthContextData | null, options: CreateEventOptions): Promise<EventRecord> => {
  try {
    const eventResult = await dofetch("/api/events", "post", {
      ...options,
      contextId: auth?.user.contextId,
    });

    return eventResult.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createWebpage = async (
  eventId: string,
  title: string
): Promise<WebpageRecord> => {
  try {
    const result = await dofetch("/api/webpages", "post", {
      defaultLanguage: "de",
      language: "de",
      title,
      eventId,
      sortorder: 1,
    });

    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createApellaRegistrationFields = async (
  eventId: string,
  widgetId: string
): Promise<void> => {
  const fieldsData = [
    {
      title: "Vorname",
      defaultField: "firstname",
      type: "textfield",
      sortorder: 1,
    },
    {
      title: "Nachname",
      defaultField: "lastname",
      type: "textfield",
      sortorder: 2,
    },
    {
      title: "E-Mail",
      defaultField: "email",
      type: "emailfield",
      sortorder: 3,
    },
    {
      title: "Telefon",
      defaultField: "cellphonenumber",
      type: "textfield",
      sortorder: 4,
    },
    {
      title: "Makler Nummer",
      defaultField: "maklernr",
      type: "textfield",
      sortorder: 5,
    },
  ];

  const promises = fieldsData.map(async (fieldData) => {
    try {
      await dofetch("/api/registrationfields", "post", {
        ...defaultCreateOptions,
        ...fieldData,
        eventId,
        widgetId,
      });
    } catch (error) {
      handleRequestError(error);
    }
  });

  await Promise.all(promises);
};

export const createRegistrationField = async (
  eventId: string,
  widgetId: string,
  title: string,
  defaultField: string,
  type: string,
  sortorder: number
): Promise<void> => {
  try {
    await dofetch("/api/registrationfields", "post", {
      defaultLanguage: "de",
      language: "de",
      title,
      defaultField,
      type,
      eventId,
      widgetId,
      sortorder,
    });
  } catch (error) {
    handleRequestError(error);
  }
};

export const createRegistrationFormWidget = async (
  eventId: string,
  webpageId: string
): Promise<WidgetRecord> => {
  try {
    const result = await dofetch("/api/widgets", "post", {
      defaultLanguage: "de",
      language: "de",
      type: "content",
      eventId,
      webpageId,
      sortorder: 1,
    });

    return result.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export async function fetchDataFromUrl(url: string, options = {}) {
  const defaultOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
    },
    signal: null
  };

  const requestOptions = {
    ...defaultOptions,
    ...options,
  };

  try {
    const response = await fetch(url, requestOptions);
    const data = await response.json();
    if (data && data.data) {
      return data.data 
    } 
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}