
import React, { createContext, useState } from 'react';

export const MaskContext = createContext<MaskContextType | null>(null);

export interface MaskContextType {
    maskVisible: boolean,
    toggleMask: Function
}

export const MaskProvider = ({
    children,
}: {
    children: JSX.Element;
}) => {
    const [maskVisible, setMaskVisible] = useState(false);

    const toggleMask = (value: boolean) => {
        setMaskVisible(value);
    };

    const contextValue = {
        maskVisible: maskVisible,
        toggleMask: toggleMask
    };

    return (
        <MaskContext.Provider value={contextValue}>
            {children}
        </MaskContext.Provider>
    );
};