import React from "react";
import {
  Box,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { getTranslations } from "../../../../translations/Translations";
import { IRecord, SessionRecord } from "../../../../interfaces/Interfaces";
import { useRevalidator } from "react-router-dom";
import { useFormik } from "formik";
import SaveBar from "../../../../components/SaveBar";
import {
  formatDate,
  formatTime,
  parseDate,
  parseTime,
} from "../../../../helpers/DateFunctions";
import { DatePicker, TimeField } from "@mui/x-date-pickers";

interface EducationFormProps {
  session: SessionRecord;
}

function EducationForm(props: EducationFormProps) {
  const translations = getTranslations();
  const revalidator = useRevalidator();

  const getFlatObj = (obj: IRecord) => {
    return {
      id: obj.id,
      title: obj.translations[obj.defaultLanguage].title || "",
      shortDescription:
        obj.translations[obj.defaultLanguage].shortDescription || "",
      blockId: obj.blockId,
      description: obj.translations[obj.defaultLanguage].description || "",
      language: obj.defaultLanguage,
      defaultLanguage: obj.defaultLanguage,
      shownOnRegistration: obj.shownOnRegistration,
      startdate: parseDate(obj.startdate),
      starttime: parseTime(obj.starttime),
      enddate: parseDate(obj.enddate),
      endtime: parseTime(obj.endtime),
      locationId: obj.locationId,
      allowedRegistrations: obj.allowedRegistrations,
      price: obj.price || "",
      maxRegistrations: obj.maxRegistrations || "",
    };
  };

  const getSubmissableData = (obj: IRecord) => {
    return {
      ...obj,
      startdate: formatDate(obj.startdate),
      enddate: formatDate(obj.enddate),
      starttime: formatTime(obj.starttime),
      endtime: formatTime(obj.endtime),
    };
  };

  const formik = useFormik({
    initialValues: getFlatObj(props.session),
    onSubmit: (values) => {
      fetch("/api/activities/" + props.session.id + "/", {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("jwt"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(getSubmissableData(values)),
      })
        .then((res) => res.json())
        .then((jsondata) => {
          if (jsondata.success) {
            revalidator.revalidate();
          }
        })
        .finally(() => {
          formik.setSubmitting(false);
          formik.resetForm({
            values: values,
          });
        });
    },
  });

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{ display: "flex", flexGrow: 1, overflow: "scroll" }}
    >
      <Box flex="1" display="flex" flexDirection="column">
        <Box flex="1" display="flex" flexDirection="column" gap={1} p={2}>
          <Box display="flex" flexDirection={"row"} className="fieldlabel">
            <Box width="260px" flexShrink={0}>
              <Typography>{translations["sessionform.title"]}</Typography>
            </Box>
            <TextField
              fullWidth
              size="small"
              {...formik.getFieldProps("title")}
            ></TextField>
          </Box>
          <Box display="flex" flexDirection={"row"}>
            <Box width="260px" flexShrink={0} className="fieldlabel">
              <Typography>
                {translations["sessionform.shortdescription"]}
              </Typography>
            </Box>
            <TextField
              fullWidth
              multiline={true}
              minRows={3}
              {...formik.getFieldProps("shortDescription")}
            ></TextField>
          </Box>
          <Box display="flex" flexDirection={"row"}>
            <Box width="260px" flexShrink={0} className="fieldlabel">
              <Typography>{translations["sessionform.description"]}</Typography>
            </Box>
            <TextField
              fullWidth
              multiline={true}
              minRows={3}
              {...formik.getFieldProps("description")}
            ></TextField>
          </Box>
          <Box display="flex" flexDirection={"row"}>
            <Box width="260px" flexShrink={0} className="fieldlabel">
              <Typography>{translations["sessionform.start"]}</Typography>
            </Box>
            <Box display="flex" flexDirection={"row"} gap={1}>
              <DatePicker
                format="dd.MM.yyyy"
                slotProps={{
                  textField: {
                    size: "small",
                    name: "startdate",
                    onChange: formik.handleChange,
                    error: Boolean(
                      formik.touched.startdate && formik.errors.startdate
                    ),
                    onBlur: formik.handleBlur,
                  },
                }}
                value={formik.values.startdate}
              />
              <TimeField
                format="HH:mm"
                slotProps={{
                  textField: {
                    size: "small",
                    name: "starttime",
                    onChange: (eventorvalue) => {
                      formik.setFieldValue("starttime", eventorvalue);
                    },
                    error: Boolean(
                      formik.touched.starttime && formik.errors.starttime
                    ),
                    onBlur: formik.handleBlur,
                  },
                }}
                value={formik.values.starttime}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection={"row"}>
            <Box width="260px" flexShrink={0} className="fieldlabel">
              <Typography>{translations["sessionform.end"]}</Typography>
            </Box>
            <Box display="flex" flexDirection={"row"} gap={1}>
              <DatePicker
                format="dd.MM.yyyy"
                slotProps={{
                  textField: {
                    size: "small",
                    name: "enddate",
                    onChange: formik.handleChange,
                    error: Boolean(
                      formik.touched.enddate && formik.errors.enddate
                    ),
                    onBlur: formik.handleBlur,
                  },
                }}
                value={formik.values.enddate}
              />
              <TimeField
                format="HH:mm"
                slotProps={{
                  textField: {
                    size: "small",
                    name: "endtime",
                    onChange: (eventorvalue) => {
                      formik.setFieldValue("endtime", eventorvalue);
                    },
                    error: Boolean(
                      formik.touched.endtime && formik.errors.endtime
                    ),
                    onBlur: formik.handleBlur,
                  },
                }}
                value={formik.values.endtime}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection={"row"}>
            <Box width="260px" flexShrink={0} className="fieldlabel">
              <Typography>
                {translations["sessionform.shownonregistration"]}
              </Typography>
            </Box>
            <Box className="switchlabel">
              {translations["sessionform.shownonregistrationno"]}
            </Box>
            <Box flex={1}>
              <FormControlLabel
                control={
                  <Switch
                    name="shownOnRegistration"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    checked={formik.values.shownOnRegistration}
                  />
                }
                label={translations["sessionform.shownonregistrationyes"]}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection={"row"}>
            <Box width="260px" flexShrink={0} className="fieldlabel">
              <Typography>
                {translations["sessionform.allowedregistrations"]}
              </Typography>
            </Box>
            <Box className="switchlabel">
              {translations["sessionform.allowedregistrationsno"]}
            </Box>
            <Box flex={1}>
              <FormControlLabel
                control={
                  <Switch
                    name="allowedRegistrations"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    checked={formik.values.allowedRegistrations}
                  />
                }
                label={translations["sessionform.allowedregistrationsyes"]}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection={"row"} className="fieldlabel">
            <Box width="260px" flexShrink={0}>
              <Typography>{translations["sessionform.price"]}</Typography>
            </Box>
            <TextField
              size="small"
              {...formik.getFieldProps("price")}
            ></TextField>
          </Box>
          <Box display="flex" flexDirection={"row"} className="fieldlabel">
            <Box width="260px" flexShrink={0}>
              <Typography>
                {translations["sessionform.maxregistrations"]}
              </Typography>
            </Box>
            <TextField
              size="small"
              {...formik.getFieldProps("maxRegistrations")}
            ></TextField>
          </Box>
        </Box>
        <SaveBar formik={formik} />
      </Box>
    </form>
  );
}

export default EducationForm;
