import React, { useEffect } from "react";
import { Box, FormControl, TextField, Typography } from "@mui/material";
import { getTranslations } from "../../../../translations/Translations";
import { IRecord, LocationRecord } from "../../../../interfaces/Interfaces";
import { useRevalidator } from "react-router-dom";
import { useFormik } from "formik";
import SaveBar from "../../../../components/SaveBar";
import * as Yup from "yup";
import { DisplayErrors } from "../../../../components/DisplayErrors";

interface LocationFormProps {
  location: LocationRecord;
  onDelete: Function;
  setDirty: Function;
}

function LocationForm(props: LocationFormProps) {
  const translations = getTranslations();
  const revalidator = useRevalidator();
  const setDirty = props.setDirty;

  const getFlatObj = (obj: IRecord) => {
    return {
      id: obj.id,
      title: obj.translations[obj.defaultLanguage].title || "",
      street: obj.translations[obj.defaultLanguage].street || "",
      street2: obj.translations[obj.defaultLanguage].street2 || "",
      postalcode: obj.translations[obj.defaultLanguage].postalcode || "",
      city: obj.translations[obj.defaultLanguage].city || "",
      country: obj.translations[obj.defaultLanguage].country || "",
      description: obj.translations[obj.defaultLanguage].description || "",
      language: obj.defaultLanguage,
      defaultLanguage: obj.defaultLanguage,
    };
  };

  const formik = useFormik({
    initialValues: getFlatObj(props.location),
    validationSchema: Yup.object({
      title: Yup.string()
        .max(400, translations["errors.maxlength400"])
        .required(translations["errors.requiredfield"]),
      street: Yup.string()
        .max(400, translations["errors.maxlength400"])
        .required(translations["errors.requiredfield"]),
      street2: Yup.string()
        .max(400, translations["errors.maxlength400"])
        .notRequired(),
      postalcode: Yup.string()
        .max(20, translations["errors.maxlength20"])
        .required(translations["errors.requiredfield"]),
      city: Yup.string()
        .max(400, translations["errors.maxlength400"])
        .required(translations["errors.requiredfield"]),
      country: Yup.string()
        .max(400, translations["errors.maxlength400"])
        .notRequired(),
      description: Yup.string().notRequired(),
    }),
    onSubmit: (values) => {
      fetch("/api/locations/" + props.location.id + "/", {
        method: "PUT",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + sessionStorage.getItem("jwt"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
        .then((res) => res.json())
        .then((jsondata) => {
          if (jsondata.success) {
            revalidator.revalidate();
          }
        })
        .finally(() => {
          formik.setSubmitting(false);
          formik.resetForm({
            values: values,
          });
        });
    },
  });

  useEffect(() => {
    setDirty(formik.dirty);
  }, [formik.dirty, setDirty]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      style={{ display: "flex", flexGrow: 1, overflow: "scroll" }}
    >
      <Box flex="1" display="flex" flexDirection="column">
        <Box flex="1" display="flex" flexDirection="column" gap={1} p={2}>
          <Box display="flex" flexDirection={"row"} className="fieldlabel">
            <Box width="260px" flexShrink={0}>
              <Typography>{translations["locationform.title"]}</Typography>
            </Box>
            <FormControl fullWidth>
              <TextField
                size="small"
                error={Boolean(formik.touched.title && formik.errors.title)}
                {...formik.getFieldProps("title")}
              ></TextField>
              <DisplayErrors
                name="title"
                touched={Boolean(formik.touched.title)}
                errors={formik.errors.title}
              />
            </FormControl>
          </Box>
          <Box display="flex" flexDirection={"row"} className="fieldlabel">
            <Box width="260px" flexShrink={0}>
              <Typography>{translations["locationform.street"]}</Typography>
            </Box>
            <FormControl fullWidth>
              <TextField
                size="small"
                error={Boolean(formik.touched.street && formik.errors.street)}
                {...formik.getFieldProps("street")}
              ></TextField>
              <DisplayErrors
                name="street"
                touched={Boolean(formik.touched.street)}
                errors={formik.errors.street}
              />
            </FormControl>
          </Box>
          <Box display="flex" flexDirection={"row"} className="fieldlabel">
            <Box width="260px" flexShrink={0}>
              <Typography>{translations["locationform.street2"]}</Typography>
            </Box>
            <FormControl fullWidth>
              <TextField
                size="small"
                error={Boolean(formik.touched.street2 && formik.errors.street2)}
                {...formik.getFieldProps("street2")}
              ></TextField>
              <DisplayErrors
                name="street2"
                touched={Boolean(formik.touched.street2)}
                errors={formik.errors.street2}
              />
            </FormControl>
          </Box>
          <Box display="flex" flexDirection={"row"} className="fieldlabel">
            <Box width="260px" flexShrink={0}>
              <Typography>{translations["locationform.postalcode"]}</Typography>
            </Box>
            <FormControl sx={{ width: "200px", flexShrink: 0 }}>
              <TextField
                size="small"
                error={Boolean(
                  formik.touched.postalcode && formik.errors.postalcode
                )}
                {...formik.getFieldProps("postalcode")}
              ></TextField>
              <DisplayErrors
                name="postalcode"
                touched={Boolean(formik.touched.postalcode)}
                errors={formik.errors.postalcode}
              />
            </FormControl>
            <Box
              flexShrink={0}
              className="fieldlabel"
              ml={3}
              mr={1}
              width="100px"
            >
              <Typography>{translations["locationform.city"]}</Typography>
            </Box>
            <FormControl fullWidth>
              <TextField
                size="small"
                error={Boolean(formik.touched.city && formik.errors.city)}
                {...formik.getFieldProps("city")}
              ></TextField>
              <DisplayErrors
                name="city"
                touched={Boolean(formik.touched.city)}
                errors={formik.errors.city}
              />
            </FormControl>
          </Box>
          <Box display="flex" flexDirection={"row"}>
            <Box width="260px" flexShrink={0} className="fieldlabel">
              <Typography>{translations["locationform.country"]}</Typography>
            </Box>
            <FormControl fullWidth>
              <TextField
                size="small"
                error={Boolean(formik.touched.country && formik.errors.country)}
                {...formik.getFieldProps("country")}
              ></TextField>
              <DisplayErrors
                name="country"
                touched={Boolean(formik.touched.country)}
                errors={formik.errors.country}
              />
            </FormControl>
          </Box>
          <Box display="flex" flexDirection={"row"}>
            <Box width="260px" flexShrink={0} className="fieldlabel">
              <Typography>
                {translations["locationform.description"]}
              </Typography>
            </Box>
            <FormControl fullWidth>
              <TextField
                multiline={true}
                error={Boolean(
                  formik.touched.description && formik.errors.description
                )}
                minRows={3}
                {...formik.getFieldProps("description")}
              ></TextField>
              <DisplayErrors
                name="description"
                touched={Boolean(formik.touched.description)}
                errors={formik.errors.description}
              />
            </FormControl>
          </Box>
        </Box>
        <SaveBar
          formik={formik}
          onDelete={() => {
            props.onDelete();
          }}
        />
      </Box>
    </form>
  );
}

export default LocationForm;
