import { useContext } from 'react';
import { MaskContext, MaskContextType } from '../MaskProvider';


const useMaskContext = (): MaskContextType => {
  const context = useContext(MaskContext);

  if (!context) {
    throw new Error('useMaskContext must be used within a MaskProvider');
  }

  return context;
};

export default useMaskContext;